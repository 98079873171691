<template>
  <div class="footer-landing">
    <div class="flex-wrapper">
      <a @click="goToTerms()">Terms of Service</a>
      <a @click="goToPrivacy()">Privacy policy</a>
      <div class="copyright">© 2024 Gestaltra. All Rights Reserved.</div>
      <div>
        Want to chat?
        <a class="email-css" href="mailto:hello@gestaltra.com"
          >hello@gestaltra.com</a
        >
      </div>
      <div class="socials">
        <a href="https://www.instagram.com/gestaltra_/" target="_blank">
          <img
            alt="insta"
            src="../../assets/Insta.png"
          />
        </a>

        <a href="https://www.linkedin.com/company/gestaltra" target="_blank">
          <img
            alt="linkdIn"
            src="../../assets/linkdIn.png"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    goToTerms() {
      this.$router.resolve({ path: "/policies" });
      window.open(window.location.href + "policies?type=terms");
    },
    goToPrivacy() {
      this.$router.resolve({ path: "/policies" });
      window.open(window.location.href + "policies?type=privacy");
    },
    goToSignUp(data) {
      this.gaEventTrack(
        "Landing page signUp",
        data,
        "Landing page signUp link"
      );
      this.$router.push("/signup");
    },
  }
  
};
</script>
