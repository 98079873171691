<template>
  <div>
    <a-modal
      v-model="isShowData"
      @ok="handleOk"
      :maskClosable="false"
      :class="className"
      :centered="true"
      :mask="mask"
    >
      <div>
        <a-row v-if="logo" class="pb-2 center">
          <a-col :span="24">
            <img
              alt="blue_check"
              width="50px"
              height="50px"
              :src="require(`../assets/${logoName || 'blue_check'}.png`)"
            />
          </a-col>
        </a-row>
        <a-row v-if="loading" class="pb-2 center">
          <a-col :span="24">
            <img
              alt="loading"
              width="150px"
              height="150px"
              src="../assets/loading_2.gif"
            />
          </a-col>
        </a-row>
        <a-row class="mt-10 center">
          <a-col :span="24">
            <span class="header-txt-pop">{{ headerText }}</span>
          </a-col>
        </a-row>
        <a-row class="mt-20 center">
          <a-col :span="24">
            <p class="confirm-contain-txt" v-html="containText" />
          </a-col>
        </a-row>
        <a-row
          class="pb-2 center"
          :class="buttonText && buttonText != 'OK' ? 'mt-20' : null"
          v-if="buttonText"
        >
          <a-col :span="24">
            <a-button
              @click="handleOk"
              class="confirm-grey-btn"
              type="primary"
              >{{ buttonText }}</a-button
            >
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "ConfirmPopUp",
  data() {
    const isShowData = this.isShow;
    return {
      isShowData,
      visible: false,
    };
  },
  props: {
    mask: {
      type: Boolean,
      default: true,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    logo: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default: "",
    },
    containText: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    className: {
      type: String,
      default: null,
    },
    logoName:{
      type: String,
      default: "",
    }
  },
  watch: {
    isShow(newVal) {
      this.isShowData = newVal;
    },
  },
  methods: {
    showModal() {
      this.visible = this.isShowData;
    },
    handleOk() {
      this.isShowData = false;
      this.$emit("closePopup", false);
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .reset-popup {
      .ant-modal-mask {
        background-color: #edeef5 !important;
      }
    }
  .ant-modal-footer {
    display: none !important;
  }
  .ant-modal-close-x {
    display: none !important;
  }
  .ant-modal {
    width: 300px;
  }
  .ant-modal-content {
    padding: 4% 19%;
    border-radius: 10px !important;
  }

  button.confirm-grey-btn.ant-btn.ant-btn-primary {
    width: 150px !important;
    min-height: 45px !important;
    margin-top: 10px;
    font-size: 18px !important;
    background-color: #edeef5 !important;
  }
  button.confirm-grey-btn.ant-btn.ant-btn-primary:hover {
    background-color: #bfc3d6 !important;
  }
  
  .ant-modal {
    width: 550px !important;
    // margin-top: -55px !important;
  }
  @media screen and (max-width: 590px) {
    .ant-modal {
      width: 80%;
    }
  }
}

.min-error-popup .confirm-contain-txt {
  padding: 10px 48px !important;
}
</style>
