<template>
  <div class="">
    <common-pop-up
      v-if="otherPopUp"
      pop-email="Enter your new password."
      :pop-password-box="true"
      passwordPlaceholder="Password"
      confirm-password-placeholder="Confirm Password"
      pop-heading="Create new password"
      pop-button-text="Reset password"
      @click="onClick"
    />
    <error-pop-up :is-show="errorPopupFlag" @closePopup="closedErrorPopup" />
    <div class="bg-color-grey" v-if="!otherPopUp">
    <confirm-pop-up
      :mask="false"
      :is-show="confirmPassPopUp"
      class="reset-popup"
      :logo="true"
      header-text="Password reset"
      contain-text="Your password has been updated successfully."
      button-text="OK"
      @closePopup="closedPopup"
    />
    </div>
  </div>
</template>
<script>
import CommonPopUp from "@/components/CommonPopUp.vue";
import ConfirmPopUp from "@/components/ConfirmPopUp.vue";
import ErrorPopUp from "@/components/ErrorPopUp.vue";
import services from "../../../services/services.js";
export default {
  name: "CreateNewPassword",
  components: {
    CommonPopUp,
    ErrorPopUp,
    ConfirmPopUp,
  },
  data() {
    return {
      errorPopupFlag: false,
      services,
      url_id: "",
      url_iv: "",
      mainPopUp: true,
      otherPopUp: true,
      confirmPassPopUp: false,
    };
  },
  mounted() {
    if (this.$route.query) {
      this.url_id = this.$route.query.ID;
      this.url_iv = this.$route.query.iv;
    }
  },
  methods: {
    closedErrorPopup(val) {
      this.errorPopupFlag = val;
    },
    backToHome() {
      this.$router.push("/");
    },
    onClick(val) {
      this.forgotPasswordUpdate(val.password);
    },
    forgotPasswordUpdate(password) {
      let changePassword = password;
      let data = {
        userID: this.url_id,
        iv: this.url_iv,
        password: changePassword,
      };
      if (data) {
        this.services
          .post("user/createNewPassword", data)
          .then((res) => {
            if (res.status == 200) {
              this.mainPopUp = false;
              this.otherPopUp = false;
              this.confirmPassPopUp = true;
            }
          })
          .catch((err) => {
            //this.errorPopupFlag = true//;
          });
      }
    },
    closedPopup() {
      this.confirmPassPopUp = false;
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {


  .anticon.ant-input-password-icon,
  .ant-select-arrow-icon {
    color: #363644 !important;
  }
  .ant-btn-primary {
    width: 203px !important;
  }
}
.has-error .ant-form-explain,
.has-error .ant-form-split {
  text-align: left !important;
}
</style>