<template>
  <div>
    <div class="white-bg-color FeedbackSurvey">
      <a-row :gutter="24" style="margin-left: 12px; margin-right: 12px">
        <a-col :span="8" :lg="8" :xs="24"> </a-col>
        <a-col class="img-css" :span="8" :lg="8" :xs="24">
          <div>
            <img
              alt="gestaltra_logo_grey"
              class="logo"
              src="../../assets/gestaltra_logo_grey.png"
            />
          </div>
        </a-col>
        <a-col :span="8" :lg="8" :xs="24"> </a-col>
      </a-row>
      <div class="nom-class">
        <div class="pl-pr" style="padding-top: 49px; padding-bottom: 43px">
          <a-row class="mt-2">
            <a-col style="padding-top: 5px; text-align: center" :span="24">
              <span class="bold-right-txt">360 Feedback Survey</span>
            </a-col>
          </a-row>
          <a-row class="mt-2">
            <a-col style="padding-top: 5%; text-align: center" :span="24">
              <span
                style="color: #262626 !important"
                class="confirm-contain-txt"
                >Welcome to the 360 Feedback Survey for<span class="b-font">
                  {{ fullName }}</span
                >.<br />
                This should take approximately 5-10 minutes to complete.</span
              >
            </a-col>
          </a-row>
          <a-row class="mt-2">
            <a-col style="padding-top: 8%; text-align: center" :span="24">
              <a-button
                style="width: fit-content !important"
                :disabled="isDisabled"
                type="primary"
                @click="goToGetStarted"
                >Get started</a-button
              >
            </a-col>
          </a-row>
          <a-row class="mt-2">
            <a-col style="padding-top: 8%; text-align: center" :span="24">
              <span class="grey-sm-text">
                Your responses and information are securely collected and kept by Gestaltra in accordance with our <span @click="goToPrivacyAndPolicy" class="policy"
                  >Privacy Policy</span>. Your response will be reported to Gestaltra based on the specific rules for this survey. By continuing, you agree to our <span @click="goToTC" class="policy">Terms of Service</span>. If you have any additional questions, please contact us at <a class="" href="mailto:hello@gestaltra.com">hello@gestaltra.com</a>.    
              </span>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <confirm-pop-up
      :is-show="loadingFlag"
      :loading="true"
      header-text="Loading"
      contain-text="Hang tight..."
    />
  </div>
</template>
<script>
import services from "../../services/services";
import { pageview } from "vue-gtag";
import ConfirmPopUp from "@/components/ConfirmPopUp.vue";
export default {
  name: "FeedbackSurvey",
  components: { ConfirmPopUp },
  data() {
    return {
      isDisabled: true,
      pageview,
      loadingFlag: false,
      services,
      reviewerId:0,
      reviewersDetailsAll: null,
      fullName: null,
    };
  },
  created() {
    if (this.$route.query.surveyReviewerId) {
      this.$store.state.surveyReviewersId = this.$route.query.surveyReviewerId;
      this.$store.state.surveyId = this.$route.query.surveyId;
      this.$store.state.teamMemberId = this.$route.query.teamMemberId;
      this.reviewerId=this.$route.query.reviewerId;
      
      this.getReviewersById(this.reviewerId);
      let getTeamMemberId = this.$route.query.teamMemberId;
      let surveyId = this.$route.query.surveyId;
      this.checkDueDate(surveyId, getTeamMemberId);
      this.getReviewrsDetails(
        getTeamMemberId,
        this.$route.query.surveyReviewerId
      );
    }

    this.track();
  },
  methods: {
    track() {
      this.pageview("respondsurvey_main");
    },
    checkDueDate(surveyId, teamMemberId) {
      this.services
        .get(
          `survey/isSurveyDue?surveyId=${surveyId}&teamMemberId=${teamMemberId}`
        )
        .then((res) => {})
        .catch((err) => {
          if (err.response.status == 305) {
            this.$router.push("/survey-link-is-expired");
          }

          //this.errorPopupFlag = true//;
        });
    },
    getReviewersById(reviewerIdRes) {
      this.services
        .get(`survey/getReviewersById?reviewerId=${reviewerIdRes}`)
        .then((res) => {
          
            this.$store.state.reviewersDetailsEmail = res.data.body[0];
        })
        .catch((err) => {
          if (err.response.status == 305) {
          }

        });
    },
    getReviewrsDetails(getTeamMemberId, surveyReviewersId) {
      this.services
        .get(`team/teamMemberDetailsById?teamMemberId=${getTeamMemberId}`)
        .then((res) => {
          let resData = res.data.body;
          this.reviewersDetailsAll = resData[0];
          this.$store.state.jobId = this.reviewersDetailsAll.jobId;
          this.fullName =
            this.reviewersDetailsAll.firstName +
            " " +
            this.reviewersDetailsAll.lastName;
          this.$store.state.reviewersDetails = this.reviewersDetailsAll;

          this.services
            .get(
              `survey/isSurveySubmited?surveyReviewersId=${surveyReviewersId}`
            )
            .then((res) => {
              this.loadingFlag = false;
              this.isDisabled = false;
              let resData = res.data.message;
              if (resData) {
                this.$router.push("/survey-already-completed");
              }
            })
            .catch(() => {
              this.loadingFlag = false;
              this.isDisabled = false;
            });
        })
        .catch((err) => {
          this.loadingFlag = false;
        });
    },
    goToPrivacyAndPolicy() {
      window.open(window.location.origin + "/#/policies?type=privacy");
    },
    goToTC() {
      window.open(window.location.origin + "/#/policies?type=terms");
    },
    goToGetStarted() {
      this.$router.push("/survey-response");
    },
  },
};
</script>
<style lang="scss" scoped>

.pl-pr {
  padding-left: 21%;
  padding-right: 21%;
}
.confirm-contain-txt {
  font-size: 16px !important;
}
.b-font {
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #262626;
}
.policy {
  font-family: "Inter", fantasy !important;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #6454f0;
}
.grey-sm-text {
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #bfc3d6;
}
</style>