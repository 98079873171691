<template>
  <div class="step1-wrapper">
    <div class="content-wrapper">
      <p class="heading-wrapper">
        Specifically, which of the following makes
        {{ reviewersDetails.firstName }}&nbsp;{{ reviewersDetails.lastName }}
        effective at their role?
      </p>
      <p class="text-wrapper font-clr-black">
        Please select between 1-5 traits or behaviours. Hover over each for
        more detail. Add a custom attribute if it doesn’t appear on the list.
      </p>
    </div>
    <div class="list-wrapper">
      <a-list :grid="{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 4,
        lg: 4,
        xl: 4,
        xxl: 3,
      }" :data-source="traitsList">
        <a-list-item slot="renderItem" slot-scope="item, index" @click="selectedTraits(item, index)" class="pointer">
          <a-popover :overlay-style="{
            width: '300px',
            'word-break': 'normal',
          }">
            <template slot="content">
              <span class="bold">{{ item.traitsAndBehaviours }}:</span>
              <span>{{ item.description }}</span>
            </template>
            <span class="value-txt round-list-grey-faint" :class="item.selectedTraitsFlag ? 'active' : null"><span
                class="txt-css">{{ item.traitsAndBehaviours }}</span>
            </span></a-popover>
        </a-list-item>
      </a-list>
    </div>
    <div class="custom-list-wrapper" :style="customTraitsList.length > 0
        ? 'visibility: unset;'
        : 'visibility: hidden;'
      ">
      <a-list :grid="{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 4,
        lg: 4,
        xl: 4,
        xxl: 3,
      }" :data-source="customTraitsList">
        <a-list-item slot="renderItem" slot-scope="item, index" @click="selectedCustomTraits(item, index)"
          class="pointer">
          <span class="value-txt round-list-grey-faint" :class="item.selectedTraitsFlag ? 'active' : null"><span
              class="txt-css">{{ item.traitsAndBehaviours }}</span>
          </span>
        </a-list-item>
      </a-list>
    </div>

    <a-row :gutter="24" class="mt-2" style="margin-top: 15px">
      <a-col style="padding-top: 5px; height: auto" :span="12" :lg="18" :sm="18" :xs="24">
        <div>
          <a-form :form="form">
            <a-form-item style="margin-bottom: 0px">
              <div class="heading-wrapper" style="padding-right: 17px !important">
                Add an attribute:
              </div>
              <div :span="14" :lg="14" style="padding-right: 17px !important">
                <a-input class="input-txt-css" :value="formData.traitName"
                  @change="onChange($event.target.value, 'traitName')" />
                <div style="color: #f13c47" class="ant-form-explain" v-if="traitValidate">
                  Attribute already exists, please select from list above
                </div>
              </div>
              <div :span="2" :lg="2" style="text-align: center; padding-top: 4px">
                <a-icon class="plus-img-css" type="plus-circle" theme="filled" @click="addCustomTraitlocal($event)" />
              </div>
            </a-form-item>
          </a-form>
        </div>
      </a-col>
      <a-col class="pop-hover-css" :span="12" :lg="12" :sm="18" :xs="24">
        <div v-if="toolTipHover" class="defination-css">
          <p>
            <strong>{{ overTraitName }}:</strong>
          </p>
          <p>
            {{ overDescription }}
          </p>
        </div>
      </a-col>
    </a-row>

    <div class="content-wrapper specific-desk" style="display: unset">
      <p class="heading-wrapper">
        Any specific observations to support the above selections?
      </p>
      <p>
        <a-textarea class="text-area-css" :max-length="5000" :value="commentData"
          @change="onChangeTextAreaComment($event.target.value)" :rows="3" />
      </p>
    </div>
    <div class="content-wrapper specific-mob" style="display: none">
      <p class="heading-wrapper">
        Any specific observations to support the above selections?
      </p>
      <p>
        <a-textarea class="text-area-css" :max-length="5000" :value="commentData"
          @change="onChangeTextAreaComment($event.target.value)" :rows="3" />
      </p>
    </div>
    <confirm-pop-up :is-show="loadingFlag" :loading="true" header-text="Loading" contain-text="Hang tight..." />
  </div>
</template>
<script>
import ConfirmPopUp from "@/components/ConfirmPopUp.vue";
import services from "../../services/services";
import { event } from "vue-gtag";
export default {
  name: "SurveyStep2",
  components: { ConfirmPopUp },
  data() {
    return {
      event,
      reviewersDetails: {},
      jobId: 0,
      userId: 1,
      services,
      loadingFlag: false,
      traitValidate: false,
      overTraitName: "",
      overDescription: "",
      toolTipHover: false,
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      formData: {
        traitName: "",
        textAreaComment: "",
      },
      errorMsgShow: false,
      traitsList: [],
      commentData: "",
      customTraitsList: [],
      teamMemberList: [
        { heading: "Interpersonal" },
        { heading: "Communication" },
        { heading: "Problem-solving" },
        { heading: "Work ethic" },
        { heading: "Professionalism" },
      ],
    };
  },
  computed: {
    setup1Flag() {
      if (this.setup1Props) {
        for (let i = 0; i < this.teamMemberList.length; i++) {
          if (this.teamMemberList[i].selected_0) {
            this.teamMemberList[i].showError = true;
          }
        }
        //return this.teamMemberList;
      }
      return this.setup1Props;
    },
  },
  created() {
    this.reviewersDetails = this.$store.state.reviewersDetails;
    this.jobId = this.$store.state.jobId;
    this.getListTraitsByJobId();
  },
  activated() {
    this.commentData =
      this.$store.state.setup2Data &&
        this.$store.state.setup2Data.textAreaComment
        ? this.$store.state.setup2Data.textAreaComment
        : "";
  },
  methods: {
    gaEventTrack(data, action, category) {
      this.event(action, {
        event_category: category,
        event_label: data,
        value: 1,
      });
    },
    getListTraitsByJobId() {
      //this.loadingFlag = true;
      this.services
        .get(`survey/listTraitsByJobId?jobId=${this.jobId}`)
        .then((res) => {
          this.traitsList = res.data.body;
          this.$store.state.setup2Data.traitsList = this.traitsList;
          this.loadingFlag = false;
        })
        .catch((err) => {
          this.loadingFlag = false;
        });
    },
    mouseoverTrait(record, index) {
      this.overTraitName = record.traitsAndBehaviours;
      this.overDescription = record.description;
      this.toolTipHover = true;
    },
    mouseleave() {
      this.toolTipHover = false;
    },
    onChange(val, key) {
      this.traitValidate = false;
      this.form.setFieldsValue({
        [key]: val,
      });
      this.formData[key] = val;
    },
    onChangeTextAreaComment(val) {
      this.commentData = val;
      this.$store.state.setup2Data.textAreaComment = val;
    },
    createTraitsAssigment(record) {
      let listOfTraitsJobs = [],
        reqBody = {};

      let customObj = {
        traitId: record.id ? record.id : 0,
        isDeleted: true,
        jobId: this.jobId,
      };
      listOfTraitsJobs.push(customObj);

      reqBody.listOfTraitsJobs = listOfTraitsJobs;
      this.loadingFlag = true;
      if (listOfTraitsJobs.length > 0) {
        this.services
          .post("admin/traitsJobsAssigment", reqBody)
          .then((res) => {
            let result = res.body;
            this.customTraitsList.push({
              id: record.id,
              traitsAndBehaviours: record.traitsAndBehaviours,
              description: record.description,
              selectedTraitsFlag: true,
            });
            this.$store.state.setup2Data.customTraitsList =
              this.customTraitsList;

            this.formData = {
              traitName: "",
            };
            this.loadingFlag = false;
          })
          .catch((err) => {
            this.loadingFlag = false;
          });
      }
    },
    addTraits() {
      let traitName = this.formData.traitName;
      if (traitName) {
        let postData = {
          jobId: this.jobId,
          traitName: traitName,
          description: "",
          userId: this.userId,
        };
        this.loadingFlag = true;

        this.services
          .post("survey/createTrait", postData)
          .then((res) => {
            if (res.status == 200) {
              let custList = [];
              custList = res.data.body;

              this.createTraitsAssigment(custList);

              this.form.setFieldsValue(this.formData);
              this.gaEventTrack(
                custList.traitsAndBehaviours,
                "respondsurvey_doeswell",
                "Responding to survey - doing well"
              );
            }
            this.loadingFlag = false;
          })
          .catch((err) => {
            // if (err.response.status == 302) {
            //   this.traitValidate = true;
            // }
            this.loadingFlag = false;
          });
      }
    },
    addCustomTraitlocal() {
      let traitName = this.formData.traitName,
        isDuplicate = false;
      if (traitName) {
        this.customTraitsList.forEach((rec, ind) => {
          if (
            rec.traitsAndBehaviours.toLowerCase().trim() ==
            this.formData.traitName.toLowerCase().trim()
          ) {
            isDuplicate = true;
          }
        });
        if (!isDuplicate) {
          this.traitsList.forEach((rec, ind) => {
            if (
              rec.traitsAndBehaviours.toLowerCase().trim() ==
              this.formData.traitName.toLowerCase().trim()
            ) {
              isDuplicate = true;
            }
          });
        }
        if (!isDuplicate) {
          this.customTraitsList.push({
            traitsAndBehaviours: traitName,
            selectedTraitsFlag: true,
          });
          this.$store.state.setup2Data.customTraitsList = this.customTraitsList;

          this.formData = {
            traitName: "",
          };
        } else {
          this.traitValidate = true;
        }
      }
    },
    addCustomTrait() {
      let traitName = this.formData.traitName;
      if (traitName) {
        let postData = {
          jobId: this.jobId,
          traitName: traitName,
        };
        this.loadingFlag = true;

        this.services
          .post("survey/isTraitExist", postData)
          .then((res) => {
            if (res.status == 200) {
              this.addTraits();
            } else {
            }
            this.loadingFlag = false;
          })
          .catch((err) => {
            this.loadingFlag = false;
            if (err.response.status == 302) {
              let isDuplicate = false;

              let resData = err.response.data.body[0];
              this.customTraitsList.forEach((rec, ind) => {
                if (
                  rec.traitsAndBehaviours.toLowerCase().trim() ==
                  this.formData.traitName.toLowerCase().trim()
                ) {
                  isDuplicate = true;
                }
              });
              if (!isDuplicate) {
                this.traitsList.forEach((rec, ind) => {
                  if (
                    rec.traitsAndBehaviours.toLowerCase().trim() ==
                    this.formData.traitName.toLowerCase().trim()
                  ) {
                    isDuplicate = true;
                  }
                });
              }
              if (!isDuplicate) {
                this.createTraitsAssigment(resData);
              } else {
                this.traitValidate = true;
              }
            }
          });
      }
    },
    selectedTraits(record, index) {
      record.selectedTraitsFlag = !record.selectedTraitsFlag;
      this.$store.state.setup2Data.traitsList.splice(index, 1, record);
    },
    selectedCustomTraits(record, index) {
      record.selectedTraitsFlag = !record.selectedTraitsFlag;
      this.$store.state.setup2Data.customTraitsList.splice(index, 1, record);
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .list-wrapper {

    .ant-row {
      padding-top: 10px;
    }

    .ant-col {
      display: block;
      box-sizing: border-box;
      width: auto !important;
    }
  }
}

::v-deep {
  span.ant-form-item-children {
    display: inline-flex !important;
  }
}

::v-deep {
  .custom-list-wrapper {
    .ant-col {
      display: block;
      box-sizing: border-box;
      width: auto !important;
    }
  }
}

.ant-form-item-control {
  line-height: 0px !important;
}

.ant-form-item.has-error {
  margin-bottom: 10px !important;
}

.ant-form-explain {
  font-size: 10px !important;
}

.step1-wrapper {
  text-align: left !important;

  .heading-wrapper {
    color: #262626;
    font-weight: 700;
    font-size: 16px;

    @media screen and (max-width: 800px) {
      line-height: 20px;
    }
  }
}

.align-css {
  position: absolute;
  bottom: 35px;
  right: 22%;
  left: 6%;
}

.pop-hover-css {
  padding-top: 5px;
  height: auto;
  overflow-wrap: break-word;
}

.text-area-css {
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #363644 !important;
  background: #edeef5 !important;
  border: 1px solid #bfc3d6;
  box-sizing: border-box;
  width: 46.875rem !important;
  border-radius: 5px;
}

.ant-list-sm .ant-list-item {
  padding-top: 12px;
  padding-bottom: 12px;
}

.list-item-css {
  background: #edeef5;
  border-radius: 5px;
  margin-bottom: 14px !important;
}

.ant-list-items {
  height: 147px !important;
  overflow-x: auto !important;
}

.name-css {
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #363644;
}

.email-css {
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #363644;
}

.list-wrapper {
  text-align: center;
  padding: 1% 1% 1% 1%;
  height: 215px;
  overflow: hidden;
  overflow-y: auto;
}

.custom-list-wrapper {
  text-align: center;
  padding: 4px 1% 0% 1%;
  height: 51px;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 19px;
}

.circle-after::after {
  content: attr(data-attr);
  width: 20px;
  height: 20px;
  padding: 2px 5px;
  border: 1px solid #363644;
  border-radius: 50%;
  box-sizing: border-box;
  transform: matrix(1, 0, 0, -1, 0, 0);
  cursor: pointer;
}

.circle-after.active::after {
  background-color: #6454f0 !important;
  color: #fff !important;
  border: 1px solid #6454f0;
}

.round-list-grey-faint:hover {
  background: rgba(100, 84, 240, 0.55);
}

.error-text {
  position: absolute;
  bottom: 6.5%;
  right: 22%;
}

.list-error {
  border: 1px solid red;
}

.input-txt-css {
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #363644 !important;
  background: #edeef5 !important;
  border: 1px solid #bfc3d6;
  box-sizing: border-box;
  border-radius: 5px;
}

.txt-css {
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 200%;
  align-items: center;
  text-align: center;
  color: #363644;
}

.plus-img-css {
  font-size: 23px;
  color: #6454f0;
  border-radius: 100px;
  cursor: pointer;
}

.plus-img-css:hover {
  color: #4a3cc0;
}

.defination-css {
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #262626;
  width: 328px;
  min-height: auto;
  background: #edeef5;
  border-radius: 5px;
  padding: 5px 8px 5px 8px;

  p {
    margin-top: 0;
    margin-bottom: 0em !important;
  }
}

.ant-list-sm .ant-list-item.active,
.pointer .value-txt.active {
  background-color: #6454f0 !important;
  color: #fff !important;

  .txt-css {
    font-family: "Inter", fantasy !important;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 200%;
    align-items: center;
    text-align: center;
    color: #fff !important;
  }
}

.bold {
  font-weight: 700;
  display: block !important;
}
</style>