import { render, staticRenderFns } from "./CommonLoader.vue?vue&type=template&id=542c493b&scoped=true&"
import script from "./CommonLoader.vue?vue&type=script&lang=js&"
export * from "./CommonLoader.vue?vue&type=script&lang=js&"
import style0 from "./CommonLoader.vue?vue&type=style&index=0&id=542c493b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "542c493b",
  null
  
)

export default component.exports