<template>
  <div class="container">
    <div class="box-title flex justify-between">
      <div>
        <p class="title-text font-700 text-white">{{ fullnameTeamMember }}</p>
        <p class="text-white">360 degree survey results</p>
        <div class="mt-10">
          <div class="flex" style="gap: 3px">
            <p class="text-white font-700">Survey initiated on:</p>
            <p class="text-white">
              {{ formatDateToShow(overview.createdOn) }}
            </p>
            <p class="text-white">
              {{ formatDateToShow(overview.createdOn, true) }}
            </p>
          </div>
          <div class="flex" style="gap: 3px">
            <p class="text-white font-700">Due date:</p>
            <p class="text-white">
              {{ formatDateToShow(overview.dueDate) }}
            </p>
            <p class="text-white">
              {{ formatDateToShow(overview.dueDate, true) }}
            </p>
          </div>
        </div>
      </div>
      <img class="logo-white" src="@/assets/gestaltra_logo_white.png" />
    </div>
    <template v-if="surveyInfo.isNotHaveSurvey">
      <div class="box-info flex justify-between">
        <p>{{ fullnameTeamMember }} has no surveys within this date range.</p>
      </div>
    </template>

    <template v-else>
      <div class="box-info flex justify-between">
        <p style="width: 45%">
          <strong>Position at time of survey:</strong>
          {{ overview.teamMemberPosition }}
        </p>
        <p style="width: 45%">
          <strong>Manager at time of survey:</strong>
          {{ fullnameManger }}
        </p>
      </div>
      <div class="box-info">
        <p class="font-700">Nominated Reviewers:</p>
        <div class="grid-cols-4" style="margin-top: 20px; gap: 11px">
          <p
            v-for="reviewerInfo in overview.surveyReviewers"
            :key="reviewerInfo.id"
          >
            {{ reviewerInfo.reviewer.firstName }}
            {{ reviewerInfo.reviewer.lastName }}
          </p>
        </div>
      </div>
      <div class="flex justify-between">
        <div style="width: 55%">
          <p class="font-700">General</p>
          <div
            class="box-info graph-div"
            style="height: 280px; margin-top: 10px"
          >
            <ApexChart
              id="chart"
              type="radar"
              :options="chartOptions"
              :series="series"
            ></ApexChart>
          </div>
        </div>
        <div style="width: 45%">
          <p class="font-700">Values and Contribution</p>
          <div class="box-info" style="height: 280px; margin-top: 10px">
            <div class="rating-div">
              <div
                v-for="record in likelihood"
                :key="record.id"
                style="
                  display: flex;
                  align-items: center;
                  margin-top: 15px;
                  gap: 8px;
                "
              >
                <div class="icon-wrapper">
                  <em :class="record.img" style="left: -1px; top: -1px" />
                </div>
                <div
                  style="
                    width: 81%;
                    height: 8px;
                    border-radius: 10px;
                    background: #f5f5f5;
                  "
                >
                  <div
                    :style="{
                      height: '100%',
                      width: `${record.percent ? record.percent : 0}%`,
                      borderRadius: '10px',
                      backgroundImage:
                        'linear-gradient(to right, rgb(0, 194, 243) 0%, rgb(100, 84, 240) 100%)',
                    }"
                  ></div>
                </div>
                <p style="color: #797994; font-weight: 600; min-width: 45px">
                  {{ record.percent ? record.percent : 0 }}%
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="font-700">Feedback</p>
      <div class="flex-col" style="gap: 20px">
        <p>Does well</p>
        <div class="grid-cols-4 box-comment" style="gap: 24px">
          <p
            v-if="!doesWell.doesWellTraitsDetails.length"
            style="color: #bfc3d6"
          >
            No feedback has been given
          </p>
          <div
            v-else
            v-for="traitInfo in doesWell.doesWellTraitsDetails"
            :key="traitInfo.traitid"
            class="flex items-center"
          >
            <p class="font-700">{{ traitInfo.trait.traitsAndBehaviours }}</p>
            <div
              style="
                width: 2px;
                height: 2px;
                border-radius: 100%;
                background: #797994;
                margin: 0 6px;
              "
            ></div>
            <p>{{ traitInfo.count }}</p>
          </div>
        </div>
        <div class="box-info" style="gap: 20px; padding: 10px 10px 10px 15px">
          <p v-if="!getDoesWellCmt.length" style="color: #bfc3d6">
            No comment has been given
          </p>
          <template v-else>
            <div v-for="(comment, idx) in getDoesWellCmt" :key="comment.id">
              <p
                :class="idx !== getDoesWellCmt.length - 1 ? 'divider' : ''"
                style="padding: 15px 0px 10px 0px; word-wrap: break-word"
              >
                {{ comment.comment }}
              </p>
            </div>
          </template>
        </div>
      </div>
      <div class="flex-col" style="gap: 20px">
        <p>Needs work</p>
        <div class="grid-cols-4 box-comment" style="gap: 24px">
          <p
            v-if="!needWork.needsWorkTraitsDetails.length"
            style="color: #bfc3d6"
          >
            No feedback has been given
          </p>
          <template v-else>
            <div
              v-for="traitInfo in needWork.needsWorkTraitsDetails"
              :key="traitInfo.traitid"
              class="flex items-center"
            >
              <p class="font-700">
                {{ traitInfo.trait.traitsAndBehaviours }}
              </p>
              <div
                style="
                  width: 2px;
                  height: 2px;
                  border-radius: 100%;
                  background: #797994;
                  margin: 0 6px;
                "
              ></div>
              <p>{{ traitInfo.count }}</p>
            </div>
          </template>
        </div>
        <div class="box-info" style="gap: 20px; padding: 10px 10px 10px 15px">
          <p v-if="!getNeedWorkCmt.length" style="color: #bfc3d6">
            No comment has been given
          </p>
          <template v-else>
            <div
              v-for="(comment, idx) in getNeedWorkCmt"
              :key="comment.id"
              v-show="!comment.isCommentHidden"
            >
              <p
                :class="idx !== getNeedWorkCmt.length - 1 ? 'divider' : ''"
                style="padding: 10px 0 10px 0; word-wrap: break-word"
              >
                {{ comment.comment }}
              </p>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import services from "../services/services";
import ApexChart from "vue-apexcharts";

const serect = "123#$%";

export default {
  props: {
    surveyInfo: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ApexChart,
  },
  data() {
    return {
      overview: {},
      general: [],
      needWork: {},
      doesWell: {},
      likelihood: [],
      chartOptions: {
        chart: {
          type: "radar",
          width: "100%",
        },
        stroke: {
          width: 2,
        },
        plotOptions: {
          radar: {
            size: 80,
            offsetX: 0,
            offsetY: 0,
            polygons: {
              strokeColors: "#BFC3D6",
              connectorColors: "#BFC3D6",
              strokeWidth: "1px",
              fill: {
                colors: ["#EDEEF5"],
              },
            },
          },
        },
        fill: {
          opacity: 0.5,
          colors: ["#46CEFC"],
        },
        markers: {
          size: 0,
        },
        dataLabels: {
          enabled: true,
          background: {
            enabled: true,
            borderRadius: 2,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          min: 0,

          tickAmount: 5,
        },

        xaxis: {
          categories: [],
          labels: {
            minWidth: 0,

            show: true,
            style: {
              colors: [],
              fontSize: "10px",
              fontFamily: "Arial",
            },
          },
        },
      },
      series: [],
      listGeneralCompetancyLabels: null,
    };
  },
  computed: {
    fullnameManger() {
      if (!this.overview.user) return "";
      const userDetail = this.overview.user.userDetails[0];
      return `${userDetail.firstName} ${userDetail.lastName}`;
    },
    fullnameTeamMember() {
      if (!this.surveyInfo.teamMember) return "";
      return `${this.surveyInfo.teamMember.firstName} ${this.surveyInfo.teamMember.lastName}`;
    },
    getDoesWellCmt() {
      if (!this.doesWell.doesWellCommentsDetails) return [];
      return this.doesWell.doesWellCommentsDetails.filter(
        (cmt) => !cmt.isCommentHidden
      );
    },
    getNeedWorkCmt() {
      if (!this.needWork.needsWorkCommentsDetails) return [];
      return this.needWork.needsWorkCommentsDetails.filter(
        (cmt) => !cmt.isCommentHidden
      );
    },
  },
  methods: {
    getMaxLikeHoodCount(data) {
      let maxCount = 0;
      data.forEach((item, index) => {
        maxCount = maxCount + item.count;
      });
      return maxCount;
    },
    async initData() {
      const payload = {
        reviewerIds: this.surveyInfo.surveyReviewers.map(
          (reviewer) => reviewer.reviewerId
        ),
        surveyId: this.surveyInfo.id,
      };

      try {
        const [
          overviewRes,
          generalRes,
          needWorkRes,
          doesWellRes,
          likelihoodRes,
          likelihoodDashboardRes,
        ] = await Promise.all([
          services.get(
            `survey/surveyOverView?userId=${this.surveyInfo.user.id}&teamMemberId=${this.surveyInfo.teamMember.id}`
          ),
          services.post(`dashboard/generalData`, payload),
          services.post(`dashboard/needsWork`, payload),
          services.post(`dashboard/doesWell`, payload),
          services.post(`dashboard/recommandedLikehood`, payload),
          services.get(`survey/listAllLikeHoodsDashboards`, payload),
        ]);
        this.overview = overviewRes.data.details.find(
          (item) => item.id === this.surveyInfo.id
        );
        this.generalRes = generalRes.data.body;
        this.needWork = needWorkRes.data;
        this.doesWell = doesWellRes.data;
        this.likelihood = likelihoodDashboardRes.data.body;

        if (this.generalRes.length > 0) {
          let finalModifiedArr = this.calculateGeneralData(this.generalRes);
          finalModifiedArr.competancy.forEach((rec, index) => {
            this.chartOptions.xaxis.categories.forEach((item, i) => {
              if (rec == item) {
                let finalData = 0;
                let compRec = finalModifiedArr.competancyData[index].data;
                let length = compRec.length;
                let total = 0;
                compRec.forEach((ratingData, ratingIndex) => {
                  total += parseFloat(ratingData);

                  finalData = parseFloat((total / length).toFixed(2));
                });
                this.series[0].data.splice(i, 1, finalData);
              }
            });
          });
          let maxData = this.series[0].data;
          let dataLength = maxData.length;
          maxData = Math.max(...maxData);
          this.chartOptions.yaxis.tickAmount =
            dataLength > maxData ? dataLength : maxData + 1;
          this.chartOptions.yaxis.max =
            dataLength > maxData ? dataLength : maxData + 1;
        } else {
          let grapgData = this.series[0].data;
          this.series[0].data = [];
          grapgData.forEach((rec, index) => {
            this.series[0].data.push(0);
          });
        }

        const likelihoodData = likelihoodRes.data.body;
        const maxCount = this.getMaxLikeHoodCount(likelihoodData);
        likelihoodData.forEach((record, i) => {
          this.likelihood.forEach((item, index) => {
            if (record.likehoodDetails.img == item.img) {
              item.count = record.count;
              let percent = (record.count / maxCount) * 100;
              item.percent = Math.round(percent);
              this.likelihood.splice(index, 1, item);
            }
          });
        });
        this.$emit("completed", this.surveyInfo.key);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    formatDateToShow(date, isReturnMonth) {
      if (!date) return "";
      let monthArr = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      date = date.substring(0, date.indexOf("T"));
      let mm = date.substring(date.indexOf("-") + 1, date.lastIndexOf("-"));
      mm = mm.substring(0, 1) == "0" ? mm.substring(1) : mm;
      mm = monthArr[parseInt(mm) - 1];

      if (isReturnMonth) return `${mm} ${date.substring(0, date.indexOf("-"))}`;
      return date.substring(date.lastIndexOf("-") + 1);
    },
    getGeneralCompetancyLabels(surveyReqId) {
      let url = `survey/listGeneralCompetancyLabels?surveyId=${surveyReqId}`;
      services
        .get(url)
        .then((res) => {
          let resData = res.data.body;
          this.series = [{ data: [100, 155, 200] }];

          resData.forEach((record, index) => {
            this.chartOptions.xaxis.categories.splice(
              index,
              1,
              record.competancy
            );
            this.series[0].data.splice(index, 1, 0);
            this.chartOptions.xaxis.labels.style.colors.splice(
              index,
              1,
              "#000000"
            );
          });
        })
        .catch((err) => {
          //this.errorPopupFlag = true//;
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
        });
    },

    calculateGeneralData(data) {
      let competancyArr = [];
      let competancyDataArr = [];

      data.forEach((record, index) => {
        record.surveyResults.forEach((item, i) => {
          let dataArr = [];
          if (
            item.generalCompetancyResponseLabel &&
            item.generalCompetancyResponseLabel.ratingNo != "0"
          ) {
            if (
              competancyArr.indexOf(item.generalCompetancyLabel.competancy) ==
              -1
            ) {
              dataArr.push(item.generalCompetancyResponseLabel.ratingNo);
              competancyArr.push(item.generalCompetancyLabel.competancy);
              competancyDataArr.push({
                data: JSON.parse(JSON.stringify(dataArr)),
              });
            } else {
              let customIndex = competancyArr.indexOf(
                item.generalCompetancyLabel.competancy
              );

              let customArr = competancyDataArr[customIndex].data;
              customArr.push(item.generalCompetancyResponseLabel.ratingNo);
              competancyDataArr.splice(customIndex, 1, { data: customArr });
            }
          }
        });
      });

      return { competancy: competancyArr, competancyData: competancyDataArr };
    },
  },

  async created() {
    if (this.surveyInfo.isNotHaveSurvey) {
      this.$emit("completed", this.surveyInfo.key);
      return;
    }
    if (localStorage.getItem("token")) {
      let token = localStorage.token;
      const decryptedText = this.$CryptoJS.AES.decrypt(token, serect).toString(
        this.CryptoJS.enc.Utf8
      );
      this.$store.state.userToken = decryptedText;
    }
    await this.getGeneralCompetancyLabels(this.surveyInfo.id);
    await this.initData();
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  color: #363644;
}
.container {
  padding: 40px 0px 0px 130px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  page-break-before: avoid !important;
  page-break-after: avoid !important;
}
.logo-white {
  height: 32px;
  width: 110px;
}
.flex {
  display: flex;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.justify-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}
.text-primary {
  color: #363644;
}
.text-white {
  color: #fff;
}

.font-700 {
  font-weight: 700;
}
.title-text {
  font-size: 18px;
}
.box-info {
  background: #edeef5;
  border-radius: 10px;
  padding: 30px;
  page-break-inside: avoid;
}

.box-comment {
  @extend .box-info;
  gap: 20px;
  padding: 15px;
}
.box-title {
  background: #6454f0;
  border-radius: 10px;
  padding: 30px;
  color: white;
}
.divider {
  border-bottom: 1px solid #bfc3d6;
}
.grid-cols-4 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.icon-wrapper {
  background: #797994;
  width: 24px;
  height: 24px;
  z-index: 1;
  border-radius: 50%;
  margin-left: 10px;
  em {
    position: relative;
    font-size: 26px;
    color: #bfc3d6;
    left: -13px;
    top: 0px;
  }
}
</style>
