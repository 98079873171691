var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-color-grey"},[_c('div',{staticClass:"center-div center-div-popup center white-bg-css"},[(_vm.popHeading)?_c('a-row',{staticClass:"pdb-20",attrs:{"gutter":24}},[_c('a-col',{staticClass:"wlcm popup-heading",attrs:{"span":24}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.popHeading)}})])],1):_vm._e(),(_vm.popEmail)?_c('a-row',{staticClass:"pdb-20 mt-25 sm-txt popup-txt",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('div',{staticClass:"pdh-7",domProps:{"innerHTML":_vm._s(_vm.popEmail)}})])],1):_vm._e(),_c('a-form',{staticClass:"mt-25 padh-15",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[(_vm.popEmailBox || _vm.popEmailPlaceholder)?_c('a-row',{attrs:{"gutter":24}},[(!_vm.textMsg)?_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"float-label",class:_vm.customErrorMsg && _vm.dataFill ? 'has-error' : null},[_c('label',{class:_vm.focusData.focusDataEmail || _vm.formData.email
                ? 'label label-float'
                : 'label'},[_vm._v(_vm._s(_vm.popEmailPlaceholder))]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'email',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please enter a valid email address',
                  },
                  {
                    type: 'email',
                    message: 'Please enter a valid email address',
                  },
                  {
                    validator: _vm.checkExistEmail,
                  } ],
                validateTrigger: 'blur',
              } ]),expression:"[\n              'email',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Please enter a valid email address',\n                  },\n                  {\n                    type: 'email',\n                    message: 'Please enter a valid email address',\n                  },\n                  {\n                    validator: checkExistEmail,\n                  },\n                ],\n                validateTrigger: 'blur',\n              },\n            ]"}],on:{"blur":function($event){return _vm.setFocus(false, 'focusDataEmail')},"focus":function($event){return _vm.setFocus(true, 'focusDataEmail')},"change":function($event){return _vm.onChange($event.target.value, 'email')}}})],1)],1):_vm._e(),(_vm.textMsg)?_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"float-label",class:_vm.optionalErrorType && _vm.dataFill ? 'has-error' : null},[_c('label',{class:_vm.focusData.focusDataEmail || _vm.formData.email
                ? 'label label-float'
                : 'label'},[_vm._v(_vm._s(_vm.popEmailPlaceholder))]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'code',
              {
                rules: [{ required: true, message: _vm.textMsg }],
              },
              {
                type: _vm.optionalErrorType,
                message: _vm.textMsg,
              } ]),expression:"[\n              'code',\n              {\n                rules: [{ required: true, message: textMsg }],\n              },\n              {\n                type: optionalErrorType,\n                message: textMsg,\n              },\n            ]"}],on:{"blur":function($event){return _vm.setFocus(false, 'focusDataEmail')},"focus":function($event){return _vm.setFocus(true, 'focusDataEmail')},"change":function($event){return _vm.onChange($event.target.value, 'email')}}})],1)],1):_vm._e(),(_vm.optionalErrorType && _vm.dataFill)?_c('a-col',{staticClass:"red ant-form-explain",attrs:{"span":24}},[_c('span',[_vm._v(_vm._s(_vm.textMsg))])]):_vm._e(),(_vm.customErrorMsg && _vm.dataFill)?_c('a-col',{staticClass:"red ant-form-explain",staticStyle:{"line-height":"5px"},attrs:{"span":24}},[_c('span',[_vm._v(_vm._s(_vm.customErrorMsg))])]):_vm._e()],1):_vm._e(),(_vm.popPasswordBox)?_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"float-label"},[_c('a-popover',{attrs:{"placement":"right","trigger":"click","overlay-style":{
              'font-size': '12px !important',
              'border-radius': '10px !important',
            }}},[_c('template',{attrs:{"overlay-style":{
                'border-radius': '10px !important',
              }},slot:"content"},[_c('p',[_c('strong',[_vm._v("Password must:")])]),_c('p',[_vm._v(" • Be a minimum of 8 characters "),(_vm.passlength)?_c('span',[_c('img',{staticClass:"success-unsuccess-icons",staticStyle:{"width":"18px"},attrs:{"src":require("../assets/tick.png"),"alt":""}})]):_c('span',[_c('img',{staticStyle:{"width":"18px"},attrs:{"src":require("../assets/cross.png"),"alt":""}})])]),_c('p',[_vm._v(" • Have a mixture of letters and numbers "),(_vm.letterNumber)?_c('span',[_c('img',{staticStyle:{"width":"18px"},attrs:{"src":require("../assets/tick.png"),"alt":""}})]):_c('span',[_c('img',{staticStyle:{"width":"18px"},attrs:{"src":require("../assets/cross.png"),"alt":""}})])]),_c('p',[_vm._v(" • Include at least one special character"),_c('br'),_vm._v("  (e.g. ! @ # ?) "),(_vm.specialCharacter)?_c('span',[_c('img',{staticStyle:{"width":"18px"},attrs:{"src":require("../assets/tick.png"),"alt":""}})]):_c('span',[_c('img',{staticStyle:{"width":"18px"},attrs:{"src":require("../assets/cross.png"),"alt":""}})])])]),_c('label',{class:_vm.focusData.focusDataPassword || _vm.formData.password
                  ? 'label label-float'
                  : 'label'},[_vm._v(_vm._s(_vm.passwordPlaceholder))]),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'password',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter a valid password',
                      },
                      {
                        validator: _vm.validateToNextPassword,
                      },
                      {
                        pattern: _vm.passwordRegex,
                        message: 'Please enter a valid password',
                      } ],
                  } ]),expression:"[\n                  'password',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please enter a valid password',\n                      },\n                      {\n                        validator: validateToNextPassword,\n                      },\n                      {\n                        pattern: passwordRegex,\n                        message: 'Please enter a valid password',\n                      },\n                    ],\n                  },\n                ]"}],on:{"blur":function($event){return _vm.setFocus(false, 'focusDataPassword')},"focus":function($event){return _vm.setFocus(true, 'focusDataPassword')},"change":function($event){return _vm.onChange($event.target.value, 'password')}}})],2)],1)],1)],1):_vm._e(),(_vm.popPasswordBox)?_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"float-label"},[_c('label',{class:_vm.focusData.confirmPassword || _vm.formData.confirmPassword
                ? 'label label-float'
                : 'label'},[_vm._v(_vm._s(_vm.confirmPasswordPlaceholder))]),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'confirmPassword',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Passwords don’t match – please try again',
                    },
                    {
                      validator: _vm.compareToFirstPassword,
                    } ],
                } ]),expression:"[\n                'confirmPassword',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Passwords don’t match – please try again',\n                    },\n                    {\n                      validator: compareToFirstPassword,\n                    },\n                  ],\n                },\n              ]"}],on:{"blur":function($event){return _vm.setFocus(false, 'confirmPassword')},"focus":function($event){return _vm.setFocus(true, 'confirmPassword')},"change":function($event){return _vm.onChange($event.target.value, 'confirmPassword')}}})],1)],1)],1):_vm._e(),(_vm.codeLinkText)?_c('a-row',{staticClass:"pdb-10 mt-2",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":6}}),_c('a-col',{attrs:{"span":18}},[_c('div',{staticClass:"blue-txt-sm underline",on:{"click":_vm.sendCodeClick}},[_vm._v(" "+_vm._s(_vm.codeLinkText)+" ")])])],1):_vm._e(),(_vm.codeSuccessText)?_c('a-row',{staticClass:"pdb-20 mt-2",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":6}}),_c('a-col',{attrs:{"span":18}},[_c('div',{staticClass:"blue-txt-sm",staticStyle:{"font-size":"18px"}},[_c('a-icon',{staticClass:"font-18",attrs:{"type":"check-circle","theme":"filled"}}),_c('span',{staticClass:"marl-5"},[_vm._v(_vm._s(_vm.codeSuccessText))])],1)])],1):_vm._e(),(_vm.popButtonText)?_c('a-row',{staticClass:"mar-auto",attrs:{"gutter":24}},[_c('a-col',{staticClass:"mt-25",attrs:{"span":12,"lg":12,"xs":24,"sm":12}},[_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.popButtonText)+" ")])],1)],1)],1):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }