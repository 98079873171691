<template>
  <div>
    <a-modal
      :visible="visible"
      :maskClosable="false"
      @cancel="cancelBtn"
      :centered="true"
      width="56%"
      class="export-survey-popup"
    >
      <div class="pl-pr">
        <a-row class="mt-2" :gutter="24">
          <a-col
            style="padding-top: 5px; text-align: left"
            :span="24"
            :lg="24"
            :xs="24"
          >
            <span class="bold-right-txt">Export to PDF</span>
          </a-col>
        </a-row>
        <a-row class="mt-2" :gutter="24">
          <a-col
            style="padding-top: 5px; text-align: left"
            :span="24"
            :lg="24"
            :xs="24"
          >
            <span class="confirm-contain-txt" style="font-size: 16px !important"
              >Select from your team list below who you would like to export
              survey results for. Surveys initiated within the date range
              selected will be included in the export.
            </span>
          </a-col>
        </a-row>
        <a-row
          class="mt-2"
          style="background: #edeef5; border-radius: 10px; height: 262px"
          :gutter="24"
        >
          <a-col style="padding: 16px" :span="24" :lg="24" :xs="24">
            <a-list
              class="survey-class"
              style="padding-right: 10px; height: 235px; overflow-y: auto"
              size="small"
              :data-source="memberList"
            >
              <a-list-item
                class="list-hover"
                slot="renderItem"
                slot-scope="item, index"
                @click="selectedSurveyTeamMember(item.id)"
                :class="selectTedMemberIds.includes(item.id) ? 'active' : null"
                style="cursor: pointer"
              >
                <a-row
                  :gutter="24"
                  style="width: 100% !important; padding-left: 15px"
                >
                  <a-col
                    class="name-list pt-2"
                    :span="8"
                    :lg="8"
                    :style="{
                      color: selectTedMemberIds.includes(item.id)
                        ? 'white'
                        : '#363644',
                    }"
                  >
                    <span class="role-item-text">
                      {{ item.firstName }}&nbsp;{{ item.lastName }}
                    </span>
                  </a-col>
                  <a-col class="role-list pt-2" :span="13" :lg="13">
                    <span
                      class="role-item-text"
                      :style="{
                        color: selectTedMemberIds.includes(item.id)
                          ? 'white'
                          : '#363644',
                      }"
                    >
                      <span v-if="item.other">{{ item.other }}</span
                      ><span v-else-if="item.jobTitle">{{
                        item.jobTitle
                      }}</span>
                      <span v-else>{{ item.job.jobTitle }}</span>
                    </span>
                  </a-col>
                  <a-col
                    class="list-icon"
                    style="
                      text-align: end !important;
                      padding-right: 0px !important;
                    "
                    :span="3"
                    :lg="3"
                  >
                    <span v-if="selectTedMemberIds.includes(item.id)">
                      <em class="icon-circle-tick" style="visibility: hidden" />
                    </span>
                  </a-col>
                </a-row>
              </a-list-item>
            </a-list>
          </a-col>
        </a-row>
        <a-row class="mt-2" :gutter="24">
          <a-col
            :span="24"
            class="error-txt"
            v-if="exportSurveyError"
            :lg="24"
            :xs="24"
          >
            <span>Please select at least one team member</span>
          </a-col>
        </a-row>
        <a-row class="mt-2" :gutter="24">
          <a-col
            style="display: flex; align-items: center; gap: 10px"
            :span="10"
            :lg="10"
            :xs="24"
          >
            <span class="date-txt" style="font-size: 16px !important"
              >From</span
            >
            <div
              style="
                padding-top: 5px;
                text-align: left;
                padding-left: 0px !important;
              "
              class="start-survey-date"
              :class="exportSurveyDateError && !fromDate ? 'has-error' : null"
            >
              <a-date-picker
                :value="fromDate"
                format="DD MMM YYYY"
                style="background: #edeef5 !important; border-radius: 10px"
                @change="
                  (e) => {
                    fromDate = moment(e).format('YYYY-MM-DD');
                    exportSurveyDateError = false;
                    getAllCompletedSurvey();
                  }
                "
                :show-today="false"
              />
            </div>
          </a-col>

          <a-col
            style="display: flex; align-items: center; gap: 10px"
            :span="10"
            :lg="10"
            :xs="24"
          >
            <span class="date-txt" style="font-size: 16px !important">To</span>
            <div
              style="
                padding-top: 5px;
                text-align: left;
                padding-left: 0px !important;
              "
              class="start-survey-date"
              :class="exportSurveyDateError && !toDate ? 'has-error' : null"
            >
              <a-date-picker
                :value="toDate"
                format="DD MMM YYYY"
                style="background: #edeef5; border-radius: 10px"
                @change="
                  (e) => {
                    toDate = moment(e).format('YYYY-MM-DD');
                    exportSurveyDateError = false;
                    getAllCompletedSurvey();
                  }
                "
                :show-today="false"
              />
            </div>
          </a-col>
        </a-row>
        <a-row class="mt-2" :gutter="24">
          <a-col
            style="padding-top: 5px; text-align: left"
            :span="24"
            :lg="24"
            :xs="24"
            class="error-txt"
            v-if="exportSurveyDateError"
          >
            <p>Please select an appropriate date range</p>
          </a-col>
          <a-col
            style="padding-top: 5px; text-align: left"
            :span="15"
            :lg="15"
            :xs="24"
          >
          </a-col>
        </a-row>
        <a-row class="mt-10" :gutter="24">
          <a-col :span="16" class="error-txt float-r">
            <a-button
              :span="8"
              class="float-r lets-go-btn"
              style="width: 150px !important; text-align: center"
              type="primary"
              :loading="isLoading"
              @click="handleExport"
              >Export</a-button
            >
          </a-col>
        </a-row>
      </div>
    </a-modal>
    <confirm-pop-up
      logo
      className="confirm-download-popup"
      :is-show="isShowDownloadExport"
      header-text="Preparing export"
      contain-text="Your PDF will begin downloading shortly."
      button-text="OK"
      @closePopup="isShowDownloadExport = false"
    />
    <PdfToHtml
      v-for="(survey, index) in getSurveyInfo"
      :key="`pdf-${survey.key}`"
      :ref="`pdf-${survey.key}`"
      :filename="getFileName(survey)"
    >
      <SurveyExportTemplate
        :surveyInfo="survey"
        @completed="completedRenderCheck.push($event)"
      ></SurveyExportTemplate>
    </PdfToHtml>
  </div>
</template>
<script>
import services from "@/services/services";
import ConfirmPopUp from "@/components/ConfirmPopUp.vue";
import SurveyExportTemplate from "@/components/SurveyExportTemplate.vue";
import PdfToHtml from "@/components/PdfToHtml.vue";
import moment from "moment";
export default {
  components: {
    SurveyExportTemplate,
    PdfToHtml,
    ConfirmPopUp,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: Number,
      default: 0,
    },
    memberList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fromDate: "",
      toDate: "",
      exportSurveyError: false,
      exportSurveyDateError: false,
      selectTedMemberIds: [],
      isShowDownloadExport: false,
      exportSurveyInfo: [],
      completedRenderCheck: [],
      isLoading: false,
    };
  },
  computed: {
    getSurveyInfo() {
      if (!this.fromDate || !this.toDate || !this.selectTedMemberIds.length)
        return [];
      const listNotHaveSurvey = [];
      this.exportSurveyInfo.forEach((survey) => {
        survey.key = this.randomKey();
      });
      const listHaveSurvey = this.exportSurveyInfo.map(
        (survey) => survey.teamMember.id
      );
      this.selectTedMemberIds.forEach((memberId) => {
        if (!listHaveSurvey.includes(memberId)) {
          const memberInfo = this.memberList.find(
            (member) => member.id === memberId
          );
          listNotHaveSurvey.push({
            key: this.randomKey(),
            isNotHaveSurvey: true,
            teamMember: memberInfo,
          });
        }
      });
      return [...this.exportSurveyInfo, ...listNotHaveSurvey];
    },
    isCompleteDownload() {
      return (
        !this.visible &&
        this.completedRenderCheck.length &&
        this.getSurveyInfo.length &&
        this.completedRenderCheck.length === this.getSurveyInfo.length
      );
    },
  },
  methods: {
    moment,
    randomKey() {
      return (
        new Date().getTime() + Math.floor(Math.random() * 10000).toString()
      );
    },
    cancelBtn() {
      this.$emit("close");
    },
    getFileName(survey) {
      return `Gestaltra-${survey.teamMember.firstName}-${
        survey.teamMember.lastName
      }${
        survey.createdOn
          ? `-${moment(survey.createdOn).format("DD-MM-YYYY")}`
          : ""
      }`;
    },
    selectedSurveyTeamMember(memberId) {
      this.exportSurveyError = false;
      if (this.selectTedMemberIds.includes(memberId)) {
        const idx = this.selectTedMemberIds.findIndex((id) => id === memberId);
        this.selectTedMemberIds.splice(idx, 1);
        this.getAllCompletedSurvey();
        return;
      }
      this.selectTedMemberIds.push(memberId);
      this.getAllCompletedSurvey();
    },
    handleExport() {
      this.exportSurveyDateError =
        !this.fromDate ||
        !this.toDate ||
        moment(this.fromDate).isAfter(this.toDate);
      this.exportSurveyError = !this.selectTedMemberIds.length;
      if (this.exportSurveyDateError || this.exportSurveyError) return;
      this.isShowDownloadExport = true;
      this.$emit("close");
    },
    handleMultiDown() {
      let delay = 1000;
      const refsKey = Object.keys(this.$refs).filter(
        (key) => this.$refs[key].length
      );
      const refValues = refsKey.map((key) => this.$refs[key][0]);
      for (const value of refValues) {
        if (typeof value.generateReport === "function") {
          setTimeout(() => value.generateReport(), delay);
          delay += 200;
        }
      }
    },
    async getAllCompletedSurvey() {
      try {
        this.isLoading = true;
        if (!this.fromDate || !this.toDate || !this.selectTedMemberIds.length)
          return;

        const res = await services.post(`survey/getAllCompletedSurvey`, {
          userId: this.userId,
          fromDate: this.fromDate,
          toDate: this.toDate,
          teamMemberId: this.selectTedMemberIds,
        });
        this.exportSurveyInfo = [];
        this.completedRenderCheck = [];
        this.exportSurveyInfo = res.data.body;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    randomKey() {
      return (
        new Date().getTime() + Math.floor(Math.random() * 10000).toString()
      );
    },
  },
  watch: {
    visible(isVisible) {
      if (isVisible) {
        this.selectTedMemberIds = [];
        this.fromDate = "";
        this.toDate = "";
        this.exportSurveyInfo = [];
        this.completedRenderCheck = [];
      }
    },
    isCompleteDownload(isComplete) {
      if (isComplete) {
        this.handleMultiDown();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.survey-class .ant-list-items .ant-list-item.active {
  background-color: #6454f0 !important;
  color: #fff !important;
  .icon-circle-tick {
    color: #46cefc;
    visibility: visible !important;
    transform: matrix(1, 0, 0, -1, 0, 0);
    font-size: 25px !important;
    border-radius: 100% !important;
    background: linear-gradient(to left, white 50%, white 50%) !important;
  }
}
.role-item-text {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}
.create-survey-button {
  width: 70%;
  font-size: 14px !important;
  position: absolute;
  top: 5%;
  left: 5%;
}
.confirm-contain-txt {
  font-size: 18px !important;
  line-height: 20px !important;
}
.date-txt {
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  color: #363644;
}
.survey-class .ant-list-items .ant-list-item:hover {
  background-color: #bfc3d6;
}

::v-deep {
  .ant-modal-footer {
    display: none !important;
  }
  .ant-calendar-picker-input.ant-input {
    line-height: 1.5 !important;
    background-color: #edeef5 !important;
    padding: 4px 11px 4px 35px !important;
  }
  .ant-calendar-picker-icon {
    /* display: flex; */
    color: #6454f0 !important;
    font-size: 14px;
    line-height: 1;
  }
  .ant-list-split .ant-list-item {
    border: 0;
  }
  .ant-calendar-picker-icon {
    position: absolute !important;
    top: 50% !important;
    left: 12px !important;
    z-index: 1 !important;
    width: 14px !important;
    height: 14px !important;
    margin-top: -7px !important;
    font-size: 16px !important;
    line-height: 14px !important;
    transition: all 0.3s !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
}
</style>
