<template>
  <div>
    <div class="header-landing">
      <div class="wrapper fadeIn">
        <div>
          <h1>
            <p>We’re here to make</p>
            <div class="container">
              <div class="text">workplaces less</div>
              <div class="flip-wrapper">
                <ul class="flip3">
                  <li>apathethic</li>
                  <li>toxic</li>
                  <li>transactional</li>
                </ul>
              </div>
            </div>
            <div class="container">
              <div class="text">and more</div>
              <div class="flip-wrapper">
                <ul class="flip3">
                  <li>accountable</li>
                  <li>transparent</li>
                  <li>human</li>
                </ul> 
              </div>
            </div>
          </h1>

          <p>We're starting by radically simplifying the process<br class="mobile-hide" /> of giving and receiving feedback at work.</p>

          <button @click="goToSignUp('sign_up')">Try for free</button>
        </div>
      </div>
      <img src="../../assets/header-screen.png" alt="" class="wow fadeIn screen" />
    </div>
    <div class="wave">
      <svg viewBox="0 0 500 200" preserveAspectRatio="none">
        <path d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z" style="stroke: none;fill: #363644;"></path>
      </svg>
    </div>
  </div>
</template>

<script>
  import { event } from "vue-gtag";
  export default {
    name: 'Header',
    data() {
      return {
        event
      }
   },
    methods: {
    gaEventTrack(data, action, category) {
      this.event(action, {
        event_category: category,
        event_label: data,
        value: 1,
      });
    },
    goToSignUp(data) {
      this.gaEventTrack(
        "Landing page signUp",
        data,
        "Landing page signUp link"
      );
      this.$router.push("/signup");
    },
   }
  }
</script>
