<template>
  <div class="get-started">
    <h1 class="text-center wow fadeInUp">Get started</h1>
    <button class="wow fadeInUp"  @click="goToSignUp('create_your_360_survey')">Create your 360 survey</button>
  </div>
</template>


<script>
import { event } from "vue-gtag";

export default {
  name: 'GetStarted',
  data(){
    return{
      event,
    }
  },
  methods: {
    gaEventTrack(data, action, category) {
      this.event(action, {
        event_category: category,
        event_label: data,
        value: 1,
      });
    },
    goToSignUp(data) {
      this.gaEventTrack(
        "Landing page signUp",
        data,
        "Landing page signUp link"
      );
      this.$router.push("/signup");
    },
  }
}
</script>