<template>
  <div class="policies__content wrapper">
     <p class="updated">Last updated: 27 May 2022</p>
    <p>
      <strong>Please read these Terms of Service carefully before using Gestaltra.</strong>
    </p>
    <hr />
<h2>Interpretation and Definitions</h2>

<h3>Interpretation</h3>

<p>The words of which the initial letter is capitalised have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

<h3>Definitions</h3>

<p>For the purposes of these Terms of Service:</p>

<p><strong>Application</strong> means the software program provided by Gestaltra, used or downloaded by You on any electronic device.</p>

<p><strong>Application Store</strong> means the digital distribution service operated and developed by Apple Inc. (Apple App Store) or Google Inc. (Google Play Store) in which the Application has been downloaded.</p>

<p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>

<p><strong>Account </strong>means a unique account created for You to access our Service or parts of our Service.</p>

<p><strong>Country</strong> refers to Australia.</p>

<p><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Gestaltra,www.gestaltra.com.</p>

<p><strong>Customer, You or Your</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>

<p><strong>Customer Data</strong> means any content or data that You or other Users submit or transfer to Gestaltra using the Services (including personal data and survey responses).</p>

<p><strong>Device</strong> means any device that can access the Service such as a computer, a mobile smartphone or a digital tablet.</p>

<p><strong>Feedback</strong> means feedback, innovations or suggestions sent by You regarding the attributes, performance or features of our Service.</p>

<p><strong>Free Trial</strong> refers to a limited period of time that may be free when purchasing a Subscription.</p>

<p><strong>Promotions</strong> refer to contests, sweepstakes or other promotions offered through the Service.</p>

<p><strong>Service </strong>refers to the Application.</p>

<p><strong>Service Improvement</strong> refers to work carried out by Gestaltra to improve the Services, including work in respect of benchmarking, text analytics, linkage analysis, attrition prediction, algorithm improvements, comment translation and the development and provision of additional services and functionality</p>

<p><strong>Subscriptions</strong> refer to the services or access to the Service offered on a subscription basis by Gestaltra to You.</p>

<p><strong>Terms of Service</strong> (also referred as "Terms") mean these Terms of Service that form the entire Agreement between You and Gestaltra regarding the use of the Service.</p>

<p><strong>Third-party Social Media Service</strong> means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.</p>

<p><strong>Users</strong> means any person or entity to whom You provide access to our Services, including any administrators or managers of Your account or survey respondents.</p>

<p><strong>Website</strong> refers to Gestaltra, accessible from www.gestaltra.com</p>

<h2>Acknowledgment</h2>

<p>These are the Terms of Service governing the use of this Service and the agreement that operates between You and Gestaltra (the Agreement). These Terms of Service set out the rights and obligations of all Users regarding the use of the Service.</p>

<p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms of Service. These Terms apply to all visitors, Users and others who access or use the Service.</p>

<p>By accessing or using the Service You agree to be bound by these Terms, and You warrant that you are authorised to enter into this Agreement. If You disagree with any part of these Terms and Conditions then You may not access the Service.</p>

<p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully, at www.gestaltra.com/privacy, before using Our Service.</p>

<h2>Acceptable use of the Service</h2>

<p>Gestaltra, its related Service, and website must only be used lawfully. Gestaltra reserves the right to suspend, cancel, or otherwise deny access to users and accounts who use the Service:</p>

<ul>
  <li>
To engage in any act that would disrupt the access, availability, and security of Gestaltra and its Service, including but not limited to:</li>
<ul>
  <li>Tampering with, reverse-engineering, or hacking our servers.</li>

  <li>Modifying, disabling, or compromising the performance of the Service.</li>

  <li>Overwhelming, or attempting to overwhelm our infrastructure by imposing an unreasonably large load on our systems that consume extraordinary resources.</li>

  <li>Compromising the integrity of our system, including probing, scanning and testing the vulnerability of our system unless expressly permitted by the Company.</li>
</ul>


<li>For any illegal purpose, or to violate any laws, including and without limitation to data, privacy, and export control laws.</li>

<li>To stalk, harass or threaten users and any member of the public.</li>
<li>To misrepresent or defraud any user or member of the public through phishing, spoofing, manipulating headers or other identifiers, impersonating anyone else, or falsely implying any sponsorship or association with the Company or any third party.</li>
<li>To access or search any part of the Service, or any other Service owned by Gestaltra other than our publicly supported interface, or otherwise allowed for in an applicable Software Licensing Agreement.</li>
<li>To post, upload, share, or otherwise circulate content in violation of Gestaltra’s content policy
  </li>
</ul>


<h2>Subscriptions</h2>

<h3>Subscription period</h3>

<p>The Service or some parts of the Service are available only with a paid Subscription. You will be billed in advance on a recurring and periodic basis (such as weekly, monthly or annually), depending on the type of Subscription plan you select when purchasing the Subscription.</p>

<p>At the end of each period (the Subscription Period ), Your Subscription will automatically renew under the exact same conditions unless You cancel it or the Company cancels it.</p>

<h3>Subscription cancellations</h3>

<p>You may cancel Your Subscription renewal either through Your account settings page or by contacting Gestaltra. You will not receive a refund for the fees You already paid for Your current Subscription period and You will be able to access the Service until the end of Your current Subscription period.</p>

<h3>Billing</h3>

<p>You shall provide Gestaltra with accurate and complete billing information including full name, address, state, post code, telephone number, and a valid payment method information.</p>

<p>Subject to the terms of any applicable Software License Agreement, the Subscription Fee may be paid by all payment methods available on the Website, and may change from time to time.</p>

<p>Payments made in the course of your use of Gestaltra may be made using third-party applications and services not owned, operated, or otherwise controlled by the Company. You acknowledge and agree that Gestaltra will not be liable for any losses or damage arising from the operations of third-party payment applications and services. You further acknowledge and warrant that you have read, understood and agree to be bound by the terms and conditions of the applicable third-party payment applications and services you choose to use as a payment method for Gestaltra’s services.</p>

<p>You acknowledge and agree that where a request for the payment of the Subscription Fee is returned or denied, for whatever reason, by your financial institution or is unpaid by you for any other reason, then you are liable for any costs, including banking fees and charges, associated with the Subscription Fee.</p>

<p>Should automatic billing fail to occur for any reason, Gestaltra will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.</p>

<p>Unpaid amounts are subject to an interest charge of 1.5% per month on any outstanding balance, or the maximum permitted by law, whichever is lower, plus all expenses of collection and may result in immediate termination of Service.</p>

<p>Where applicable, any goods or services tax, charge, impost or duty payable in respect of this Agreement or the supply of any goods or service made under or in respect of this Agreement and any other taxes, duties or levies will be paid by the Customer at the then-prevailing rate.</p>

<h3>Fee Changes</h3>

<p>Gestaltra, in its sole discretion and at any time, may modify the Subscription fees. Any Subscription fee change will become effective at the end of the then-current Subscription period.</p>

<p>Gestaltra will provide You with reasonable prior notice of any change in Subscription fees to give You an opportunity to terminate Your Subscription before such change becomes effective.</p>

<p>Your continued use of the Service after the Subscription fee change comes into effect constitutes Your agreement to pay the modified Subscription fee amount.</p>

<h3>Refunds</h3>

<p>Except when required by law, paid Subscription fees are non-refundable.</p>

<p>Certain refund requests for Subscriptions may be considered by the Company on a case-by-case basis and granted at the sole discretion of the Company.</p>

<h3>Free Trial</h3>

<p>The Company may, at its sole discretion, offer a Subscription with a Free Trial for a limited period of time.</p>

<p>You may be required to enter Your billing information in order to sign up for the Free Trial.</p>

<p>If You do enter Your billing information when signing up for a Free Trial, You will not be charged by the Company until the Free Trial has expired. On the last day of the Free Trial period, unless You cancelled Your Subscription, You will be automatically charged the applicable Subscription fees for the type of Subscription You have selected.</p>

<p>At any time and without notice, the Company reserves the right to (i) modify the terms and conditions of the Free Trial offer, or (ii) cancel such Free Trial offer.</p>

<h2>Promotions</h2>

<p>Any Promotions made available through the Service may be governed by rules that are separate from these Terms.</p>

<p>If You participate in any Promotions, please review the applicable rules as well as our Privacy policy. If the rules for a Promotion conflict with these Terms, the Promotion rules will apply.</p>

<h2>User Accounts</h2>

<p>When You create an account with Us, You must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on Our Service.</p>

<p>You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service.</p>

<p>You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your account.</p>

<p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</p>

<h2>Intellectual Property</h2>

<p>The Service and its original content (excluding Customer Data provided by You or other Users), features and functionality are and will remain the exclusive property of Gestaltra and its licensors.</p>

<p>Gestaltra shall own and retain all right, title and interest in and to (a) the Services, all improvements, enhancements or modifications thereto, (b) any software, applications, inventions or other technology developed in connection with delivering the Service or support, and (c) all intellectual property rights related to any of the foregoing.</p>

<p>You grant Gestaltra a worldwide, royalty free licence to use, reproduce, distribute, modify, adapt, create derivative works, archive, or otherwise use Customer Data for the purposes of providing the Services to you as contemplated by this Agreement, Service Improvement and creating de-identified aggregated data. This de-identified aggregated data may be made publicly available and may be used after termination of this Agreement provided that such data cannot directly or indirectly identify the Customer or its Users.</p>

<p>Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company.</p>

<p>The Service and all of the related products of Gestaltra are subject to copyright, trademark, and other laws of both Australia and through international treaties. Unless otherwise indicated, all rights (including copyright) in the site content and compilation of the Application (including text, graphics, logos, button icons, video images, audio clips and software) are owned or controlled for these purposes, and are reserved by Gestaltra or its contributors.</p>

<h2>Your Feedback to Us</h2>

<p>You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such assignment is ineffective, You agree to grant Gestaltra a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without restriction.</p>

<h2>Links to Other Websites</h2>

<p>Our Service may contain links to third-party web sites or services that are not owned or controlled by Gestaltra.</p>

<p>Gestaltra has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Gestaltra shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>

<p>We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.</p>

<h2>Use of Customer Logo</h2>

<p>You authorise Gestaltra to use your name, logo and/or trademark without notice to or consent by You, in connection with certain promotional materials that Gestaltra may disseminate to the public. The promotional materials may include, but are not limited to, brochures, videos, internet website, press releases, advertising in newspaper and/or other periodicals, and any other materials relating the fact that Gestaltra provides the Service to You and such materials may be developed, disseminated and used without Your review. Nothing herein obligates Gestaltra to use Your name, logo and/or trademark, in any promotional materials of Gestaltra.</p>

<h2>Termination of Contract</h2>

<p>The Terms will continue to apply until terminated by either You or by Gestaltra as set out below.</p>

<p>If You want to terminate the Terms, You may do so by not renewing the Subscription prior to the end of the Subscription Period.</p>

<p>Gestaltra may at any time, terminate the Terms with you if:</p>


<ul>
  <li>You do not renew the Subscription at the end of the Subscription Period;</li>
  <li>You have breached any provision of the Terms or intend to breach any provision;</li>
  <li>Gestaltra is required to do so by law;</li>
  <li>The provision of the Services to you by Gestaltra is, in the opinion of Gestaltra, no longer commercially viable.</li>
</ul>

<p>Subject to local applicable laws, Gestaltra reserves the right to discontinue or cancel your Subscription or Account at any time and may suspend or deny, in its sole discretion, your access to all or any portion of the Website or the Services without notice if you breach any provision of the Terms or any applicable law or if your conduct impacts Gestaltra's name or reputation or violates the rights of those of another party.</p>

<h2>Indemnity</h2>

<p>You will at all times indemnify and hold harmless the Company and its officers, employees and agents in respect of any third party claim for any injury, loss, damage or expense occasioned by or arising directly or arising directly or indirectly from:</p>

<ul>
  <li>A breach by the Customer of its obligations under this Agreement;</li>
  <li>Any wilful, unlawful or negligent act or omission of the Customer.</li>
</ul>

<h2>Limitation of Liability</h2>

<p>The liability of the Company under or in connection with this Agreement whether arising in contract, tort, negligence, breach of statutory duty or otherwise must not exceed the fees paid by the Customer to the Company for the Services under this Agreement in the 12 months prior to the act that gave rise to the liability, in each case, whether or not the Company has been advised of the possibility of such damages.</p>

<p>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>

<p>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.</p>

<h2>"As is" and "As available" Disclaimer</h2>

<p>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>

<p>Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>

<p>Gestaltra will not be liable for any failure of the Services to provide any function not described in the documentation (provided online as part of the Services) or any failure attributable to:</p>

<ul>
  <li>Any modification to the Services other than by the Company;</li>
  <li>Accident, abuse or misapplication of Services by the Customer;</li>
  <li>Use of the Services with other software or equipment without the Company’s written consent;</li>
  <li>Use of other than the latest, unaltered current release of the Services;</li>
  <li>Or use other than in accordance with this Agreement.</li>
</ul>

<p>If, upon investigation, a problem with the Services is determined not to be the Company’s responsibility, the Company may invoice You immediately for all reasonable costs and expenses incurred by the Company in the course of or in consequence of such investigation.</p>

<p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.</p>

<h2>Governing Law</h2>

<p>The Terms are governed by the laws of Victoria, Australia. Any dispute, controversy, proceeding or claim of whatever nature arising out of or in any way relating to the Terms and the rights created hereby shall be governed, interpreted and construed by, under and pursuant to the laws of Victoria, Australia, without reference to conflict of law principles, notwithstanding mandatory rules. The validity of this governing law clause is not contested. The Terms shall be binding to the benefit of the parties hereto and their successors and assigns.</p>

<h2>Disputes Resolution</h2>

<p>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.</p>

<h2>Severability and Waiver</h2>

<h3>Severability</h3>

<p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>

<h3>Waiver</h3>

<p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.</p>

<h2>Translation Interpretation</h2>

<p>These Terms and Conditions may have been translated if we have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.</p>

<h2>Changes to these Terms of Service</h2>

<p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.</p>

<p>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>

<h2>Contact Us</h2>

<p>If you have any questions about these Terms and Conditions, You can contact us:</p>

• By email: <a href="mailto:hello@gestaltra.com" target="_blank">hello@gestaltra.com</a>

  </div>
</template>
<script>
import { pageview } from "vue-gtag";
export default {
  name: "TermsAndConditions",
  data() {
    return { pageview };
  },
  created() {
    this.track();
  },
  methods: {
    track() {
      this.pageview("landingpage_termsofservice");
    },
    goToLogin() {
      this.$router.push("/login");
    },
    goToSignUp() {
      this.$router.push("/signup");
    },
  },
};
</script>
<style scoped lang="scss">
.header {
  position: fixed;
  top: 0px;
  z-index: 1000;
  width: -webkit-fill-available !important;
  width: -moz-available !important;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  margin: 32px 30px 32px 30px;
}
#body {
  overflow: hidden;
}
</style>