<template>
  <div class="white-bg-color">
    <div class="NominateReviewers">
      <a-row :gutter="24" style="margin-left: 12px; margin-right: 12px">
        <a-col :span="8" :lg="8" :xs="24"> </a-col>
        <a-col class="img-css" :span="8" :lg="8" :xs="24">
          <div>
            <img
              alt="gestaltra_logo_grey"
              class="logo"
              src="../../assets/gestaltra_logo_grey.png"
            />
          </div>
        </a-col>
        <a-col :span="8" :lg="8" :xs="24"> </a-col>
      </a-row>
      <div class="nom-class">
        <div class="pl-pr" style="padding-top: 49px; padding-bottom: 43px">
          <a-row class="mt-2" :gutter="24">
            <a-col
              style="padding-top: 5px; text-align: left"
              :span="24"
              :lg="24"
              :xs="24"
            >
              <span class="bold-right-txt"
                >{{ fullName }}, it’s time for your 360!</span
              >
            </a-col>
          </a-row>
          <a-row class="pb-2 mt-2" :gutter="24">
            <a-col
              style="padding-top: 5px; text-align: left"
              :span="24"
              :lg="24"
              :xs="24"
            >
              <span class="confirm-contain-txt"
                >Who would you like to nominate to provide feedback on you?</span
              >
            </a-col>
          </a-row>
          <a-form :form="form" @submit="handleSubmit">
            <a-row
              class="pb-2 round-grey-box"
              style="margin-top: 18px"
              :gutter="24"
            >
              <a-icon
                width="30px"
                height="30px"
                class="plus-img-css"
                type="plus-circle"
                theme="filled"
                html-type="submit"
                @click="addNewMember($event)"
              />
              <a-col :span="24" :lg="24" :xs="24">
                <a-row class="mt-2" :gutter="24">
                  <a-col :span="12" :lg="12" :xs="24">
                    <a-form-item class="float-label">
                      <label
                        :class="
                          focusData.focusDataFn || formData.firstName
                            ? 'label label-float'
                            : 'label'
                        "
                        >First name</label
                      >
                      <a-input
                        @blur="setFocus(false, 'focusDataFn')"
                        @focus="setFocus(true, 'focusDataFn')"
                        :value="formData.firstName"
                        v-decorator="[
                          'firstName',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Please enter first name',
                              },
                            ],
                          },
                        ]"
                        @change="onChange($event.target.value, 'firstName')"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" :lg="12" :xs="24">
                    <a-form-item class="float-label">
                      <label
                        :class="
                          focusData.focusDataLn || formData.lastName
                            ? 'label label-float'
                            : 'label'
                        "
                        >Last name</label
                      >
                      <a-input
                        @blur="setFocus(false, 'focusDataLn')"
                        @focus="setFocus(true, 'focusDataLn')"
                        :value="formData.lastName"
                        v-decorator="[
                          'lastName',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Please enter last name',
                              },
                            ],
                          },
                        ]"
                        @change="onChange($event.target.value, 'lastName')"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row class="mt-2" :gutter="24">
                  <a-col :span="14" :lg="14" :xs="24">
                    <a-form-item
                      :class="emailValidate ? 'has-error' : null"
                      class="float-label"
                    >
                      <label
                        :class="
                          focusData.email || formData.email
                            ? 'label label-float'
                            : 'label'
                        "
                        >Work email</label
                      >
                      <a-input
                        @focus="setFocus(true, 'email')"
                        :value="formData.email"
                        v-decorator="[
                          'email',
                          {
                            rules: [
                              {
                                type: 'email',
                                message: 'Please enter a valid email address',
                              },
                              {
                                required: true,
                                message: 'Please enter a valid email address',
                              },
                              {
                                validator: checkExistEmail,
                              },
                            ],
                            validateTrigger: 'blur',
                          },
                        ]"
                        @change="onChange($event.target.value, 'email')"
                        @blur="checkExistingEmail($event.target.value)"
                      />
                      <div class="ant-form-explain" v-if="emailValidate">
                        Someone with this email address has already been selected
                      </div>
                    </a-form-item>
                  </a-col>
                  <a-col :span="10" :lg="10" :xs="24">
                    <a-form-item class="float-label custom-top">
                      <label
                        :class="
                          focusData.jobTitle || formData.jobTitle
                            ? 'label label-float'
                            : 'label'
                        "
                        >Relationship</label
                      >
                      <a-select
                        :show-search="true"
                        not-found-content="No matches found"
                        @blur="setFocus(false, 'jobTitle')"
                        @focus="setFocus(true, 'jobTitle')"
                        optionFilterProp="children"
                        v-decorator="[
                          'jobTitle',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Please enter a relationship',
                              },
                            ],
                          },
                        ]"
                        :value="dropDataShow(relationshipList, 'name')"
                        @change="onChangeOthers(relationshipList[$event], 'id')"
                      >
                        <a-select-option
                          v-for="(item, index) in relationshipList"
                          :key="index"
                          :filter-option="
                            (input, item) => {
                              option.name
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0;
                            }
                          "
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
            <a-row :gutter="24" style="margin-top: 20px">
              <a-col :span="24" :lg="24" :xs="24">
                <a-list
                  size="small"
                  :data-source="teamMemberList"
                  v-if="teamMemberList.length > 0"
                >
                  <a-list-item
                    class="list-item-css"
                    slot="renderItem"
                    slot-scope="record, index"
                  >
                    <a-row :gutter="24" class="row-css">
                      <a-icon
                        class="minus-img-css"
                        type="minus-circle"
                        theme="filled"
                        @click="deleteTeamMember(index)"
                      />
                      <a-col class="name-css" :span="6">
                        <a-popover placement="right" :mouse-enter-delay="0.5">
                          <template slot="content">
                            {{ record.firstName }}&nbsp;{{
                              record.lastName
                            }}</template
                          >
                          <span class="role-item-text">
                            {{ record.firstName }}&nbsp;{{ record.lastName }}
                          </span></a-popover
                        >
                      </a-col>
                      <a-col class="email-css" :span="10">
                        <a-popover placement="right" :mouse-enter-delay="0.5">
                          <template slot="content">
                            {{ record.email }}</template
                          >
                          <span class="role-item-text">
                            {{ record.email }}
                          </span></a-popover
                        >
                      </a-col>
                      <a-col
                        class="role-css role-item"
                        :span="8"
                        v-if="record.other"
                        ><a-popover placement="right" :mouse-enter-delay="0.5">
                          <template slot="content"
                            >{{ record.other }}
                          </template>
                          <span class="role-item-text"
                            >{{ record.other }}
                          </span></a-popover
                        ></a-col
                      >
                      <a-col class="role-css role-item" :span="8" v-else
                        ><a-popover placement="right" :mouse-enter-delay="0.5">
                          <template slot="content"
                            >{{ record.jobTitle }}
                          </template>
                          <span class="role-item-text"
                            >{{ record.jobTitle }}
                          </span></a-popover
                        ></a-col
                      >
                    </a-row>
                  </a-list-item>
                </a-list>
              </a-col>
            </a-row>
            <a-row
              :gutter="24"
              v-if="teamMemberList.length == 0"
              class="no-data-wrapper"
            >
              <a-col class="font-16 clr" :span="24" :lg="24" :xs="24">
                <p>
                  Your nominated reviewers will display here for you to preview.
                </p>
              </a-col>
            </a-row>
            <a-row :gutter="24" class="pb-2" style="padding-top: 15px">
              <a-col
                style="padding-top: 13px; text-align: end"
                :span="18"
                :lg="18"
                :xs="24"
              >
                <div
                  style="padding-top: 5px; text-align: end"
                  class="red"
                  v-if="dataValidate && teamMemberList.length <= 1"
                >
                  Please add at least two reviewers by hitting the '+' button
                </div>
              </a-col>

              <a-col
                style="padding-top: 5px; text-align: end"
                :span="6"
                :lg="6"
                :xs="24"
              >
                <a-button
                  style="width: fit-content !important"
                  type="primary"
                  :html-type="teamMemberList.length == 0 ? 'submit' : null"
                  @click="saveTeamMember"
                  >Submit</a-button
                >
              </a-col>
            </a-row>
          </a-form>
        </div>
      </div>
      <confirm-pop-up
        :is-show="loadingFlag"
        :loading="true"
        header-text="Loading"
        contain-text="Hang tight..."
      />
      <error-pop-up :is-show="errorPopupFlag" @closePopup="closedErrorPopup" />
    </div>
  </div>
</template>
<script>
import services from "../../services/services";

import { pageview } from "vue-gtag";
import ErrorPopUp from "@/components/ErrorPopUp.vue";
import ConfirmPopUp from "@/components/ConfirmPopUp.vue";
export default {
  name: "NominateReviewers",
  components: { ErrorPopUp, ConfirmPopUp },
  data() {
    return {
      pageview,
      services,
      errorPopupFlag: false,
      loadingFlag: false,
      dataValidate: false,
      teamMemberList: [],
      jobTitleTextBox: false,
      relationshipList: [],
      dynamicSpan: 24,
      emailValidate: false,
      surveyId: 0,
      reviewersDetails: [],
      reviewersDetailsAll: {},
      fullName: "",
      teamMemberId: 0,
      userId: 0,
      form: this.$form.createForm(this, { name: "coordinated" }),
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        jobId: "",
        userId: this.userId,
        jobTitle: null,
        other: null,
        createdBy: 0,
        updatedBy: 0,
      },
      focusData: {
        focusDataFn: false,
        focusDataLn: false,
        jobTitle: false,
        other: false,
        email: false,
      },
    };
  },
  created() {
    this.track();

    if (this.$route.query.surveyId) {
      this.surveyId = this.$route.query.surveyId;
      this.userId = this.$route.query.userId;
      this.teamMemberId = this.$route.query.teamMemberId;
      this.checkDueDate();
    }
    this.services
      .get("survey/listReviewerRelationships")
      .then((res) => {
        this.relationshipList = res.data.body;
      })
      .catch((err) => {
        console.log(err);
      });
    this.getReviewrsDetails();
  },
  mounted() {
    if (this.$route.query.surveyId) {
      this.surveyId = this.$route.query.surveyId;
      this.userId = this.$route.query.userId;
    }
  },
  methods: {
    track() {
      this.pageview("nominatereviewers_main");
    },
    getReviewrsDetails() {
      this.services
        .get(`team/teamMemberDetailsById?teamMemberId=${this.teamMemberId}`)
        .then((res) => {
          let resData = res.data.body;
          this.reviewersDetailsAll = resData[0];
          this.fullName =
            this.reviewersDetailsAll.firstName +
            " " +
            this.reviewersDetailsAll.lastName;
          this.$store.state.reviewersDetails = this.reviewersDetailsAll;
          this.loadingFlag = false;
        })
        .catch((err) => {
          this.loadingFlag = false;
        });
    },
    checkDueDate() {
      this.services
        .get(
          `survey/isSurveyDue?surveyId=${this.surveyId}&teamMemberId=${this.teamMemberId}`
        )
        .then((res) => {})
        .catch((err) => {
          if (err.response.status == 305) {
            this.$router.push("/nominate-reviewers-expired");
          }

          //this.errorPopupFlag = true//;
        });
    },
    closedErrorPopup(val) {
      this.errorPopupFlag = val;
    },
    setDataOnUpdate() {
      let getformData = JSON.parse(JSON.stringify(this.updatedMemberData));
      if (this.updatedOption && getformData.firstName) {
        for (let key in getformData) {
          this.formData[key] = getformData[key];
          if (key == "job") {
            this.formData[key] = getformData.job.jobTitle;
            key = "jobTitle";

            this.onChangeOthers(
              {
                id: getformData.jobId,
                jobTitle: getformData.job.jobTitle,
              },
              "id"
            );
          }
          if (!this.preValue) {
            this.form.getFieldDecorator(key, {
              initialValue: this.formData[key],
            });
          } else {
            this.form.setFieldsValue(this.formData);
          }
        }
        this.preValue = this.updatedOption;
      } else {
      }
    },
    removeTeamMember() {
      this.$emit("removedTeamMember", this.formData);
    },
    setFocus(val, key) {
      this.focusData[key] = val;
    },
    deleteTeamMember(index) {
      this.emailValidate = false;
      this.teamMemberList.splice(index, 1);
    },
    addNewTeamMember() {
      this.callApiCheckExistEmail(this.emailErrormsg, this.formData.email);
    },
    addNewTeamMemberonTable() {
      if (!this.emailValidate) {
        this.formData.userId = this.userId;
        this.teamMemberList.unshift(this.formData);
        this.formData = {
          firstName: null,
          lastName: null,
          email: null,
          jobId: null,
          userId: this.userId,
          jobTitle: null,
          other: null,
          createdBy: this.userId,
          updatedBy: this.userId,
        };
        this.form.setFieldsValue(this.formData);
        this.form.setFieldsValue({ jobTitle: null });
      }
    },
    addNewMember(e) {
      this.handleSubmit(e, true);
    },
    dropDataShow(val, key) {
      return val[key];
    },
    saveTeamMember() {
      
      if (this.teamMemberList.length > 1 && !this.updatedOption) {
        this.addMemberFlag = false;
        this.isShowAddMemberPopUpLocal = false;
        this.loadingFlag = true;
        let reqArr = [];
        this.teamMemberList.forEach((element) => {
          let commonObj = {
            firstName: element.firstName,
            lastName: element.lastName,
            email: element.email,
            reviewerRelationshipsId: element.jobId,
            userId: element.userId,
          };
          reqArr.push(commonObj);
        });
        this.loadingFlag = true;
        
        this.services
          .post(`survey/addReviewer`, reqArr)
          .then((res) => {
            this.loadingFlag = false;
            this.reviewersDetails = res.data.reviewersDetails;
            if (this.reviewersDetails.length > 0) {
              this.assignReviwer();
            }
          })
          .catch((err) => {
            this.loadingFlag = false;
            //this.errorPopupFlag = true//;
          });
      } else {
        this.dataValidate = true;
      }
    },
    assignReviwer() {
      let reqData = [];
      for (let i = 0; i < this.reviewersDetails.length; i++) {
        reqData.push({
          surveyId: this.surveyId,
          reviewerId: this.reviewersDetails[i].id,
        });
      }
      this.services
        .post(`survey/assignReviewersToSurvey`, reqData)
        .then((res) => {
          this.$router.push("/nominate-complete");
        })
        .catch((err) => {
          //this.errorPopupFlag = true//;
        });
    },
    checkExistEmail(rule, value, callback) {
      
      const form = this.form;
      let emailValidate = false;
      if (value && emailValidate) {
        callback("");
      } else {
        callback();
      }
      this.emailErrormsg = form.getFieldError("email");
    },

    callApiCheckExistEmail(emailErrormsg, val) {
      this.emailValidate = false;
      if (val && (!emailErrormsg || this.emailErrormsg[0] == "")) {
        this.isSpinning = true;
        if (this.teamMemberList.length > 0) {
          this.teamMemberList.forEach((item, index) => {
            if (item.email.toLowerCase() == val.toLowerCase()) {
              this.emailValidate = true;
            }
          });
        }
        if (!this.emailValidate) {
          this.loadingFlag = true;
          this.services
            .get(
              `survey/isReviewerAssignedToSurvey?surveyId=${this.surveyId}&email=${val}`
            )
            .then((res) => {
              this.isSpinning = false;
              this.setFocus(false, "jobTitle");
              let alreadyExist = res.data.body;

              if (alreadyExist) {
                this.emailValidate = true;
              } else {
                this.emailValidate = false;
                this.addNewTeamMemberonTable();
              }
              this.loadingFlag = false;
            })
            .catch((err) => {
              this.loadingFlag = false;
              if (err.response.status == 302) {
                this.emailValidate = true;
              } else {
                //this.errorPopupFlag = true//;
              }
            });
        }
      }
    },
    checkExistingEmail(val) {
      this.setFocus(false, "email");
    },
    onChange(val, key) {
      if (key == "email") {
        this.emailValidate = false;
        this.form.setFieldsValue({
          [key]: val,
        });
      }
      this.formData[key] = val;
    },
    onChangeOthers(val, key) {
      this.formData.jobId = val[key];
      this.formData.jobTitle = val.name;

      if (val.name.toLowerCase() == "other") {
        this.jobTitleTextBox = true;
        this.dynamicSpan = 12;
        this.dynamicSpan2 = 12;
      } else {
        this.jobTitleTextBox = false;
        this.dynamicSpan = 24;
      }
    },

    handleClose() {
      this.successFlag = !this.successFlag;
    },
    closedPopup(val) {
      this.addMemberFlag = !val;
      this.isShowAddMemberPopUpLocal = val;
      this.$emit("sendCode", this.isShowAddMemberPopUpLocal);
    },
    cancelBtn() {
      this.formData = {
        firstName: null,
        lastName: null,
        email: null,
        jobId: null,
        userId: this.userId,
        jobTitle: null,
        other: null,
        createdBy: this.userId,
        updatedBy: this.userId,
      };
      this.form.setFieldsValue(this.formData);

      this.isShowAddMemberPopUpLocal = false;

      this.$emit("sendCode", false);
    },
    handleSubmit(e, flag) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err && !this.emailValidate) {
          if (flag) {
            this.addNewTeamMember();
          } else {
            this.saveTeamMember();
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>

::v-deep {
  .ant-modal-footer {
    display: none !important;
  }

  .round-grey-box {
    background: #bfc3d6;
    border-radius: 10px;
    padding: 30px;
  }
  .pl-pr {
    padding-left: 15px;
    padding-right: 25px;
  }

  .list-item-css {
    background: #edeef5;
    border-radius: 5px;
    margin-bottom: 10px !important;
  }
  .ant-list-item {
    padding: 13px 2% !important;
  }
  .name-css {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #363644;
  }
  .email-css {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #363644;
  }
  .role-css {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #363644;
  }
  .row-css {
    width: 100%;
    margin-left: 0px !important;
    margin-right: 12px !important;
  }
  .plus-img-css {
    position: absolute !important;
    right: -2.8% !important;
    top: 40% !important;
    font-size: 40px !important;
    color: #6454f0 !important;
    background: linear-gradient(to left, white 50%, white 50%) !important;
    border-radius: 50% !important;
  }
  @media screen and (min-width: 1370px) {
    .minus-img-css {
      right: -39px !important;
    }
    .plus-img-css {
      right: -2% !important;
    }
  }
  @media screen and (min-width: 1511px ) {
    .minus-img-css {
      right: -35px !important;
    }
    .plus-img-css {
      right: -2.7% !important;
    }
  }
   @media screen and (min-width: 1700px ) {
    .minus-img-css {
      right: -36px !important;
    }
    .plus-img-css {
      right: -2.5% !important;
    }
  }
   @media screen and (min-width: 1813.33px ) {
    .minus-img-css {
      right: -38px !important;
    }
    .plus-img-css {
      right: -2.3% !important;
    }
  }
    @media screen and (min-width: 2720px ) {
    .minus-img-css {
      right: -46px !important;
    }
    .plus-img-css {
      right: -1.5% !important;
    }
  }
  @media screen and (min-width: 4080px ) {
    .minus-img-css {
      right: -62px !important;
    }
    .plus-img-css {
      right: -0.9% !important;
    }
  }
@media screen and (min-width: 5440px ) {
    .minus-img-css {
      right: -72px !important;
    }
    .plus-img-css {
      right: -0.85% !important;
    }
  }
  .plus-img-css:hover,
  .plus-img-css:focus {
    color: #493bc8 !important;
    border-color: #493bc8 !important;
    cursor: pointer !important;
  }
  .minus-img-css {
    position: absolute !important;
    right: -5.3% ;
    top: 10% !important;
    font-size: 18px !important;
    color: #6454f0 !important;
  }
  .minus-img-css:hover,
  .minus-img-css:focus {
    color: #493bc8 !important;
    border-color: #493bc8 !important;
    cursor: pointer !important;
  }
  .ant-popover-inner-content {
    padding: 8px 8px !important;
    color: rgba(0, 0, 0, 0.65) !important;
  }
  .role-item {
    width: 33.33%;
  }
  .role-item-text {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  .ant-form-explain {
    text-align: left !important;
    color: #f13c47;
    line-height: 19px;
    margin-bottom: -20px !important;
  }
  .ant-btn-primary {
    font-size: 18px !important;
    height: 45px !important;
    span {
      margin: auto 35px !important;
    }
  }
  .confirm-contain-txt {
    font-size: 16px !important;
  }
  .pl-pr {
    padding-left: 4%;
    padding-right: 7%;
  }
  .ant-input {
    padding-top: 13px !important;
  }
  .ant-select-selection {
    padding-top: 7px !important;
  }
  .pointer {
    font-size: 16px;
    border-bottom: 1px solid #6454f0;
  }
  .red {
    color: #f13c47 !important;
    text-align: end !important;
    font-size: 14px !important;
    margin: 0px !important;
  }
  .ant-input,
  .ant-select-selection {
    font-size: 14px !important;
  }
  .clr {
    color: #bfc3d6 !important;
  }
  .label,
  .ant-form-item label {
    color: #bfc3d6 !important;
  }
  .custom-top .label,
  .custom-top .ant-form-item label {
    color: #bfc3d6 !important;
    top: 5px !important;
  }
  .custom-top .label-float,
  .custom-top .ant-form-item .label-float {
    color: #bfc3d6 !important;
    top: -5px !important;
  }
  .pl-pr .ant-select-selection__rendered,
  .pl-pr .ant-select-selection {
    line-height: 34px;
    background-color: #ffffff !important;
  }

  .ant-select-arrow,
  .anticon .anticon-down .ant-select-arrow-icon {
    color: black !important;
  }
}
</style>