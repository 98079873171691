var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-html2pdf',{ref:_vm.filename,attrs:{"show-layout":false,"float-layout":true,"enable-download":true,"preview-modal":false,"pdf-quality":2,"manual-pagination":false,"paginate-elements-by-height":2000,"pdf-content-width":"1100px","htmlToPdfOptions":{
      margin: [10, 0, 0, 0],
      filename: (_vm.filename + ".pdf"),
      jsPDF: {
        orientation: 'p',
        unit: 'mm',
        format: [320, 470],
      },
      html2canvas: { scale: 2 },
    }}},[_vm._t("default",null,{"slot":"pdf-content"})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }