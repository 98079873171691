<template>
  <div class="grey-bg-color user-profile-res">
    <div>
      <a-row :gutter="24">
        <a-col :span="16"
          ><img
            alt="gestaltra_logo_grey"
            width="180px"
            height="52px"
            src="../../assets/gestaltra_logo_grey.png"
          />
        </a-col>
        <a-col :span="6"> </a-col>
        <a-col
          class="profile-icon float-r"
          style="text-align: right"
          :span="4"
          :lg="4"
          :sm="8"
        >
          <a-dropdown :trigger="['click']">
            <span
              class="dot ant-dropdown-link pointer"
              @click="(e) => e.preventDefault()"
            >
              <span class="profile-icon-txt">{{ firstLetter }}</span>
            </span>
            <a-menu id="arrow" slot="overlay">
              <a-menu-item class="cus-disable" key="0">
                <a-row :gutter="24">
                  <a-col :span="24" style="text-align: center !important">
                    <span class="user-name"
                      >{{ firstName }}&nbsp;{{ lastName }}</span
                    >
                  </a-col>
                </a-row>
              </a-menu-item>
              <a-menu-divider />
              <a-menu-item key="1" @click="goToProfile()">
                <a-row :gutter="24">
                  <a-col :span="24">
                    <span class="profile-txt"
                      ><img
                        alt="user"
                        style="margin-right: 10px"
                        width="16px"
                        height="16px"
                        src="../../assets/user.png"
                      />Profile</span
                    >
                  </a-col>
                </a-row>
              </a-menu-item>
              <a-menu-item key="2" @click="goToLandingPage()">
                <a-row :gutter="24">
                  <a-col :span="24">
                    <span class="profile-txt"
                      ><img
                        alt="logout-icon"
                        style="margin-right: 10px"
                        width="16px"
                        height="16px"
                        src="../../assets/logout-icon.png"
                      />Logout</span
                    >
                  </a-col>
                </a-row>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </a-col>
      </a-row>
      <a-row :gutter="24" class="padding-css">
        <a-col class="profile-bg" :span="24" :lg="24" :sm="24" :xs="24">
          <a-row style="padding-bottom: 13px" :gutter="24">
            <a-col
              class="header-title-txt"
              :span="24"
              :lg="24"
              :sm="20"
              :xs="24"
            >
              Profile
            </a-col>
          </a-row>
          <a-row class="pt-2" style="padding-bottom: 13px" :gutter="24">
            <a-col
              class="bold-right-txt"
              style="font-size: 18px !important"
              :span="24"
              :lg="24"
              :sm="20"
              :xs="24"
            >
              Contact information
            </a-col>
          </a-row>
          <a-row class="grey-bg-css" :gutter="24">
            <a-col class="label-font-css" :span="12" :lg="12" :sm="20" :xs="24">
              Name
            </a-col>
            <a-col class="v-txt-css" :span="16" :lg="16" :sm="20" :xs="24">
              {{ firstName }} {{ lastName }}
            </a-col>
          </a-row>
          <a-row class="grey-bg-css" style="margin-top: 10px" :gutter="24">
            <a-col class="label-font-css" :span="12" :lg="12" :sm="20" :xs="24">
              Email
            </a-col>
            <a-col class="v-txt-css" :span="16" :lg="16" :sm="20" :xs="24">
              {{ emailId }}
            </a-col>
          </a-row>
          <a-row style="padding-bottom: 13px; margin-top: 20px">
            <a-col
              class="bold-right-txt"
              style="font-size: 18px !important"
              :span="24"
              :lg="24"
              :sm="24"
            >
              Change password
            </a-col>
          </a-row>
          <a-row class="grey-bg-css" :gutter="24">
            <a-col :span="24" :lg="24" :sm="20" :xs="24">
              <a-form :form="form" @submit="handleSubmit">
                <a-row :gutter="24">
                  <a-col
                    class="label-font-css"
                    style="padding-top: 10px"
                    :span="12"
                    :lg="12"
                    :sm="24"
                    :xs="24"
                  >
                    Current password
                  </a-col>
                  <a-col
                    class="label-font-css"
                    :span="12"
                    :lg="12"
                    :sm="20"
                    :xs="24"
                  >
                    <a-form-item :class="dataValidate ? 'has-error' : null">
                      <a-input-password
                        v-decorator="[
                          'c_password',
                          {
                            rules: [
                              {
                                required: true,
                                message:
                                  'Current password can’t be blank – please enter your current password',
                              },
                            ],
                          },
                        ]"
                        :value="formData.c_password"
                        @change="onChange($event.target.value, 'c_password')"
                        @blur="checkpassword($event.target.value)"
                      />
                      <span class="ant-form-explain" v-if="dataValidate">
                        Password is incorrect
                      </span>
                    </a-form-item>
                  </a-col>
                  <a-col
                    class="error-txt"
                    style="padding-top: 10px"
                    :span="12"
                    :lg="12"
                    :sm="24"
                  >
                  </a-col>
                </a-row>
                <a-row :gutter="24">
                  <a-col
                    class="label-font-css"
                    style="padding-top: 10px"
                    :span="12"
                    :lg="12"
                    :sm="24"
                  >
                    New password
                  </a-col>
                  <a-col
                    class="label-font-css"
                    :span="12"
                    :lg="12"
                    :sm="20"
                    :xs="24"
                  >
                    <a-form-item>
                      <a-popover
                        placement="right"
                        trigger="click"
                        :overlay-style="{
                          'font-size': '12px !important',
                          'border-radius': '10px !important',
                        }"
                      >
                        <template
                          slot="content"
                          :overlay-style="{
                            'border-radius': '10px !important',
                          }"
                        >
                          <p><strong>Password must:</strong></p>
                          <p>
                            • Be a minimum of 8 characters
                            <span v-if="passlength">
                              <img
                                src="../../assets/tick.png"
                                style="width: 18px"
                                alt=""
                                class="success-unsuccess-icons"
                              />
                            </span>
                            <span v-else>
                              <img
                                src="../../assets/cross.png"
                                style="width: 18px"
                                alt=""
                              />
                            </span>
                          </p>
                          <p>
                            • Have a mixture of letters and numbers
                            <span v-if="letterNumber">
                              <img
                                src="../../assets/tick.png"
                                alt=""
                                style="width: 18px"
                              />
                            </span>
                            <span v-else>
                              <img
                                src="../../assets/cross.png"
                                alt=""
                                style="width: 18px"
                            /></span>
                          </p>
                          <p>
                            • Include at least one special character<br />&nbsp;&nbsp;(e.g.
                            ! @ # ?)
                            <span v-if="specialCharacter">
                              <img
                                src="../../assets/tick.png"
                                style="width: 18px"
                                alt=""
                              />
                            </span>
                            <span v-else>
                              <img
                                src="../../assets/cross.png"
                                style="width: 18px"
                                alt=""
                            /></span>
                          </p>
                        </template>
                        <a-input-password
                          v-decorator="[
                            'n_password',
                            {
                              rules: [
                                {
                                  required: true,
                                  message:
                                    'New password can’t be blank – please enter a new password',
                                },
                                {
                                  validator: validateToNextPassword,
                                },
                                {
                                  pattern: passwordRegex,
                                  lengthRegex,
                                  letternumRegex,
                                  specialCharRegex,
                                  message: 'Please enter a valid password',
                                },
                              ],
                            },
                          ]"
                          :value="formData.n_password"
                          @change="onChange($event.target.value, 'n_password')"
                        />
                      </a-popover>
                    </a-form-item>
                  </a-col>
                  <a-col
                    class="error-txt"
                    style="padding-top: 10px"
                    :span="6"
                    :lg="6"
                    :sm="24"
                  >
                  </a-col>
                </a-row>
                <a-row :gutter="24">
                  <a-col
                    class="label-font-css"
                    style="padding-top: 10px"
                    :span="12"
                    :lg="12"
                    :sm="24"
                  >
                    Confirm new password
                  </a-col>
                  <a-col
                    class="label-font-css"
                    :span="12"
                    :lg="12"
                    :sm="20"
                    :xs="24"
                  >
                    <a-form-item>
                      <a-input-password
                        v-decorator="[
                          'cn_password',
                          {
                            rules: [
                              {
                                required: true,
                                message:
                                  'Passwords don’t match – please try again',
                              },
                              {
                                validator: compareToFirstPassword,
                              },
                            ],
                          },
                        ]"
                        :value="formData.cn_password"
                        @change="onChange($event.target.value, 'cn_password')"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col
                    class="error-txt"
                    style="padding-top: 10px"
                    :span="12"
                    :lg="12"
                    :sm="24"
                  >
                  </a-col>
                </a-row>
                <a-row :gutter="24">
                  <a-button
                    class="btn-black"
                    style="width: auto !important"
                    type="primary"
                    html-type="submit"
                    >Change password</a-button
                  >
                </a-row>
              </a-form>
            </a-col>
          </a-row>

          <a-row style="padding-bottom: 13px; margin-top: 20px" :gutter="24">
            <a-col
              class="bold-right-txt"
              style="font-size: 18px !important"
              :lg="12"
              :sm="24"
              :xs="24"
            >
              Remove account
            </a-col>
          </a-row>
          <a-row class="grey-bg-css" :gutter="24">
            <a-col :span="24" :lg="24" :sm="20" :xs="24">
              <a-row :gutter="24">
                <a-col
                  class="label-font-css"
                  style="padding-top: 10px"
                  :span="24"
                  :lg="24"
                  :sm="24"
                >
                  Would you like to delete your account and data? Please note
                  that this is not reversible.
                </a-col>
              </a-row>
              <a-row :gutter="24" style="padding-top: 20px">
                <a-button
                  class="btn-black"
                  type="primary"
                  style="width: auto !important"
                  @click="confirmRemoveAccount()"
                  >Remove account</a-button
                >
              </a-row>
            </a-col>
          </a-row>
          <a-row style="padding-bottom: 13px; padding-top: 32px" :gutter="24">
            <a-col :span="20" :lg="20" :sm="20" :xs="24"> </a-col>
            <a-col :span="4" :lg="4" :sm="20" :xs="24">
              <a-button type="primary" @click="BackToHome">Back</a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
    <log-out-pop-up
      :is-show="isShowLogOut"
      button-text="Cancel"
      @closeRemove="colseLoginPopup"
      @removeMemberData="logOutDevice"
    />
    <all-log-out-pop-up
      :is-show="isShowAllLogOut"
      :is-all-device="isAllDevice"
      button-text="Cancel"
      @closeRemove="colseAllLogOutPopup"
    />
    <confirm-pop-up
      :is-show="successUpdateFlag"
      class="success-popup"
      :logo="true"
      header-text="Password changed"
      contain-text="Your password has been changed successfully."
      button-text="Close"
      @closePopup="closedPopupUpdate"
    />
    <remove-pop-up
      :is-show="isShowRemove"
      class-name="remove-css"
      confirm-msg="Are you sure you want to remove your account?"
      header-text="Remove account"
      contain-text="If you delete your account, you will permanently lose your profile. This is not reversible."
      button-text="Cancel"
      @closeRemove="colseRemovePopup"
      @removeMemberData="removeAccount"
    />
    <confirm-pop-up
      :is-show="loadingFlag"
      :loading="true"
      header-text="Loading"
      contain-text="Hang tight..."
    />
    <confirm-pop-up
      :is-show="successDeleteFlag"
      class="success-popup"
      :logo="true"
      header-text="Done"
      contain-text="Your account has been successfully removed."
      button-text="OK"
      @closePopup="closedPopupDelete"
    />
  </div>
</template>
<script>
import ConfirmPopUp from "@/components/ConfirmPopUp.vue";
import RemovePopUp from "@/components/RemovePopUp.vue";
import services from "../../services/services";
import LogOutPopUp from "@/components/LogOutPopUp.vue";
import AllLogOutPopUp from "@/components/AllLogOutPopUp.vue";
import { pageview, event } from "vue-gtag";
export default {
  name: "Profile",
  components: {
    ConfirmPopUp,
    RemovePopUp,
    LogOutPopUp,
    AllLogOutPopUp,
  },
  data() {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\\\])(?=.{8,})/;
    const lengthRegex = /^[a-zA-Z0-9]{8,}$/;
    const letternumRegex = /^(?=.*[a-z])(?=.*[0-9])/;
    const specialCharRegex = /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\\\]).*$/;
    return {
      loadingFlag: false,
      passlength: false,
      letterNumber: false,
      specialCharacter: false,
      isShowLogOut: false,
      isShowAllLogOut: false,
      isAllDevice: false,
      lengthRegex,
      passwordRegex,
      specialCharRegex,
      letternumRegex,
      pageview,
      event,
      services,
      secret: "123#$%",
      userId: 0,
      firstName: "",
      lastName: "",
      firstLetter: "",
      emailId: "",
      isShowRemove: false,
      successDeleteFlag: false,
      successUpdateFlag: false,
      dataValidate: false,
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      formData: {
        c_password: "",
        n_password: "",
        cn_password: "",
      },
    };
  },
  created() {
    if (localStorage.getItem("token")) {
      let token = localStorage.token;
      const decryptedText = this.$CryptoJS.AES.decrypt(
        token,
        this.secret
      ).toString(this.CryptoJS.enc.Utf8);
      this.$store.state.userToken = decryptedText;
    }
    this.track();
    let userDetailsData = localStorage.getItem("userDetails");
    this.userDetails = JSON.parse(userDetailsData);
    this.userId = this.userDetails.userId
      ? this.userDetails.userId
      : this.userId;

    this.emailId = this.userDetails.email
      ? this.userDetails.email
      : this.emailId;
    this.firstName = this.userDetails.firstName
      ? this.userDetails.firstName
      : this.firstName;
    this.firstName = this.firstName[0].toUpperCase() + this.firstName.slice(1);
    this.lastName = this.userDetails.lastName
      ? this.userDetails.lastName
      : this.lastName;
    this.lastName = this.lastName[0].toUpperCase() + this.lastName.slice(1);
    let firstChar = this.userDetails.firstName
      ? this.userDetails.firstName
      : this.firstName;
    this.firstLetter = firstChar.substring(0, 1).toUpperCase();
  },
  methods: {
    track() {
      this.pageview("manageaccount_main");
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.changePassword();
        }
      });
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;

      if (value && value !== form.getFieldValue("n_password")) {
        callback("Passwords don’t match – please try again");
      } else {
        callback();
      }
    },

    gaEventTrack(data, action, category) {
      this.event(action, {
        event_category: category,
        event_label: data,
        value: 1,
      });
    },
    changePassword() {
      let postData = {
        currentPassword: this.formData.c_password,
        newPassword: this.formData.n_password,
        userId: this.userId,
      };
      if (postData) {
        this.services
          .post("user/changePassword", postData)
          .then((res) => {
            if (res.status == 200) {
              this.gaEventTrack(
                postData.userId,
                "manageaccount_passwordchange_success",
                "Change password pop up - success"
              );
              this.successUpdateFlag = true;
              this.formData = {
                c_password: "",
                n_password: "",
                cn_password: "",
              };
              this.form.setFieldsValue(this.formData);
            }

            console.log(res);
          })
          .catch((err) => {
            if (err.response.status == 305) {
              this.dataValidate = true;
            } else if (err.response.status == 304) {
              this.$notification["error"]({
                message: "Record Not Found.",
              });
            } else if (err.response.status == 500) {
              this.$notification["error"]({
                message: "Internal Server Error.",
              });
            }
            console.log(err);
          });
      }
    },
    colseRemovePopup(val) {
      this.isShowRemove = val;
    },
    confirmRemoveAccount() {
      this.isShowRemove = true;
    },

    closedPopupDelete(val) {
      this.successDeleteFlag = val;
      this.$router.push("/");
    },

    removeAccount(val) {
      this.loadingFlag = true;
      let reqBody = {
        userId: this.userId,
      };
      this.services
        .post(`user/removeAccount`, reqBody)
        .then((res) => {
          this.loadingFlag = false;
          if (res.status == 200) {
            this.isShowRemove = val;
            this.successDeleteFlag = !val;
            localStorage.removeItem("token");
            localStorage.removeItem("userDetails");
            localStorage.removeItem("userDeviceName");
          }
          this.gaEventTrack(
            this.userId,
            "manageaccount_removeaccount_success",
            "Remove account (success)"
          );
        })
        .catch((err) => {
          this.loadingFlag = false;
          if (err.response.status == 400) {
            this.$notification["error"]({
              message: "User Id should not be blank.",
            });
          } else if (err.response.status == 304) {
            this.$notification["error"]({
              message: "Record Not Found.",
            });
          } else if (err.response.status == 500) {
            this.$notification["error"]({
              message: "Internal Server Error.",
            });
          }
          console.log(err);
        });
    },
    onChange(val, key) {
      this.dataValidate = false;
      this.form.setFieldsValue({
        [key]: val,
      });
      this.formData[key] = val;
    },
    checkpassword(val) {
      if (val) {
        this.services
          .post(`user/checkPassword`, {
            currentPassword: val,
            userId: this.userId,
          })
          .then((res) => {})
          .catch((err) => {
            if (err.response.status == 305) {
              this.dataValidate = true;
            }
          });
      }
    },
    closedPopupUpdate(val) {
      this.successUpdateFlag = val;
    },
    goToProfile() {
      this.$router.push({
        path: "/profile",
        query: { tab: this.$route.query.tab || "" },
      });
    },
    BackToHome() {
      this.$router.push({
        path: "/main",
        query: { tab: this.$route.query.tab || "" },
      });
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      if (value && this.confirmDirty) {
        form.validateFields(["confirm"], { force: true });
      }

      if (value) {
        this.passlength =
          this.lengthRegex.test(value) || this.passwordRegex.test(value);
        this.letterNumber = this.letternumRegex.test(value);
        this.specialCharacter = this.specialCharRegex.test(value);
      } else {
        this.passlength = false;
        this.letterNumber = false;
        this.specialCharacter = false;
      }

      callback();
    },
    goToLandingPage() {
      this.isShowLogOut = true;
    },
    colseLoginPopup(val) {
      this.isShowLogOut = val;
    },
    colseAllLogOutPopup(val) {
      this.isShowAllLogOut = val;
      this.$router.push("/");
    },
    logOutDevice(val) {
      this.isShowLogOut = false;
      this.loadingFlag = true;
      let postData = {
        userID: this.userId,
        device: localStorage.getItem("userDeviceName"),
        logOutFromAllDevice: val,
      };
      this.services
        .post(`user/logOut`, postData)
        .then((res) => {
          this.loadingFlag = false;
          if (res.status == 200) {
            localStorage.removeItem("token");
            localStorage.removeItem("userDetails");
            localStorage.removeItem("userDeviceName");

            this.isAllDevice = val;
            this.isShowAllLogOut = true;
            // this.$router.push("/");
          }
          // this.isStartNewSurveyPop = true;
        })
        .catch((err) => {
          this.loadingFlag = false;
          if (err.response.status == 303) {
            this.errorPopupFlag = true;
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.error-css {
  color: #f13c47;
  font-size: 12px;
  line-height: 19px;
  margin-bottom: -15px;
}
#arrow {
  background-color: #fff;
  color: #fff;
  border-radius: 0px !important;
  padding: 5px;
  z-index: 999;
  width: 178px;
  position: relative;
}

#arrow:before {
  position: absolute;
  top: -10px;
  right: 15px;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #fff;
}
::v-deep {
  .ant-dropdown-menu-item-divider,
  .ant-dropdown-menu-submenu-title-divider {
    height: 0px !important;
    margin: 0px 0 !important;
    overflow: hidden !important;
    line-height: 0 !important;
    background-color: #e8e8e8 !important ;
  }
  .ant-form-item-control {
    line-height: 0px !important;
  }
  .ant-form-item.has-error {
    margin-bottom: 10px !important;
  }
  .ant-form-explain {
    font-size: 12px !important;
  }
  .ant-input {
    height: 45px !important;
  }
  .ant-modal-content {
    padding: 4% 15% !important;
  }
  .confirm-msg {
    padding-right: 5% !important;
  }
}
</style>
