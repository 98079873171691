<template>
  <div class="white-bg-color AcceptEmailInvitation">
    <a-row :gutter="24" style="margin-left: 12px; margin-right: 12px">
        <a-col :span="8" :lg="8" :xs="24"> </a-col>
        <a-col class="img-css" :span="8" :lg="8" :xs="24">
          <div>
            <img
            alt="gestaltra_logo_grey"
              width="353px"
              height="99px"
              src="../../assets/gestaltra_logo_grey.png"
            />
          </div>
        </a-col>
        <a-col :span="8" :lg="8" :xs="24"> </a-col>
      </a-row>
    <div class="nom-class">
      <div class="pl-pr" style="padding-top: 49px; padding-bottom: 43px">
        <a-row class="mt-2">
          <a-col style="padding-top: 5px; text-align: center" :span="24">
            <span class="bold-right-txt"
              >Thanks for nominating<br />your reviewers.</span
            >
          </a-col>
        </a-row>
        <a-row class="mt-2">
          <a-col style="padding-top: 10%; text-align: center" :span="24">
            <span style="color: #262626 !important" class="confirm-contain-txt"
              >Now sit back and relax. We’ll do the rest for you! <br />
              If you haven’t already, why not create an account so you can
              view your feedback and add additional reviewers?</span
            >
          </a-col>
        </a-row>
        <a-row class="mt-2">
          <a-col style="padding-top: 10%; text-align: center" :span="24">
            <a-button
              style="width: 150px !important"
              type="primary"
              @click="goToSignUp"
              >Sign up</a-button
            >
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
import { pageview } from "vue-gtag";
export default {
  name: "AcceptEmailInvitation",

  data() {
    return {
      pageview,
    };
  },
  created() {
    this.track();
  },
  methods: {
    track() {
      this.pageview('nominatereviewers_success');
    },
    goToSignUp() {
      this.$router.push("/signup");
    },
  },
};
</script>
<style lang="scss" scoped>
.pl-pr {
  padding-left: 21%;
  padding-right: 21%;
}
.confirm-contain-txt {
  font-size: 16px !important;
}
</style>