<template>
  <div class="grey-bg-color">
    <div>
      <a-row :gutter="24">
        <a-col :span="isSuperUser ? 10 : 18" :sm="10"><img alt="gestaltra_logo_grey" width="180px"
            src="../../assets/gestaltra_logo_grey.png" />
        </a-col>

        <a-col class="profile-icon float-r" :style="getProfileContainerStyle" :span="isSuperUser ? 10 : 4"
          :lg="isSuperUser ? 10 : 4" :sm="8">
          <div v-if="isSuperUser" class="tab">
            <div style="width: 100px; height: 24px" v-for="tab in [ShowTypes.ADMIN, ShowTypes.USER]" :class="[
              `tab-item ${tab}`,
              { 'tab-item--active': tab === curTab },
            ]" @click="handleChangeTab(tab)">
              <p>{{ tab }}</p>
            </div>
          </div>
          <a-dropdown :trigger="['click']">
            <span class="dot ant-dropdown-link pointer" @click="(e) => e.preventDefault()">
              <span class="profile-icon-txt">{{ firstLetter }}</span>
            </span>
            <a-menu id="arrow" slot="overlay">
              <a-menu-item class="cus-disable" key="0">
                <a-row :gutter="24">
                  <a-col :span="24" style="text-align: center !important">
                    <span class="user-name">{{ firstName }}&nbsp;{{ lastName }}</span>
                  </a-col>
                </a-row>
              </a-menu-item>
              <a-menu-divider />
              <a-menu-item key="1" @click="goToProfile">
                <a-row :gutter="24">
                  <a-col :span="24">
                    <span class="profile-txt"><img alt="user" style="margin-right: 10px" width="16px" height="16px"
                        src="../../assets/user.png" />Profile</span>
                  </a-col>
                </a-row>
              </a-menu-item>
              <a-menu-item key="2" @click="goToLandingPage()">
                <a-row :gutter="24">
                  <a-col :span="24">
                    <span class="profile-txt"><img alt="logout-icon" style="margin-right: 10px" width="16px"
                        height="16px" src="../../assets/logout-icon.png" />Logout</span>
                  </a-col>
                </a-row>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </a-col>
      </a-row>
      <a-row :gutter="24" class="pt-25 dashborad-wrapper">
        <a-col :span="8" :lg="8" :s="8" :xs="24" class="left-div-row">
          <div class="left-div-wrapper">
            <div class="left-div">
              <a-row class="pt-10" :gutter="24">
                <a-col class="blue-txt-no-pointer" :span="curTab === ShowTypes.ADMIN ? 14 : 10">
                  <a-icon class="pr-2" :type="curTab === ShowTypes.ADMIN ? 'home' : 'team'" />
                  {{
                    curTab === ShowTypes.ADMIN ? "My Organisation" : "My Team"
                  }}
                </a-col>
                <a-col v-if="curTab === ShowTypes.ADMIN" :span="10">
                  <a-button type="primary" @click="isShowExportPopup = true" class="add-btn small"><img
                      src="@/assets/export.png" style="margin-right: 8px" />
                    Export</a-button>
                </a-col>
                <a-col v-else span="14" :lg="14" :sm="16" :xs="24">
                  <a-button type="primary" @click="addNewMember" class="add-btn small"><a-icon type="plus" /> Add new
                    member</a-button>
                </a-col>
              </a-row>
              <div :class="`mt-10 ${curTab === ShowTypes.ADMIN ? 'list-team-scroll' : 'div-scroll'
                }`" v-if="teamMemberList.length > 0">
                <a-collapse class="accordian-wrapper" v-model="activeKey" :expand-icon-position="expandIconPosition">
                  <a-collapse-panel key="1" header="In progress">
                    <a-list size="small" :data-source="teamMemberList" style="padding-right: 4px !important">
                      <a-list-item slot="renderItem" slot-scope="item, index"
                        @click="selectedTeamMember(item, index, 0)" :class="item.editShow ? 'active' : null" v-if="
                          (item.survey.length > 0 &&
                            item.survey.length == 1 &&
                            item.survey[0].completionFlag == '0') ||
                          (item.survey.length > 0 && !item.surveyCompleted)
                        ">
                        <a-row :gutter="24" style="width: 100% !important; padding-left: 15px">
                          <a-col class="name-list" :span="10" :lg="10" :xs="10">
                            <span :class="!item.isSurveySeen ? 'show' : 'hide'" :span="1"><img alt="dot"
                                src="../../assets/dot.png" class="dot-css" /></span>
                            <a-popover placement="right" :mouse-enter-delay="1.5">
                              <template slot="content">
                                {{ item.firstName }}&nbsp;{{
                                  item.lastName
                                }}</template>
                              <span class="role-item-text">
                                {{ item.firstName }}&nbsp;{{ item.lastName }}
                              </span></a-popover>
                          </a-col>
                          <a-col class="role-list" v-if="item.other" :span="12" :lg="12" :xs="12">
                            <a-popover placement="right" :mouse-enter-delay="1.5">
                              <template slot="content">
                                {{
                                  item.job.jobTitle == "Other"
                                    ? item.other
                                    : item.job.jobTitle
                                }}</template>

                              <span class="role-item-text">
                                {{
                                  item.job.jobTitle == "Other"
                                    ? item.other
                                    : item.job.jobTitle
                                }}
                              </span></a-popover>
                          </a-col>
                          <a-col class="role-list" v-else :span="12" :lg="12" :xs="12">
                            <a-popover placement="right" :mouse-enter-delay="1.5">
                              <template slot="content">
                                {{ item.job.jobTitle }}</template>

                              <span class="role-item-text">
                                {{ item.job.jobTitle }}
                              </span></a-popover>
                          </a-col>
                          <a-col class="list-icon" :span="1" :lg="1">
                            <span v-if="item.editShow" class="edit-icon" @click="UpdateTeamMember(item, 0)"><img
                                alt="Pencil" width="10px" height="10px" src="../../assets/Pencil.png" /></span>
                          </a-col>
                        </a-row>
                      </a-list-item>
                    </a-list>
                    <span class="not-get" v-if="isInProgressFlag">No one’s here... yet</span>
                  </a-collapse-panel>
                  <a-collapse-panel key="2" header="Completed" :disabled="false">
                    <a-list size="small" :data-source="teamMemberList" style="padding-right: 4px !important">
                      <a-list-item slot="renderItem" slot-scope="item, index"
                        @click="selectedTeamMember(item, index, 1)" :class="item.editShow ? 'active' : null" v-if="
                          (item.survey.length > 0 &&
                            item.survey.length == 1 &&
                            item.survey[0].completionFlag == '1') ||
                          (item.survey.length > 1 && item.surveyCompleted)
                        ">
                        <a-row :gutter="24" style="width: 100% !important; padding-left: 15px">
                          <a-col class="name-list" :span="10" :lg="10" :xs="10">
                            <span :class="!item.isSurveySeen ? 'show' : 'hide'" :span="1"><img alt="dot"
                                src="../../assets/dot.png" class="dot-css" /></span>
                            <a-popover placement="right" :mouse-enter-delay="1.5">
                              <template slot="content">
                                {{ item.firstName }}&nbsp;
                                {{ item.lastName }}</template>
                              <span class="role-item-text">
                                {{ item.firstName }}&nbsp;{{ item.lastName }}
                              </span></a-popover>
                          </a-col>
                          <a-col class="role-list" v-if="item.other" :span="12" :lg="12" :xs="12">
                            <a-popover placement="right" :mouse-enter-delay="1.5">
                              <template slot="content">
                                {{
                                  item.job.jobTitle == "Other"
                                    ? item.other
                                    : item.job.jobTitle
                                }}</template>

                              <span class="role-item-text">
                                {{
                                  item.job.jobTitle == "Other"
                                    ? item.other
                                    : item.job.jobTitle
                                }}
                              </span></a-popover>
                          </a-col>
                          <a-col class="role-list" v-else :span="12" :lg="12" :xs="12">
                            <a-popover placement="right" :mouse-enter-delay="1.5">
                              <template slot="content">
                                {{ item.job.jobTitle }}</template>

                              <span class="role-item-text">
                                {{ item.job.jobTitle }}
                              </span></a-popover>
                          </a-col>
                          <a-col class="list-icon" :span="1" :lg="1" :sm="1" :xs="1">
                            <span v-if="item.editShow" class="edit-icon" @click="UpdateTeamMember(item, 1)"><img
                                alt="Pencil" width="10px" height="10px" src="../../assets/Pencil.png" /></span>
                          </a-col>
                        </a-row>
                      </a-list-item>
                    </a-list>
                    <span class="not-get" v-if="isCompletedFlag">No one’s here... yet</span>
                  </a-collapse-panel>
                  <a-collapse-panel key="3" header="Not started" :disabled="false">
                    <a-list size="small" :data-source="teamMemberList" style="padding-right: 4px !important"
                      :ellipsis="true">
                      <a-list-item slot="renderItem" slot-scope="item, index"
                        @click="selectedTeamMember(item, index, 2)" :class="item.editShow ? 'active' : null"
                        v-if="item.survey.length == 0">
                        <a-row :gutter="24" style="width: 100% !important; padding-left: 15px">
                          <a-col class="name-list" :span="10" :lg="10" :xs="10">
                            <a-popover placement="right" :mouse-enter-delay="1.5">
                              <template slot="content">
                                {{ item.firstName }}&nbsp;
                                {{ item.lastName }}</template>
                              <span class="role-item-text">
                                {{ item.firstName }}&nbsp;{{ item.lastName }}
                              </span></a-popover>
                          </a-col>
                          <a-col class="role-list" v-if="item.other" :span="12" :lg="12" :xs="12">
                            <a-popover placement="right" :mouse-enter-delay="1.5" :ellipsis="true">
                              <template slot="content">
                                {{
                                  item.job.jobTitle == "Other"
                                    ? item.other
                                    : item.job.jobTitle
                                }}</template>

                              <span class="role-item-text">
                                {{
                                  item.job.jobTitle == "Other"
                                    ? item.other
                                    : item.job.jobTitle
                                }}
                              </span></a-popover>
                          </a-col>
                          <a-col class="role-list" v-else :span="12" :lg="12" :xs="12">
                            <a-popover placement="right" :mouse-enter-delay="1.5">
                              <template slot="content">
                                {{ item.job.jobTitle }}</template>

                              <span class="role-item-text">
                                {{ item.job.jobTitle }}
                              </span></a-popover>
                          </a-col>
                          <a-col class="list-icon" :span="1" :lg="1">
                            <span v-if="item.editShow" class="edit-icon" @click="UpdateTeamMember(item, 2)"><img
                                alt="Pencil" width="10px" height="10px" src="../../assets/Pencil.png" /></span>
                          </a-col>
                        </a-row>
                      </a-list-item>
                    </a-list>
                    <span class="not-get" v-if="isNotStartedFlag">No one’s here... yet</span>
                  </a-collapse-panel>
                </a-collapse>
              </div>
              <div v-if="teamMemberList.length == 0" class="mt-40">
                <p class="font-16">You currently have no team members</p>
              </div>
            </div>
            <div class="fixed-btm">
              <template v-if="curTab === ShowTypes.USER">
                <hr class="hr-css" />
                <a-row style="padding-bottom: 15px" :gutter="24">
                  <a-col class="blue-txt-no-pointer ml-30" :span="24" :lg="24" :sm="20" :xs="24">
                    <a-icon class="pr-2" type="user" /> Me
                  </a-col>
                </a-row>
                <a-row :gutter="24" class="user-name user-name-sec" :class="userActiveflag ? 'active' : null">
                  <a-col class="bold-txt-list" :span="22" :lg="22" :sm="20" :xs="24" @click="userName">
                    {{ firstName }}&nbsp;{{ lastName }}
                  </a-col>
                </a-row>
              </template>

              <div class="btn-black-wrapper" :gutter="24">
                <a-button class="btn-black" type="primary" @click="startSurvey">Create new survey</a-button>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :span="16" :lg="16" :s="16" :xs="24" class="right-div-wrapper">
          <div class="right-div mr-top-res">
            <a-row :gutter="24" style="padding-top: 0px"><a-col :span="24"></a-col></a-row>
            <div class="right-div-scroll">
              <div v-if="
                (selectedTeamMemberFlag && dashBoradDetailsDrop.length > 0) ||
                (userActiveflag && selfDashBoradDetails.length > 0)
              ">
                <a-row style="padding-bottom: 13px" :gutter="24">
                  <a-col class="bold-right-txt" :span="24" :lg="24" :sm="20" :xs="24">
                    Survey Overview
                  </a-col>
                </a-row>
                <a-row class="grey-box-css p-l-r">
                  <a-col :span="10" :lg="10" :sm="20" :xs="24" style="padding-top: 2px !important">
                    <span class="label-txt">Survey initiated on:</span>
                    <a-select format="DD MMMM YYYY" class="survey-init-class" placeholder="Survey initiated on"
                      key="survey" :default-value="dashBoradDetailsDrop[selectedIndex].dropData
                        ? selectedIndex
                        : null
                        " @change="
                          selectSurveyData(dashBoradDetailsDrop[$event], 'survey')
                          ">
                      <a-select-option class="drop-option" v-for="(item, index) in dashBoradDetailsDrop" :key="index"
                        :value="index">
                        {{ formatDateToShow(item.dropData.createdOn) }}
                      </a-select-option>
                    </a-select>
                  </a-col>
                  <a-col style="padding-top: 4px !important" :span="7" :lg="7" :sm="14" :xs="24">
                    <span class="label-txt">Due date:</span><span class="pl-2 value-txt">{{ dueDate }}</span>
                  </a-col>
                  <a-col style="padding-top: 4px !important" :span="7" :lg="7" :sm="14" :xs="24">
                    <span class="label-txt">Days remaining:</span><span class="pl-2 value-txt">{{ remainingDate
                      }}</span>
                  </a-col>
                </a-row>
                <a-row class="grey-box-css p-l-r mt-10">
                  <a-col style="padding-top: 5px" :span="12" :lg="12" :sm="18" :xs="24">
                    <span class="label-txt">Position at time of survey:</span><span class="pl-2 value-txt">{{
                      teamMemberPosition
                    }}</span>
                  </a-col>
                  <a-col style="padding-top: 5px" :span="12" :lg="12" :sm="18" :xs="24">
                    <span class="label-txt">Manager at time of survey:</span><span class="pl-2 value-txt">{{
                      firstNameManager
                    }}&nbsp;{{ lastNameManager }}</span>
                  </a-col>
                </a-row>
                <div class="grey-box-css p-l-r mt-10">
                  <a-row class="pb-2">
                    <a-col style="padding-top: 5px" :span="18" :lg="18" :sm="20" :xs="24">
                      <span class="label-txt">Nominated Reviewers:</span>
                    </a-col>
                    <a-col style="padding-top: 5px" :span="6" :lg="6" :sm="12" :xs="24">
                      <span class="pointer blue-txt-sm underline"
                        v-if="this.remainingDate > 0 && additionalReviewersLink"><a @click="additionalReviewers">Add
                          additional reviewers</a></span>
                    </a-col>
                  </a-row>

                  <a-row class="additional-reviewer-sec">
                    <a-col v-if="additionalReviewersList.length > 0" class="div-scroll-right" style="padding-top: 5px"
                      :style="additionalReviewersList.length > 0
                        ? 'overflow-y:auto;'
                        : 'overflow-y:auto'
                        ">
                      <span v-if="
                        additionalReviewersList.length > 0 && !userActiveflag
                      " class="round-list-grey show-all-btn" :class="showAllFlag
                        ? 'round-list-grey active pointer'
                        : 'round-list-grey pointer'
                        " @click="selectedReviewersAll">Show All</span>
                      <a-list :data-source="additionalReviewersList">
                        <a-list-item slot="renderItem" slot-scope="item, index" @click="selectedReviewers(item, index)"
                          class="list-pad" :class="!userActiveflag ? 'pointer' : 'disable-pointer'
                            ">
                          <span class="value-txt" :class="item.completionFlag &&
                            item.selectedReviewersFlag &&
                            !userActiveflag
                            ? 'round-list-grey active'
                            : item.completionFlag && !userActiveflag
                              ? 'round-list-grey'
                              : ''
                            ">
                            <a-popover v-if="
                              !item.completionFlag &&
                              !userActiveflag &&
                              remainingDate > 0
                            " placement="right">
                              <template slot="content">
                                <div class="label-txt">
                                  This person has not yet completed their review
                                </div>
                                <div class="value-txt">
                                  <a class="rem-class underline" @click="reminderPopUp(item)">Send reminder</a>
                                  to
                                  {{ item.reviewer.email }}
                                </div>
                              </template>

                              <span>{{ item.reviewer.firstName }}&nbsp;{{
                                item.reviewer.lastName
                              }}</span>
                            </a-popover>
                            <span v-else>{{ item.reviewer.firstName }}&nbsp;{{
                              item.reviewer.lastName
                            }}</span>
                            <em v-if="item.completionFlag && !userActiveflag" class="icon-check check-wrapper" /></span>
                        </a-list-item>
                      </a-list>
                    </a-col>
                    <a-col v-else class="no-reviwer-section">
                      <p v-if="remainingDate > 0">
                        Names will appear here once your team member has
                        nominated their reviewers.<br />
                        You can also add additional reviewers on their behalf by
                        selecting ‘Add additional reviewers’.
                      </p>
                    </a-col>
                  </a-row>
                </div>

                <a-row>
                  <a-col class="bold-right-txt mt-20" :span="13" :lg="13" :sm="24" :xs="24" style="padding-left: 0">
                    General

                    <div class="graph-div mt-10">
                      <div id="chart" class="pt-10" style="text-align: -webkit-center !important">
                        <common-loader class="set-position" :loading="isGraphDataLoaded"></common-loader>
                        <apexchart v-if="!isGraphDataLoaded" class="pt-2 apex-chart" type="radar"
                          :options="chartOptions" :series="series"></apexchart>
                      </div>
                    </div>
                  </a-col>
                  <a-col
                    class="bold-right-txt mt-20 likelihood"
                    :span="11"
                    :lg="11"
                    :sm="24"
                    :xs="24"
                    style="padding-right: 0"
                  >
                    Values and Contribution
                    <div class="rating-div mt-10" style="padding: 20px">
                      <a-list size="small" :data-source="likeHoodList">
                        <a-list-item slot="renderItem" slot-scope="record" style="cursor: default !important">
                          <a-row :gutter="24" style="display: flex; width: 100%">
                            <a-col :span="3" class="icon-wrapper">
                              <em :class="record.img" class="" />
                            </a-col>
                            <a-col :span="21">
                              <a-progress style="width: 100%" :stroke-color="{
                                '0%': '#00C2F3',
                                '100%': '#6454F0',
                              }" :percent="record.percent ? record.percent : 0" status="active" />
                              <!-- <span class="votes-css"
                              >{{
                                record.count ? record.count : 0
                              }}&nbsp;Votes</span
                            > -->
                            </a-col>
                          </a-row>
                        </a-list-item>
                      </a-list>
                    </div>
                  </a-col>
                </a-row>

                <a-row style="padding-bottom: 10px; margin-top: 10px !important" :gutter="24">
                  <a-col class="bold-right-txt" :span="24" :lg="24" :sm="24" :xs="24" style="margin-top: 20px">
                    Feedback
                  </a-col>
                </a-row>
                <a-row style="padding-bottom: 13px" :gutter="24">
                  <a-col class="bold-right-txt" :span="24" :lg="24" :sm="24" :xs="24">
                    <div class="feedback-section">
                      <a-tabs default-active-key="1" @change="changedTab">
                        <a-tab-pane key="1" tab="Does well">
                          <div>
                            <div class="feedback-div" :style="dynamicHeight" :class="feedbackSectionHeight < 145
                              ? 'rounded-border'
                              : 'not-all-rounded'
                              ">
                              <div class="feedback-div-scroll" v-if="feedBackDoesWellList.length > 0">
                                <a-list :grid="{
                                  gutter: 24,
                                  xs: 1,
                                  sm: 2,
                                  md: 4,
                                  lg: 4,
                                  xl: 4,
                                  xxl: 3,
                                }" :data-source="feedBackDoesWellList">
                                  <a-list-item class="cursor-arw" slot="renderItem" slot-scope="item, index">
                                    <span class="label-txt">{{
                                      item.trait.traitsAndBehaviours
                                    }}&nbsp;</span>
                                    <span class="value-txt feedback-clr">&#8226;&nbsp;{{ item.count }}</span>
                                  </a-list-item>
                                </a-list>
                              </div>
                              <div class="feedback-div-scroll rounded-border" v-else>
                                <span class="value-txt feedback-clr">No feedback has been given</span>
                              </div>
                            </div>
                            <div v-if="
                              feedBackDoesWellList.length > 0 &&
                              feedbackSectionHeight > 145
                            ">
                              <a-spin v-if="loadingMoreFeedback" />
                              <a-button type="primary"
                                style="width: 100% !important border-radius: 0px 0px 10px 10px !important"
                                @click="onLoadMoreFeedback('btnTextDoesWell')">
                                {{ btnTextDoesWell }}
                              </a-button>
                            </div>
                          </div>
                          <a-row style="padding: 13px 0px" :gutter="24">
                            <a-col class="bold-right-txt-comment" :span="24" :lg="24" :sm="24" :xs="24">
                              Comments
                            </a-col>
                          </a-row>
                          <a-row :gutter="24" v-if="feedBackDoesWellCommentsList.length > 0">
                            <a-col class="bold-right-txt-comment comment-div" :class="feedBackDoesWellCommentsList.length > 5
                              ? 'not-all-rounded'
                              : 'rounded-border'
                              " :span="24" :lg="24" :sm="24" :xs="24">
                              <a-list item-layout="horizontal" :data-source="feedBackDoesWellCommentsList">
                                <div slot="loadMore" v-if="feedBackDoesWellCommentsList.length > 5" :style="{
                                  textAlign: 'center',
                                  height: '32px',
                                  lineHeight: '32px',
                                  width: '100% !important',
                                }">
                                  <a-spin v-if="loadingMore" />
                                  <a-button type="primary" style="width: 100% !important" @click="
                                    onLoadMore(
                                      feedBackDoesWellCommentsList,
                                      'commentCount',
                                      'showCommentBtnTextDoesWell'
                                    )
                                    ">
                                    {{ showCommentBtnTextDoesWell }}
                                  </a-button>
                                </div>
                                <a-list-item slot="renderItem" slot-scope="item, index"
                                   :style="{
                                    borderBottom: '1px solid #bfc3d6',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'default !important',
                                    display: 'block',
                                    opacity:
                                      item.isCommentHidden &&
                                        curTab === ShowTypes.USER
                                        ? 0.6
                                        : 1,
                                  }">
                                  <div class="label-txt" style="
                                      display: flex;
                                      align-items: center;
                                      justify-content: space-between;
                                    ">
                                    <span>
                                      <p v-if="isTeamMember">{{ item.reviewerName }}</p>
                                    </span>
                                    <template v-if="curTab === ShowTypes.ADMIN">
                                      <a-button v-if="!item.isCommentHidden" type="primary" class="hide-button" style="
                                          width: 49px !important;
                                          height: 22px !important;
                                          border-radius: 3px !important;
                                          font-size: 10px !important;
                                          padding: 0;
                                        " @click="
                                          handleHideComment(
                                            true,
                                            item.id,
                                            TypeComments.DOES_WELL
                                          )
                                          ">
                                        Hide
                                      </a-button>
                                      <a-button v-else class="unhide-button" style="
                                          width: 49px !important;
                                          height: 22px !important;
                                          border-radius: 3px !important;
                                          font-size: 10px !important;
                                          padding: 0;
                                          color: #363644;
                                          border: 1px solid #6454f0;
                                        " @click="
                                          handleHideComment(
                                            false,
                                            item.id,
                                            TypeComments.DOES_WELL
                                          )
                                          ">
                                        Unhide
                                      </a-button>
                                    </template>
                                    <p v-else style="
                                        color: #797994;
                                        font-size: 10px;
                                        width: 115px;
                                        font-weight: 400;
                                        text-align: right;
                                      ">
                                      {{
                                        item.isCommentHidden
                                          ? "Comment hidden from subject by Admin"
                                          : ""
                                      }}
                                    </p>
                                  </div>
                                  <a-row style="width: 100%" :gutter="24">
                                    <a-col class="comment-txt" :span="18" :lg="18" :sm="24" :xs="24">
                                      {{ item.comment }}
                                    </a-col>
                                    <a-col class="comment-txt" style="font-style: italic" :span="6" :lg="6" :sm="12"
                                      :xs="24">
                                      <!-- <span
                                        v-if="!userActiveflag && !isSuperUser"
                                      >
                                        - {{ item.reviewerName }}</span
                                      > -->
                                    </a-col>
                                  </a-row>
                                </a-list-item>
                              </a-list>
                            </a-col>
                          </a-row>
                          <div class="feedback-div-scroll rounded-border feedback-comment-div" v-else>
                            <span class="value-txt feedback-clr">No comment has been given</span>
                          </div>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="Needs work" force-render>
                          <div>
                            <div class="feedback-div" :style="dynamicHeight" :class="feedbackSectionHeight < 145
                              ? 'rounded-border'
                              : 'not-all-rounded'
                              ">
                              <div class="feedback-div-scroll" v-if="feedBackNeedWorkList.length > 0">
                                <a-list :grid="{
                                  gutter: 24,
                                  xs: 1,
                                  sm: 2,
                                  md: 4,
                                  lg: 4,
                                  xl: 4,
                                  xxl: 3,
                                }" :data-source="feedBackNeedWorkList">
                                  <a-list-item slot="renderItem" slot-scope="item">
                                    <span class="label-txt">{{
                                      item.trait.traitsAndBehaviours
                                    }}&nbsp;</span>
                                    <span class="value-txt feedback-clr">&#8226;&nbsp;{{ item.count }}</span>
                                  </a-list-item>
                                </a-list>
                              </div>
                              <div class="feedback-div-scroll rounded-border" v-else>
                                <span class="value-txt feedback-clr">No feedback has been given</span>
                              </div>
                            </div>
                            <div v-if="
                              feedBackNeedWorkList.length > 0 &&
                              feedbackSectionHeight > 145
                            ">
                              <a-spin v-if="loadingMoreFeedback" />
                              <a-button type="primary"
                                style="width: 100% !important border-radius: 0px 0px 10px 10px !important"
                                class="feedback-btn" @click="onLoadMoreFeedback('btnTextNeedWork')">
                                {{ btnTextNeedWork }}
                              </a-button>
                            </div>
                          </div>
                          <a-row style="padding: 13px 0px" :gutter="24">
                            <a-col class="bold-right-txt-comment" :span="24" :lg="24" :sm="24" :xs="24">
                              Comments
                            </a-col>
                          </a-row>
                          <a-row style="margin: 0" :gutter="24" class="comment-div"
                            v-if="feedBackNeedWorkCommentsList.length > 0">
                            <a-col class="bold-right-txt-comment" style="padding: 0" :class="feedBackNeedWorkCommentsList.length > 5
                              ? 'not-all-rounded'
                              : 'rounded-border'
                              " :span="24" :lg="24" :sm="20" :xs="24">
                              <a-list class="demo-loadmore-list" :loading="loading" item-layout="horizontal"
                                :data-source="feedBackNeedWorkCommentsList">
                                <div v-if="feedBackNeedWorkCommentsList.length > 5" slot="loadMore" :style="{
                                  textAlign: 'center',
                                  height: '32px',
                                  lineHeight: '32px',
                                  width: '100% !important',
                                }">
                                  <a-spin v-if="loadingMore" />
                                  <a-button type="primary" style="width: 100% !important" @click="
                                    onLoadMore(
                                      feedBackNeedWorkCommentsList,
                                      'commentCountNeedWork',
                                      'showCommentBtnTextNeedWork'
                                    )
                                    ">
                                    {{ showCommentBtnTextNeedWork }}
                                  </a-button>
                                </div>
                                <a-list-item slot="renderItem" slot-scope="item, index"
                                  :style="{
                                    borderBottom: '1px solid #bfc3d6',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'default !important',
                                    display: 'block',
                                    opacity:
                                      item.isCommentHidden &&
                                        curTab === ShowTypes.USER
                                        ? 0.6
                                        : 1,
                                  }">
                                  <div class="label-txt" style="
                                      display: flex;
                                      align-items: center;
                                      justify-content: space-between;
                                    ">
                                    <span>
                                      <p v-if="isTeamMember">{{ item.reviewerName }}</p>
                                    </span>
                                    <template v-if="curTab === ShowTypes.ADMIN">
                                      <a-button v-if="!item.isCommentHidden" type="primary" class="hide-button" style="
                                          width: 49px !important;
                                          height: 22px !important;
                                          border-radius: 3px !important;
                                          font-size: 10px !important;
                                          padding: 0;
                                        " @click="
                                          handleHideComment(
                                            true,
                                            item.id,
                                            TypeComments.NEED_WORK
                                          )
                                          ">
                                        Hide
                                      </a-button>
                                      <a-button v-else class="unhide-button" style="
                                          width: 49px !important;
                                          height: 22px !important;
                                          border-radius: 3px !important;
                                          font-size: 10px !important;
                                          padding: 0;
                                          color: #363644;
                                          border: 1px solid #6454f0;
                                        " @click="
                                          handleHideComment(
                                            false,
                                            item.id,
                                            TypeComments.NEED_WORK
                                          )
                                          ">
                                        Unhide
                                      </a-button>
                                    </template>
                                    <p style="
                                        color: #797994;
                                        font-size: 10px;
                                        width: 115px;
                                        font-weight: 400;
                                        text-align: right;
                                      " v-else>
                                      {{
                                        item.isCommentHidden
                                          ? "Comment hidden from subject by Admin"
                                          : ""
                                      }}
                                    </p>
                                  </div>
                                  <a-row style="width: 100%" :gutter="24">
                                    <a-col class="comment-txt" :span="18" :lg="18" :sm="20" :xs="24">
                                      {{ item.comment }}
                                    </a-col>
                                    <a-col v-if="!isSuperUser" class="comment-txt" style="font-style: italic" :span="6"
                                      :lg="6" :sm="12" :xs="24">
                                      <!-- <span v-if="!userActiveflag">
                                        - {{ item.reviewerName }}</span
                                      > -->
                                    </a-col>
                                  </a-row>
                                </a-list-item>
                              </a-list>
                            </a-col>
                          </a-row>
                          <div class="feedback-div-scroll rounded-border feedback-comment-div" v-else>
                            <span class="value-txt feedback-clr">No comment has been given</span>
                          </div>
                        </a-tab-pane>
                      </a-tabs>
                    </div>
                  </a-col>
                </a-row>
              </div>
              <div v-else style="padding: 10px">
                <span v-if="selectedTeamMemberFlag">
                  <p class="font-16">
                    Start a 360 survey for this employee to collect data to
                    display here.
                  </p>
                </span>
                <div v-else>
                  <span v-if="teamMemberList.length == 0 && !userActiveflag">
                    <p class="font-16">Add team members to start survey.</p>
                  </span>
                  <span v-else>
                    <p class="font-16" v-if="!userActiveflag">
                      Select a team member to view data.
                    </p>
                  </span>
                  <p class="font-16" v-if="userActiveflag">
                    Data will appear here once a 360 survey has been started for
                    you.
                  </p>
                </div>

                <div v-if="
                  !selectedTeamFlag &&
                  !userActiveflag &&
                  selfDashBoradDetails.length == 0
                ">
                  <p class="font-16 pos-bottom">
                    Or select your name to view your 360 survey results, if a
                    survey has been created for you.
                  </p>
                </div>
              </div>
            </div>
            <a-row :gutter="24" style="padding-top: 0px"><a-col :span="24"></a-col></a-row>
          </div>
        </a-col>
      </a-row>
    </div>
    <confirm-pop-up class="start-survey-popup" :is-show="isShowConfirm" header-text="Hold up!" contain-text="Add a team member first by selecting 'Add new member',
before starting new survey." button-text="Close" @closePopup="closedPopup" />
    <time-out-pop-up :is-show="isTimeOut" @closePopup="logInTimeOutPopup" />
    <remove-pop-up :is-show="isShowRemove" confirm-msg="Are you sure you want to remove this team member?"
      header-text="Remove team member"
      contain-text="All historical data will be removed and you won’t be able to recover it." button-text="Close"
      @closeRemove="colseRemovePopup" @removeMemberData="removedMemberData" />
    <send-reminder-pop-up :is-show="isShowReminder" :full-name="reminderFullName" button-text="Close"
      @closeRemove="colseReminderPopup" @removeMemberData="sendReminder" />
    <add-members-pop-up pop-up-name="Add Members" :is-show-add-member-pop-up-all="isShowAddMembers"
      header-text="Add members to your team" contain-text="Enter the details of your team members below."
      :job-title-list="jobList" :plus-icon="true" :added-list="true" button-text="Done" :status-number="statusNumber"
      @sendCode="sendCodeClick" @savedDataEvent="saveDataApi" />
    <add-members-pop-up pop-up-name="Add Members" :is-show-add-member-pop-up-all="isUpdatedMember"
      :updated-option="true" header-text="Update your team member"
      contain-text="Update the details of your team members below. Some attributes blocked while survey in progress."
      :job-title-list="jobList" :updated-member-data="updatedMemberData" :status-number="statusNumber"
      @sendCode="sendCodeClick" button-text="Update" @updatedTeamMember="updateTeamMemberApi"
      @removedTeamMember="removeTeamMemberShowFlag" />
    <confirm-pop-up :is-show="successUpdateFlag" class="success-popup" :logo="true" header-text="Done"
      contain-text="Team member has been successfully updated." button-text="OK" @closePopup="closedPopupUpdate" />

    <confirm-pop-up :is-show="successDeleteFlag" class="success-popup" :logo="true" header-text="Done"
      contain-text="Team member has been successfully removed." button-text="OK" @closePopup="closedPopupDelete" />
    <confirm-pop-up :is-show="successReminderFlag" class="success-popup" :logo="true" header-text="Done"
      contain-text="A reminder email has succcessfully been sent." button-text="OK" @closePopup="colseSuccessPopup" />
    <confirm-pop-up :is-show="selfNominateFlag" class="success-popup" :logo="false" header-text="No data... yet"
      contain-text="You’ll start seeing information here once your manager has initiated a survey for you."
      button-text="Close" @closePopup="colseSelfNominatePopup" />
    <log-out-pop-up :is-show="isShowLogOut" button-text="Cancel" @closeRemove="colseLoginPopup"
      @removeMemberData="logOutDevice" />
    <all-log-out-pop-up :is-show="isShowAllLogOut" :is-all-device="isAllDevice" button-text="Cancel"
      @closeRemove="colseAllLogOutPopup" />
    <carousel-pop-up :is-show="carouselFlag" @closePopup="closedCarousel" />
    <div>
      <a-modal :visible="isStartNewSurveyPop" :maskClosable="false" @cancel="cancelBtn" :centered="true" width="56%"
        class="create-survey-res">
        <div class="pl-pr">
          <a-row class="mt-2" :gutter="24">
            <a-col style="padding-top: 5px; text-align: left" :span="24" :lg="24" :xs="24">
              <span class="bold-right-txt">Create new survey</span>
            </a-col>
          </a-row>
          <a-row class="mt-2" :gutter="24">
            <a-col style="padding-top: 5px; text-align: left" :span="24" :lg="24" :xs="24">
              <span class="confirm-contain-txt" style="font-size: 16px !important">Select from your team list below who
                you
                would like to initiate
                the 360 feedback survey for. Note that your selected team
                members will be able to view anonymised survey results.
                <span style="font-size: 16px !important" class="pointer blue-txt-sm" @click="openCarouselPopUp">Learn
                  more
                  about the survey.</span></span>
            </a-col>
          </a-row>
          <a-row class="mt-2" style="background: #edeef5; border-radius: 10px; height: 262px" :gutter="24">
            <a-col style="padding: 16px" :span="24" :lg="24" :xs="24">
              <a-list class="survey-class" style="padding-right: 10px; height: 235px; overflow-y: auto" size="small"
                :data-source="memberList">
                <a-list-item class="list-hover" slot="renderItem" slot-scope="item, index"
                  @click="selectedSurveyTeamMember(item, index)" :class="item.editShowSelected ? 'active' : null">
                  <a-row :gutter="24" style="width: 100% !important; padding-left: 15px">
                    <a-col class="name-list pt-2" :span="8" :lg="8">
                      <a-popover placement="right" :mouse-enter-delay="1.5">
                        <template slot="content">
                          {{ item.firstName }}&nbsp;
                          {{ item.lastName }}</template>
                        <span class="role-item-text">
                          {{ item.firstName }}&nbsp;{{ item.lastName }}
                        </span></a-popover>
                    </a-col>
                    <a-col class="role-list pt-2" :span="13" :lg="13">
                      <a-popover placement="left" :mouse-enter-delay="1.5">
                        <template slot="content">
                          <span v-if="item.other">{{ item.other }}</span><span v-else-if="item.jobTitle">{{
                            item.jobTitle
                          }}</span>
                          <span v-else>{{ item.job.jobTitle }}</span></template>

                        <span class="role-item-text">
                          <span v-if="item.other">{{ item.other }}</span><span v-else-if="item.jobTitle">{{
                            item.jobTitle
                          }}</span>
                          <span v-else>{{ item.job.jobTitle }}</span>
                        </span></a-popover>
                    </a-col>
                    <a-col class="list-icon" style="
                        text-align: end !important;
                        padding-right: 0px !important;
                      " :span="3" :lg="3">
                      <span v-if="item.editShowSelected">
                        <em class="icon-circle-tick" style="visibility: hidden" />
                      </span>
                    </a-col>
                  </a-row>
                </a-list-item>
              </a-list>
            </a-col>
          </a-row>
          <a-row class="mt-2" :gutter="24">
            <a-col :span="24" class="error-txt" v-if="createSurveyError" :lg="24" :xs="24">
              <span>Please select at least one team member</span>
            </a-col>
          </a-row>
          <a-row class="mt-2" :gutter="24">
            <a-col style="padding-top: 5px; text-align: left" :span="24" :lg="24" :xs="24">
              <span class="confirm-contain-txt" style="font-size: 16px !important">Select when you require completed
                responses by:</span>
            </a-col>
          </a-row>
          <a-row class="mt-2" :gutter="24">
            <a-col style="padding-top: 5px; text-align: left" :span="5" :lg="5" :xs="24">
              <span class="date-txt" style="font-size: 16px !important">Due date</span>
            </a-col>
            <a-col style="
                padding-top: 5px;
                text-align: left;
                padding-left: 0px !important;
              " :span="8" :lg="8" :xs="24" class="start-survey-date" :class="createSurveyDateError && !formatedDate ? 'has-error' : null
                ">
              <a-date-picker format="DD MMM YYYY" style="background: #edeef5; border-radius: 10px"
                @change="onChangeDate" :disabled-date="(current) => current.isBefore(moment())" :show-today="false" />
            </a-col>
            <a-col style="padding-top: 5px; text-align: left" :span="14" :lg="14" :xs="24">
            </a-col>
          </a-row>
          <a-row class="mt-2" :gutter="24">
            <a-col style="padding-top: 5px; text-align: left" :span="24" :lg="24" :xs="24" class="error-txt"
              v-if="createSurveyDateError && !formatedDate">
              <p>Please select a due date for the survey</p>
            </a-col>
            <a-col style="padding-top: 5px; text-align: left" :span="15" :lg="15" :xs="24">
            </a-col>
          </a-row>
          <a-row class="mt-10" :gutter="24">
            <a-col :span="16" class="error-txt float-r">
              <span :span="8" class="create-survey-button" v-if="createSurveyErrorMsg">
                One or more of your selected team members are already part of an
                active survey
              </span>
              <a-button :span="8" class="float-r lets-go-btn" style="width: 150px !important; text-align: center"
                type="primary" @click="letsGo">Let’s go</a-button>
            </a-col>
          </a-row>
        </div>
      </a-modal>
    </div>
    <additional-reviewers pop-up-name="Add Members" :is-show-add-member-pop-up-all="additionalReviewersFlag"
      header-text="Add additional reviewers" :job-title-list="relationshipList" :plus-icon="true" :added-list="true"
      @sendCode="sendCodeClick" button-text="Add" :survey-id="surveyReqId" :survey-date-drop-id="surveyDateDropId"
      @savedReviwersEvent="savedReviwersData" />
    <confirm-pop-up :is-show="loadingFlag" :loading="true" header-text="Loading" contain-text="Hang tight..." />
    <error-pop-up :is-show="errorPopupFlag" @closePopup="closedErrorPopup" />
    <confirm-pop-up logo logoName="hide-comment" className="comment-hidden-popup" :is-show="isShowHiddenCmtConfirm"
      header-text="Comment hidden"
      contain-text="This comment will no longer be visible to the subject, and will be flagged in the manager’s view."
      button-text="OK" @closePopup="isShowHiddenCmtConfirm = false" />
    <ExportPdfSurveyPopup :memberList="memberList" :visible="isShowExportPopup" :userId="userId"
      @close="isShowExportPopup = false"></ExportPdfSurveyPopup>
  </div>
</template>
<script>
import reqwest from "reqwest";

const fakeDataUrl =
  "https://randomuser.me/api/?results=5&inc=name,gender,email,nat&noinfo";
import CommonPopUp from "@/components/CommonPopUp.vue";
import LogOutPopUp from "@/components/LogOutPopUp.vue";
import AllLogOutPopUp from "@/components/AllLogOutPopUp.vue";
import ErrorPopUp from "@/components/ErrorPopUp.vue";
import ConfirmPopUp from "@/components/ConfirmPopUp.vue";
import TimeOutPopUp from "@/components/TimeOutPopUp.vue";
import RemovePopUp from "@/components/RemovePopUp.vue";
import SendReminderPopUp from "@/components/SendReminderPopUp.vue";
import AddMembersPopUp from "@/components/AddMembersPopUp.vue";
import AdditionalReviewers from "./AdditionalReviewers.vue";
import VueApexCharts from "vue-apexcharts";
import services from "../../services/services";
import moment from "moment";
import CarouselPopUp from "@/components/CarouselPopUp.vue";
import { pageview, event } from "vue-gtag";
import CommonLoader from "../../components/CommonLoader.vue";

import { UserRole } from "@/constants/user";
import { ShowTypes, TypeComments } from "@/constants/home";
import ExportPdfSurveyPopup from "@/components/ExportPdfSurveyPopup.vue";

export default {
  name: "HomePage",
  components: {
    LogOutPopUp,
    AllLogOutPopUp,
    CommonLoader,
    ErrorPopUp,
    CommonPopUp,
    ConfirmPopUp,
    CarouselPopUp,
    RemovePopUp,
    TimeOutPopUp,
    SendReminderPopUp,
    AddMembersPopUp,
    apexchart: VueApexCharts,
    AdditionalReviewers,
    ExportPdfSurveyPopup,
  },
  data() {
    return {
      TypeComments,
      ShowTypes,
      isShowExportPopup: false,
      isShowHiddenCmtConfirm: false,
      selectedIndex: 0,
      isAllDevice: false,
      isTeamMember: true,
      isShowLogOut: false,
      isShowAllLogOut: false,
      secret: "123#$%",
      statusNumber: 0,
      pageview,
      event,
      screenHeight: 0,
      isGraphDataLoaded: false,
      showCommentBtnTextDoesWell: "Show all",
      showCommentBtnTextNeedWork: "Show all",
      commentCount: 5,
      commentCountNeedWork: 5,
      teamMemberPosition: null,
      feedbackHeight: 145,
      likeHoodList: [],
      surveyReqId: 0,
      showAllFlag: false,
      reminderFullName: "",
      btnTextDoesWell: "Show more",
      btnTextNeedWork: "Show more",
      dynamicHeight: "max-height: 145px;",
      feedbackSectionHeight: 50,
      teamMemberId: 0,
      surveyId: 0,
      teamMemberIdforView: 0,
      surveyIdforView: 0,
      surveyDateDropId: 0,
      initialDate: null,
      dueDate: null,
      showFeedbackBtn: true,
      additionalReviewersLink: true,
      remainingDate: null,
      dashBoradDetails: [],
      selfDashBoradDetails: [],
      dashBoradDetailsDrop: [],
      isInProgressFlag: true,
      isCompletedFlag: true,
      selfNominateFlag: false,
      selfNominateMsgFlag: false,
      isNotStartedFlag: true,
      errorPopupFlag: false,
      createSurveyError: false,
      createSurveyDateError: false,
      createSurveyErrorMsg: false,
      selectedTeamMemberFlag: false,
      additionalReviewersList: [],
      loadingFlag: false,
      additionalReviewersFlag: false,
      moment,
      relationshipList: [],
      formatedDate: false,
      successDeleteFlag: false,
      successReminderFlag: false,
      userActiveflag: false,
      teamMemberList: [],
      memberList: [],
      listGeneralCompetancyLabels: [],
      updatedMemberData: {},
      isUpdatedMember: false,
      carouselFlag: false,
      isStartNewSurveyPop: false,
      successUpdateFlag: false,
      removedData: {},
      userId: 0,
      firstName: "",
      lastName: "",
      firstNameManager: "",
      lastNameManager: "",
      firstLetter: "",
      services,
      token: "",
      statusId: 2,
      dynamicSpan: 24,
      selectedTeamFlag: false,
      jobList: [],
      userDetails: {},
      teamMemberDeatils: {},
      reminderData: {},
      collapseFlag: false,
      loading: true,
      loadingMore: false,
      loadingMoreFeedback: false,
      showLoadingMore: true,
      commentsData: [],
      firstCall: true,
      emailId: "",
      isShowConfirm: false,
      isTimeOut: false,
      isShowRemove: false,
      isShowReminder: false,
      isShowAddMembers: false,
      addedMemberList: [],
      activeKey: [],
      expandIconPosition: "right",
      formLayout: "horizontal",
      form: {
        survey: "",
      },
      categoryArr: [
        { id: 0, dropData: "30 June 2021" },
        { id: 1, dropData: "10 May 2021" },
        { id: 2, dropData: "18 Feb 2021" },
      ],
      feedBackDoesWellList: [],
      feedBackNeedWorkList: [],
      feedBackDoesWellCommentsList: [],
      feedBackNeedWorkCommentsList: [],

      series: [
        {
          name: "Series 1",
        },
      ],
      tooltip: {
        enabled: true,

        fixed: {
          enabled: false,
          position: "topRight",
          offsetX: 0,
          offsetY: 0,
        },
      },
      chartOptions: {
        chart: {
          type: "radar",
          width: "90%",
        },
        stroke: {
          width: 2,
        },
        plotOptions: {
          radar: {
            size: 80,
            offsetX: 0,
            offsetY: 0,
            polygons: {
              strokeColors: "#BFC3D6",
              connectorColors: "#BFC3D6",
              strokeWidth: "1px",
              fill: {
                colors: ["#EDEEF5"],
              },
            },
          },
        },
        fill: {
          opacity: 0.5,
          colors: ["#46CEFC"],
        },
        markers: {
          size: 0,
        },
        dataLabels: {
          enabled: true,
          background: {
            enabled: true,
            borderRadius: 2,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          min: 0,

          tickAmount: 5,
        },

        xaxis: {
          categories: [],
          labels: {
            minWidth: 0,

            show: true,
            style: {
              colors: [],
              fontSize: "10px",
              fontFamily: "Arial",
            },
          },
        },
      },
      curTab: "",
      memberList: [],
    };
  },
  computed: {
    isSuperUser() {
      return this.userDetails.role === UserRole.HR;
    },
    getProfileContainerStyle() {
      if (this.isSuperUser)
        return {
          display: "flex",
          "justify-content": "flex-end",
          "align-items": "center",
          gap: "20px",
        };
      else
        return {
          "text-align": "right",
        };
    },
  },
  created() {
    //this.screenHeight = screen.height - (screen.height * 3) / 100 - 200;
    this.screenHeight = screen.height - (screen.height * 3) / 100 - 55;
    this.screenHeight = "height:" + this.screenHeight + "px !important";
    if (this.$route.query.surveyId) {
      this.$store.state.surveyIdforView = this.$route.query.surveyId;
      this.$store.state.teamMemberIdforView = this.$route.query.teamMemberId;
    }
    this.surveyIdforView = this.$store.state.surveyIdforView
      ? this.$store.state.surveyIdforView
      : 0;
    this.teamMemberIdforView = this.$store.state.teamMemberIdforView
      ? this.$store.state.teamMemberIdforView
      : 0;
    if (localStorage.getItem("token")) {
      let token = localStorage.token;
      const decryptedText = this.$CryptoJS.AES.decrypt(
        token,
        this.secret
      ).toString(this.CryptoJS.enc.Utf8);
      this.$store.state.userToken = decryptedText;
      this.track();
      let userDetailsData = localStorage.getItem("userDetails");
      this.userDetails = JSON.parse(userDetailsData);
      this.userId = this.userDetails.userId
        ? this.userDetails.userId
        : this.userId;
      this.emailId = this.userDetails.email
        ? this.userDetails.email
        : this.emailId;
      this.firstName = this.userDetails.firstName
        ? this.userDetails.firstName
        : this.firstName;
      this.firstName =
        this.firstName[0].toUpperCase() + this.firstName.slice(1);
      this.lastName = this.userDetails.lastName
        ? this.userDetails.lastName
        : this.lastName;
      this.lastName = this.lastName[0].toUpperCase() + this.lastName.slice(1);
      let firstChar = this.userDetails.firstName
        ? this.userDetails.firstName
        : this.firstName;
      this.firstLetter = firstChar.substring(0, 1).toUpperCase();

      // this.getAdditionalReviewerList();
      //this.getGeneralCompetancyLabels();
      this.getLikehoodData();
      if (!this.$route.query?.tab)
        this.$router.replace({
          query: { tab: this.isSuperUser ? ShowTypes.ADMIN : ShowTypes.USER },
        });
      this.curTab = this.$route.query?.tab;
      setTimeout(() => {
        this.getAllTeamMemberData();
        setTimeout(() => {
          this.getRelationshipList();
          setTimeout(() => {
            this.getJobList();
          }, 500);
        }, 500);
      }, 500);
    } else {
      this.$router.push("/login");
    }
  },

  mounted() {
    this.getData((res) => {
      this.loading = false;
      this.commentsData = res.results;
    });
  },
  updated() {
    let innerHeight = document.querySelector(".feedback-div-scroll");
    if (innerHeight) {
      innerHeight = innerHeight.offsetHeight;
      this.feedbackSectionHeight = innerHeight;
    }
  },
  methods: {
    changedTab() { },
    track() {
      this.pageview("userportal_main");
    },
    reminderPopUp(val) {
      this.reminderData = val;

      this.reminderFullName = [
        val.reviewer.firstName,
        " ",
        val.reviewer.lastName,
      ].join("");
      this.isShowReminder = true;
    },
    colseSuccessPopup(val) {
      this.successReminderFlag = val;
    },
    colseSelfNominatePopup(val) {
      this.selfNominateFlag = val;
      this.selfNominateMsgFlag = val;
    },
    colseReminderPopup(val) {
      this.isShowReminder = val;
    },
    sendReminder(val) {
      this.isShowReminder = false;
      this.loadingFlag = true;
      // setTimeout(() => {
      //
      // }, 1000);

      let postData = {
        surveyId: this.surveyReqId,
        reviewerId: this.reminderData.reviewerId,
        teamMemberId: this.teamMemberId,
      };

      this.services
        .post(`crone/reminderToReviewers`, postData)
        .then((res) => {
          this.loadingFlag = false;
          if (res.data.status == 200) {
            this.isShowReminder = val;
            this.successReminderFlag = !val;
          }
        })
        .catch((err) => {
          this.loadingFlag = false;
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
          console.log(err);
        });
    },
    getLikehoodData() {
      this.services
        .get("survey/listAllLikeHoodsDashboards")
        .then((res) => {
          this.likeHoodList = res.data.body;
        })
        .catch((err) => {
          //this.errorPopupFlag = true//;
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
        });
    },
    getGeneralCompetancyLabels(surveyReqId, additionalReviewersListArr) {
      let _this = this;
      let url = surveyReqId
        ? `survey/listGeneralCompetancyLabels?surveyId=${this.surveyReqId}`
        : `survey/listGeneralCompetancyLabel`;
      _this.services
        .get(url)
        .then((res) => {
          let resData = res.data.body;
          this.listGeneralCompetancyLabels = resData;
          _this.series = [{ data: [100, 155, 200] }];

          resData.forEach((record, index) => {
            _this.chartOptions.xaxis.categories.splice(
              index,
              1,
              record.competancy
            );
            _this.series[0].data.splice(index, 1, 0);
            _this.chartOptions.xaxis.labels.style.colors.splice(
              index,
              1,
              "#000000"
            );
          });
          if (
            additionalReviewersListArr &&
            additionalReviewersListArr.length > 0
          ) {
            this.getAllDataAsPerReviewers(additionalReviewersListArr);
          }
        })
        .catch((err) => {
          //this.errorPopupFlag = true//;
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
        });
    },

    teamMemberSeen(id) {
      this.services
        .post(`dashboard/surveySeen`, { teamMemberId: id })
        .then((res) => {
          //this.getAllTeamMemberData("seen");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    recommandedLikehood(data) {
      let reqData = {
        surveyId: this.surveyReqId,
        reviewerIds: data,
      };
      this.clearLikeHoodData();
      this.services
        .post(`dashboard/recommandedLikehood`, reqData)
        .then((res) => {
          let resData = res.data.body;
          let maxCount = this.getMaxLikeHoodCount(resData);
          resData.forEach((record, i) => {
            this.likeHoodList.forEach((item, index) => {
              if (record.likehoodDetails.img == item.img) {
                item.count = record.count;
                let percent = (record.count / maxCount) * 100;
                item.percent = Math.round(percent);
                this.likeHoodList.splice(index, 1, item);
              }
            });
          });
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
        });
    },
    sortDate(a, b) {
      return new Date(b.createdOn) - new Date(a.createdOn);
    },
    getMaxLikeHoodCount(data) {
      let maxCount = 0;
      data.forEach((item, index) => {
        maxCount = maxCount + item.count;
      });
      return maxCount;
    },
    getGeneralData(data) {
      let reqData = {
        surveyId: this.surveyReqId,
        reviewerIds: data,
      };
      this.isGraphDataLoaded = true;
      this.services
        .post(`dashboard/generalData`, reqData)
        .then((res) => {
          let resGeneralData = res.data.body;
          if (resGeneralData.length > 0) {
            let finalModifiedArr = this.calculateGeneralData(resGeneralData);

            finalModifiedArr.competancy.forEach((rec, index) => {
              this.chartOptions.xaxis.categories.forEach((item, i) => {
                if (rec == item) {
                  let finalData = 0;
                  let compRec = finalModifiedArr.competancyData[index].data;
                  let length = compRec.length;
                  let total = 0;
                  compRec.forEach((ratingData, ratingIndex) => {
                    total += parseFloat(ratingData);

                    finalData = parseFloat((total / length).toFixed(2));
                  });

                  this.series[0].data.splice(i, 1, finalData);
                }
              });
            });
            let maxData = this.series[0].data;
            let dataLength = maxData.length;
            maxData = Math.max(...maxData);
            this.chartOptions.yaxis.tickAmount =
              dataLength > maxData ? dataLength : maxData + 1;
            this.chartOptions.yaxis.max =
              dataLength > maxData ? dataLength : maxData + 1;
          } else {
            let grapgData = this.series[0].data;
            this.series[0].data = [];
            grapgData.forEach((rec, index) => {
              this.series[0].data.push(0);
            });
          }
          this.isGraphDataLoaded = false;
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
        });
    },
    calculateGeneralData(data) {
      let competancyArr = [];
      let competancyDataArr = [];

      data.forEach((record, index) => {
        record.surveyResults.forEach((item, i) => {
          let dataArr = [];
          if (
            item.generalCompetancyResponseLabel &&
            item.generalCompetancyResponseLabel.ratingNo != "0"
          ) {
            if (
              competancyArr.indexOf(item.generalCompetancyLabel.competancy) ==
              -1
            ) {
              dataArr.push(item.generalCompetancyResponseLabel.ratingNo);
              competancyArr.push(item.generalCompetancyLabel.competancy);
              competancyDataArr.push({
                data: JSON.parse(JSON.stringify(dataArr)),
              });
            } else {
              let customIndex = competancyArr.indexOf(
                item.generalCompetancyLabel.competancy
              );

              let customArr = competancyDataArr[customIndex].data;
              customArr.push(item.generalCompetancyResponseLabel.ratingNo);
              competancyDataArr.splice(customIndex, 1, { data: customArr });
            }
          }
        });
      });

      return { competancy: competancyArr, competancyData: competancyDataArr };
    },
    getDoesWell(data) {
      let reqData = {
        surveyId: this.surveyReqId,
        reviewerIds: data,
      };
      this.feedBackDoesWellCommentsList = [];
      this.feedBackDoesWellList = [];
      this.services
        .post(`dashboard/doesWell`, reqData)
        .then((res) => {
          // this.feedBackDoesWellCommentsList = res.data.doesWellCommentsDetails;
          res.data.doesWellCommentsDetails.forEach(record => {
            if(this.isTeamMember) {
              this.feedBackDoesWellCommentsList.push(record);
            }else if(!record.isCommentHidden) {
              this.feedBackDoesWellCommentsList.push(record);
            }
          });
          this.feedBackDoesWellList = res.data.doesWellTraitsDetails;
          this.showCommentBtnTextDoesWell = `Show all ${this.feedBackDoesWellCommentsList.length} received`;
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
        });
    },
    getNeedsWork(data) {
      let reqData = {
        surveyId: this.surveyReqId,
        reviewerIds: data,
      };
      this.feedBackNeedWorkCommentsList = [];
      this.feedBackNeedWorkList = [];
      this.services
        .post(`dashboard/needsWork`, reqData)
        .then((res) => {
          // this.feedBackNeedWorkCommentsList = res.data.needsWorkCommentsDetails;
          res.data.needsWorkCommentsDetails.forEach(record => {
            if(this.isTeamMember) {
              this.feedBackNeedWorkCommentsList.push(record);
            }else if(!record.isCommentHidden) {
              this.feedBackNeedWorkCommentsList.push(record);
            }
          });
          this.feedBackNeedWorkList = res.data.needsWorkTraitsDetails;
          this.showCommentBtnTextNeedWork = `Show all ${this.feedBackNeedWorkCommentsList.length} received`;
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
        });
    },
    cancelBtn() {
      this.createSurveyError = false;
      this.createSurveyDateError = false;
      this.isStartNewSurveyPop = false;
    },
    closedErrorPopup(val) {
      this.errorPopupFlag = val;
    },
    openCarouselPopUp() {
      let fullname = this.firstName + " " + this.lastName;
      this.gaEventTrack(
        fullname,
        "userportal_createnewsurvey_learnmore",
        "Learn more about the survey popup"
      );
      this.carouselFlag = true;
    },
    closedCarousel(val) {
      this.carouselFlag = val;
    },
    savedReviwersData() {
      if (this.userActiveflag) {
        this.getMeTeamMemberDashboardData(
          this.emailId,
          this.surveyDateDropId,
          true
        );
      } else {
        this.teamMemberSeen(this.teamMemberId);
        this.getTeamMemberDashboardData(
          this.teamMemberId,
          this.surveyDateDropId,
          true
        );
      }
    },
    selectedReviewers(record, index, flag) {
      if (!flag && !record) {
        this.additionalReviewersList.forEach((item, i) => {
          this.additionalReviewersList[i].selectedReviewersFlag = false;
        });
      }
      this.showAllFlag = false;
      let additionalReviewersListArr = [];
      if (record && record.completionFlag) {
        record.selectedReviewersFlag = !record.selectedReviewersFlag;
        this.additionalReviewersList.splice(index, 1, record);
      }
      let completionFlagCount = 0;
      this.additionalReviewersList.sort(this.sortArray);
      this.additionalReviewersList.sort(
        (a, b) => Number(b.completionFlag) - Number(a.completionFlag)
      );

      this.additionalReviewersList.forEach((rec, ind) => {
        rec.selectedReviewersFlag =
          rec.completionFlag && flag ? true : rec.selectedReviewersFlag;
        if (rec.selectedReviewersFlag) {
          additionalReviewersListArr.push(rec.reviewerId);
        }
        if (rec.completionFlag) {
          this.additionalReviewersList.splice(ind, 1, rec);
          completionFlagCount += 1;
        }
      });

      if (
        completionFlagCount == additionalReviewersListArr.length &&
        completionFlagCount > 0
      ) {
        this.showAllFlag = true;
      }

      this.getAllDataAsPerReviewers(additionalReviewersListArr, true);
    },
    getAllDataAsPerReviewers(additionalReviewersListArr, needClear) {
      if (additionalReviewersListArr.length > 0) {
        this.getDoesWell(additionalReviewersListArr);
        this.getNeedsWork(additionalReviewersListArr);
        this.recommandedLikehood(additionalReviewersListArr);
        this.getGeneralData(additionalReviewersListArr);
      } else {
        this.clearAllData();
      }
    },
    clearAllData(setTimeOutFlag) {
      this.feedBackDoesWellList = [];
      this.feedBackNeedWorkList = [];
      this.feedBackNeedWorkCommentsList = [];
      this.feedBackDoesWellCommentsList = [];
      this.series[0].data = [];

      this.listGeneralCompetancyLabels.forEach((rec, index) => {
        this.series[0].data.push(0);
      });
      // maxData = Math.max(...maxData);
      // this.chartOptions.yaxis.tickAmount = maxData;
      this.clearLikeHoodData();
      if (!setTimeOutFlag) {
        this.isGraphDataLoaded = true;
        setTimeout(() => {
          this.isGraphDataLoaded = false;
        }, 500);
      }
    },
    clearLikeHoodData() {
      this.likeHoodList.forEach((item, index) => {
        item.count = 0;
        item.percent = 0;
        this.likeHoodList.splice(index, 1, item);
      });
    },
    selectedReviewersAll() {
      this.showAllFlag = !this.showAllFlag;
      if (this.showAllFlag) {
        this.selectedReviewers(null, null, true);
      } else {
        this.selectedReviewers(null, null, false);
      }
    },
    getAdditionalReviewerList() {
      this.services
        .get("survey/listReviewers")
        .then((res) => {
          let additionalReviewersData = res.data.body;
          if (this.teamMemberList.length > 0) {
            this.mergeByProperty(
              this.additionalReviewersList,
              additionalReviewersData,
              "email"
            );
          } else {
            this.additionalReviewersList = additionalReviewersData;
          }
          this.additionalReviewersList.sort(this.sortArray);
          this.additionalReviewersList.sort(
            (a, b) => Number(b.completionFlag) - Number(a.completionFlag)
          );
        })
        .catch((err) => {
          //this.errorPopupFlag = true//;
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
        });
    },
    getJobList() {
      this.services
        .get("team/jobs")
        .then((res) => {
          this.jobList = res.data.body;
          let jobListRes = this.jobList;
          jobListRes.forEach((item, index) => {
            if (item.jobTitle == "Other") {
              let fromIndex = index;
              Array.prototype.move = function (from, to) {
                this.splice(to, 0, this.splice(from, 1)[0]);
                return this;
              };
              jobListRes.move(fromIndex, jobListRes.length - 1);
            }
          });
          this.jobList = jobListRes;
        })
        .catch((err) => {
          //this.errorPopupFlag = true//;
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
        });
    },
    getRelationshipList() {
      this.services
        .get("survey/listReviewerRelationships")
        .then((res) => {
          this.relationshipList = res.data.body;
        })
        .catch((err) => {
          //this.errorPopupFlag = true//;
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
        });
    },
    additionalReviewers() {
      let fullName = this.firstName + " " + this.lastName;
      let eventName = this.userActiveFlag
        ? "userportal_self_addadditionalreviewers"
        : "userportal_manager_addadditionalreviewers";
      this.gaEventTrack(
        fullName,
        eventName,
        "Manager: add additional reviewer1"
      );
      this.additionalReviewersFlag = !this.additionalReviewersFlag;
    },
    letsGo() {
      let selectedList = [];
      this.createSurveyError, (this.createSurveyDateError = false);

      for (let i = 0; i < this.memberList.length; i++) {
        if (this.memberList[i].editShowSelected) {
          let currData = this.memberList[i];
          let currentObj = {
            dueDate: this.formatedDate,
            email: currData.email,
            teamMemberId: currData.id,
            teamMemberName: currData.firstName + " " + currData.lastName,
            managerPosition: this.userDetails.otherIndustry
              ? this.userDetails.otherIndustry
              : this.userDetails.industry.industryName,
            teamMemberPosition: currData.other
              ? currData.other
              : currData.job.jobTitle,
            managerName: this.firstName + " " + this.lastName,
            userId: currData.userId,
          };
          selectedList.push(currentObj);
        }
      }
      if (!this.formatedDate) {
        this.createSurveyDateError = true;
      } else {
        this.formatedDate.replace(" ", "-");
      }
      if (selectedList.length > 0 && this.formatedDate) {
        this.createSurveySaveData(selectedList);
      } else {
        if (selectedList.length == 0) {
          this.createSurveyError = true;
        }
      }
    },
    createSurveySaveData(data) {
      this.isStartNewSurveyPop = false;
      this.loadingFlag = true;
      this.services
        .post("survey/createSurvey", data)
        .then((res) => {
          this.getAllTeamMemberData();
          this.additionalReviewersLink = true;
          if (this.userActiveflag) {
            this.getMeTeamMemberDashboardData(
              this.emailId,
              this.surveyDateDropId,
              true
            );
          } else {
            this.getTeamMemberDashboardData(
              this.teamMemberId,
              this.surveyDateDropId,
              true
            );
          }
          this.loadingFlag = false;
        })
        .catch((err) => {
          this.loadingFlag = false;
          //this.errorPopupFlag = true//;
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
        });
    },
    userName() {
      this.selectedTeamMemberFlag = false;
      this.additionalReviewersLink = true;
      this.teamMemberList.forEach((item, index) => {
        if (item.editShow) {
          this.teamMemberList[index].editShow = false;
        }
      });
      this.userActiveflag = !this.userActiveflag;

      if (this.userActiveflag) {
        let email = this.emailId;
        this.surveyDateDropId = 0;
        this.getMeTeamMemberDashboardData(email, this.surveyDateDropId);
      } else {
        this.selfDashBoradDetails = [];
      }
    },
    sortTeamMemberData(a, b) {
      if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
        return -1;
      }
      if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
        return 1;
      }
      return 0;
    },

    onChangeDate(date, dateString) {
      this.formatedDate = this.moment(dateString).format("YYYY-MM-DD");
    },
    removedMemberData(val) {
      if (this.statusNumber == 2) {
        this.loadingFlag = true;
        let reqBody = {
          teamMemberId: this.removedData.id,
        };
        this.services
          .post(`team/deleteTeamMember`, reqBody)
          .then((res) => {
            this.isShowRemove = val;
            this.loadingFlag = false;
            this.successDeleteFlag = !val;
            this.gaEventTrack(
              this.removedData.id,
              "userportal_removeteammember_success",
              "Remove team member (success)"
            );
          })
          .catch((err) => {
            this.loadingFlag = false;
            if (err.response.status == 401) {
              this.sessionTimeOut();
            }
          });
      } else {
        this.loadingFlag = true;
        let reqBody = {
          teamMemberId: this.removedData.id,
          userId: this.userId,
        };
        this.services
          .post(`team/deactivateTeamMember`, reqBody)
          .then((res) => {
            this.isShowRemove = val;
            this.loadingFlag = false;
            this.successDeleteFlag = !val;
            this.gaEventTrack(
              this.removedData.id,
              "userportal_removeteammember_success",
              "Remove team member (success)"
            );
          })
          .catch((err) => {
            this.loadingFlag = false;
            if (err.response.status == 401) {
              this.sessionTimeOut();
            }
          });
      }
    },
    removeTeamMemberShowFlag(val) {
      this.removedData = val;

      this.isUpdatedMember = false;
      this.isShowRemove = true;
    },
    removedPopUp(val) {
      this.isShowRemove = val;
    },
    colseRemovePopup(val) {
      this.isShowRemove = val;
    },

    updateTeamMemberApi(val) {
      let reqData = {
        firstName: val.firstName,
        lastName: val.lastName,
        email: val.email,
        jobId: val.jobId,
        userId: val.userId,
        otherJobDescription: val.other,
        updatedBy: val.updatedBy,
        teamMemberId: val.id,
      };
      this.services
        .post(`team/updateMyTeam`, reqData)
        .then((res) => {
          this.isUpdatedMember = false;
          this.successUpdateFlag = true;
          this.gaEventTrack(
            reqData.email,
            "userportal_updateteammember_success",
            "Team member updated (success)"
          );
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
        });
      ///call API
    },
    getAllTeamMemberData(flag) {
      // this.loadingFlag = flag == "seen" ? false : true;

      this.services
        .get(`team/myTeam?userId=${this.userId}&activeTab=${this.curTab}`)
        .then((res) => {
          let teamMemberData = res.data.body;
          this.memberList = res.data.body;
          if (this.teamMemberList.length > 0 && !flag) {
            this.mergeByProperty(this.teamMemberList, teamMemberData, "id");
          } else {
            this.teamMemberList = teamMemberData;
          }

          this.teamMemberList.sort(this.sortTeamMemberData);
          this.isNotStartedFlag = true;
          if (this.teamMemberList.length > 0) {
            for (let i = 0; i < this.teamMemberList.length; i++) {
              if (
                this.teamMemberList[i].survey &&
                this.teamMemberList[i].survey.length > 0
              ) {
                let surveyData = this.teamMemberList[i].survey;
                this.teamMemberList[i].surveyCompleted = true;
                this.isCompletedFlag = false;
                // surveyData.forEach((record, index) => {
                //   if (record.completionFlag == 0) {
                //     let currentDate = new Date().toLocaleDateString();
                //     let duedate = this.formatDateCheck(record.dueDate);
                //     const dateOne = new Date(currentDate);
                //     const dateTwo = new Date(duedate);
                //     let diff = dateTwo.getTime() - dateOne.getTime();

                //     if (diff <= 0) {
                //       record.completionFlag = 1;
                //     }
                //   }
                // });
                surveyData.forEach((record, index) => {
                  if (record.completionFlag == 0) {
                    this.teamMemberList[i].surveyCompleted = false;
                  }
                });
                this.teamMemberList[i].isSurveySeen = true;
                surveyData.forEach((item, index) => {
                  if (item.surveyReviewers.length > 0) {
                    item.surveyReviewers.forEach((rec, ind) => {
                      if (!rec.isSurveySeen && rec.isSurveySeen != null) {
                        this.teamMemberList[i].isSurveySeen = false;
                      }
                    });
                  }
                });
              } else {
                this.isNotStartedFlag = false;
              }
            }

            this.isCompletedFlag = true;
            this.isInProgressFlag = true;
            this.teamMemberList.forEach((rec, ind) => {
              if (rec.surveyCompleted) {
                this.isCompletedFlag = false;
              } else if (rec.survey.length > 0) {
                this.isInProgressFlag = false;
              }
            });

            if (this.surveyIdforView) {
              this.teamMemberList.forEach((rec, ind) => {
                if (rec.id == this.teamMemberIdforView) {
                  if (rec.surveyCompleted) {
                    this.selectedTeamMember(rec, ind, 1, this.surveyIdforView);
                    this.activeKey = ["2"];
                  } else {
                    this.selectedTeamMember(rec, ind, 0, this.surveyIdforView);
                    this.activeKey = ["1"];
                  }
                  this.surveyIdforView = 0;
                  this.$store.state.surveyIdforView = 0;
                }
              });
            }
          }
          this.loadingFlag = false;
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
          this.loadingFlag = false;
          //this.errorPopupFlag = true//;
        });
    },
    sortArray(a, b) {
      if (
        a.reviewer.firstName.toLowerCase() < b.reviewer.firstName.toLowerCase()
      ) {
        return -1;
      }
      if (
        a.reviewer.firstName.toLowerCase() > b.reviewer.firstName.toLowerCase()
      ) {
        return 1;
      }
      return 0;
    },

    mergeByProperty(target, source, prop) {
      source.forEach((sourceElement) => {
        let targetElement = target.find((targetElement) => {
          return sourceElement[prop] === targetElement[prop];
        });
        targetElement
          ? Object.assign(targetElement, sourceElement)
          : target.push(sourceElement);
      });
    },
    getMyTeamForSurveyData() {
      this.services
        .get(
          `survey/myTeamForSurvey?userId=${this.userId}&activeTab=${this.curTab}`
        )
        .then((res) => {
          this.memberList = [];
          let resData = res.data.body;
          resData.forEach((rec, ind) => {
            rec.forEach((item, index) => {
              if (item.id) {
                this.memberList.push(item);
              }
            });
          });
          this.isStartNewSurveyPop = true; //this is a comment
          this.gaEventTrack(
            this.userId,
            "userportal_createnewsurvey",
            "create survey overlay"
          );
        })
        .catch((err) => {
          //this.errorPopupFlag = true//;
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
        });
    },
    UpdateTeamMember(item, statusNumber) {
      this.statusNumber = statusNumber;
      this.updatedMemberData = item;
      this.isUpdatedMember = true;
    },
    closedPopup(val) {
      this, (this.isShowConfirm = val);
    },
    closedPopupUpdate(val) {
      this.getAllTeamMemberData();
      this.successUpdateFlag = val;
    },
    logInTimeOutPopup(val) {
      this.isTimeOut = val;
      this.$router.push("/login");
    },
    closedPopupDelete(val) {
      this.getAllTeamMemberData(true);
      this.successDeleteFlag = val;
    },
    getTeamMemberDashboardData(teamId, id, changedFlag, surveyIdForView) {
      this.teamMemberId = teamId;
      this.dashBoradDetailsDrop = [];
      this.isTeamMember = true;
      this.services
        .get(
          `survey/surveyOverView?userId=${this.userId}&teamMemberId=${teamId}`
        )
        .then((res) => {
          this.dashBoradDetails = res.data.details;
          if (this.dashBoradDetails.length > 0) {
            this.dashBoradDetails.sort(this.sortDate);
            this.dashBoradDetails.forEach((item, index) => {
              this.dashBoradDetailsDrop.push({ dropData: item, id: index });
            });
            let dashBoardData = [];
            if (changedFlag) {
              if (id >= 0) {
                this.dashBoradDetailsDrop.forEach((record, index) => {
                  if (record.id == id) {
                    this.selectedIndex = index;
                    dashBoardData = this.dashBoradDetailsDrop[index];
                  }
                });
              }
            } else if (surveyIdForView) {
              this.dashBoradDetailsDrop.forEach((record, index) => {
                if (record.dropData.id == surveyIdForView) {
                  this.selectedIndex = index;
                  dashBoardData = this.dashBoradDetailsDrop[index];
                }
              });
            } else {
              this.selectedIndex = 0;
              dashBoardData = this.dashBoradDetailsDrop[0];
            }
            this.selectSurveyData(dashBoardData, "survey", changedFlag);
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
        });
    },
    getMeTeamMemberDashboardData(emailId, id, changedFlag) {
      this.dashBoradDetailsDrop = [];
      this.isTeamMember = false;
      let postData = {
        email: emailId,
      };

      this.services
        .post(`survey/mySurveyDetails`, postData)
        .then((res) => {
          this.dashBoradDetails = res.data.details;
          this.selfDashBoradDetails = this.dashBoradDetails;

          if (this.dashBoradDetails.length > 0) {
            this.dashBoradDetails.sort(this.sortDate);
            this.dashBoradDetails.forEach((item, index) => {
              this.dashBoradDetailsDrop.push({ dropData: item, id: index });
            });

            let dashBoardData = [];
            if (changedFlag) {
              if (id >= 0) {
                this.dashBoradDetailsDrop.forEach((record, index) => {
                  if (record.id == id) {
                    this.selectedIndex = index;
                    dashBoardData = this.dashBoradDetailsDrop[index];
                  }
                });
              }
            } else {
              this.selectedIndex = 0;
              dashBoardData = this.dashBoradDetailsDrop[0];
            }
            this.selectSurveyData(dashBoardData, "survey");
            this.gaEventTrack(
              this.formatDate(dashBoardData.dropData.createdOn),
              "userportal_survey_overview",
              "User Portal: started new survey"
            );
          } else {
            this.selfNominateFlag = true;
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
        });
    },
    formatDateToShow(date) {
      let monthArr = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      date = date.substring(0, date.indexOf("T"));
      let mm = date.substring(date.indexOf("-") + 1, date.lastIndexOf("-"));
      mm = mm.substring(0, 1) == "0" ? mm.substring(1) : mm;
      mm = monthArr[parseInt(mm) - 1];

      date =
        date.substring(date.lastIndexOf("-") + 1) +
        " " +
        mm +
        " " +
        date.substring(0, date.indexOf("-"));

      return date;
    },
    formatDate(date) {
      date = date.substring(0, date.indexOf("T"));
      date =
        date.substring(date.lastIndexOf("-") + 1) +
        " " +
        date.substring(date.indexOf("-") + 1, date.lastIndexOf("-")) +
        " " +
        date.substring(0, date.indexOf("-"));
      return date;
    },
    formatDateCheck(date) {
      date = date.substring(0, date.indexOf("T"));
      date =
        date.substring(date.indexOf("-") + 1, date.lastIndexOf("-")) +
        "/" +
        date.substring(date.lastIndexOf("-") + 1) +
        "/" +
        date.substring(0, date.indexOf("-"));
      return date;
    },
    selectSurveyData(val, key, changedFlag) {
      this.clearAllData(true);
      this.surveyReqId = val.dropData.id;

      this.firstNameManager = val.dropData.user.userDetails[0].firstName;
      this.lastNameManager = val.dropData.user.userDetails[0].lastName;

      this.surveyDateDropId = val.id;
      this.initialDate = this.formatDateToShow(val.dropData.createdOn);
      this.dueDate = this.formatDateToShow(
        this.dashBoradDetails[val.id].dueDate
      );
      this.remainingDate = this.dashBoradDetails[val.id].daysRemaining;
      this.teamMemberPosition =
        this.dashBoradDetails[val.id].teamMemberPosition;
      this.additionalReviewersList =
        this.dashBoradDetails[val.id].surveyReviewers;
      let additionalReviewersListArr = [];
      this.additionalReviewersList.forEach((record, index) => {
        if (record.completionFlag) {
          this.showAllFlag = true;
          record.selectedReviewersFlag = true;
          additionalReviewersListArr.push(record.reviewerId);
          this.additionalReviewersList.splice(index, 1, record);
        }
      });

      this.additionalReviewersList.sort(this.sortArray);
      this.additionalReviewersList.sort(
        (a, b) => Number(b.completionFlag) - Number(a.completionFlag)
      );
      this.gaEventTrack(
        this.formatDate(val.dropData.createdOn),
        "userportal_survey_overview",
        "User Portal: started new survey"
      );
      this.getGeneralCompetancyLabels(
        this.surveyReqId,
        additionalReviewersListArr
      );
    },
    selectedTeamMember(val, index, statusId, surveyIdforView) {
      this.additionalReviewersLink = true;
      this.showAllFlag = false;
      this.statusId = statusId;
      if (statusId == 1 || statusId == 0) {
        this.additionalReviewersLink =
          statusId == 1 ? false : this.additionalReviewersLink;
        if (!val.isSurveySeen && val.isSurveySeen != null) {
          this.teamMemberSeen(val.id, index);
          val.isSurveySeen = true;
        }
      }

      this.surveyDateDropId = 0;
      this.selectedTeamMemberFlag = false;
      this.teamMemberDeatils = val;
      this.teamMemberId = val.id;
      this.getTeamMemberDashboardData(
        this.teamMemberId,
        this.surveyDateDropId,
        false,
        surveyIdforView
      );
      this.teamMemberList.forEach((ele, ind) => {
        if (ind == index) {
          ele.editShow = ele.editShow ? ele.editShow : false;
        } else {
          ele.editShow = false;
        }
      });
      val.editShow = !this.isUpdatedMember ? !val.editShow : val.editShow;
      if (val.editShow) {
        this.selectedTeamMemberFlag = true;
        this.userActiveflag = false;
      }
      this.teamMemberList.splice(index, 1, val);
    },
    selectedSurveyTeamMember(val, index) {
      this.createSurveyError = false;
      val.editShowSelected = !val.editShowSelected;
      this.memberList.splice(index, 1, val);
    },
    deSelectedTeamMember(val, index) {
      val.editShow = !val.editShow;
      this.teamMemberList.splice(index, 0, val);
    },
    saveDataApi(val) {
      this.activeKey = ["3"];
      this.getAllTeamMemberData();
    },
    startSurvey() {
      this.isShowConfirm = false;
      if (this.teamMemberList.length == 0) {
        this.isShowConfirm = true;
      } else {
        this.getMyTeamForSurveyData();
      }
    },
    gaEventTrack(data, action, category) {
      this.event(action, {
        event_category: category,
        event_label: data,
        value: 1,
      });
    },
    addNewMember() {
      let fullName = this.firstName + " " + this.lastName;
      this.gaEventTrack(
        fullName,
        "portal_addnewmember",
        "Main page - Add team memeber"
      );

      this.isShowAddMembers = !this.isShowAddMembers;
    },
    sendCodeClick(val) {
      this.isShowAddMembers = val;
      this.isUpdatedMember = val;
      this.additionalReviewersFlag = val;
    },
    callback(key) {
      console.log(key);
    },
    dropDataShow(val, key) {
      return val[key];
    },
    onChange(val, key) {
      this.formData[key] = val;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
    goToSignUp() {
      this.$router.push("/signup");
    },
    goToProfile() {
      this.$router.push({
        path: '/profile',
        query: { tab: this.$route.query.tab },
      });
    },
    goToLandingPage() {
      this.isShowLogOut = true;
    },
    colseLoginPopup(val) {
      this.isShowLogOut = val;
    },
    colseAllLogOutPopup(val) {
      this.isShowAllLogOut = val;
      this.$router.push("/");
    },
    logOutDevice(val) {
      this.isShowLogOut = false;
      this.loadingFlag = true;
      let postData = {
        userID: this.userId,
        device: localStorage.getItem("userDeviceName"),
        logOutFromAllDevice: val,
      };
      this.services
        .post(`user/logOut`, postData)
        .then((res) => {
          this.loadingFlag = false;
          if (res.status == 200) {
            localStorage.removeItem("token");
            localStorage.removeItem("userDetails");
            localStorage.removeItem("userDeviceName");

            this.isAllDevice = val;
            this.isShowAllLogOut = true;
            // this.$router.push("/");
          }
          // this.isStartNewSurveyPop = true;
        })
        .catch((err) => {
          this.loadingFlag = false;
          if (err.response.status == 303) {
            this.errorPopupFlag = true;
          }
        });
    },
    forgotPasswordClick() {
      this.$store.state.forgotFlag = true;

      this.$router.push("/forgot-password");
    },
    getData(callback) {
      reqwest({
        url: fakeDataUrl,
        type: "json",
        method: "get",
        contentType: "application/json",
        success: (res) => {
          callback(res);
        },
      });
    },
    onLoadMoreFeedback(btnText) {
      let innerHeight = document.querySelector(
        ".feedback-div-scroll"
      ).offsetHeight;
      this.feedbackSectionHeight = innerHeight;
      innerHeight = innerHeight + 5;

      if (this.showFeedbackBtn) {
        this.dynamicHeight =
          "max-height: " + innerHeight > 145 ? innerHeight : 145 + "px";
        this.showFeedbackBtn = false;
        this[btnText] = "Show less";
      } else {
        this.dynamicHeight = "max-height: 145px;";
        this.showFeedbackBtn = true;
        this[btnText] = "Show more";
      }
    },
    onLoadMore(data, count, commentBtn) {
      if (this[count] < 6) {
        this[count] = data.length;
        this[commentBtn] = `Show less`;
      } else {
        this[count] = 5;
        this[commentBtn] = `Show all ${data.length} received`;
      }
    },
    sessionTimeOut() {
      localStorage.removeItem("token");
      localStorage.removeItem("userDetails");
      localStorage.removeItem("userDeviceName");
      if (!this.isTimeOut) {
        this.isTimeOut = true;
      }
    },
    async handleChangeTab(tab) {
      this.curTab = tab;
      this.selectedTeamMemberFlag = false;
      this.$router.replace({ query: { tab: this.curTab } });
      await this.getAllTeamMemberData(true);
    },
    async handleHideComment(isHidden, commentId, type) {
      const response = await this.services.post(`dashboard/hide-comments`, {
        isHidden,
        commentId,
      });
      if (response.status === 200) {
        switch (type) {
          case TypeComments.NEED_WORK:
            this.feedBackNeedWorkCommentsList.forEach((comment) => {
              if (comment.id === commentId) comment.isCommentHidden = isHidden;
            });
            break;
          case TypeComments.DOES_WELL:
            this.feedBackDoesWellCommentsList.forEach((comment) => {
              if (comment.id === commentId) comment.isCommentHidden = isHidden;
            });
            break;
          default:
            console.warn("Unexpected comment type:", type);
        }
        if (isHidden) this.isShowHiddenCmtConfirm = true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.hiddenCmt {
  opacity: 70;
}

.disable-pointer {
  pointer-events: none;
}

.feedback-clr {
  color: #bfc3d6 !important;
}

.no-reviwer-section {
  font-size: 14px;
  color: #bfc3d6;

  p {
    padding: 20px 40px;
  }
}

::v-deep {
  .ant-list-empty-text {
    border-radius: 10px;
    background-color: #edeef5 !important;
  }
}

.dot-css {
  width: 8px;
  height: 8px;
  margin-left: -12px;
  margin-bottom: 3px;
}

.show {
  visibility: visible;
}

.hide {
  visibility: hidden;
}

.cursor-arw {
  cursor: default !important;
}

.rem-class {
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 400;

  // font-size: 20px;
  line-height: 24px;
  color: #6454f0;
  cursor: pointer;
}

::v-deep {
  .feedback-div {
    width: 100% !important;
    min-height: 50px;
    max-height: 145px;
    transition: all 0.3s ease-in;
    background: #edeef5;
    padding: 15px 10px 15px 15px !important;

    .ant-row:last-child .ant-list-item {
      margin-bottom: 0px !important;
    }
  }

  .feedback-comment-div {
    width: 100% !important;
    min-height: 50px;
    max-height: 145px;
    transition: all 0.3s ease-in;
    background: #edeef5;
    padding: 15px 10px 15px 15px !important;
  }

  .feedback-div-scroll {
    overflow-x: hidden !important;
  }

  .feedback-section .ant-btn-primary {
    border-radius: 0px 0px 10px 10px !important;
  }

  .btn-black-wrapper {
    width: 93%;
    margin: auto;
    padding-top: 5px;
    padding-bottom: 15px;
  }

  .feedback-div-scroll {
    .ant-col {
      display: block;
      box-sizing: border-box;
      width: auto !important;
    }
  }

  .feedback-div .ant-row:last-child .ant-list-item {
    cursor: default !important;
  }
}

::v-deep {
  .left-div-row {
    padding-left: 0px !important;
    padding-right: 0px !important;
    height: calc(100vh - 120px);
    overflow: hidden;
  }

  .additional-reviewer-sec {
    .ant-list-item {
      display: inline-block !important;
      margin-right: 15px;
    }
  }

  .ant-input,
  .ant-select-selection {
    // padding-top: 3px !important;
    // width: 50% !important;
    padding-left: 5px;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    color: #363644;
  }
}

.show-all-btn {
  margin-right: 10px;
}

.list-pad {
  padding: 6px 0px !important;
}

.survey-init-class {
  width: 50% !important;
  padding-left: 5px;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  color: #363644;
}

.not-get {
  font-family: "Inter", fantasy !important;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  padding-left: 14px;
  line-height: 19px;
  color: #bfc3d6;
}

// .demo-loadmore-list {
//   min-height: 350px;
// }
::v-deep {

  .pl-pr .ant-input,
  .pl-pr .ant-select-selection {
    height: 45px !important;
    font-size: 14px !important;
  }

  .icon-wrapper {
    background: #797994;
    width: 24px;
    height: 24px;
    z-index: 1;
    border-radius: 50%;
    margin-left: 10px;

    em {
      position: relative;
      font-size: 26px;
      color: #bfc3d6;
      left: -13px;
      top: 0px;
    }
  }

  .bold-right-txt .ant-list-items {
    background: #edeef5 !important;
    margin: 0 !important;
    padding: 0 !important;
    list-style: none !important;
  }

  .bold-right-txt-comment .ant-list-items {
    background: #edeef5 !important;
    margin: 0 !important;
    padding: 0 !important;
    list-style: none !important;
  }
}

.ant-list-split .ant-list-item .name-list {
  display: inline-flex !important;
}

.ant-list-split .ant-list-item {

  .name-list,
  .role-list {
    text-overflow: ellipsis !important;
  }

  border-bottom: none !important;
}

.ant-list-sm .ant-list-item {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}

.ant-list-sm .ant-list-item:hover {
  background-color: #edeef5;
}

.ant-list-sm .ant-list-item.active,
.pointer .value-txt.active,
.round-list-grey.active {
  background-color: #6454f0 !important;
  color: #fff !important;
}

.ant-list-sm .ant-list-item.active .name-list,
.ant-list-sm .ant-list-item.active .role-list {
  color: #fff !important;
  text-overflow: ellipsis !important;
}

#arrow {
  background-color: #fff;
  color: #fff;
  border-radius: 0px !important;
  padding: 5px;
  z-index: 999;
  width: 178px;
  position: relative;
}

#arrow:before {
  position: absolute;
  top: -10px;
  right: 15px;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #fff;
}

.round-list-grey {
  padding: 8px 18px;
  height: auto !important;
}

::v-deep {

  .ant-dropdown-menu-item-divider,
  .ant-dropdown-menu-submenu-title-divider {
    height: 0px !important;
    margin: 0px 0 !important;
    overflow: hidden !important;
    line-height: 0 !important;
    background-color: #e8e8e8 !important;
  }

  .ant-calendar-header .ant-calendar-prev-century-btn,
  .ant-calendar-header .ant-calendar-prev-decade-btn,
  .ant-calendar-header .ant-calendar-prev-year-btn,
  .ant-calendar-header .ant-calendar-prev-month-btn {
    pointer-events: none !important;
  }

  .accordian-wrapper .ant-collapse-content>.ant-collapse-content-box {
    padding: 0px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .ant-list-item {
    cursor: pointer;
  }

  .left-div {
    height: 100%;
    padding: 10px 30px !important;

    .div-scroll {
      // height: 330px;
      overflow: hidden;
      overflow-y: auto;
    }
  }

  .ant-btn-primary {
    font-size: 16px !important;

    span {
      padding: auto 20px !important;
    }
  }

  .ant-btn.small {
    font-size: 14px !important;
    height: 35px !important;
  }

  .add-member-success-popup .ant-btn-primary {
    margin-top: 10px !important;
  }

  .ant-modal-footer {
    display: none !important;
  }

  .ant-calendar-picker-input.ant-input {
    line-height: 1.5 !important;
    background-color: #edeef5 !important;
  }

  .ant-calendar-picker-icon {
    /* display: flex; */
    color: #6454f0 !important;
    font-size: 14px;
    line-height: 1;
  }

  .font-16 {
    color: #bfc3d6 !important;
  }

  .blue-txt,
  .blue-txt-no-pointer {
    padding-left: 0px !important;
  }
}

.pl-pr {
  padding-left: 4%;
  padding-right: 4%;
}

.confirm-contain-txt {
  font-size: 18px !important;
  line-height: 20px !important;
}

// .survey-class .ant-list-items .ant-list-item:hover:after {
//   right: 25px;
//   padding-top: 5px;
//   position: absolute;
//   content: url("../../assets/grey_tik.png"); /* no need for qoutes */
//   display: block !important;
// }
.survey-class .ant-list-items .ant-list-item:hover {
  background-color: #bfc3d6 !important;

  .icon-circle-tick {
    color: #a6a8b3;
    visibility: visible !important;
    transform: matrix(1, 0, 0, -1, 0, 0);
    font-size: 25px !important;
    background: linear-gradient(to left, white 50%, white 50%) !important;
    border-radius: 100% !important;
  }
}

.blue-tik {
  color: #46cefc;
}

// .survey-class .ant-list-items .ant-list-item.active:hover:after {
//   right: 25px;
//   padding-top: 5px;
//   position: absolute;
//   content: url("../../assets/blue_tik_s.png"); /* no need for qoutes */
//   display: block !important;
// }
.survey-class .ant-list-items .ant-list-item.active {
  background-color: #6454f0 !important;
  color: #fff !important;

  .icon-circle-tick {
    color: #46cefc;
    visibility: visible !important;
    transform: matrix(1, 0, 0, -1, 0, 0);
    font-size: 25px !important;
    border-radius: 100% !important;
    background: linear-gradient(to left, white 50%, white 50%) !important;
  }
}

.team-error-txt {
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #f13c47;
}

.date-txt {
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  color: #363644;
}

.user-name-sec {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 20px;

  &:hover {
    background-color: #bfc3d6 !important;
    color: #fff;
  }

  .bold-txt-list {
    padding: 0px 5% !important;
    height: 42px !important;
    cursor: pointer;
  }
}

.user-name-sec.active {
  background-color: #6454f0 !important;

  .bold-txt-list {
    color: #fff;
  }
}

.pos-bottom {
  position: absolute;
  bottom: 22%;
  max-width: calc(100% - 80px);
}

::v-deep {
  .ant-calendar-selected-day .ant-calendar-date {
    background: #6454f0 !important;
  }

  .div-scroll-right * {
    display: inline;
  }

  .div-scroll-right .ant-list-empty-text,
  .ant-list-empty-text * {
    display: block !important;
  }

  .ant-calendar-date {
    display: block !important;
    width: 24px !important;
    height: 24px !important;
    margin: 0 auto !important;
    padding: 0 !important;
    color: rgba(0, 0, 0, 0.65) !important;
    line-height: 22px !important;
    text-align: center !important;
    background: transparent !important;
    border: 1px solid transparent !important;
    border-radius: 2px !important;
  }

  .ant-calendar-picker-input,
  .ant-input {
    box-sizing: border-box !important;
    margin: 0 !important;
    font-variant: tabular-nums !important;
    list-style: none !important;
    font-feature-settings: "tnum" !important;
    position: relative !important;
    display: inline-block !important;
    width: 100% !important;
    height: 32px !important;
    padding: 4px 11px 4px 35px !important;
    color: rgba(0, 0, 0, 0.65) !important;
    font-size: 16px !important;
    line-height: 1.5 !important;
    background-color: #fff !important;
    background-image: none !important;
    border: 1px solid #edeef5 !important;
    border-radius: 4px !important;
    transition: all 0.3s !important;
  }

  .has-error {

    .ant-calendar-picker-input,
    .ant-input {
      border-color: #f5222d !important;
    }
  }

  .ant-calendar-picker-clear {
    display: none !important;
    position: absolute !important;
    top: 50% !important;
    left: 12px !important;
    z-index: 1 !important;
    width: 14px !important;
    height: 14px !important;
    margin-top: -7px !important;
    font-size: 16px !important;
    line-height: 14px !important;
    transition: all 0.3s !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }

  .ant-calendar-picker-clear,
  .ant-calendar-picker-icon {
    position: absolute !important;
    top: 50% !important;
    left: 12px !important;
    z-index: 1 !important;
    width: 14px !important;
    height: 14px !important;
    margin-top: -7px !important;
    font-size: 16px !important;
    line-height: 14px !important;
    transition: all 0.3s !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }

  .ant-tabs .ant-tabs-top-content>.ant-tabs-tabpane,
  .ant-tabs .ant-tabs-bottom-content>.ant-tabs-tabpane {
    -webkit-backface-visibility: visible !important;
  }

  @media screen and (max-width: 1200px) and (min-width: 990px) {
    .accordian-wrapper {

      .name-list,
      .role-list {
        font-size: 1.2vw !important;
      }
    }
  }

  @media screen and (max-width: 991px) {

    .left-div-row,
    .right-div {
      height: 100% !important;
    }

    .likelihood {
      padding: 0 !important;
    }

    .pos-bottom {
      position: relative;
      max-width: 100%;
    }

    .add-btn {
      margin-top: 10px;
      max-width: 100%;
      font-size: 15px;
      padding-left: 0;
    }

    .left-div-wrapper {
      box-shadow: none;
    }

    .left-div-wrapper .fixed-btm {
      width: 100% !important;
      position: relative;
    }

    .left-div {
      padding: 10px 20px !important;
    }

    .graph-div {
      width: 100%;
    }

    .accordian-wrapper {

      .name-list,
      .role-list {
        font-size: 16px !important;
      }
    }

    .mr-top-res {
      margin-top: 30px !important;
    }

    .left-div-row {
      margin: auto;
      width: 40% !important;
    }

    .user-name-sec {
      margin-right: -5px !important;
    }

    .right-div-scroll {
      padding: 20px 0 !important;

      .ant-row {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }

    .bold-right-txt {
      padding: 0 !important;
    }

    .bold-right-txt-comment {
      padding: 0 !important;
    }
  }

  .list-icon {
    margin-top: -2px;
  }

  .edit-icon {
    transition: 0.3s ease all;

    &:hover {
      img {
        transform: scale(1.3);
        transition: 0.3s ease all;
      }
    }
  }

  .ant-calendar-prev-year-btn,
  .ant-calendar-prev-month-btn {
    pointer-events: none !important;
  }

  .create-survey-button {
    width: 70%;
    font-size: 14px !important;
    position: absolute;
    top: 5%;
    left: 5%;
  }

  .error-txt {
    font-size: 14px !important;
  }

  .anticon-check {
    padding-left: 5px;

    svg {
      height: 15px !important;
      stroke: 10px #cccc;
    }
  }

  .role-item-text {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
  }

  .votes-css {
    display: inline;
    font-size: 12px;
  }

  .set-position {
    text-align: center;
  }

  .bold-right-txt.rounded-border .ant-list-items {
    border-radius: 10px !important;
  }

  .bold-right-txt.not-all-rounded .ant-list-items {
    border-radius: 10px 10px 0px 0px !important;
  }

  .bold-right-txt-comment.rounded-border .ant-list-items {
    border-radius: 10px !important;
  }

  .bold-right-txt-comment.not-all-rounded .ant-list-items {
    border-radius: 10px 10px 0px 0px !important;
  }

  .comment-div .ant-list-item {
    border-bottom: 1px solid #bfc3d6 !important;
  }

  .comment-div .ant-list-item:last-child {
    border-bottom: 1px solid transparent !important;
  }

  .check-wrapper.icon-check {
    margin-left: 8px;
    font-size: 12px;
  }

  .right-div {
    height: calc(100vh - 120px);
    overflow: hidden;
    margin-top: 0 !important;

    ::-webkit-scrollbar {
      background-color: #fff !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #e0e0e0;
      border-radius: 5px;
      margin: 30px 0 30px 0 !important;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #bebcbc;
      border-radius: 5px;
      margin: 30px 0 30px 0 !important;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: rgb(133, 132, 132);
    }
  }

  .additional-reviewer-sec {
    ::-webkit-scrollbar {
      height: 3px !important;
      background-color: #bfc3d6 !important;
      border-radius: 5px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #e0e0e0;
      border-radius: 5px;
      margin: 0px 0px !important;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #bebcbc;
      border-radius: 5px;
      margin: 0px 0px !important;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: rgb(133, 132, 132);
    }
  }
}

.tab {
  display: flex;
  border-radius: 5px;
  border: solid 1px #4a3cc0;
  height: 26px;

  .tab-item {
    text-align: center;
    color: #363644;
    background: white;

    cursor: pointer;

    p::first-letter {
      margin: 0;
      text-transform: capitalize;
    }

    &:hover {
      background: #edeef5;
    }
  }

  .user {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .admin {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-left: solid 1px #4a3cc0;
  }

  .tab-item--active {
    color: white;
    font-weight: 600;
    background: #6454f0;

    &:hover {
      background: #6454f0;
    }
  }

  .admin.tab-item--active {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .user.tab-item--active {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.hide-button {
  &:hover {
    background: #4a3cc0 !important;
  }

  &:focus {
    background: #6454f0;
  }
}

.unhide-button {
  &:hover {
    background: #edeef5 !important;
  }
}

@media screen and (max-width: 768px) {

  .left-div-row,
  .right-div-wrapper {
    width: 100% !important;
  }
}
</style>
