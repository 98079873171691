<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :pdf-quality="2"
      :manual-pagination="false"
      :paginate-elements-by-height="2000"
      pdf-content-width="1100px"
      :htmlToPdfOptions="{
        margin: [10, 0, 0, 0],
        filename: `${filename}.pdf`,
        jsPDF: {
          orientation: 'p',
          unit: 'mm',
          format: [320, 470],
        },
        html2canvas: { scale: 2 },
      }"
      :ref="filename"
    >
      <slot slot="pdf-content"></slot>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
  props: {
    filename: {
      type: String,
      default: "test",
    },
  },
  methods: {
    generateReport() {
      this.$emit("startDownload");
      this.$refs[this.filename].generatePdf();
    },
  },

  components: {
    VueHtml2pdf,
  },
};
</script>
