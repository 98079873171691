import Vue from "vue";
import VueRouter from "vue-router";
import LandingPage from "../views/LandingPage/LandingPage.vue";
import Login from "../views/Auth/Login/Login.vue";
import ForgotPassword from "../views/Auth/Login/ForgotPassword.vue";
import CreateNewPassword from "../views/Auth/Login/CreateNewPassword.vue";
import SignUp from "../views/Auth/SignUp/SignUp.vue";
import TermsAndConditions from "../views/Auth/SignUp/TermsAndConditions.vue";
import PrivacyAndPolicy from "../views/Auth/SignUp/PrivacyAndPolicy.vue"
import VerificationPage from "../views/Auth/SignUp/VerificationPage.vue";
import HomePage from "../views/Home/HomePage.vue";
import Profile from "../views/Profile/Profile.vue";
import NominateReviewers from "../views/Email/NominateReviewers.vue";
import AcceptEmailInvitation from "../views/Email/AcceptEmailInvitation.vue";
import FeedbackSurvey from "../views/Email/FeedbackSurvey.vue";
import SurveySetup from "../views/SurveySetup/";
import Policies from "../views/Auth/SignUp/Policies.vue"
import RespondSurveySuccess from "../views/SurveySetup/RespondSurveySuccess.vue";
import SurveyAlreadyCompleted from "../views/SurveySetup/SurveyAlreadyCompleted.vue";
import SurveyLinkIsExpired from "../views/SurveySetup/SurveyLinkIsExpired.vue";
import AlreadyNominatedReviewers from "../views/SurveySetup/AlreadyNominatedReviewers.vue";
import NominateReviewersExpired from "../views/SurveySetup/NominateReviewersExpired.vue";
import AdminProfile from "../views/Admin/AdminProfile.vue";
import AdminConsole from "../views/Admin/AdminConsole.vue";
import AdminLogin from "../views/Admin/AdminLogin.vue";
import store from "@/store";
// import VueGtag from "vue-gtag";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Gestaltra - 360 surveys",
    component: LandingPage,
  },
  {
    path: "/login",
    name: "Gestaltra - Login",
    component: Login,
  },
  {
    path: "/signup",
    name: "Gestaltra - Sign up",
    component: SignUp,
  },
  {
    path: "/verify",
    name: "Gestaltra - Verify",
    component: VerificationPage,
    beforeEnter: (to, from, next) => {
      if (!store.state.signUpFlag) {
        return next({ path: "/" });
      }

      return next();
    },
    },
    {
        path: "/policies",
        name: "Gestaltra - Legal",
        component: Policies,

    },
  {
    path: "/policies",
    name: "Gestaltra - Terms of Service",
    component: TermsAndConditions,
  },
  {
    path: "/policies",
    name: "Gestaltra - Privacy Policy",
    component: PrivacyAndPolicy,
  },
  {
    path: "/main",
    name: "Gestaltra - Home",
    component: HomePage,
  },
  {
    path: "/profile",
    name: "Gestaltra - Profile",
    component: Profile,
  },
  {
    path: "/admin-login",
    name: "Gestaltra Admin - Login",
    component: AdminLogin,
  },
  {
    path: "/admin-profile",
    name: "Gestaltra Admin - Profile",
    component: AdminProfile,
  },
  {
    path: "/admin-main",
    name: "Gestaltra Admin - Console",
    component: AdminConsole,

  },

  {
    path: "/nominate",
    name: "Gestaltra - Nominate reviewers",
    component: NominateReviewers,
  },
  {
    path: "/nominate-complete",
    name: "Gestaltra - Thanks!",
    component: AcceptEmailInvitation,
  },
  {
    path: "/survey",
    name: "Gestaltra - Survey",
    component: FeedbackSurvey,
  },
  {
    path: "/survey-complete",
    name: "Gestaltra - Thanks!",
    component: RespondSurveySuccess,
  },
  {
    path: "/survey-already-completed",
    name: "Gestaltra - Survey completed",
    component: SurveyAlreadyCompleted,
  },
  {
    path: "/survey-link-is-expired",
    name: "Gestaltra - Link expired",
    component: SurveyLinkIsExpired,
  },
  {
    path: "/already-nominated-reviewers",
    name: "Gestaltra - Reviewers nominated",
    component: AlreadyNominatedReviewers,
  },
  {
    path: "/nominate-reviewers-expired",
    name: "Gestaltra - Link expired",
    component: NominateReviewersExpired,
  },
  {
    path: "/forgot-password",
    name: "Gestaltra - Forgot password",
    component: ForgotPassword,
    // beforeEnter: (to, from, next) => {
    //   if (!store.state.forgotFlag) {

    //     return next({ path: "/login" });
    //   }

    //   return next();
    // },
  },
  {
    path: "/reset-password",
    name: "Gestaltra - Reset password",
    component: CreateNewPassword,
    beforeEnter: (to, from, next) => {
      let urlId = to.query.ID
      if (!urlId) {
        return next({ path: "/login" });
      }

      return next();
    },
  },

  {
    path: "/survey-response",
    name: "Gestaltra - Survey",
    component: SurveySetup,

  },
];

// Vue.config.globalProperties = store;
// Vue.use(VueGtag, {
//   config: { id: "G-V3HRF2DL37" },
//   pageTrackerTemplate(to) {
//     return {
//       page_title: 'amazing page',
//       page_path: to.path
//     }
//   },
// }, router);
const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.name;
  next();
});

export default router;
