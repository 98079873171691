<template>
  <div class="bg-color">
    <a-spin :spinning="isSpinning">
      <div class="center-signUp-div white-bg-css">
        <a-row :gutter="24" class="center">
          <a-col :span="24" class="wlcm">
            <div class="wlcm">Create an account</div>
          </a-col>
        </a-row>
        <a-row class="txt-labal" :gutter="24">
          <a-col :span="24">
            <span class="sm-txt" style="padding-left: 5px"
              >Already have an account? </span
            ><span class="pointer blue-txt-sm" @click="goToLogin">Sign in</span>
          </a-col>
        </a-row>
        <a-form :form="form" @submit="handleSubmit" class="mt-25">
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-item class="float-label">
                <label
                  :class="
                    focusData.focusDataFn || formData.firstName
                      ? 'label label-float'
                      : 'label'
                  "
                  >First name</label
                >
                <a-input
                  @blur="setFocus(false, 'focusDataFn')"
                  @focus="setFocus(true, 'focusDataFn')"
                  v-decorator="[
                    'firstName',
                    {
                      rules: [
                        { required: true, message: 'Please enter first name' },
                      ],
                    },
                  ]"
                  @change="onChange($event.target.value, 'firstName')"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item class="float-label">
                <label
                  :class="
                    focusData.focusDataLn || formData.lastName
                      ? 'label label-float'
                      : 'label'
                  "
                  >Last name</label
                >
                <a-input
                  @blur="setFocus(false, 'focusDataLn')"
                  @focus="setFocus(true, 'focusDataLn')"
                  v-decorator="[
                    'lastName',
                    {
                      rules: [
                        { required: true, message: 'Please enter last name' },
                      ],
                    },
                  ]"
                  @change="onChange($event.target.value, 'lastName')"
                /> </a-form-item
            ></a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="24">
              <a-form-item class="float-label">
                <label
                  :class="
                    focusData.organisation || formData.organisation
                      ? 'label label-float'
                      : 'label'
                  "
                  >Organisation name</label
                >
                <a-input
                  @blur="setFocus(false, 'organisation')"
                  @focus="setFocus(true, 'organisation')"
                  v-decorator="[
                    'organisation',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter organisation name',
                        },
                      ],
                    },
                  ]"
                  @change="onChange($event.target.value, 'organisation')"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="dynamicSpan">
              <a-form-item class="float-label custom-top">
                <label
                  :class="
                    focusData.industry || formData.industry
                      ? 'label label-float'
                      : 'label'
                  "
                  >Industry</label
                >
                <a-select
                  :show-search="true"
                  not-found-content="No matches found"
                  @blur="setFocus(false, 'industry')"
                  @focus="setFocus(true, 'industry')"
                  optionFilterProp="children"
                  key="industry"
                  v-decorator="[
                    'industry',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please select an industry',
                        },
                      ],
                    },
                  ]"
                  :value="dropDataShow(formData.industry, 'industryName')"
                  @change="onChangeOthers(indData[$event], 'industry')"
                >
                  <a-select-option
                    v-for="(item, index) in indData"
                    :key="index"
                    :filter-option="
                      (input, item) => {
                        option.industryName
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0;
                      }
                    "
                  >
                    {{ item.industryName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="dynamicSpan2" v-if="industryTextBox">
              <a-form-item class="float-label">
                <label
                  :class="
                    focusData.otherIndustry || formData.otherIndustry
                      ? 'label label-float'
                      : 'label'
                  "
                  >Enter industry</label
                >
                <a-input
                  @blur="setFocus(false, 'otherIndustry')"
                  @focus="setFocus(true, 'otherIndustry')"
                  v-decorator="[
                    'otherIndustry',
                    {
                      rules: [
                        { required: true, message: 'Please enter an industry' },
                      ],
                    },
                  ]"
                  @change="onChange($event.target.value, 'otherIndustry')"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="24">
              <a-form-item
                :class="emailValidate ? 'has-error' : null"
                class="float-label email-wrapper"
              >
                <label
                  :class="
                    focusData.email || formData.email
                      ? 'label label-float'
                      : 'label'
                  "
                  >Work email</label
                >
                <a-input
                  @focus="setFocus(true, 'email')"
                  v-decorator="[
                    'email',
                    {
                      rules: [
                        {
                          type: 'email',
                          message: 'Please enter a valid email address',
                        },
                        {
                          required: true,
                          message: 'Please enter a valid email address',
                        },
                        {
                          validator: checkExistEmail,
                        },
                      ],
                      validateTrigger: 'blur',
                    },
                  ]"
                  @change="onChange($event.target.value, 'email')"
                  @blur="checkExistingEmail($event.target.value)"
                />
                <div class="ant-form-explain" v-if="emailValidate">
                  This email address is already registered. Enter a different
                  email or
                  <router-link :to="{ name: 'Login' }" class="blue-txt-sm"
                    >log in</router-link
                  >
                  instead
                </div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="24">
              <a-form-item class="float-label password-section">
                <a-popover
                  placement="right"
                  trigger="click"
                  :overlay-style="{
                    'font-size': '12px !important',
                    'border-radius': '10px !important',
                  }"
                >
                  <template
                    slot="content"
                    :overlay-style="{
                      'border-radius': '10px !important',
                    }"
                  >
                    <p><strong>Password must:</strong></p>
                    <p>
                      • Be a minimum of 8 characters
                      <span v-if="passlength">
                        <img
                          src="../../../assets/tick.png"
                          style="width: 18px"
                          alt=""
                          class="success-unsuccess-icons"
                        />
                      </span>
                      <span v-else>
                        <img
                          src="../../../assets/cross.png"
                          style="width: 18px"
                          alt=""
                        />
                      </span>
                    </p>
                    <p>
                      • Have a mixture of letters and numbers
                      <span v-if="letterNumber">
                        <img
                          src="../../../assets/tick.png"
                          alt=""
                          style="width: 18px"
                        />
                      </span>
                      <span v-else>
                        <img
                          src="../../../assets/cross.png"
                          alt=""
                          style="width: 18px"
                      /></span>
                    </p>
                    <p>
                      • Include at least one special character<br />&nbsp;&nbsp;(e.g.
                      ! @ # ?)
                      <span v-if="specialCharacter">
                        <img
                          src="../../../assets/tick.png"
                          style="width: 18px"
                          alt=""
                        />
                      </span>
                      <span v-else>
                        <img
                          src="../../../assets/cross.png"
                          style="width: 18px"
                          alt=""
                      /></span>
                    </p>
                  </template>
                  <label
                    :class="
                      focusData.password || formData.password
                        ? 'label label-float'
                        : 'label'
                    "
                    >Password</label
                  >
                  <a-input-password
                    @blur="setFocus(false, 'password')"
                    @focus="setFocus(true, 'password')"
                    v-decorator="[
                      'password',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Please enter a valid password',
                          },
                          {
                            validator: validateToNextPassword,
                          },
                          {
                            pattern: passwordRegex,
                            lengthRegex,
                            letternumRegex,
                            specialCharRegex,
                            message: 'Please enter a valid password',
                          },
                        ],
                      },
                    ]"
                    @change="onChange($event.target.value, 'password')"
                  />
                </a-popover>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="24">
              <a-form-item class="float-label">
                <label
                  :class="
                    focusData.confirmPassword || formData.confirmPassword
                      ? 'label label-float'
                      : 'label'
                  "
                  >Confirm password</label
                >
                <a-input-password
                  @blur="setFocus(false, 'confirmPassword')"
                  @focus="setFocus(true, 'confirmPassword')"
                  v-decorator="[
                    'confirmPassword',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Passwords don’t match – please try again',
                        },
                        {
                          validator: compareToFirstPassword,
                        },
                      ],
                    },
                  ]"
                  @change="onChange($event.target.value, 'confirmPassword')"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="24" class="pdb-20">
            <a-col :span="2">
              <a-checkbox
                class="grey-txt-sm"
                v-decorator="[
                  'terms-and-contions',
                  {
                    rules: [
                      {
                        required: true,
                        message:
                          'You must agree to the Terms of Service to continue',
                      },
                    ],
                  },
                ]"
                @change="
                  checkBoxChange($event.target.checked, 'termConditionCheckBox')
                "
              />
            </a-col>
            <a-col :span="20" class="pad-left-none">
              <span>I have read and agree to the&nbsp;</span>
              <a class="blue-txt-sm" @click="goToTermsAndConditions"
                >Terms of Service</a
              >
              and
              <a class="blue-txt-sm" @click="goToPrivacyAndPolicy"
                >Privacy Policy</a
              >
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="24">
              <a-form-item>
                <a-button type="primary" html-type="submit" class="arrow-cls">
                  Sign up<a-icon
                    style="
                      font-size: 25px !important;
                      font-weight: bold !important;
                      margin-top: -4px !important;
                      border-bottom-style: none;
                    "
                    type="arrow-right"
                  />
                </a-button>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row
            :gutter="24"
            v-if="termConditionCheckBoxMsg"
            class="center error"
            ><a-col :span="24"
              ><span
                >You must agree to the Terms of Service to continue</span
              ></a-col
            ></a-row
          >
        </a-form>
      </div>
    </a-spin>
    <error-pop-up :is-show="errorPopupFlag" @closePopup="closedErrorPopup" />
  </div>
</template>
<script>
import services from "../../../services/services.js";
import ErrorPopUp from "@/components/ErrorPopUp.vue";
import { pageview, event } from "vue-gtag";
// indData=[];
export default {
  name: "Signup",
  components: {
    ErrorPopUp,
  },

  data() {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\\\])(?=.{8,})/;
    const lengthRegex = /^[a-zA-Z0-9]{8,}$/;
    const letternumRegex = /^(?=.*[a-z])(?=.*[0-9])/;
    const specialCharRegex = /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\\\]).*$/;
    return {
      passlength: false,
      letterNumber: false,
      specialCharacter: false,
      lengthRegex,
      passwordRegex,
      specialCharRegex,
      letternumRegex,
      event,
      pageview,
      errorPopupFlag: false,
      isSpinning: false,
      termConditionCheckBox: false,
      termConditionCheckBoxMsg: false,
      emailValidate: false,
      services,
      indData: [],
      industryTextBox: false,
      formLayout: "horizontal",
      dynamicSpan: 24,
      form: this.$form.createForm(this, { name: "coordinated" }),
      formData: {
        industry: "",
      },
      focusData: {
        focusDataFn: false,
        focusDataLn: false,
        organisation: false,
        industry: false,
        otherIndustry: false,
        email: false,
        confirmPassword: false,
        password: false,
      },
      orgIndData: [],
    };
  },

  async created() {
    this.track();
    this.indData = [];
    this.services
      .get("user/getAllIndustries")
      .then((res) => {
        this.indData = res.data.body;
        this.indData.forEach((item, index) => {
          if (item.industryName == "Other") {
            let fromIndex = index;
            Array.prototype.move = function (from, to) {
              this.splice(to, 0, this.splice(from, 1)[0]);
              return this;
            };
            this.indData.move(fromIndex, this.indData.length - 1);
          }
        });
        this.orgIndData = this.indData;
      })
      .catch((err) => {
        console.log(err);
      });

    if (this.$store.state.reviewersDetailsEmail) {
      let teamMemberDeatails = this.$store.state.reviewersDetailsEmail;
      if (teamMemberDeatails.email) {
        this.formData.email = teamMemberDeatails.email;
        this.form.getFieldDecorator("email", {
          initialValue: teamMemberDeatails.email,
        });
        this.$store.state.reviewersDetails = {};
      }
    }
    let teamMemberDeatails = this.$store.state.reviewersDetails;
    if (teamMemberDeatails.email) {
      this.formData.email = teamMemberDeatails.email;
      this.form.getFieldDecorator("email", {
        initialValue: teamMemberDeatails.email,
      });
      this.$store.state.reviewersDetails = {};
    }
  },

  methods: {
    track() {
      this.pageview("createaccount_main");
    },
    closedErrorPopup(val) {
      this.errorPopupFlag = val;
    },
    filterData(input, option) {
      if (this.indData.length > 0)
        return (
          option.industryName.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
    },
    inputValue(val) {},
    setFocus(val, key) {
      this.focusData[key] = val;
    },
    checkBoxChange(val, key) {
      this.termConditionCheckBox = val;
    },
    signUp() {
      let signUpdata = this.$store.state.signUpDataObject;
      let postData = {
        email: signUpdata.email,
        password: signUpdata.password,
        firstName: signUpdata.firstName,
        lastName: signUpdata.lastName,
        organisation: signUpdata.organisation,
        industryId: signUpdata.industry.id,
        otherIndustry: signUpdata.otherIndustry ? signUpdata.otherIndustry : "",
      };
      if (postData) {
        this.services
          .post("user/signup", postData)
          .then((res) => {
            if (res.data.userID && res.status == 200) {
              this.$store.state.userId = res.data.userID;
              this.$store.state.signUpDataObject.userId = res.data.userID;
              this.$store.state.userDetails =
                this.$store.state.signUpDataObject;
              localStorage.setItem(
                "userDetails",
                JSON.stringify(this.$store.state.signUpDataObject)
              );

              this.$store.state.signUpFlag = true;
              this.verificationeEmail();
            }
          })
          .catch((err) => {
            if (err.response.status == 302) {
              this.emailValidate = true;
            }
          });
      }
    },
    verificationeEmail() {
      let sendTo = this.$store.state.signUpDataObject.email;

      let data = {
        toEmail: sendTo,
      };

      if (data) {
        this.services
          .post("user/sendVerificationEmail", data)
          .then((res) => {
            if (res) {
              this.$store.state.signUpFlag = true;
              this.$router.push("/verify");
            }
          })
          .catch((err) => {
            //this.errorPopupFlag = true//;
          });
      }
    },
    dropDataShow(val, key) {
      return val[key];
    },
    onChange(val, key) {
      if (key == "email") {
        this.form.setFieldsValue({
          [key]: val,
        });
        this.emailValidate = false;
      }

      this.formData[key] = val;
      this.$store.state.signUpDataObject = this.formData;
    },
    onChangeOthers(val, key) {
      this.formData[key] = val;
      this.formData.otherIndustry = "";
      this.$store.state.signUpDataObject = this.formData;
      if (val.industryName.toLowerCase() == "other") {
        this.industryTextBox = true;
        this.dynamicSpan = 12;
        this.dynamicSpan2 = 12;
      } else {
        this.industryTextBox = false;
        this.dynamicSpan = 24;
      }
    },
    goToLogin() {
      this.$router.push("/login");
    },
    goToTermsAndConditions() {
      let route = this.$router.resolve({ path: "/policies?type=terms" });
      window.open(window.location.origin + "/#/policies?type=terms");
    },
    goToPrivacyAndPolicy() {
      let route = this.$router.resolve({ path: "/policies?type=privacy" });
      window.open(window.location.origin + "/#/policies?type=privacy");
    },
    goTohomePage() {
      this.$router.push("/main");
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err && this.termConditionCheckBox) {
          this.termConditionCheckBoxMsg = false;
        } else {
          this.termConditionCheckBoxMsg = !this.termConditionCheckBox;
        }
        if (!err && this.termConditionCheckBox) {
          this.signUp();
        }
      });
    },
    checkExistEmail(rule, value, callback) {
      const form = this.form;
      let emailValidate = false;
      if (value && emailValidate) {
        callback("");
      } else {
        callback();
      }

      this.emailErrormsg = form.getFieldError("email");
    },
    callApiCheckExistEmail(emailErrormsg, val) {
      this.emailValidate = false;
      if (val && (!emailErrormsg || this.emailErrormsg[0] == "")) {
        this.isSpinning = true;
        this.services
          .post("user/isUserExist", { email: val })
          .then((res) => {
            if (res.status == 200) {
              this.isSpinning = false;
              this.emailValidate = false;
            }
          })
          .catch((err) => {
            this.isSpinning = false;
            if (err.response.status == 302) {
              this.emailValidate = true;
            } else {
              this.emailValidate = false;
              //this.errorPopupFlag = true//;
            }
          });
      }
    },
    checkExistingEmail(val) {
      this.setFocus(false, "email");
      this.callApiCheckExistEmail(this.emailErrormsg, val);
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;

      if (value && value !== form.getFieldValue("password")) {
        callback("Passwords don’t match – please try again");
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      if (value && this.confirmDirty) {
        form.validateFields(["confirm"], { force: true });
      }

      if (value) {
        this.passlength =
          this.lengthRegex.test(value) || this.passwordRegex.test(value);
        this.letterNumber = this.letternumRegex.test(value);
        this.specialCharacter = this.specialCharRegex.test(value);
      } else {
        this.passlength = false;
        this.letterNumber = false;
        this.specialCharacter = false;
      }

      callback();
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .bg-color {
    display: grid;
  }
  .anticon.ant-input-password-icon,
  .ant-select-arrow-icon {
    color: #363644 !important;
  }
  .ant-col.ant-col-24 {
    white-space: normal !important;
  }

  .white-bg-css {
    .ant-form-item {
      margin-bottom: 18px !important;
    }
    .ant-input,
    .ant-select-selection {
      height: 43px !important;
    }
  }

  .ant-btn-primary {
    height: 44px !important;
    font-size: 18px !important;
  }
  .arrow-cls span {
    padding-left: 10%;
  }
  .pointer {
    border-bottom: 1px solid #6454f0;
  }
  .grey-txt-sm.ant-checkbox-wrapper,
  .ant-checkbox-wrapper .blue-txt-sm {
    font-size: 14px !important;
  }
  .pointer {
    font-size: 18px !important;
  }

  .ant-input,
  .ant-select-selection {
    font-size: 14px !important;
    padding: 0px 10px !important;
    padding-top: 10px !important;
  }
  .ant-select-selection__rendered {
    margin: 0px !important;
  }
  .custom-top .label,
  .custom-top .ant-form-item label {
    top: 1px !important;
  }
  .custom-top .label-float,
  .custom-top .ant-form-item label {
    top: -6px !important;
  }
  .pad-left-none {
    padding-left: 0px !important;
  }
  .success-unsuccess-icons {
    width: 18px;
  }

  @media screen and (max-width: 590px) {
    .pad-left-none {
      padding-left: 10px !important;
    }
  }
}
</style>
