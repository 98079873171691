<template>
  <div :class="scrolled ? 'top-bar scroll' : 'top-bar'">
    <div class="wrapper">
      <div class="top-bar__logo" @click="goToHome"></div>

      <div class="top-bar__menu">
        <button @click="goToLogin" class="button--transparent">Login</button>
        <button @click="goToSignUp('try_for_free')">Sign up</button>
      </div>
    </div>
  </div>
</template>

<script>
import { event } from "vue-gtag";

export default {
  name: 'TopBar',
  data(){
    return{
      scrolled: false,
      event,
    }
  },

  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    gaEventTrack(data, action, category) {
      this.event(action, {
        event_category: category,
        event_label: data,
        value: 1,
      });
    },
    handleScroll () {
      this.scrolled = window.scrollY > 0;
    },
   goToLogin() {
      this.gaEventTrack(
        "Landing page login",
        "log_in",
        "Landing page login link"
      );
      this.$router.push("/login");
    },

    goToHome() {
      this.$router.push('/')
    },
    goToSignUp(data) {
      this.gaEventTrack(
        "Landing page signUp",
        data,
        "Landing page signUp link"
      );
      this.$router.push("/signup");
    },
  }
}
</script>

