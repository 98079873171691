<template>
  <div>
    <common-pop-up
      v-if="mainPopUp"
      :pop-email-box="true"
      class="send-verification-popup"
      pop-email-placeholder="Enter 6-digit code"
      :pop-email="userEmail"
      pop-heading="We’ve sent a verification code to:"
      pop-button-text="Continue"
      :code-link-text="codeLinkTextData"
      :optional-error-type="validationErrorMsg"
      :text-msg="inputBoxMsg"
      @sendCode="sendCodeClick"
      :code-success-text="codeSuccessTextData"
      @click="onClick"
    />
    <common-pop-up
      v-if="verfiedBox"
      pop-email="Your email has been verified, and we’ve created your account."
      pop-heading="Success!"
      pop-button-text="Let’s go"
      @click="verificationEmail"
    />
    <error-pop-up :is-show="errorPopupFlag" @closePopup="closedErrorPopup" />
  </div>
</template>
<script>
import CommonPopUp from "@/components/CommonPopUp.vue";
import services from "../../../services/services.js";
import ErrorPopUp from "@/components/ErrorPopUp.vue";
import device from "vue-device-detector";
export default {
  name: "VerificationPage",
  components: {
    CommonPopUp,
    ErrorPopUp,
  },
  data() {
    return {
      device,
      secret: "123#$%",
      IDeviceDetector: {
        ios: Boolean,
        iphone: Boolean,
        iphoneX: Boolean,
        iPhoneXR: Boolean,
        iPhoneXSMax: Boolean,
        ipod: Boolean,
        ipad: Boolean,
        android: Boolean,
        androidPhone: Boolean,
        windows: Boolean,
        mobile: Boolean,
        dingding: Boolean,
        wechat: Boolean,
        wechatMiniApp: Boolean,
      },
      services,
      errorPopupFlag: false,
      mainPopUp: true,
      verfiedBox: false,
      userEmail: this.$store.state.signUpDataObject.email,
      codeSuccessTextData: "",
      codeLinkTextData: "Send a new code",
      inputBoxMsg: "Please enter code",
      validationErrorMsg: false,
    };
  },
  methods: {
    closedErrorPopup(val) {
      this.errorPopupFlag = val;
    },
    onClick(val) {
      let formData = val;
      this.validationErrorMsg = false;

      if (formData.email) {
        let data = {
          verificationCode: formData.email,
          userId: this.$store.state.userId,
        };
        this.verifyCode(data);
      } else {
        this.inputBoxMsg = "Please enter code";
      }
    },
    verifyCode(data) {
      let postData = data;
      if (postData) {
        this.services
          .post("user/verifyCode", postData)
          .then((res) => {
            if (res) {
              this.mainPopUp = false;
              this.verfiedBox = true;
              this.validationErrorMsg = false;
              let userDetails = localStorage.getItem("userDetails");
              let token = res.data.token;
              let deviceName;
              for (let key in this.IDeviceDetector) {
                if (this.$device[key]) {
                  deviceName =
                    key +
                    " " +
                    userDetails.userId +
                    " " +
                    this.currentDate +
                    " " +
                    this.currentDAteTime;
                }
              }
              localStorage.setItem("userDeviceName", deviceName);
              token = this.$CryptoJS.AES.encrypt(token, this.secret).toString();
              localStorage.setItem("token", token);
              const decryptedText = this.$CryptoJS.AES.decrypt(
                token,
                this.secret
              ).toString(this.CryptoJS.enc.Utf8);
              this.$store.state.userToken = decryptedText;
            }
          })
          .catch((err) => {
            if (err.response.status == 304) {
              this.validationErrorMsg = true;
              this.inputBoxMsg =
                "Incorrect code – please try again or request new code";
            } else if (err.response.status == 500) {
              //this.errorPopupFlag = true//;
            }
          });
      }
    },
    createNewPassword() {
      this.$route.push("/login");
    },
    sendCodeClick() {
      this.codeSuccessTextData = "New code sent";
      this.codeLinkTextData = "";
      this.verificationeEmail();
      setTimeout(() => {
        this.codeSuccessTextData = "";
        this.codeLinkTextData = "Send a new code";
      }, 5000);
    },
    verificationeEmail() {
      let sendTo = this.$store.state.signUpDataObject.email;

      let data = {
        toEmail: sendTo,
      };

      if (data) {
        this.services
          .post("user/sendVerificationEmail", data)
          .then((res) => {
            if (res) {
              this.$store.state.signUpFlag = true;
            }
          })
          .catch((err) => {
            //this.errorPopupFlag = true//;
          });
      }
    },
    verificationEmail() {
      this.$router.push("/main");
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .send-verification-popup .ant-col-12 {
    width: 47% !important;
  }
  .mt-25 {
    margin-top: 10px !important;
  }
  .popup-txt {
    font-size: 20px !important;
  }
  .center-div {
    padding: 7% !important;
    width: 630px !important;
  }
  @media screen and (max-width: 649px) {
    .center-div {
      width: 80% !important;
      margin: auto;
      padding: 6% 8%;
    }
    .ant-btn-primary {
      min-width: 130px;
    }
  }
  .ant-btn-primary {
    margin-top: 25px !important;
    height: 45px !important;
  }
  .white-bg-css .ant-input,
  .white-bg-css .ant-select-selection {
    height: 45px !important;
  }
  .white-bg-css .ant-form-item {
    margin-bottom: 15px !important;
  }
  .send-verification-popup .center-div {
    padding: 4% 8% !important;
  }
}
</style>