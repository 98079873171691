<template>
  <div class="white-bg-color SurveyLinkIsExpired">
    <a-row :gutter="24" style="margin-left: 12px; margin-right: 12px">
        <a-col :span="8" :lg="8" :xs="24"> </a-col>
        <a-col class="img-css" :span="8" :lg="8" :xs="24">
          <div>
            <img
            alt="gestaltra_logo_grey"
              width="353px"
              height="99px"
              src="../../assets/gestaltra_logo_grey.png"
            />
          </div>
        </a-col>
        <a-col :span="8" :lg="8" :xs="24"> </a-col>
      </a-row>
    <div class="nom-class">
      <div class="pl-pr" style="padding-top: 49px; padding-bottom: 43px">
        <a-row class="mt-2">
          <a-col style="padding-top: 5px; text-align: center" :span="24">
            <span  class="bold-right-txt">Oops, looks like you’re a little too late</span>
          </a-col>
        </a-row>
        <a-row class="mt-2">
          <a-col style="padding-top: 5%; text-align: center" :span="24">
            <span style="color: #262626 !important" class="confirm-contain-txt"
              >It’s no longer possible to submit responses.</span
            >
          </a-col>
        </a-row>
        <a-row class="mt-2">
          <a-col style="padding-top: 5%; text-align: center" :span="24">
            <span style="color: #262626 !important" class="confirm-contain-txt"
              >Interested in creating your own survey?</span
            >
          </a-col>
        </a-row>
        <a-row class="mt-2">
          <a-col style="padding-top: 8%; text-align: center" :span="24">
            <a-button
              style="width: 161px !important"
              type="primary"
              @click="letsDoIt"
              >Let’s do it</a-button
            >
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SurveyLinkIsExpired",

  data() {
    return {};
  },
  methods: {
      letsDoIt(){
          this.$router.push("/");
      },
  },
};
</script>
<style lang="scss" scoped>
.bold-right-txt {
  font-style: normal;
  font-weight: bold;
  font-family: "Inter",fantasy !important;
  font-size: 24px;
  line-height: 22px;
  color: #262626 !important;
}

.pl-pr {
  padding-left: 18%;
  padding-right: 18%;
}
.confirm-contain-txt {
  font-size: 16px !important;
}
.b-font {
  font-family: "Inter",fantasy !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #262626;
}
.tell-me {
  font-family: "Inter",fantasy !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  text-decoration-line: underline;
  color: #6454f0;
  cursor: pointer;
}
.grey-sm-text {
  font-family: "Inter",fantasy !important;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #bfc3d6;
}
</style>