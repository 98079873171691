import axios from "axios";
import store from "@/store";

const apiUrl = process.env.VUE_APP_API_URL;


const services = {

    get(url) {

        let token = store.state.userToken;
        if (window.location.href.indexOf('admin-main') != -1) {
            token = store.state.adminToken;
        }

        // token = CryptoJS.AES.decrypt(token, '123#$%');
        let userDeviceName = localStorage.getItem("userDeviceName") ? localStorage.getItem("userDeviceName") : 'Mobile';
        token = `${token}`
        const config = {
            headers: {
                "device": userDeviceName,
                "Content-Type": "application/json",
                "authorization": token,
            }

        }

        return axios.get(apiUrl + `/${url}`, config);
    },
    post(url, body) {


        let token = store.state.userToken;
        if (window.location.href.indexOf('admin-main') != -1) {
            token = store.state.adminToken;
        }
        let userDeviceName = localStorage.getItem("userDeviceName") ? localStorage.getItem("userDeviceName") : 'Mobile';
        token = `${token}`
        const config = {
            headers: {
                "device": userDeviceName,
                "Content-Type": "application/json",
                "authorization": token,
            }

        }
        return axios.post(apiUrl + `/${url}`, body, config);
    },
    delete(url, body) {

        let token = store.state.userToken;
        if (window.location.href.indexOf('admin-main') != -1) {
            token = store.state.adminToken;
        }
        let userDeviceName = localStorage.getItem("userDeviceName");
        token = `${token}`
        const config = {
            headers: {
                "device": userDeviceName,
                "Content-Type": "application/json",
                "authorization": token,
            }

        }
        let arr = [];
        return axios.delete(apiUrl + `/${url}`, body, config);
    },
    put(url, body) {
        let token = store.state.userToken;
        if (window.location.href.indexOf('admin-main') != -1) {
            token = store.state.adminToken;
        }
        let userDeviceName = localStorage.getItem("userDeviceName");
        token = `${token}`
        const config = {
            headers: {
                "device": userDeviceName,
                "Content-Type": "application/json",
                "authorization": token,
            }

        }

        return axios.put(apiUrl + `/${url}`, body, config);
    },
};

export default services;