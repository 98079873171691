var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-color"},[_c('a-spin',{attrs:{"spinning":_vm.isSpinning}},[_c('div',{staticClass:"center-signUp-div white-bg-css"},[_c('a-row',{staticClass:"center",attrs:{"gutter":24}},[_c('a-col',{staticClass:"wlcm",attrs:{"span":24}},[_c('div',{staticClass:"wlcm"},[_vm._v("Create an account")])])],1),_c('a-row',{staticClass:"txt-labal",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('span',{staticClass:"sm-txt",staticStyle:{"padding-left":"5px"}},[_vm._v("Already have an account? ")]),_c('span',{staticClass:"pointer blue-txt-sm",on:{"click":_vm.goToLogin}},[_vm._v("Sign in")])])],1),_c('a-form',{staticClass:"mt-25",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"float-label"},[_c('label',{class:_vm.focusData.focusDataFn || _vm.formData.firstName
                    ? 'label label-float'
                    : 'label'},[_vm._v("First name")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'firstName',
                  {
                    rules: [
                      { required: true, message: 'Please enter first name' } ],
                  } ]),expression:"[\n                  'firstName',\n                  {\n                    rules: [\n                      { required: true, message: 'Please enter first name' },\n                    ],\n                  },\n                ]"}],on:{"blur":function($event){return _vm.setFocus(false, 'focusDataFn')},"focus":function($event){return _vm.setFocus(true, 'focusDataFn')},"change":function($event){return _vm.onChange($event.target.value, 'firstName')}}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"float-label"},[_c('label',{class:_vm.focusData.focusDataLn || _vm.formData.lastName
                    ? 'label label-float'
                    : 'label'},[_vm._v("Last name")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'lastName',
                  {
                    rules: [
                      { required: true, message: 'Please enter last name' } ],
                  } ]),expression:"[\n                  'lastName',\n                  {\n                    rules: [\n                      { required: true, message: 'Please enter last name' },\n                    ],\n                  },\n                ]"}],on:{"blur":function($event){return _vm.setFocus(false, 'focusDataLn')},"focus":function($event){return _vm.setFocus(true, 'focusDataLn')},"change":function($event){return _vm.onChange($event.target.value, 'lastName')}}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"float-label"},[_c('label',{class:_vm.focusData.organisation || _vm.formData.organisation
                    ? 'label label-float'
                    : 'label'},[_vm._v("Organisation name")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'organisation',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter organisation name',
                      } ],
                  } ]),expression:"[\n                  'organisation',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please enter organisation name',\n                      },\n                    ],\n                  },\n                ]"}],on:{"blur":function($event){return _vm.setFocus(false, 'organisation')},"focus":function($event){return _vm.setFocus(true, 'organisation')},"change":function($event){return _vm.onChange($event.target.value, 'organisation')}}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":_vm.dynamicSpan}},[_c('a-form-item',{staticClass:"float-label custom-top"},[_c('label',{class:_vm.focusData.industry || _vm.formData.industry
                    ? 'label label-float'
                    : 'label'},[_vm._v("Industry")]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'industry',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please select an industry',
                      } ],
                  } ]),expression:"[\n                  'industry',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please select an industry',\n                      },\n                    ],\n                  },\n                ]"}],key:"industry",attrs:{"show-search":true,"not-found-content":"No matches found","optionFilterProp":"children","value":_vm.dropDataShow(_vm.formData.industry, 'industryName')},on:{"blur":function($event){return _vm.setFocus(false, 'industry')},"focus":function($event){return _vm.setFocus(true, 'industry')},"change":function($event){return _vm.onChangeOthers(_vm.indData[$event], 'industry')}}},_vm._l((_vm.indData),function(item,index){return _c('a-select-option',{key:index,attrs:{"filter-option":function (input, item) {
                      _vm.option.industryName
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0;
                    }}},[_vm._v(" "+_vm._s(item.industryName)+" ")])}),1)],1)],1),(_vm.industryTextBox)?_c('a-col',{attrs:{"span":_vm.dynamicSpan2}},[_c('a-form-item',{staticClass:"float-label"},[_c('label',{class:_vm.focusData.otherIndustry || _vm.formData.otherIndustry
                    ? 'label label-float'
                    : 'label'},[_vm._v("Enter industry")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'otherIndustry',
                  {
                    rules: [
                      { required: true, message: 'Please enter an industry' } ],
                  } ]),expression:"[\n                  'otherIndustry',\n                  {\n                    rules: [\n                      { required: true, message: 'Please enter an industry' },\n                    ],\n                  },\n                ]"}],on:{"blur":function($event){return _vm.setFocus(false, 'otherIndustry')},"focus":function($event){return _vm.setFocus(true, 'otherIndustry')},"change":function($event){return _vm.onChange($event.target.value, 'otherIndustry')}}})],1)],1):_vm._e()],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"float-label email-wrapper",class:_vm.emailValidate ? 'has-error' : null},[_c('label',{class:_vm.focusData.email || _vm.formData.email
                    ? 'label label-float'
                    : 'label'},[_vm._v("Work email")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'email',
                  {
                    rules: [
                      {
                        type: 'email',
                        message: 'Please enter a valid email address',
                      },
                      {
                        required: true,
                        message: 'Please enter a valid email address',
                      },
                      {
                        validator: _vm.checkExistEmail,
                      } ],
                    validateTrigger: 'blur',
                  } ]),expression:"[\n                  'email',\n                  {\n                    rules: [\n                      {\n                        type: 'email',\n                        message: 'Please enter a valid email address',\n                      },\n                      {\n                        required: true,\n                        message: 'Please enter a valid email address',\n                      },\n                      {\n                        validator: checkExistEmail,\n                      },\n                    ],\n                    validateTrigger: 'blur',\n                  },\n                ]"}],on:{"focus":function($event){return _vm.setFocus(true, 'email')},"change":function($event){return _vm.onChange($event.target.value, 'email')},"blur":function($event){return _vm.checkExistingEmail($event.target.value)}}}),(_vm.emailValidate)?_c('div',{staticClass:"ant-form-explain"},[_vm._v(" This email address is already registered. Enter a different email or "),_c('router-link',{staticClass:"blue-txt-sm",attrs:{"to":{ name: 'Login' }}},[_vm._v("log in")]),_vm._v(" instead ")],1):_vm._e()],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"float-label password-section"},[_c('a-popover',{attrs:{"placement":"right","trigger":"click","overlay-style":{
                  'font-size': '12px !important',
                  'border-radius': '10px !important',
                }}},[_c('template',{attrs:{"overlay-style":{
                    'border-radius': '10px !important',
                  }},slot:"content"},[_c('p',[_c('strong',[_vm._v("Password must:")])]),_c('p',[_vm._v(" • Be a minimum of 8 characters "),(_vm.passlength)?_c('span',[_c('img',{staticClass:"success-unsuccess-icons",staticStyle:{"width":"18px"},attrs:{"src":require("../../../assets/tick.png"),"alt":""}})]):_c('span',[_c('img',{staticStyle:{"width":"18px"},attrs:{"src":require("../../../assets/cross.png"),"alt":""}})])]),_c('p',[_vm._v(" • Have a mixture of letters and numbers "),(_vm.letterNumber)?_c('span',[_c('img',{staticStyle:{"width":"18px"},attrs:{"src":require("../../../assets/tick.png"),"alt":""}})]):_c('span',[_c('img',{staticStyle:{"width":"18px"},attrs:{"src":require("../../../assets/cross.png"),"alt":""}})])]),_c('p',[_vm._v(" • Include at least one special character"),_c('br'),_vm._v("  (e.g. ! @ # ?) "),(_vm.specialCharacter)?_c('span',[_c('img',{staticStyle:{"width":"18px"},attrs:{"src":require("../../../assets/tick.png"),"alt":""}})]):_c('span',[_c('img',{staticStyle:{"width":"18px"},attrs:{"src":require("../../../assets/cross.png"),"alt":""}})])])]),_c('label',{class:_vm.focusData.password || _vm.formData.password
                      ? 'label label-float'
                      : 'label'},[_vm._v("Password")]),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'password',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter a valid password',
                        },
                        {
                          validator: _vm.validateToNextPassword,
                        },
                        {
                          pattern: _vm.passwordRegex,
                          lengthRegex: _vm.lengthRegex,
                          letternumRegex: _vm.letternumRegex,
                          specialCharRegex: _vm.specialCharRegex,
                          message: 'Please enter a valid password',
                        } ],
                    } ]),expression:"[\n                    'password',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Please enter a valid password',\n                        },\n                        {\n                          validator: validateToNextPassword,\n                        },\n                        {\n                          pattern: passwordRegex,\n                          lengthRegex,\n                          letternumRegex,\n                          specialCharRegex,\n                          message: 'Please enter a valid password',\n                        },\n                      ],\n                    },\n                  ]"}],on:{"blur":function($event){return _vm.setFocus(false, 'password')},"focus":function($event){return _vm.setFocus(true, 'password')},"change":function($event){return _vm.onChange($event.target.value, 'password')}}})],2)],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"float-label"},[_c('label',{class:_vm.focusData.confirmPassword || _vm.formData.confirmPassword
                    ? 'label label-float'
                    : 'label'},[_vm._v("Confirm password")]),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'confirmPassword',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Passwords don’t match – please try again',
                      },
                      {
                        validator: _vm.compareToFirstPassword,
                      } ],
                  } ]),expression:"[\n                  'confirmPassword',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Passwords don’t match – please try again',\n                      },\n                      {\n                        validator: compareToFirstPassword,\n                      },\n                    ],\n                  },\n                ]"}],on:{"blur":function($event){return _vm.setFocus(false, 'confirmPassword')},"focus":function($event){return _vm.setFocus(true, 'confirmPassword')},"change":function($event){return _vm.onChange($event.target.value, 'confirmPassword')}}})],1)],1)],1),_c('a-row',{staticClass:"pdb-20",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":2}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'terms-and-contions',
                {
                  rules: [
                    {
                      required: true,
                      message:
                        'You must agree to the Terms of Service to continue',
                    } ],
                } ]),expression:"[\n                'terms-and-contions',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message:\n                        'You must agree to the Terms of Service to continue',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"grey-txt-sm",on:{"change":function($event){return _vm.checkBoxChange($event.target.checked, 'termConditionCheckBox')}}})],1),_c('a-col',{staticClass:"pad-left-none",attrs:{"span":20}},[_c('span',[_vm._v("I have read and agree to the ")]),_c('a',{staticClass:"blue-txt-sm",on:{"click":_vm.goToTermsAndConditions}},[_vm._v("Terms of Service")]),_vm._v(" and "),_c('a',{staticClass:"blue-txt-sm",on:{"click":_vm.goToPrivacyAndPolicy}},[_vm._v("Privacy Policy")])])],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',[_c('a-button',{staticClass:"arrow-cls",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" Sign up"),_c('a-icon',{staticStyle:{"font-size":"25px !important","font-weight":"bold !important","margin-top":"-4px !important","border-bottom-style":"none"},attrs:{"type":"arrow-right"}})],1)],1)],1)],1),(_vm.termConditionCheckBoxMsg)?_c('a-row',{staticClass:"center error",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('span',[_vm._v("You must agree to the Terms of Service to continue")])])],1):_vm._e()],1)],1)]),_c('error-pop-up',{attrs:{"is-show":_vm.errorPopupFlag},on:{"closePopup":_vm.closedErrorPopup}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }