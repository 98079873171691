<template>
  <div class="policies">
    <TopBar />

    <div class="policies__top-bar">
      <h1>{{title}}</h1>
    </div>

    <a-tabs v-model="activeKey" :change="updateURL()">
      <a-tab-pane key="1" tab="Privacy policy">
        <PrivacyAndPolicy></PrivacyAndPolicy>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Terms of service">
        <TermsAndConditions></TermsAndConditions>
      </a-tab-pane>
    </a-tabs>

    <Footer />
  </div>
</template>
<script>
import { pageview } from "vue-gtag";
import Footer from "../../LandingPage/Footer.vue";
import TopBar from "../../LandingPage/TopBar.vue";
import PrivacyAndPolicy from "./PrivacyAndPolicy.vue";
import TermsAndConditions from "./TermsAndConditions.vue";

export default {
  name: "policies",
  data() {
    return { 
      pageview,
      title: "",
      activeKey: "1"
    };
  },
  created() {
    this.track();
    if (this.$route.query.type === "privacy") {
      this.title="Privacy policy"
    } else {
      this.title = "Terms of Service"
      this.activeKey = "2";
    }
  },
  methods: {
    track() {
      this.pageview("landingpage_privacypolicy");
    },
    updateURL() {
      if (this.activeKey === "1") {
        this.title="Privacy policy";
        if (this.$route.query.type !== 'privacy') {
          this.$router.replace({ path: this.$route.path, query: { type: 'privacy' } })
        }
      } else if (this.$route.query.type !== 'terms') {
        this.title = "Terms of Service";
        this.$router.replace({ path: this.$route.path, query: { type: 'terms' } })
      }
    }
  },
  components: { Footer, TopBar, PrivacyAndPolicy, TermsAndConditions },
};
</script>
