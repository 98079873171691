<template>
  <div>
    <div class="main-wrapper">
      <div class="grey-div-css desktop-traits-wrapper">
        <div class="admin-traits-role-wrapper-header">
          <div class="left-wrapper">
            <a-row style="" :gutter="24" class="heading-wrapper">
              <a-col :span="8" :lg="8" :xs="24">Traits & behaviours</a-col>
              <a-col :span="14" :lg="14" :xs="24">Description</a-col>
            </a-row>
          </div>
          <div
            class="right-wrapper"
            id="text-box-custom-padding-scroll"
            :style="dynamicRightWidth"
          >
            <div class="coulmn-wrapper" :style="dynamicWidth">
              <div v-for="(item, index) in traitsAndRolsListOrg" :key="index">
                <a-row
                  :gutter="24"
                  v-if="index == 0"
                  class="text-box-custom-padding"
                >
                  <div
                    v-for="(record, colIndex) in columnData"
                    :key="colIndex"
                    class="custom-css"
                  >
                    <a-col v-if="!record.deletedFlag">
                      <a-row :gutter="24">
                        <a-col>
                          <span
                            ><a-input
                              :disabled="
                                record.jobTitle.toLowerCase().trim() == 'other'
                                  ? true
                                  : editFlag
                              "
                              :value="record.jobTitle"
                              @blur="
                                checkExist(
                                  $event.target.value,
                                  'jobTitle',
                                  record,
                                  colIndex
                                )
                              " /><a-icon
                              v-if="
                                record.jobTitle.toLowerCase().trim() == 'other'
                                  ? false
                                  : !editFlag
                              "
                              class="minus-img-css"
                              type="minus-circle"
                              theme="filled"
                              @click="deleteColumn(colIndex, record)"
                          /></span>
                        </a-col>
                      </a-row>
                    </a-col>
                  </div>
                </a-row>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-scroll-wrapper">
          <div class="admin-traits-role-wrapper">
            <div class="left-wrapper">
              <a-form>
                <a-form-item>
                  <span
                    v-for="(record, index) in traitsAndRolsListOrg"
                    :key="index"
                  >
                    <a-row style="" :gutter="24" v-if="!record.deletedFlag">
                      <a-col :span="8" :lg="8">
                        <a-input
                          :disabled="editFlag"
                          :value="record.traitsAndBehaviours"
                          @blur="
                            checkExist(
                              $event.target.value,
                              'traitsAndBehaviours',
                              record,
                              index
                            )
                          "
                        />
                      </a-col>
                      <a-col :span="14" :lg="14">
                        <span
                          class="edit-css minus-btn-res2"
                          style=""
                          :span="1"
                          :lg="1"
                        >
                          <a-icon
                            v-if="!editFlag"
                            class="minus-img-css"
                            type="minus-circle"
                            theme="filled"
                            @click="deleteRow(index, record)"
                          />
                        </span>
                        <a-input
                          :disabled="editFlag"
                          :value="record.description"
                          @blur="
                            onChangeText(
                              $event.target.value,
                              'description',
                              record
                            )
                          "
                        />
                      </a-col>
                      <a-col
                        class="edit-css minus-btn-res1"
                        style=""
                        :span="1"
                        :lg="1"
                      >
                        <a-icon
                          v-if="!editFlag"
                          class="minus-img-css"
                          type="minus-circle"
                          theme="filled"
                          @click="deleteRow(index, record)"
                        />
                      </a-col>
                    </a-row>
                  </span>
                </a-form-item>
              </a-form>
            </div>
            <div
              class="right-wrapper right-wrapper-scroll"
              :style="dynamicRightWidth"
              @scroll="dynamicScroll"
            >
              <div class="coulmn-wrapper" :style="dynamicWidth">
                <div v-for="(item, index) in traitsAndRolsListOrg" :key="index">
                  <a-row :gutter="24" v-if="!item.deletedFlag">
                    <span v-for="(record, index1) in columnData" :key="index1">
                      <a-col v-if="!record.deletedFlag" class="custom-css">
                        <span>
                          <a-row :gutter="24" class="custom-padding">
                            <a-col>
                              <span
                                ><a-checkbox
                                  :disabled="editFlag"
                                  :checked="
                                    item['rel' + index1] == record.jobTitle
                                  "
                                  @change="
                                    checkedJobCheckBox(
                                      $event.target.checked,
                                      item,
                                      index,
                                      record,
                                      index1
                                    )
                                  "
                              /></span>
                            </a-col>
                          </a-row>
                        </span>
                      </a-col>
                    </span>
                  </a-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grey-div-css trait-res">
        <div class="update-wrapper" v-if="!editFlag">
          <div class="trait1">
            <a-row :gutter="24">
              <a-col class="content-heading" :span="14" :lg="14">
                Add trait or behaviour:
              </a-col>
              <a-col class="content-heading" :span="7" :lg="7">
                Add new job title:
              </a-col>

              <a-col :span="3" :lg="3"
                ><a-button type="primary" @click="updateSection"
                  >Update</a-button
                >
              </a-col>
            </a-row>
            <a-form>
              <a-form-item class="float-label">
                <a-row :gutter="24">
                  <a-col :span="4" :lg="4">
                    <label
                      :class="
                        focusData.traitsAndBehaviours ||
                        formData.traitsAndBehaviours
                          ? 'label label-float'
                          : 'label'
                      "
                      >Add char or trait</label
                    >
                    <a-input
                      class="grey-input-field-css"
                      @blur="setFocus(false, 'traitsAndBehaviours')"
                      @focus="setFocus(true, 'traitsAndBehaviours')"
                      :value="formData.traitsAndBehaviours"
                      @change="
                        onChange($event.target.value, 'traitsAndBehaviours')
                      "
                    />
                  </a-col>
                  <a-col :span="9" :lg="9">
                    <label
                      :class="
                        focusData.description || formData.description
                          ? 'label label-float'
                          : 'label'
                      "
                      >Add description</label
                    >
                    <a-input
                      class="grey-input-field-css"
                      @blur="setFocus(false, 'description')"
                      @focus="setFocus(true, 'description')"
                      :value="formData.description"
                      @change="onChange($event.target.value, 'description')"
                    />
                  </a-col>
                  <a-col :span="2" :lg="1" class="plus-img-css-wrapper">
                    <a-icon
                      class="plus-img-css"
                      type="plus-circle"
                      theme="filled"
                      @click="addTraits($event)"
                    />
                  </a-col>
                  <a-col :span="6" :lg="6">
                    <label
                      :class="
                        focusData.jobTitle || formData.jobTitle
                          ? 'label label-float'
                          : 'label'
                      "
                      >Add job title</label
                    >
                    <a-input
                      class="grey-input-field-css"
                      @blur="setFocus(false, 'jobTitle')"
                      @focus="setFocus(true, 'jobTitle')"
                      :value="formData.jobTitle"
                      @change="onChange($event.target.value, 'jobTitle')"
                    />
                  </a-col>
                  <a-col :span="2" :lg="1" class="plus-img-css-wrapper">
                    <a-icon
                      class="plus-img-css"
                      type="plus-circle"
                      theme="filled"
                      @click="addJobTitle($event)"
                    />
                  </a-col>
                  <a-col :span="3" :lg="3"
                    ><a-button
                      @click="cancelTraits"
                      class="confirm-grey-btn"
                      type="primary"
                      >Cancel</a-button
                    >
                  </a-col>
                </a-row>
              </a-form-item>
            </a-form>
          </div>
          <div class="trait2">
            <a-form>
              <a-form-item class="float-label">
                <a-row :gutter="24">
                  <a-col class="content-heading" :span="14" :lg="14" :xs="24">
                    Add trait or behaviour:
                  </a-col>
                  <a-col :span="4" :lg="4" :xs="24">
                    <label
                      :class="
                        focusData.traitsAndBehaviours ||
                        formData.traitsAndBehaviours
                          ? 'label label-float'
                          : 'label'
                      "
                      >Add char or trait</label
                    >
                    <a-input
                      class="grey-input-field-css"
                      @blur="setFocus(false, 'traitsAndBehaviours')"
                      @focus="setFocus(true, 'traitsAndBehaviours')"
                      :value="formData.traitsAndBehaviours"
                      @change="
                        onChange($event.target.value, 'traitsAndBehaviours')
                      "
                    />
                  </a-col>

                  <a-col :span="9" :lg="9" :xs="24">
                    <label
                      :class="
                        focusData.description || formData.description
                          ? 'label label-float'
                          : 'label'
                      "
                      >Add description</label
                    >
                    <a-input
                      class="grey-input-field-css"
                      @blur="setFocus(false, 'description')"
                      @focus="setFocus(true, 'description')"
                      :value="formData.description"
                      @change="onChange($event.target.value, 'description')"
                    />
                  </a-col>
                  <a-col
                    :span="2"
                    :lg="1"
                    :xs="24"
                    class="plus-img-css-wrapper"
                  >
                    <a-icon
                      class="plus-img-css"
                      type="plus-circle"
                      theme="filled"
                      @click="addTraits($event)"
                    />
                  </a-col>
                  <a-col class="content-heading" :span="7" :lg="7" :xs="24">
                    Add new job title:
                  </a-col>
                  <a-col :span="6" :lg="6" :xs="24">
                    <label
                      :class="
                        focusData.jobTitle || formData.jobTitle
                          ? 'label label-float'
                          : 'label'
                      "
                      >Add job title</label
                    >
                    <a-input
                      class="grey-input-field-css"
                      @blur="setFocus(false, 'jobTitle')"
                      @focus="setFocus(true, 'jobTitle')"
                      :value="formData.jobTitle"
                      @change="onChange($event.target.value, 'jobTitle')"
                    />
                  </a-col>
                  <a-col
                    :span="2"
                    :lg="1"
                    :xs="24"
                    class="plus-img-css-wrapper"
                  >
                    <a-icon
                      class="plus-img-css"
                      type="plus-circle"
                      theme="filled"
                      @click="addJobTitle($event)"
                    />
                  </a-col>
                  <a-col
                    :span="3"
                    :lg="3"
                    :xs="24"
                    style="text-align: end !important"
                    ><a-button
                      @click="cancelTraits"
                      class="confirm-grey-btn"
                      type="primary"
                      >Cancel</a-button
                    >
                    <a-button type="primary" @click="updateSection"
                      >Update</a-button
                    >
                  </a-col>
                </a-row>
              </a-form-item>
            </a-form>
          </div>
        </div>
      </div>
    </div>
    <error-pop-up-for-duplicate
      :is-show="isDataDuplicate"
      @closePopup="closePopup"
    />
    <error-pop-up-for-missing
      :is-show="isDataMissing"
      @closePopup="closePopupMissing"
    />
    <time-out-pop-up :is-show="isTimeOut" @closePopup="logInTimeOutPopup" />
  </div>
</template>
<script>
import services from "../../services/services";
import ErrorPopUpForDuplicate from "@/components/ErrorPopUpForDuplicate.vue";
import ErrorPopUpForMissing from "@/components/ErrorPopUpForMissing.vue";

import TimeOutPopUp from "@/components/TimeOutPopUp.vue";
export default {
  name: "AdminTraits",
  components: {
    ErrorPopUpForDuplicate,
    ErrorPopUpForMissing,
    TimeOutPopUp,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    editFlagProp: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dynamicRightWidth: "width: 0px",
      isTimeOut: false,
      traitsYHeight: 0,
      isDataDuplicate: false,
      isDataMissing: false,
      columnDataOrg: [],
      services,
      focusData: {
        traitsAndBehaviours: false,
        jobTitle: false,
        description: false,
      },
      formData: {
        traitsAndBehaviours: null,
        jobTitle: null,
        description: null,
      },
      columnData: [],
      traitsAndRolsList: [],
      traitsAndRolsListOrg: [],
    };
  },
  created() {
    this.createdApis();

    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  mounted() {
    this.myEventHandler();
  },

  computed: {
    dynamicWidth() {
      let width = this.columnData.length * 145;
      return "width:" + width + "px;";
    },

    editFlag() {
      // if (!this.editFlagProp) {
      //   this.createdApis();
      // }
      return this.editFlagProp;
    },
  },

  methods: {
    myEventHandler() {
      let width = document.querySelector(".main-wrapper").offsetWidth;
      let leftWidth = document.querySelector(".left-wrapper").offsetWidth;
      width = width - leftWidth - 30;
      this.dynamicRightWidth = "width:" + width + "px";
    },
    logInTimeOutPopup(val) {
      this.isTimeOut = val;
      this.$router.push("/admin-login");
    },
    closePopup(val) {
      this.isDataDuplicate = val;
    },

    traitsWiseJobs(data) {
      data.forEach((record, index) => {
        if (this.columnData.length > 0) {
          this.columnData.forEach((item, colIndex) => {
            if (record.traitsJobs.length > 0) {
              record.traitsJobs.forEach((traitsItem, i) => {
                if (
                  traitsItem.job.jobTitle == item.jobTitle &&
                  traitsItem.isDeleted &&
                  (traitsItem.job.isActive == null || traitsItem.job.isActive)
                ) {
                  let customKey = "rel" + colIndex;
                  record[customKey] = item.jobTitle;
                }
              });
            }
          });
        }
      });
    },

    closePopupMissing(val) {
      this.isDataMissing = val;
    },
    isJobExist(val, i, record, key) {
      let isDataDuplicateFlag = false;
      this.columnData.forEach((item, index) => {
        if (
          val.toLowerCase().trim() == item.jobTitle.toLowerCase().trim() &&
          i != index
        ) {
          isDataDuplicateFlag = true;
        }
      });
      if (!isDataDuplicateFlag) {
        this.services
          .post("admin/isJobExist", { jobTitle: val })
          .then((res) => {
            this.traitsAndRolsListOrg.forEach((rec, ind) => {
              if (this.columnData.length > 0) {
                this.columnData.forEach((item, colIndex) => {
                  let customKey = "rel" + colIndex;
                  if (rec.traitsJobs.length > 0) {
                    rec.traitsJobs.forEach((traitsItem, i) => {
                      if (
                        traitsItem.job.jobTitle == item.previousJobData &&
                        traitsItem.isDeleted &&
                        rec[customKey]
                      ) {
                        traitsItem.job.jobTitle = val;
                        rec[customKey] = val;
                        rec.changedStatus = true;
                        let customFlag = "checked" + colIndex;
                        rec[customFlag] = true;

                        let customJobId = "jobId" + colIndex;
                        rec[customJobId] = item.id;
                        rec.isDeleted = true;
                      }
                    });
                  }
                });
              }
            });
          })
          .catch((err) => {
            if (err.response.status == 401) {
              this.sessionTimeOut();
            }
            if (err.response.status == 302) {
            }
          });
      } else {
        record[key] = record.previousJobData;
        this.isDataDuplicate = true;
      }
    },
    isTraitsExist(val, i, record, key) {
      let isDataDuplicateFlag = false;
      this.traitsAndRolsList.forEach((item, index) => {
        if (
          val.toLowerCase().trim() ==
            item.traitsAndBehaviours.toLowerCase().trim() &&
          i != index
        ) {
          isDataDuplicateFlag = true;
        }
      });
      if (!isDataDuplicateFlag) {
        this.services
          .post("survey/isTraitExist", {
            jobId: 0,
            traitName: val,
          })
          .then((res) => {
            this.traitsAndRolsListOrg.forEach((rec, ind) => {
              if (this.columnData.length > 0) {
                this.columnData.forEach((item, colIndex) => {
                  let customKey = "rel" + colIndex;
                  if (rec.traitsJobs.length > 0) {
                    rec.traitsJobs.forEach((traitsItem, i) => {
                      if (
                        traitsItem.job.jobTitle == item.jobTitle &&
                        traitsItem.isDeleted &&
                        rec[customKey]
                      ) {
                        //traitsItem.job.jobTitle = item.jobTitle;
                        rec[customKey] = item.jobTitle;
                        rec.changedStatus = true;
                        let customFlag = "checked" + colIndex;
                        rec[customFlag] = true;

                        let customJobId = "jobId" + colIndex;
                        rec[customJobId] = item.id;
                        rec.isDeleted = true;
                      }
                    });
                  }
                });
              }
            });
          })
          .catch((err) => {
            if (err.response.status == 401) {
              this.sessionTimeOut();
            }
            if (err.response.status == 302) {
            }
          });
        let count = 0;
        this.traitsAndRolsList.forEach((rec, ind) => {
          if (
            rec.traitsAndBehaviours.toLowerCase() ==
              record.previousTraitsData.toLowerCase() &&
            count < 1
          ) {
            rec.selectedFlag = true;
            rec.traitsAndBehaviours = val;
            this.traitsAndRolsList.splice(ind, 1, rec);
            count = 1;
          }
        });
      } else {
        this.isDataDuplicate = true;
        record[key] = record.previousTraitsData;
      }
    },
    checkedJobCheckBox(val, rec, ind, recCol, indCol) {
      let customKey = "rel" + indCol;
      let customJobId = "jobId" + indCol;
      let customFlag = "checked" + indCol;
      rec.changedStatus = true;
      rec[customFlag] = true;
      rec[customJobId] = recCol.id;
      rec.isDeleted = true;
      if (val) {
        rec[customKey] = recCol.jobTitle;
      } else {
        rec.isDeleted = false;
        rec[customKey] = null;
      }
      rec.traitsJobs.forEach((traitsItem, i) => {
        if (traitsItem.job.jobTitle == recCol.jobTitle) {
          traitsItem.isDeleted = rec.isDeleted;
        }
      });
      this.traitsAndRolsList.splice(ind, 1, rec);
      this.traitsAndRolsListOrg.splice(ind, 1, rec);
    },
    dynamicScroll() {
      let element = document.querySelector(".right-wrapper-scroll");
      let elementLeftValue = element.scrollLeft;
      let dynamicScrollDiv = document.getElementById(
        "text-box-custom-padding-scroll"
      );
      dynamicScrollDiv.scrollLeft = elementLeftValue;
    },
    removeJob() {
      let listOfJobs = [],
        reqBody = {};
      this.columnDataOrg.forEach((record, index) => {
        if (record.deletedFlag) {
          listOfJobs.push({ jobId: record.id, userId: this.userId });
        }
      });
      reqBody.listOfJobs = listOfJobs;
      if (listOfJobs.length > 0) {
        this.services
          .post("admin/deleteJobs", reqBody)
          .then((res) => {
            this.columnDataOrg = [];
          })
          .catch((err) => {
            if (err.response.status == 401) {
              this.sessionTimeOut();
            }
          });
      }
    },
    sessionTimeOut() {
      localStorage.removeItem("admin-token");
      localStorage.removeItem("userAdminDetails");
      localStorage.removeItem("userDeviceName");
      if (!this.isTimeOut) {
        this.isTimeOut = true;
      }
    },
    removeTraits() {
      let listOfTraits = [],
        reqBody = {};

      this.traitsAndRolsListOrg.forEach((record, index) => {
        if (record.deletedFlag) {
          listOfTraits.push({ traitId: record.id, userId: this.userId });
        }
      });
      reqBody.listOfTraits = listOfTraits;
      if (listOfTraits.length > 0) {
        this.services
          .post("admin/deleteTraits", reqBody)
          .then((res) => {})
          .catch((err) => {
            if (err.response.status == 401) {
              this.sessionTimeOut();
            }
          });
      }
    },
    createJob() {
      let listOfJobs = [],
        reqBody = {};

      this.columnData.forEach((record, index) => {
        if (record.addedFlag) {
          listOfJobs.push({
            jobId: 0,
            jobTitle: record.jobTitle,
            userId: this.userId,
          });
        } else if (record.selectedFlag) {
          listOfJobs.push({
            jobId: record.id,
            jobTitle: record.jobTitle,
            userId: this.userId,
          });
        }
      });
      reqBody.listOfJobs = listOfJobs;

      if (listOfJobs.length > 0) {
        this.services
          .post("admin/createJobs", reqBody)
          .then((res) => {
            let resultAdd = res.data.body;
            let resultUpdate = res.data.updatedJobsDetails;
            resultUpdate.forEach((item, index) => {
              resultAdd.push(item.id);
            });

            resultAdd.forEach((item, index) => {
              this.traitsAndRolsListOrg.forEach((rec, ind) => {
                this.columnData.forEach((record, i) => {
                  let customKey = "rel" + i;
                  let customKeyJob = "jobId" + i;
                  if (
                    (record.addedFlag || record.selectedFlag) &&
                    record.jobTitle.toLowerCase().trim() ==
                      item.jobTitle.toLowerCase().trim()
                  ) {
                    record.id = item.id;
                    record.addedFlag = false;
                    record.selectedFlag = false;
                    if (
                      rec.changedStatus &&
                      rec[customKey] &&
                      rec[customKey].toLowerCase().trim() ==
                        record.jobTitle.toLowerCase().trim() &&
                      !rec.deletedFlag
                    ) {
                      rec[customKeyJob] = item.id;
                    }
                  } else if (
                    rec.changedStatus &&
                    rec[customKey] &&
                    rec[customKey].toLowerCase().trim() ==
                      item.jobTitle.toLowerCase().trim() &&
                    !rec.deletedFlag
                  ) {
                    rec[customKeyJob] = item.id;
                  }
                });
              });
            });
            this.createTraitsAssignment();
          })
          .catch((err) => {
            if (err.response.status == 401) {
              this.sessionTimeOut();
            }
          });
      } else {
        this.createTraitsAssignment();
      }
    },
    createTraitsAssignment() {
      let listOfTraitsJobs = [],
        reqBody = {};

      this.traitsAndRolsListOrg.forEach((rec, ind) => {
        this.traitsAndRolsList.forEach((record, index) => {
          if (
            rec.traitsAndBehaviours.toLowerCase().trim() ==
              record.traitsAndBehaviours.toLowerCase().trim() &&
            rec.changedStatus
          ) {
            this.traitsAndRolsList.splice(index, 1, rec);
          }
        });
      });
      this.traitsAndRolsList.forEach((record, index) => {
        let customObj = {};
        if (record.changedStatus) {
          this.columnData.forEach((item, indexCol) => {
            let key = "checked" + indexCol;

            if (record[key] && record["jobId" + indexCol] && record.id) {
              customObj = {
                traitId: record.id ? record.id : 0,
                isDeleted: record.isDeleted,
                jobId: record["jobId" + indexCol]
                  ? record["jobId" + indexCol]
                  : 0,
              };
              listOfTraitsJobs.push(customObj);
              //record[key] = false;
            }
          });
        }
      });
      reqBody.listOfTraitsJobs = listOfTraitsJobs;
      if (listOfTraitsJobs.length > 0) {
        this.services
          .post("admin/traitsJobsAssigment", reqBody)
          .then((res) => {
            let result = res.body;
          })
          .catch((err) => {
            if (err.response.status == 401) {
              this.sessionTimeOut();
            }
          });
      }
    },
    createTraits() {
      console.log("this.traitsAndRolsList=>", this.traitsAndRolsList);
      let listOfTraits = [],
        reqBody = {};

      this.traitsAndRolsListOrg.forEach((record, index) => {
        let customObj = {};

        if (record.addedFlag || record.selectedFlag) {
          customObj = {
            traitId: record.selectedFlag ? record.id : 0,

            traitName: record.traitsAndBehaviours,
            description: record.description,
            userId: this.userId,
          };
          listOfTraits.push(customObj);
        }
      });
      reqBody.listOfTraits = listOfTraits;

      if (listOfTraits.length > 0) {
        this.services
          .post("admin/createListTrait", reqBody)
          .then((res) => {
            let resultAdd = res.data.body;
            let resultUpdate = res.data.updatedTraitsDetails;
            resultUpdate.forEach((item, index) => {
              resultAdd.push(item.id);
            });

            resultAdd.forEach((item, index) => {
              this.traitsAndRolsListOrg.forEach((record, i) => {
                if (
                  (record.addedFlag || record.selectedFlag) &&
                  record.traitsAndBehaviours.toLowerCase().trim() ==
                    item.traitsAndBehaviours.toLowerCase().trim()
                ) {
                  record.id = item.id;

                  record.addedFlag = false;
                  record.selectedFlag = false;
                }
              });
            });

            this.createJob();
            //this.createTraitsAssignment();
          })
          .catch((err) => {
            if (err.response.status == 401) {
              this.sessionTimeOut();
            }
          });
      } else {
        this.createJob();
      }
    },
    getJobList() {
      this.services
        .get("team/jobs")
        .then((res) => {
          this.columnData = res.data.body;
          this.columnData.forEach((item, index) => {
            if (item.jobTitle == "Other") {
              let fromIndex = index;
              Array.prototype.move = function (from, to) {
                this.splice(to, 0, this.splice(from, 1)[0]);
                return this;
              };

              this.columnData.move(fromIndex, this.columnData.length - 1);
            }
          });
          this.traitsWiseJobs(this.traitsAndRolsList);
          this.traitsAndRolsListOrg = JSON.parse(
            JSON.stringify(this.traitsAndRolsList)
          );
        })
        .catch((err) => {
          //this.errorPopupFlag = true//;
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
          console.log(err);
        });
    },
    setFocus(val, key) {
      this.focusData[key] = val;
    },
    deleteColumn(index, val) {
      let record = this.columnData[index];
      record.deletedFlag = true;
      this.columnData.splice(index, 1);
      // this.columnData.forEach((item, index) => {
      //   if (item.jobTitle == "Other") {
      //     let fromIndex = index;
      //     Array.prototype.move = function (from, to) {
      //       this.splice(to, 0, this.splice(from, 1)[0]);
      //       return this;
      //     };
      //     this.columnData.move(fromIndex, this.columnData.length - 1);
      //   }
      // });
      this.traitsWiseJobs(this.traitsAndRolsListOrg);
      this.columnDataOrg.push(record);
    },
    deleteRow(index, record) {
      record.deletedFlag = true;

      this.traitsAndRolsListOrg.splice(index, 1, record);
      this.traitsAndRolsList.splice(index, 1);
    },
    onChange(val, key) {
      this.formData[key] = val;
    },
    onChangeText(val, key, record) {
      if (val) {
        record.selectedFlag = true;
        if (key == "traitsAndBehaviours") {
          record.previousTraitsData = record[key];
        }

        record[key] = val;
      } else {
        this.isDataMissing = true;
      }
    },
    checkExist(val, key, record, i) {
      if (val) {
        record.selectedFlag = true;
        if (key == "jobTitle") {
          record.previousJobData = record[key];
          record[key] = val;
          this.isJobExist(val, i, record, key);
        } else {
          record.previousTraitsData = record[key];
          record[key] = val;

          this.isTraitsExist(val, i, record, key);
        }
      } else {
        this.isDataMissing = true;
      }
    },
    addTraits() {
      let isDataDuplicateFlag = false;
      if (this.formData.traitsAndBehaviours && this.formData.description) {
        this.traitsAndRolsList.forEach((item, index) => {
          if (
            item.traitsAndBehaviours.toLowerCase().trim() ==
            this.formData.traitsAndBehaviours.toLowerCase().trim()
          ) {
            isDataDuplicateFlag = true;
          }
        });

        if (!isDataDuplicateFlag) {
          this.formData.addedFlag = true;
          this.formData.traitsJob = { jobTitle: null };
          this.traitsAndRolsList.push(
            JSON.parse(JSON.stringify(this.formData))
          );
          this.traitsAndRolsListOrg.push(
            JSON.parse(JSON.stringify(this.formData))
          );
          this.formData.addedFlag = false;
          this.formData.traitsAndBehaviours = null;
          this.formData.description = null;
        } else {
          this.isDataDuplicate = true;
        }
      } else {
        this.isDataMissing = true;
      }
    },
    addJobTitle() {
      let isDataDuplicateFlag = false;
      if (this.formData.jobTitle) {
        this.columnData.forEach((item, index) => {
          if (
            item.jobTitle.toLowerCase().trim() ==
            this.formData.jobTitle.toLowerCase().trim()
          ) {
            isDataDuplicateFlag = true;
          }
        });
        if (!isDataDuplicateFlag) {
          this.columnData.push({
            jobTitle: this.formData.jobTitle,
            addedFlag: true,
          });
          this.columnData.forEach((item, index) => {
            if (item.jobTitle == "Other") {
              let fromIndex = index;
              Array.prototype.move = function (from, to) {
                this.splice(to, 0, this.splice(from, 1)[0]);
                return this;
              };
              this.columnData.move(fromIndex, this.columnData.length - 1);
            }
          });
          this.traitsWiseJobs(this.traitsAndRolsListOrg);
          this.formData.jobTitle = null;
          this.formData.addedFlag = null;
        } else {
          this.isDataDuplicate = true;
        }
      } else {
        this.isDataMissing = true;
      }
    },
    createdApis() {
      this.services
        .get("admin/listJobWiseTraits")
        .then((res) => {
          this.traitsAndRolsList = res.data.body;

          this.traitsAndRolsListOrg = JSON.parse(
            JSON.stringify(this.traitsAndRolsList)
          );
          this.getJobList();
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
        });
    },
    cancelTraits() {
      this.formData = {};
      this.createdApis();
      this.$emit("changedUpdatedFlag", true);
    },
    async updateSection() {
      await this.createTraits();
      await this.removeTraits();
      await this.removeJob();
      this.traitsAndRolsListOrg.sort(this.sortArray);
      this.traitsAndRolsList.sort(this.sortArray);
      this.$emit("changedUpdatedFlag", true);
    },
    sortArray(a, b) {
      if (
        a.traitsAndBehaviours.toLowerCase() <
        b.traitsAndBehaviours.toLowerCase()
      ) {
        return -1;
      }
      if (
        a.traitsAndBehaviours.toLowerCase() >
        b.traitsAndBehaviours.toLowerCase()
      ) {
        return 1;
      }
      return 0;
    },
  },
};
</script>
<style lang="scss" scoped>
// .minus-btn-res2{
//   display: none ;
// }
.trait2 {
  display: none;
}
::v-deep {
  .plus-img-css-wrapper.ant-col {
    padding-left: 0px !important;
  }
}
.custom-scroll-wrapper {
  width: 100%;
  padding: 0px 10px 20px 10px;
}
.admin-traits-role-wrapper {
  width: 100%;
  max-height: 500px;
  margin: auto;
  margin-right: 30px;
  overflow: hidden;
  overflow-y: auto;

  padding: 20px 0px;
  > * {
    display: inline-block;
  }
  .left-wrapper {
    width: 49%;
    padding-left: 10px;
  }
}
.admin-traits-role-wrapper-header {
  width: 100%;
  margin: auto;
  margin-right: 30px;
  overflow: hidden;
  padding: 0px 10px;
  > * {
    display: inline-block;
  }
  .right-wrapper {
    overflow-x: hidden !important;
  }
  .heading-wrapper {
    padding-bottom: 0px !important;
  }
}
.left-wrapper {
  width: 50%;
  .heading-wrapper {
    padding: 30px 10px;
    font-size: 18px;
    color: #363644;
  }
  .minus-img-css {
    position: absolute !important;
    left: -95% !important;
    top: 8px !important;
    font-size: 22px !important;
    color: #6454f0 !important;
    border-radius: 50% !important;
  }
  .ant-input {
    height: 40px !important;
    margin-bottom: 10px !important;
  }
}
.right-wrapper {
  // width: 50%;
  overflow: hidden;
  min-height: 450px;
  overflow-x: auto;
  @media screen and (max-width: 1033) and (min-width: 470px) {
    width: 433px !important;
  }
  @media screen and (max-width: 470px) {
    width: 40%;
  }
  .coulmn-wrapper {
    .custom-css {
      display: inline-block;
    }
  }
  vertical-align: top;
}

.admin-traits-role-wrapper .coulmn-wrapper {
  position: relative;
  top: -13px;
  left: 10px;
}
.grey-div-css {
  margin-top: 15px !important;
  background: #edeef5;
  border-radius: 5px;
}

.minusCR-img-css:hover,
.minusCR-img-css:focus {
  color: #493bc8 !important;
  border-color: #493bc8 !important;
  cursor: pointer !important;
}
.grey-input-field-css {
  border: 1px solid #bfc3d6;
  background: #bfc3d6;
  box-sizing: border-box;
  border-radius: 5px;
  height: 41px !important;
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 17px;
  color: #ffffff !important;
}

.ant-input[disabled] {
  background-color: #edeef5 !important;
}
.ant-input {
  font-size: 14px !important;
}
.input-field-css {
  // background: #ffffff;
  border: 1px solid #bfc3d6;
  box-sizing: border-box;
  border-radius: 5px;
  height: 41px !important;
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 17px;
  color: #363644;
}
.label-css {
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363644;
}
.plus-img-css {
  font-size: 23px;
  color: #6454f0;
  border-radius: 100px;
  cursor: pointer;
  margin-top: 10px;
}
.plus-img-css:hover {
  color: #4a3cc0;
}
.update-wrapper {
  padding: 20px 10px 15px 20px;
}
.content-heading {
  color: #262626;
  font-size: 1rem;
  font-weight: 700;
}
.ant-btn.ant-btn-primary {
  width: 113px !important;
  height: 36px !important;
}
button.confirm-grey-btn.ant-btn.ant-btn-primary {
  width: 113px !important;
  height: 36px !important;
  font-size: 1rem !important;
  color: #ffff;
  background-color: #797994 !important;
  margin-top: 10px;
}
button.confirm-grey-btn.ant-btn.ant-btn-primary:hover {
  background-color: #4b4b5a !important;
}
.custom-padding {
  text-align: center;
  padding: 20px 0px 9px 0px;
  .a-col {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
.text-box-custom-padding {
  padding: 26px 0px 15px 0px;
  .a-input {
    width: 80% !important;
  }

  .minus-img-css {
    position: absolute !important;
    left: 85% !important;
    top: 5px !important;
    font-size: 20px !important;
    color: #6454f0 !important;
    border-radius: 50% !important;
  }
}
::v-deep {
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #edeef5 !important;
  }
  .ant-checkbox .ant-checkbox-inner {
    border-color: #6454f0 !important;
    border-radius: 2px !important;
  }
}
.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #6454f0 !important;
}
.custom-css {
  width: 145px !important;
}
.admin-traits-role-wrapper-header .right-wrapper {
  .custom-css:first-child {
    padding-left: 0px;
  }
  .custom-css:last-child {
    padding-right: 0px;
  }
  min-height: 0px;
}
::-webkit-scrollbar {
  width: 3px;
  height: 3px !important;
  background-color: #edeef5 !important;
  border-radius: 5px !important;
}
/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
  border-radius: 5px;
  margin: 0px 30px 45px 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bebcbc;
  border-radius: 5px;
}
@media screen and (max-width: 658px) {
  .desktop-traits-wrapper {
    .left-wrapper {
      width: 200px;
    }
  }
  .custom-css {
    width: 130px !important;
  }
}
@media screen and (max-width: 380px) {
  .desktop-traits-wrapper {
    .left-wrapper {
      width: 150px;
    }
  }
  .custom-css {
    width: 130px !important;
  }
}
</style>