<template>
  <div>
    <a-modal
      :class="className"
      v-model="isShow"
      @cancel="handleClose"
      :maskClosable="false"
      :centered="true"
      width="40%"
      class="remove-pop-res"
    >
      <a-row class="pb-2 mt-2">
        <a-col style="padding-top: 5px; text-align: left" :span="24">
          <span class="remove-pop-header">{{ headerText }}</span>
        </a-col>
      </a-row>
      <a-row class="pb-2">
        <a-col
          style="padding-top: 10px; text-align: left"
          :span="24"
          :class="className ? 'profile-confirm-msg' : 'confirm-msg'"
        >
          <h4 style="font-weight: bold">{{ confirmMsg }}</h4>
        </a-col>
      </a-row>
      <a-row class="pb-2">
        <a-col
          style="padding-top: 5px; text-align: left"
          :style="className ? 'padding-right: 35px' : 'padding-right: 26%'"
          :span="24"
        >
          <span class="confirm-contain-txt">{{ containText }}</span>
        </a-col>
      </a-row>
      <a-row :gutter="24" class="pb-2 mt-20">
        <a-col style="text-align: end" :span="24" :lg="24" :xs="24">
          <a-button
            @click="handleClose"
            class="confirm-grey-btn mr-10"
            type="primary"
            >Cancel</a-button
          >
          <a-button
            @click="handleOk"
            style="width: fit-content !important"
            type="primary"
            >Confirm</a-button
          >
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "RemovePopUp",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    logo: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default: "",
    },
    confirmMsg: {
      type: String,
      default: "",
    },
    containText: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    className: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    showModal() {
      this.visible = isShow;
    },
    handleOk() {
      this.$emit("removeMemberData", false);
    },
    handleClose() {
      this.visible = false;
      this.$emit("closeRemove", false);
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .ant-modal-footer {
    display: none !important;
  }
  //   .ant-modal-close-x {
  //     display: none !important;
  //   }
  .ant-modal {
    width: 41%;
    .ant-modal-content {
      border-radius: 10px !important;
    }
  }
  @media screen and (max-width: 590px) {
    .ant-modal {
      width: 80%;
    }
  }
  .confirm-msg {
    font-size: 18px;
    color: #666666 !important;
    padding-right: 25% !important;
  }
  .profile-confirm-msg {
    font-size: 18px;
    color: #666666 !important;
    padding-right: 5% !important;
  }
  .remove-css {
    .confirm-msg {
      padding-right: 0% !important;
    }
  }
  .ant-btn.ant-btn-primary {
    min-width: 150px !important;
  }
}
</style>
