<template>
  <div>
    <p style="font-weight: bold">Add Super User:</p>
    <div>
      <a-select
        :show-search="true"
        placeholder="Unique company domain"
        style="width: 394px"
        key="domain"
        @change="handleChangeDomain($event)"
      >
        <a-select-option
          v-for="domain in domains"
          :key="domain"
          :filter-option="
            (input, item) => {
              option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }
          "
        >
          @{{ domain }}
        </a-select-option>
      </a-select>
    </div>
    <div class="listUser">
      <template v-if="currentDomain">
        <div
          v-for="(user, idx) in domainUser"
          :key="idx"
          :gutter="[0, 20]"
          style="cursor: pointer"
          :class="getClassUser(user)"
          @click="handleCheckUser(user)"
        >
          <p style="font-weight: bold">
            {{ user.firstName }} {{ user.lastName }}
          </p>
          <p>
            {{ user.role }}
          </p>
          <p>
            {{ user.email }}
          </p>
          <p
            style="
              padding: 8px 0;
              display: flex;
              justify-content: end;
              padding-right: 25px;
            "
          >
            <i
              v-if="
                (user.role === UserRole.HR &&
                  !listDeSelectUser.includes(user.userId)) ||
                listSelectUser.includes(user.userId)
              "
              class="icon-circle-tick"
              style="font-size: 23px"
            />
          </p>
        </div>
      </template>
    </div>
    <a-row type="flex" justify="end" :gutter="24" style="margin-top: 2rem">
      <a-col style="">
        <a-button
          class="confirm-grey-btn"
          type="primary"
          @click="$emit('cancelEditSuperUser')"
          >Cancel</a-button
        >
      </a-col>
      <a-col style="">
        <a-button
          @click="handleUpdate"
          type="primary"
          :disabled="disabledUpdate"
          >Update</a-button
        >
      </a-col>
    </a-row>
  </div>
</template>

<script>
import services from "@/services/services";
import { UserRole } from "@/constants/user";

export default {
  data() {
    return {
      UserRole,
      currentDomain: "",
      domains: [],
      domainUser: [],
      listSelectUser: [],
      listDeSelectUser: [],
    };
  },
  methods: {
    async getUserDomain() {
      try {
        const res = await services.get("user/domains");
        if (res.status === 200) this.domains = res.data.data;
        console.log(this.domains);
      } catch (e) {
        console.error(e);
      }
    },
    async getAllDomainUser() {
      try {
        const res = await services.get(`user/${this.currentDomain}`);
        if (res.status === 200) this.domainUser = res.data.data;
        console.log(this.domainUser);
      } catch (e) {
        console.error(e);
      }
    },
    async updateSuperUser() {
      try {
        const res = await services.put("user/role/true", {
          ids: [...this.listSelectUser],
        });
        if (res.status === 200) this.listSelectUser = [];
      } catch (e) {
        console.error(e);
      }
    },
    async updateDeselectSuperUser() {
      try {
        const res = await services.put("user/role/false", {
          ids: [...this.listDeSelectUser],
        });
        if (res.status === 200) this.listDeSelectUser = [];
      } catch (e) {
        console.error(e);
      }
    },

    handleUpdate() {
      if (this.listSelectUser.length) {
        this.updateSuperUser();
      }
      if (this.listDeSelectUser.length) {
        this.updateDeselectSuperUser();
      }
      this.$emit("updateSuperUser");
    },

    handleCheckUser(user) {
      if (user.role === UserRole.HR) {
        if (this.listDeSelectUser.includes(user.userId)) {
          const idx = this.listDeSelectUser.findIndex(
            (id) => user.userId === id
          );
          this.listDeSelectUser.splice(idx, 1);
          return;
        }
        this.listDeSelectUser.push(user.userId);
        return;
      }
      if (this.listSelectUser.includes(user.userId)) {
        const idx = this.listSelectUser.findIndex((id) => user.userId === id);
        this.listSelectUser.splice(idx, 1);
        return;
      }
      this.listSelectUser.push(user.userId);
    },

    async handleChangeDomain(domain) {
      this.currentDomain = domain;
      this.listSelectUser = [];
      this.listDeSelectUser = [];
      await this.getAllDomainUser();
    },
    getClassUser(user) {
      if (
        this.listSelectUser.includes(user.userId) ||
        (user.role === UserRole.HR &&
          !this.listDeSelectUser.includes(user.userId))
      )
        return "user-container isCheckedUser";
      else return "user-container";
    },
  },
  computed: {
    disabledUpdate() {
      return !this.listSelectUser.length && !this.listDeSelectUser.length;
    },
  },

  async created() {
    await this.getUserDomain();
  },
};
</script>

<style lang="scss" scoped>
.listUser {
  min-height: 150px;
  max-height: 220px;
  margin-top: 2rem;
  overflow-y: auto;
  .user-container {
    display: flex;
    margin-bottom: 1rem;
    width: 90%;
    p {
      padding: 10px 20px;
      margin-bottom: 0px;
      width: 30%;
    }
    &:hover {
      background-color: #bfc3d6 !important;
    }
  }
  .isCheckedUser {
    background: #6454f0;
    color: white;
    .icon-circle-tick {
      color: #46cefc;
      visibility: visible !important;
      font-size: 25px !important;
      border-radius: 100% !important;
      background: linear-gradient(to left, white 50%, white 50%) !important;
    }
    &:hover {
      .icon-circle-tick {
        color: #a6a8b3;
        visibility: visible !important;
        font-size: 25px !important;
        background: linear-gradient(to left, white 50%, white 50%) !important;
        border-radius: 100% !important;
      }
    }
  }
}
::v-deep {
  .ant-select-selection {
    font-size: 14px !important;
    height: 44px !important;
    background-color: #edeef5 !important;
    border: 1px solid #edeef5;
    padding: 0px 9px !important;
    padding-top: 10px !important;
  }
  .ant-select-arrow-icon {
    color: #363644 !important;
  }
}
</style>
