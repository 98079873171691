<template>
  <div class="policies__content wrapper">
    <p class="updated">Last updated: 27 May 2022</p>
    <p>
      <strong>Please read this Privacy Policy carefully before using Gestaltra.</strong>
    </p>
    <hr />

    <p>
      Gestaltra is committed to providing quality services to you and this
      policy outlines our ongoing obligations to you in respect of how we
      manage your Personal Information. We have adopted the Australian Privacy
      Principles (APPs) contained in the Privacy Act 1988 (Cth) (the Privacy
      Act).
    </p>

    <p>
      The APPs govern the way in which we collect, use, disclose, store,
      secure and dispose of your Personal Information. A copy of the
      Australian Privacy Principles may be obtained from the website of The
      Office of the Australian Information Commissioner at
      <a href="http://oaic.gov.au" target="_blank">oaic.gov.au </a>
    </p>

    <h2>What is Personal Information and why do we collect it?</h2>

    <p>
      Personal Information is information or an opinion that identifies an
      individual. Examples of Personal Information we collect include: names,
      email addresses and job titles.
    </p>

    <p>
      This Personal Information is obtained in many ways including via our
      website www.gestaltra.com and web application, from cookies, third
      parties and other publicly available sources. We don’t guarantee website
      links or policy of authorised third parties.
    </p>

    <p>
      We collect your Personal Information for the primary purpose of
      providing our services to you, providing information to our clients, and
      marketing. We may also use your Personal Information for secondary
      purposes closely related to the primary purpose, in circumstances where
      you would reasonably expect such use or disclosure.
    </p>

    <p>
      To provide users with a better understanding of their survey results, we
      use survey data in a de-identified aggregated form to compare users’
      results to the results of other surveys or other users. We also use your
      survey data to continually improve our Services, including our
      de-identified aggregated data sets. None of your survey data will be
      disclosed to other unrelated users in a non-aggregated or identifiable
      form.
    </p>

    <p>
      When we collect Personal Information we will, where appropriate and
      where possible, explain to you why we are collecting the information and
      how we plan to use it.
    </p>

    <p>
      You may unsubscribe from our mailing/marketing lists at any time by
      contacting us in writing.
    </p>

    <h2>Sensitive Information</h2>

    <p>
      Sensitive information is defined in the Privacy Act to include
      information or opinion about such things as an individual's racial or
      ethnic origin, political opinions, membership of a political
      association, religious or philosophical beliefs, membership of a trade
      union or other professional body, criminal record or health information.
    </p>

    <p>Sensitive information will be used by us only:</p>

    <ul>
      <li>For the primary purpose for which it was obtained</li>

      <li>For a secondary purpose that is directly related to the primary purpose</li>

      <li>With your consent; or where required or authorised by law.</li>
      
    </ul>

    <h2>Third Parties</h2>

    <p>
      Where reasonable and practicable to do so, we will collect your Personal
      Information only from you. However, in some circumstances we may be
      provided with information by third parties. In such a case we will take
      reasonable steps to ensure that you are made aware of the information
      provided to us by the third party.
    </p>

    <p>
      We may partner with vendors such as Google Analytics, to allow tracking
      technologies and remarketing services on the Gestaltra website and web
      application through the use of first party cookies and third-party
      cookies, to, among other things, analyse and track users’ use of
      Gestaltra, determine the popularity of certain content and better
      understand online activity. By accessing Gestaltra, you consent to the
      collection and use of your information by these third-party vendors. You
      are encouraged to review their privacy policy and contact them directly
      for responses to your questions. We do not transfer personal information
      to these third-party vendors.
    </p>

    <h2>Disclosure of Personal Information</h2>

    <p>
      Your Personal Information may be disclosed in a number of circumstances
      including the following:
    </p>

    <ul>
      <li>Third parties where you consent to the use or disclosure; and</li>

      <li>Where required or authorised by law.</li>
    </ul>

    <h2>Security of Personal Information</h2>

    <p>
      Your Personal Information is stored in a manner that reasonably protects
      it from misuse and loss and from unauthorized access, modification or
      disclosure.
    </p>

    <p>
      Our servers are based in Australia, so your personal information will
      generally be processed and hosted in Australia. However, as some of our
      service providers are located outside of Australia, there may be times
      when your personal information may be transferred, disclosed or
      processed in another country, such as the United States, Ireland and the
      United Kingdom. For example, if you contact our team for support or
      questions, any information you provide us in the support request
      (including personal information such as your name and email address)
      will be processed and hosted outside of Australia by the service
      provider we use to manage support requests.
    </p>

    <p>
      When your Personal Information is no longer needed for the purpose for
      which it was obtained, we will take reasonable steps to destroy or
      permanently de-identify your Personal Information. However, most of the
      Personal Information is or will be stored in client files which will be
      kept by us for a minimum of 7 years.
    </p>

    <h2>Access to your Personal Information</h2>

    <p>
      You may access the Personal Information we hold about you and to update
      and/or correct it, subject to certain exceptions. If you wish to access
      your Personal Information, please contact us in writing.
    </p>

    <p>
      Gestaltra will not charge any fee for your access request, but may
      charge an administrative fee for providing a copy of your Personal
      Information.
    </p>

    <p>
      In order to protect your Personal Information we may require
      identification from you before releasing the requested information.
    </p>

    <h2>Maintaining the Quality of your Personal Information</h2>

    <p>
      It is important to us that your Personal Information is up to date. We
      will take reasonable steps to make sure that your Personal Information
      is accurate, complete and up-to-date. If you find that the information
      we have is not up to date or is inaccurate, please advise us as soon as
      practicable so we can update our records and ensure we can continue to
      provide quality services to you.
    </p>

    <h2>Policy Updates</h2>

    <p>
      This Policy may change from time to time and is available on our
      website.
    </p>

    <h2>Privacy Policy Complaints and Enquiries</h2>

    <p>
      If you have any queries or complaints about our Privacy Policy please
      contact us at <a href="mailto:hello@gestaltra.com" >hello@gestaltra.com</a>
    </p>
  </div>
</template>
<script>
export default {
  name: "privacyAndPolicy"
};
</script>
