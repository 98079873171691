import Vue from "vue";
import App from "./App.vue";
// import VueGtag from "vue-gtag";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import "./styles/styles.scss";
import "./styles/media-query.scss";
import VueApexCharts from "vue-apexcharts";
import VueGtag from "vue-gtag";
import JsonExcel from "vue-json-excel";
import device from "vue-device-detector";
import VueCryptojs from 'vue-cryptojs'

Vue.use(VueCryptojs)

Vue.component("downloadExcel", JsonExcel);
Vue.config.productionTip = false;
const instance = axios.create({
  timeout: 900000, // 15 minutes
});
Vue.config.globalProperties = store;
Vue.use(VueGtag, {
  config: { id: "G-V3HRF2DL37" },

});
Vue.use(VueAxios, instance);
Vue.use(Antd);
Vue.use(device);
Vue.use(VueApexCharts);


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
