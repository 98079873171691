<template>
  <div class="faq wrapper flex-wrapper">
    <h1 class="wow fadeInUp">Things people often<br class="mobile-hide" /> ask about</h1>
    <div class="faq__content wow fadeIn">
     <a-collapse v-model="activeKey">
      <a-collapse-panel key="1" header="How does Gestaltra work?">
        <p>Gestaltra enables managers to initiate 360 surveys for their own team members within minutes, without the need for lengthy setup or training.</p><p>Team members select their reviewers, and surveys are automatically sent for completion.</p>
          <p>Our carefully designed survey avoids leading questions, instead opting for a unique approach that encourages targeted feedback, while protecting anonymity. </p>
      </a-collapse-panel>
      <a-collapse-panel key="2" header="Performance reviews are onerous enough, why add to it?">
        <p> 360s complement the performance evaluation process, by surfacing richer information that can help identify key talent and highlight areas that may need further attention.</p>
          <p>They ensure reviewees get a fair go by reducing bias, while providing managers with insights they may otherwise not have access to. </p>
      </a-collapse-panel>
      <a-collapse-panel key="4" header="I already do 360s, how is Gestaltra different?">
        <p>Traditional 360 surveys are time-consuming.</p>
        <p>We've gone over every step of the 360 journey from the perspective of the reviewer, reviewee and the manager, with the sole purpose of removing friction. Less effort involved in setting up, filling out, and analysing surveys, means more people completing them. And better, more actionable results.</p>
      </a-collapse-panel>
      <a-collapse-panel key="5" header="How much does Gestaltra cost?">
        <p>We're committed to making 360s accessible to businesses of all sizes and budgets.</p>
        <p>Try Gestaltra on us, then get in touch once you need more capability unlocked.</p>
      </a-collapse-panel>
      <a-collapse-panel key="6" header="Sounds good, how do I get started?">
        <p><a  target="_blank" @click="goToSignUp('sign_up')">Create your account</a>, then follow the prompts to start adding team members. Click 'Create new survey' when you're ready to kick off the review process.</p>
        <p>This should all take no longer than 10 minutes.</p>
      </a-collapse-panel>
    </a-collapse>
    </div>
  </div>
</template>

<script>
import { event } from "vue-gtag";

export default {
  name: 'FAQ',
  data() {
    return {
      event,
      text: `A dog is a type of domesticated animal.Known for its loyalty and faithfulness,it can be found as a welcome guest in many households across the world.`,
      activeKey: ['1'],
      expandIconPosition: 'left',
    };
  },
  methods: {
    gaEventTrack(data, action, category) {
      this.event(action, {
        event_category: category,
        event_label: data,
        value: 1,
      });
    },
    goToSignUp(data) {
      this.gaEventTrack(
        "Landing page signUp",
        data,
        "Landing page signUp link"
      );
      this.$router.push("/signup");
    },
  }
}
</script>