<template>
  <div class="bg-color">
    <div class="center-signIn-div white-bg-css">    
      <a-row :gutter="12" class="center">
        <a-col :span="24" class="wlcm">
          <div class="wlcm">Welcome</div>
        </a-col>
      </a-row>
      <a-row class="txt-labal" :gutter="24">
        <a-col :span="24">
          <div class="sm-txt">We’re so glad you’re here!</div>
        </a-col>
      </a-row>
      <a-form :form="form" @submit="handleSubmit" class="mt-25">
        <a-form-item
          class="float-label"
          :class="dataValidate ? 'has-error' : null"
        >
          <label
            :class="
              focusData.focusDataEmail || formData.email
                ? 'label label-float'
                : 'label'
            "
            >Email</label
          >
          <a-input
            @blur="setFocus(false, 'focusDataEmail')"
            @focus="setFocus(true, 'focusDataEmail')"
            v-decorator="[
              'email',
              {
                rules: [
                  {
                    pattern: emailRegExp,
                    message: 'Please input valid email!',
                  },
                  {
                    required: true,
                    message:
                      'Email can’t be blank - please enter a valid email address',
                  },
                ],
                validateTrigger: 'blur',
              },
            ]"
            :value="formData.email"
            @change="onChange($event.target.value, 'email')"
          />
        </a-form-item>
        <a-form-item
          class="float-label"
          :class="dataValidate ? 'has-error' : null"
        >
          <label
            :class="
              focusData.focusDataPassword || formData.password
                ? 'label label-float'
                : 'label'
            "
            >Password</label
          >
          <a-input-password
            @blur="setFocus(false, 'focusDataPassword')"
            @focus="setFocus(true, 'focusDataPassword')"
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message:
                      'Password can’t be blank - please enter a  password',
                  },
                ],
              },
            ]"
            :value="formData.password"
            @change="onChange($event.target.value, 'password')"
          />
          <div class="ant-form-explain" v-if="dataValidate">
            The email or password is incorrect
          </div>
        </a-form-item>
        <a-row :gutter="24" class="mt-35">
          <a-col :span="12">
            <a-checkbox
              class="grey-txt-sm"
              v-decorator="[
                'rememberMe',
                {
                  valuePropName: 'checked',
                  initialValue: true,
                },
              ]"
              :checked="formData.rememberMe"
              @change="onChangeRemember($event.target.checked, 'rememberMe')"
            >
              Remember me
            </a-checkbox>
          </a-col>
          <a-col :span="12">
            <div class="blue-txt-sm">
              <a @click="forgotPasswordClick">Forgot password?</a>
            </div>
          </a-col>
        </a-row>
        <a-form-item class="pb-2 mt-25">
          <a-button type="primary" html-type="submit" class="arrow-cls">
            Log in<a-icon
              style="
                font-size: 25px !important;
                font-weight: bold !important;
                margin-top: -4px !important;
                border-bottom-style: none;
              "
              type="arrow-right"
            />
          </a-button>
        </a-form-item>
        <a-row :gutter="24" class="mt-40">
          <a-col :span="24">
            <span class="grey-txt-sm">New to Gestaltra? </span
            ><span class="blue-txt-sm pointer" @click="goToSignUp"
              >Sign up now</span
            >
          </a-col>
        </a-row>
      </a-form>
    </div>
    <error-pop-up :is-show="errorPopupFlag" @closePopup="closedErrorPopup" />
  </div>
</template>
<script>
import CommonPopUp from "@/components/CommonPopUp.vue";
import services from "../../../services/services.js";
import ErrorPopUp from "@/components/ErrorPopUp.vue";
import device from "vue-device-detector";
export default {
  name: "Login",
  components: {
    CommonPopUp,
    ErrorPopUp,
  },
  data() {
    const emailRegExp =
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}[^\\S]{1,})+$/;
    return {
      secret: "123#$%",
      IDeviceDetector: {
        ios: Boolean,
        iphone: Boolean,
        iphoneX: Boolean,
        iPhoneXR: Boolean,
        iPhoneXSMax: Boolean,
        ipod: Boolean,
        ipad: Boolean,
        android: Boolean,
        androidPhone: Boolean,
        windows: Boolean,
        mobile: Boolean,
        dingding: Boolean,
        wechat: Boolean,
        wechatMiniApp: Boolean,
      },
      emailRegExp,
      errorPopupFlag: false,
      services,
      currentDate: "",
      currentDAteTime: "",
      device,
      dataValidate: false,
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      formData: {
        email: "",
        password: "",
        rememberMe: false,
      },
      focusData: {
        focusDataEmail: false,
        focusDataPassword: false,
      },
    };
  },
  created() {
    if (!localStorage.getItem("token")) {
      if (localStorage.checkbox) {
        this.formData.email = localStorage.username;
        this.formData.password = localStorage.password;
        this.formData.rememberMe = JSON.parse(localStorage.checkbox);

        // this.form.setFieldsValue(this.formData);
        for (let key in this.formData) {
          this.form.getFieldDecorator(key, {
            initialValue: this.formData[key],
          });
        }
      }
    } else {
      this.$router.push("/main");
    }
    this.getCurrentDate();
    this.getCurrentDateTime();
  },
  methods: {
    getCurrentDate() {
      this.currentDate = new Date().toLocaleDateString();
    },
    getCurrentDateTime() {
      this.currentDAteTime = new Date().toLocaleTimeString();
    },
    closedErrorPopup(val) {
      this.errorPopupFlag = val;
    },
    setFocus(val, key) {
      if (key == "focusDataEmail") {
        this.formData.email = this.formData.email.trim();
      }
      this.focusData[key] = val;
    },
    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          this.loginClick();
        }
      });
    },
    loginClick() {
      if (
        this.formData.rememberMe &&
        this.formData.email &&
        this.formData.password
      ) {
        localStorage.username = this.formData.email;
        localStorage.password = this.formData.password;
        localStorage.checkbox = this.formData.rememberMe;
      } else {
        localStorage.username = "";
        localStorage.password = "";
        localStorage.checkbox = false;
      }

      this.$store.state.signUpDataObject.email = this.formData.email;
      let data = {
        email: this.formData.email,
        password: this.formData.password,
      };

      this.dataValidate = false;
      if (data.email && data.password) {
        this.services
          .post("user/login", data)
          .then((res) => {
            if (res) {
              let userDetails = res.data.propUserDetails;
              this.$store.state.userId = userDetails.userId;
              this.$store.state.userDetails = res.data.propUserDetails;
              let token = res.data.token;
              let deviceName;
              for (let key in this.IDeviceDetector) {
                if (this.$device[key]) {
                  deviceName =
                    key +
                    " " +
                    userDetails.userId +
                    " " +
                    this.currentDate +
                    " " +
                    this.currentDAteTime;
                }
              }
              localStorage.setItem("userDeviceName", deviceName);
              token = this.$CryptoJS.AES.encrypt(token, this.secret).toString();
              localStorage.setItem("token", token);
              const decryptedText = this.$CryptoJS.AES.decrypt(
                token,
                this.secret
              ).toString(this.CryptoJS.enc.Utf8);
              this.$store.state.userToken = decryptedText;

              localStorage.setItem(
                "userDetails",
                JSON.stringify(res.data.propUserDetails)
              );

              this.$router.push("/main");
            }
          })
          .catch((err) => {
            if (err.response.status == 400) {
              this.dataValidate = true;
            } else if (err.response.status == 301) {
              this.$store.state.userId = err.response.data.userId;
              this.$store.state.signUpFlag = true;
              this.verificationeEmail();
            } else {
              //this.errorPopupFlag = true//;
            }
          });
      }
    },
    verificationeEmail() {
      let sendTo = this.$store.state.signUpDataObject.email;
      let data = {
        toEmail: sendTo,
      };
      if (data) {
        this.services
          .post("user/sendVerificationEmail", data)
          .then((res) => {
            if (res) {
              this.$store.state.signUpFlag = true;
              this.$router.push("/verify");
            }
          })
          .catch((err) => {
            //this.errorPopupFlag = true//;
          });
      }
    },
    onChange(val, key) {
      this.dataValidate = false;

      val = key == "email" ? val.replace(/\s/g, "") : val;
      this.form.setFieldsValue({
        [key]: val,
      });
      this.formData[key] = val;
    },
    onChangeRemember(val, key) {
      this.form.setFieldsValue({
        [key]: val,
      });
      this.formData[key] = val;
    },
    goToSignUp() {
      this.$router.push("/signup");
    },
    forgotPasswordClick() {
      this.$store.state.forgotFlag = true;

      this.$router.push("/forgot-password");
    },
  },
};
</script>
<style scoped lang="scss">

::v-deep {
  .anticon.ant-input-password-icon,
  .ant-select-arrow-icon {
    color: #363644 !important;
  }
  .ant-input,
  .ant-select-selection {
    font-size: 14px !important;
    height: 44px !important;
    background-color: #edeef5 !important;
    border: 1px solid #edeef5;
    padding: 0px 9px !important;
    padding-top: 10px !important;
  }
  .grey-txt-sm.ant-checkbox-wrapper {
    font-size: 14px !important;
  }
  .grey-txt-sm,
  .pointer {
    font-size: 18px !important;
  }
  .sm-txt {
    font-size: 18px;
  }

  .ant-btn-primary {
    height: 43px !important;
    font-size: 18px !important;
  }
  .arrow-cls span {
    padding-left: 9%;
  }
  .pointer {
    border-bottom: 1px solid #6454f0;
  }
  
}
</style>