<template>
  <div>
    <div class="whole-picture">
      <h1 class="wow fadeInUp">See the whole picture</h1>
      <p class="wow fadeInUp">Get the most out of your organisation by focusing on the people within it</p>
      <div class="box-content">

        <div class="box wow zoomIn">
          <div class="box__icon">
            <img src="../../assets/employees.png" alt=""/>
          </div>
          <h3>Employees</h3>
          <p>Gather input from multiple stakeholders to reduce feedback bias and maximise likelihood of action.</p>
        </div>

        <div class="box wow zoomIn">
          <div class="box__icon">
            <img src="../../assets/leadership.png" alt=""/>
          </div>
          <h3>Leadership</h3>
          <p>Augment perspectives with additional data points for richer conversations and fairer performance reviews.</p>
        </div>

        <div class="box people wow zoomIn">
          <div class="box__icon">
            <img src="../../assets/pc.png" alt=""/>
          </div>
          <h3>People & culture</h3>
          <p>Derive multi-directional insights that inform more targeted training and balanced talent management.</p>
        </div>

        <div class="box wow zoomIn">
          <div class="box__icon">
            <img src="../../assets/organisation.png" alt=""/>
          </div>
          <h3>Organisation</h3>
          <p>Develop a culture where the 'how' is as important as the 'what', and promote sustainable performance.</p>
        </div>
      </div>
    </div>
    <!-- <div class="wave">
      <svg viewBox="0 0 500 200" preserveAspectRatio="none">
        <path d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z" style="stroke: none;fill: #363644;"></path>
      </svg>
    </div> -->
  </div>
</template>


<script>
export default {
  name: 'WholePicture'
}
</script>