<template>
  <div>
    <div class="grey-bg-color admin-res-css">
      <div>
        <a-row :gutter="24" class="header-logo-res">
          <a-col :span="16"><img alt="gestaltra_logo_grey" width="180px" src="../../assets/gestaltra_logo_grey.png" />
          </a-col>
          <a-col :span="6"> </a-col>
          <a-col class="profile-icon float-r" style="text-align: right" :span="4" :lg="4" :sm="8">
            <a-dropdown :trigger="['click']">
              <span class="dot ant-dropdown-link pointer" @click="(e) => e.preventDefault()">
                <span class="profile-icon-txt">{{ firstLetter }}</span>
              </span>
              <a-menu id="arrow" slot="overlay">
                <a-menu-item class="cus-disable" key="0">
                  <a-row :gutter="24">
                    <a-col :span="24" style="text-align: center !important">
                      <span class="user-name">{{ firstName }}&nbsp;{{ lastName }}</span>
                    </a-col>
                  </a-row>
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item key="1" @click="goToProfile()">
                  <a-row :gutter="24">
                    <a-col :span="24">
                      <span class="profile-txt"><img alt="user" style="margin-right: 10px" width="16px" height="16px"
                          src="../../assets/user.png" />Profile</span>
                    </a-col>
                  </a-row>
                </a-menu-item>
                <a-menu-item key="2" @click="goToLandingPage()">
                  <a-row :gutter="24">
                    <a-col :span="24">
                      <span class="profile-txt"><img alt="logout-icon" style="margin-right: 10px" width="16px"
                          height="16px" src="../../assets/logout-icon.png" />Logout</span>
                    </a-col>
                  </a-row>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-col>
        </a-row>
        <a-row style="padding-bottom: 13px; padding-top: 54px" :gutter="24">
          <a-col class="header-title-txt" style="margin-left: 15px" :span="24" :lg="24" :sm="20" :xs="24">
            Admin Console
          </a-col>
        </a-row>
        <a-row class="date-div-sec1" style="padding-top: 54px" :gutter="24">
          <a-col class="date-div" :span="18" :lg="18" :sm="18" :xs="24">
            <a-row class="date-div-sec" :gutter="24" style="padding-top: 17px !important">
              <a-col style="padding-left: 20px" :span="9" :lg="9" :sm="18" :xs="24" class="start-res">
                <span class="date-txt">Start date: &nbsp;</span>
                <a-date-picker format="DD MMM YYYY" style="background: #edeef5; border-radius: 10px"
                  :disabled-date="(current) => current.isAfter(moment())" @change="onChangeStartDate" />
                <div style="color: #f13c47; text-align: center" class="ant-form-explain" v-if="startDateError">
                  Please enter start date
                </div>
              </a-col>
              <a-col style="padding-left: 45px" :span="9" :lg="9" :sm="18" :xs="24" class="end-res">
                <span class="date-txt">End date: &nbsp;</span>
                <a-date-picker format="DD MMM YYYY" :disabled-date="disabledDate"
                  style="background: #edeef5; border-radius: 10px" @change="onChangeEndDate" />
                <div style="color: #f13c47; text-align: center" class="ant-form-explain" v-if="endDateError">
                  Please enter end date
                </div>
              </a-col>
              <a-col :span="6" :lg="6" :sm="24" :xs="24" style="text-align: center; padding-top: 12px">
                <download-excel name="Survey_details.xls" :data="exportJson">
                  <a-button class="tab-res" :disabled="isExportDis" style="min-width: 150px !important" type="primary"
                    @mouseover="mouseoverExportClick" @click="exportdata">Export</a-button>
                </download-excel>
                <div style="color: #f13c47; text-align: center; padding-top: 6px" class="ant-form-explain"
                  v-if="isExportBtn">
                  Data not found
                </div>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="6" :lg="6" :sm="4" :xs="24"> </a-col>
        </a-row>
        <a-row :gutter="24" style="margin-top: 4.5rem !important">
          <a-col :span="13" :lg="13" :xs="24" style="padding-left: 0px;">
            <div class="left-div-css">
              <a-row style="" :gutter="24">
                <a-col class="header-title-css" style="" :span="22" :lg="22" :sm="20" :xs="24">
                  General competencies labels
                </a-col>
                <a-col class="edit-css" style="" :span="2" :lg="2" :sm="20" :xs="24">
                  <a-icon @click="editCompetencies" class="edit-icon-css" type="edit" />
                </a-col>
              </a-row>
              <a-row style="margin-top: 25px" :gutter="24">
                <a-col class="label-css" style="" :span="9" :lg="9">
                  Competency
                </a-col>
                <a-col class="label-css" style="" :span="13" :lg="13">
                  Description
                </a-col>
                <a-col class="edit-css" style="" :span="2" :lg="2"> </a-col>
              </a-row>
              <div class="com-div-scoll">
                <a-row v-for="(item, index) in competenciesList" :key="index" :style="item.deletedFlag ? 'margin-top: 0px' : 'margin-top: 13px'
                  " :gutter="24">
                  <span v-if="!item.deletedFlag">
                    <a-col class="label-css" style="" :span="9" :lg="9">
                      <a-input :disabled="!isEditCompetencies" class="input-field-css" :value="item.competancy" @blur="
                        checkExistCompentency(
                          $event.target.value,
                          'competancy',
                          item,
                          index
                        )
                        " />
                    </a-col>
                    <a-col class="label-css" style="" :span="14" :lg="14">
                      <a-input class="input-field-css" :disabled="!isEditCompetencies" :value="item.description" @blur="
                        onChangeCompetency(
                          $event.target.value,
                          index,
                          'description',
                          item
                        )
                        " />
                    </a-col>
                    <a-col class="edit-css" style="" :span="1" :lg="1">
                      <a-icon :style="isEditCompetencies
                        ? 'visibility: unset'
                        : 'visibility: hidden'
                        " class="minus-img-css" type="minus-circle" theme="filled"
                        @click="deleteCriteriaRecord(index, item)" />
                    </a-col>
                  </span>
                </a-row>
              </div>
              <div :style="isEditCompetencies
                ? 'visibility: unset'
                : 'visibility: hidden'
                ">
                <a-row class="mt15-res" style="margin-top: 15px" :gutter="24">
                  <a-col class="bold-label-css" style="" :span="24" :lg="24" :xs="24">
                    Add general criteria:
                  </a-col>
                </a-row>

                <a-form :form="form">
                  <a-form-item class="float-label">
                    <a-row :gutter="24">
                      <a-col :span="8" :lg="8" :xs="24" style="padding-right: 0px !important">
                        <label :class="focusData.criteria || formData.competencyName
                          ? 'label label-float'
                          : 'label'
                          ">Add criteria</label>
                        <a-input class="grey-input-field-css" @blur="setFocus(false, 'criteria')"
                          @focus="setFocus(true, 'criteria')" :value="formData.competencyName" @change="
                            onChangeCriteria(
                              $event.target.value,
                              'competencyName'
                            )
                            " />
                      </a-col>
                      <a-col :span="14" :lg="14" :xs="24" style="padding-right: 0px !important">
                        <label :class="focusData.description_c || formData.description
                          ? 'label label-float'
                          : 'label'
                          ">Add description</label>
                        <a-input class="grey-input-field-css" @blur="setFocus(false, 'description_c')"
                          @focus="setFocus(true, 'description_c')" :value="formData.description" @change="
                            onChangeCriteria($event.target.value, 'description')
                            " />
                      </a-col>
                      <a-col :span="2" :lg="2" :xs="24">
                        <a-icon class="plus-img-css" type="plus-circle" theme="filled"
                          @click="addGeneralCriteria($event)" />
                      </a-col>
                    </a-row>
                  </a-form-item>
                </a-form>
                <a-row class="btn-foot-res1" style="margin-top: 25px" :gutter="24">
                  <a-col style="" :span="14" :lg="14" :xs="24"> </a-col>
                  <a-col style="" :span="5" :lg="5" :xs="24">
                    <a-button style="width: 113px !important; float: right !important" @click="cancelCompetencies"
                      class="confirm-grey-btn" type="primary">Cancel</a-button>
                  </a-col>
                  <a-col style="" :span="5" :lg="5" :xs="24">
                    <a-button style="width: 113px !important; float: right !important" @click="updateCompetencies"
                      type="primary">Update</a-button>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-col>
          <a-col :span="11" :lg="11" :xs="24" style="padding-right: 0px;">
            <div class="right-div-css">
              <a-row style="" :gutter="24">
                <a-col class="header-title-css" style="" :span="22" :lg="22" :sm="20" :xs="24">
                  General competencies response labels
                </a-col>
                <a-col class="edit-css" style="" :span="2" :lg="2" :sm="20" :xs="24">
                  <a-icon @click="editCompetenciesResponse" class="edit-icon-css" type="edit" />
                </a-col>
              </a-row>

              <div class="comR-div-scoll">
                <a-row v-for="(item, index) in orignalCompetenciesReponseList" :key="index" :style="item.deletedFlag ? 'margin-top: 0px' : 'margin-top: 13px'
                  " :gutter="24"><span v-if="!item.deletedFlag">
                    <a-col class="label-css" style="padding-top: 10px" :span="3" :lg="3" :xs="24">
                      <label v-if="index == 0">Lowest</label>
                      <label v-if="index == orignalCompetenciesReponseList.length - 1 &&
                        orignalCompetenciesReponseList.length > 1
                        ">Highest</label>
                    </a-col>
                    <a-col class="label-css" style="" :span="3" :lg="3" :xs="6">
                      <a-input :disabled="true" :style="isEditCompetenciesResponse
                        ? ' background-color: white'
                        : 'background-color: ##BFC3D6'
                        " class="input-field-css" :value="index" @change="
    onChangeCompetencyResponse(
      $event.target.value,
      index,
      'index_id',
      item
    )
    " />
                    </a-col>
                    <a-col class="label-css" style="" :span="16" :lg="16" :xs="16">
                      <a-input class="input-field-css" :disabled="!isEditCompetenciesResponse" :value="item.description"
                        @blur="
                          checkExistCompentencyResponse(
                            $event.target.value,
                            'description',
                            item,
                            index
                          )
                          " />
                      <span class="edit-css" style="">
                        <a-icon :style="isEditCompetenciesResponse
                          ? 'visibility: unset'
                          : 'visibility: hidden'
                          " class="minusCR-img-css" type="minus-circle" theme="filled"
                          @click="deleteCriteriaRecordResponse(index, item)" />
                      </span>
                    </a-col>
                    <a-col class="edit-css" style="" :span="2" :lg="2">
                      <!-- <a-icon
                      :style="
                        isEditCompetenciesResponse
                          ? 'visibility: unset'
                          : 'visibility: hidden'
                      "
                      class="minusCR-img-css"
                      type="minus-circle"
                      theme="filled"
                      @click="deleteCriteriaRecordResponse(index, item)"
                    /> -->
                    </a-col>
                  </span>
                </a-row>
              </div>
              <div :style="isEditCompetenciesResponse
                ? 'visibility: unset'
                : 'visibility: hidden'
                ">
                <a-row mt15-res style="margin-top: 15px" :gutter="24">
                  <a-col class="bold-label-css" style="" :span="24" :lg="24" :xs="24">
                    Add general criteria:
                  </a-col>
                </a-row>

                <a-form :form="form">
                  <a-form-item class="float-label">
                    <a-row :gutter="24">
                      <a-col :span="2" :lg="2" :xs="24" style="padding-right: 0px !important">
                        <label :class="focusData.hash_index || formData.hash_index
                          ? 'label label-float'
                          : 'label'
                          ">#</label>
                        <a-input :disabled="true" class="grey-input-field-css" @blur="setFocus(false, 'hash_index')"
                          @focus="setFocus(true, 'hash_index')" :value="formData.hash_index" @change="
                            onChangeCriteria($event.target.value, 'hash_index')
                            " />
                      </a-col>
                      <a-col :span="20" :lg="20" :xs="24" style="padding-right: 0px !important">
                        <label :class="focusData.criteriaLabel || formData.criteriaLabel
                          ? 'label label-float'
                          : 'label'
                          ">Add criteria label</label>
                        <a-input class="grey-input-field-css" @blur="setFocus(false, 'criteriaLabel')"
                          @focus="setFocus(true, 'criteriaLabel')" :value="formData.criteriaLabel" @change="
                            onChangeCriteria(
                              $event.target.value,
                              'criteriaLabel'
                            )
                            " />
                      </a-col>
                      <a-col :span="2" :lg="2" :xs="24">
                        <a-icon class="plus-img-css" type="plus-circle" theme="filled"
                          @click="addGeneralCriteriaReponse($event)" />
                      </a-col>
                    </a-row>
                  </a-form-item>
                </a-form>
                <a-row v-if="showError">
                  <a-col :span="2" :lg="2" class="error-txt"> </a-col>
                  <a-col :span="12" :lg="12" :xs="24" class="error-txt">
                    Maximum 5 response can be added</a-col></a-row>
                <a-row class="btn-foot-res2" style="margin-top: 25px" :gutter="24">
                  <a-col style="" :span="12" :lg="12" :xs="24"> </a-col>
                  <a-col style="" :span="6" :lg="6" :xs="24">
                    <a-button @click="cancelCompetenciesResponse" style="width: 113px !important; float: right !important"
                      class="confirm-grey-btn" type="primary">Cancel</a-button>
                  </a-col>
                  <a-col style="" :span="6" :lg="6" :xs="24">
                    <a-button style="width: 113px !important; float: right !important" @click="updateCompetenciesResponse"
                      type="primary">Update</a-button>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-col>
        </a-row>

        <a-row class="bg-css custom-scroll-traits-only" :gutter="24" style="margin-top: 4.5rem !important">
          <a-col :span="24" :lg="24">
            <a-row style="" :gutter="24">
              <a-col class="header-title-css" style="" :span="22" :lg="22" :sm="20" :xs="24">
                Traits and Roles
              </a-col>
              <a-col class="edit-css" style="" :span="2" :lg="2" :sm="20" :xs="24">
                <a-icon @click="editTraitsAndRoles" class="edit-icon-css" type="edit" />
              </a-col>
            </a-row>
            <div>
              <admin-traits :edit-flag-prop="editTraitsAndRolesFlag" :user-id="userId"
                @changedUpdatedFlag="changedUpdatedFlag" />
            </div>
          </a-col>
        </a-row>
        <a-row class="bg-css" :gutter="24" style="margin-top: 4.5rem !important">
          <a-col :span="24" :lg="24">
            <a-row style="" :gutter="24">
              <a-col class="header-title-css" style="" :span="22" :lg="22" :sm="20" :xs="24">
                Industries
              </a-col>
              <a-col class="edit-css" style="" :span="2" :lg="2" :sm="20" :xs="24">
                <a-icon @click="editIndustry" class="edit-icon-css" type="edit" />
              </a-col>
            </a-row>
            <div class="grey-div-css" style="padding: 16px">
              <div class="industries-scroll" style="">
                <a-list :grid="{
                  gutter: 24,
                  xs: 1,
                  sm: 2,
                  md: 4,
                  lg: 4,
                  xl: 4,
                  xxl: 3,
                }" :data-source="industryList">
                  <a-list-item slot="renderItem" slot-scope="item, index" class="pointer" :disabled="!isEditIndustry">
                    <span class="value-txt round-list-grey-faint" :class="item.selectedIndustryFlag && isEditIndustry
                      ? 'active'
                      : null
                      "><span @click="selectedIndustry(item, index)" class="txt-css">{{ item.industryName }}</span>
                      <a-icon @click="deleteSelectedIndustry(item, index)" v-if="item.industryName.toLowerCase().trim() == 'other'
                        ? false
                        : item.selectedIndustryFlag
                        " :style="isEditIndustry ? 'display: unset' : 'display: none'
    " type="close" style="font-size: 13px; padding-left: 5px" />
                    </span>
                  </a-list-item>
                </a-list>
              </div>
            </div>
            <div class="grey-div-css indus-res" style="padding: 16px" v-if="isEditIndustry">
              <div style="padding: 5px">
                <a-row :gutter="24">
                  <a-col class="bold-label-css" style="padding-top: 5px" :span="8" :lg="8" :xs="24">
                    Add industry:
                  </a-col>

                  <a-col :span="13" :lg="13" :xs="24"> </a-col>
                  <a-col :span="3" :lg="3" :xs="24"><a-button class="industUpdateBtn1" @click="updateIndustry"
                      type="primary" style="margin-top: 5px !important">Update</a-button>
                  </a-col>
                </a-row>
                <a-form :form="form" style="margin-top: 5px">
                  <a-form-item class="float-label">
                    <a-row :gutter="24" style="margin-bottom: 0px">
                      <a-col :span="6" :lg="6" :xs="24">
                        <label :class="focusData.industry || formData.industry
                          ? 'label label-float'
                          : 'label'
                          ">Add industry</label>
                        <a-input class="grey-input-field-css" @blur="setFocus(false, 'industry')"
                          @focus="setFocus(true, 'industry')" :value="formData.industry" @change="
                            onChangeIndustry($event.target.value, 'industry')
                            " />
                        <div style="color: #f13c47" class="ant-form-explain" v-if="industryValidate">
                          Industry name already exists
                        </div>
                      </a-col>
                      <a-col :span="2" :lg="2" :xs="24">
                        <a-icon class="plus-img-css" type="plus-circle" theme="filled" @click="addIndustry($event)" />
                      </a-col>

                      <a-col :span="13" :lg="13" :xs="24"> </a-col>
                      <a-col :span="3" :xs="24" :lg="3"><a-button style="margin-top: 5px !important"
                          @click="cancelIndustry" class="confirm-grey-btn" type="primary">Cancel</a-button>
                        <a-button class="industUpdateBtn2" @click="updateIndustry" type="primary"
                          style="margin-top: 5px !important">Update</a-button>
                      </a-col>
                    </a-row>
                  </a-form-item>
                </a-form>
              </div>
            </div>
          </a-col>
        </a-row>
        <a-row class="bg-css" :gutter="[0,20]" style="margin-top: 4.5rem;min-height: 700px;margin-left: -12px;margin-right: -12px;">
          <a-col>
            <a-row>
              <a-col class="header-title-css" :span="22" :lg="22">
                Super Users
              </a-col>
              <a-col class="edit-css" :span="2" :lg="2">
                <a-icon @click="isEditSuperUser = true" class="edit-icon-css" type="edit" />
              </a-col>
            </a-row>
          </a-col>
          <a-col>
            <a-row>
              <a-col style="font-weight: bold" :span="8">
                Name
              </a-col>
              <a-col style="font-weight: bold" :span="8">
                Job role
              </a-col>
              <a-col style="font-weight: bold" :span="8">
                Email
              </a-col>
            </a-row>
          </a-col>
          <a-col class="listUser">
            <a-row v-for="(user,idx) in superUsers" :key="idx"  :gutter="[0,20]">
              <a-col  :span="8">
                {{ user.userDetails[0].firstName }} {{ user.userDetails[0].lastName }}
              </a-col>
              <a-col  :span="8">
                {{ user.role }}
              </a-col>
              <a-col :span="8">
                {{ user.email }}
              </a-col>
            </a-row>
          </a-col>
          <AddSuperUser  v-if="isEditSuperUser" style="margin-top: 5rem" @cancelEditSuperUser="isEditSuperUser = false" @updateSuperUser="getSuperUser"/>

        </a-row>
       
      </div>
      <log-out-pop-up :is-show="isShowLogOut" button-text="Cancel" @closeRemove="colseLoginPopup"
        @removeMemberData="logOutDevice" />
      <all-log-out-pop-up :is-show="isShowAllLogOut" :is-admin="true" :is-all-device="isAllDevice" button-text="Cancel"
        @closeRemove="colseAllLogOutPopup" />
      <confirm-pop-up :is-show="loadingFlag" :loading="true" header-text="Loading" contain-text="Hang tight..." />
      <error-pop-up-for-duplicate :is-show="isDataDuplicate" @closePopup="closePopup" />
      <error-pop-up-for-missing :is-show="isDataMissing" @closePopup="closePopupMissing" />
    </div>
    <time-out-pop-up :is-show="isTimeOut" @closePopup="logInTimeOutPopup" />
  </div>
</template>
<script>
import AdminTraits from "./AdminTraits.vue";
import ConfirmPopUp from "@/components/ConfirmPopUp.vue";
import services from "../../services/services";
import ErrorPopUpForDuplicate from "@/components/ErrorPopUpForDuplicate.vue";
import ErrorPopUpForMissing from "@/components/ErrorPopUpForMissing.vue";
import LogOutPopUp from "@/components/LogOutPopUp.vue";
import AllLogOutPopUp from "@/components/AllLogOutPopUp.vue";
import moment from "moment";
import JsonExcel from "vue-json-excel";
import TimeOutPopUp from "@/components/TimeOutPopUp.vue";
import AddSuperUser from "@/components/admin/AddSuperUser.vue";

import {UserRole} from '@/constants/user'

export default {
  name: "AdminConsole",
  components: {
    ConfirmPopUp,
    AdminTraits,
    ErrorPopUpForDuplicate,
    ErrorPopUpForMissing,
    TimeOutPopUp,
    LogOutPopUp,
    AllLogOutPopUp,
    AddSuperUser
  },
  data() {
    return {
      loadingFlag: false,
      isTimeOut: false,
      secret: "123#$%",
      moment,
      isDataMissing: false,
      isDataDuplicate: false,
      isExportBtn: false,
      services,
      showError: false,
      addedList: [],
      removeList: [],
      editTraitsAndRolesFlag: true,
      industryValidate: false,
      isExportClick: false,
      isEditCompetencies: false,
      isEditCompetenciesResponse: false,
      isEditIndustry: false,
      generalCompetancyLabels: [],
      firstName: "",
      lastName: "",
      firstLetter: "",
      formatedStartDate: "",
      formatedEndDate: "",
      startDateError: false,
      endDateError: false,
      dateError: false,
      isShowLogOut: false,
      isShowAllLogOut: false,
      isAllDevice: false,
      isExportDis: false,
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      focusData: {
        criteria: false,
        description_c: false,
        hash_index: false,
        criteriaLabel: false,
        industry: false,
      },
      formData: {
        competencyName: "",
        description: "",
        hash_index: "",
        criteriaLabel: "",
        industry: "",
      },
      exportJson: [],
      exportJsonFinal: [],
      competenciesList: [],
      competenciesReponseList: [],
      orignalCompetenciesReponseList: [],
      industryList: [],
      isEditSuperUser: false,
      superUsers:[]
    };
  },
  created() {
    if (localStorage.getItem("admin-token")) {
      let token = localStorage.getItem("admin-token");
      const decryptedText = this.$CryptoJS.AES.decrypt(
        token,
        this.secret
      ).toString(this.CryptoJS.enc.Utf8);
      this.$store.state.adminToken = decryptedText;
      let userDetailsData = localStorage.getItem("userAdminDetails");

      this.userDetails = JSON.parse(userDetailsData);
      this.userId = this.userDetails.userId
        ? this.userDetails.userId
        : this.userId;

      this.emailId = this.userDetails.email
        ? this.userDetails.email
        : this.emailId;
      this.firstName = this.userDetails.firstName
        ? this.userDetails.firstName
        : this.firstName;
      this.firstName =
        this.firstName[0].toUpperCase() + this.firstName.slice(1);
      this.lastName = this.userDetails.lastName
        ? this.userDetails.lastName
        : this.lastName;
      this.lastName = this.lastName[0].toUpperCase() + this.lastName.slice(1);
      let firstChar = this.userDetails.firstName
        ? this.userDetails.firstName
        : this.firstName;
      this.firstLetter = firstChar.substring(0, 1).toUpperCase();
      this.getAllIndustries();
      this.getGeneralCompetancyLabels();
      this.getGeneralCompetancyResponseLabels();
      this.getSuperUser()
    } else {
      this.$router.push("/admin-login");
    }
  },
  methods: {
    logInTimeOutPopup(val) {
      this.isTimeOut = val;
      this.$router.push("/admin-login");
    },
    closePopup(val) {
      this.isDataDuplicate = val;
    },
    closePopupMissing(val) {
      this.isDataMissing = val;
    },
    getGeneralCompetancyLabels() {
      this.services
        .get(`survey/listGeneralCompetancyLabels?surveyId=0`)
        .then((res) => {
          this.competenciesList = res.data.body;
        })
        .catch((err) => {
          //this.errorPopupFlag = true//;
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
        });
    },
    getGeneralCompetancyResponseLabels() {
      this.services
        .get("survey/listGeneralCompetancyResponseLabels?surveyId=0")
        .then((res) => {
          this.competenciesReponseList = res.data.body;
          this.orignalCompetenciesReponseList = JSON.parse(
            JSON.stringify(this.competenciesReponseList)
          );
        })
        .catch((err) => {
          //this.errorPopupFlag = true//;
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
        });
    },
    getAllIndustries() {
      this.industryList = [];
      this.$store.state.adminConsole.industryList = [];
      this.services
        .get(`user/getAllIndustries`)
        .then((res) => {
          let reqArr = [];
          reqArr = res.data.body;
          reqArr.forEach((element) => {
            this.industryList.push({
              id: element.id,
              industryName: element.industryName,
              selectedIndustryFlag: true,
            });
          });
          this.industryList.forEach((item, index) => {
            if (item.industryName == "Other") {
              let fromIndex = index;
              Array.prototype.move = function (from, to) {
                this.splice(to, 0, this.splice(from, 1)[0]);
                return this;
              };
              this.industryList.move(fromIndex, this.industryList.length - 1);
            }
          });
          this.$store.state.adminConsole.industryList = this.industryList;
          this.loadingFlag = false;
        })
        .catch((err) => {
          this.loadingFlag = false;
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
        });
    },
    editTraitsAndRoles() {
      this.editTraitsAndRolesFlag = false;
    },
    selectedIndustry(record, index) {
      record.selectedIndustryFlag = true;
      this.industryList.splice(index, 1, record);
      this.industryList.forEach((item, index) => {
        if (item.industryName == "Other") {
          let fromIndex = index;
          Array.prototype.move = function (from, to) {
            this.splice(to, 0, this.splice(from, 1)[0]);
            return this;
          };
          this.industryList.move(fromIndex, this.industryList.length - 1);
        }
      });
    },
    deleteSelectedIndustry(record, index) {
      record.selectedIndustryFlag = false;
      this.industryList.splice(index, 1, record);
      this.industryList.forEach((item, index) => {
        if (item.industryName == "Other") {
          let fromIndex = index;
          Array.prototype.move = function (from, to) {
            this.splice(to, 0, this.splice(from, 1)[0]);
            return this;
          };
          this.industryList.move(fromIndex, this.industryList.length - 1);
        }
      });
    },
    setFocus(val, key) {
      this.focusData[key] = val;
    },
    editCompetencies() {
      setTimeout(() => {
        this.getGeneralCompetancyLabels();
      }, 500);
      this.isEditCompetencies = true;
    },
    updateCompetencies() {
      //this.updatedCopentenciesList = this.competenciesList;
      this.createCompentencies();
      this.removeCompentencies();

      this.isEditCompetencies = false;
    },
    createCompentencies() {
      let reqBody = {},
        selectedLength = false,
        customObj;
      let listOfGeneralCompLabel = [];
      if (this.competenciesList.length > 0) {
        this.competenciesList.forEach((item, index) => {
          if (item.addedFlag) {
            selectedLength = true;
            customObj = {
              competancyId: 0,
              competancy: item.competancy,
              description: item.description,
              userId: this.userId,
            };
            listOfGeneralCompLabel.push(customObj);
            item.addedFlag = false;
          }
          if (item.selectedFlag) {
            selectedLength = true;
            customObj = {
              competancyId: item.id,
              competancy: item.competancy,
              description: item.description,
              userId: this.userId,
            };
            listOfGeneralCompLabel.push(customObj);
            item.selectedFlag = false;
          }
        });
        reqBody.listOfGeneralCompLabel = listOfGeneralCompLabel;
        if (selectedLength) {
          this.services
            .post(`admin/createGeneralCompLabel`, reqBody)
            .then((res) => {
              let result = res.body;
              result.forEach(item, (index) => {
                this.competenciesList.forEach(record, (i) => {
                  if (
                    record.addedFlag &&
                    record.competancy.toLowerCase().trim() ==
                    item.competancy.toLowerCase().trim()
                  ) {
                    record.id = item.id;
                  }
                });
              });
            })
            .catch((err) => {
              if (err.response.status == 401) {
                this.sessionTimeOut();
              }
            });
        }
      }
    },
    removeCompentencies() {
      let reqBody = {},
        deletedLength = false,
        customObj;
      let listOfGeneralCompLabel = [];
      if (this.competenciesList.length > 0) {
        this.competenciesList.forEach((item, index) => {
          if (item.deletedFlag) {
            deletedLength = true;
            customObj = {
              competancyId: item.id,
              userId: this.userId,
            };

            listOfGeneralCompLabel.push(customObj);
            reqBody.listOfGeneralCompLabel = listOfGeneralCompLabel;
          }
        });
        if (deletedLength) {
          this.services
            .post(`admin/deleteGeneralCompLabel`, reqBody)
            .then((res) => { })
            .catch((err) => {
              if (err.response.status == 401) {
                this.sessionTimeOut();
              }
            });
        }
      }
    },

    createResponseCompentencies() {
      let reqBody = {},
        selectedLength = false,
        tempTable = [],
        customObj;
      let listOfGeneralCompResLabel = [];
      if (this.competenciesReponseList.length > 0) {
        this.competenciesReponseList.forEach((item, index) => {
          if (!item.deletedFlag) {
            tempTable.push(item);
          }
        });
        tempTable.forEach((item, index) => {
          if (item) {
            selectedLength = true;
            customObj = {
              competancyId: item.id ? item.id : 0,
              ratingNo: index,
              description: item.description,
              userId: this.userId,
            };
            listOfGeneralCompResLabel.push(customObj);
            item.addedFlag = false;
          }
        });
        reqBody.listOfGeneralCompResLabel = listOfGeneralCompResLabel;

        if (selectedLength) {
          this.services
            .post(`admin/createGeneralCompRespLabel`, reqBody)
            .then((res) => {
              let result = res.data.body;
              result.forEach((item, index) => {
                this.competenciesReponseList.forEach((record, i) => {
                  if (
                    record.addedFlag &&
                    record.description.toLowerCase().trim() ==
                    item.description.toLowerCase().trim()
                  ) {
                    record.id = item.id;
                  }
                });
              });
            })
            .catch((err) => {
              if (err.response.status == 401) {
                this.sessionTimeOut();
              }
            });
        }
      }
    },
    removeResponseCompentencies() {
      let reqBody = {},
        deletedLength = false,
        customObj;
      let listOfGeneralCompResLabel = [];
      if (this.competenciesReponseList.length > 0) {
        this.competenciesReponseList.forEach((item, index) => {
          if (item.deletedFlag) {
            deletedLength = true;
            customObj = {
              competancyId: item.id,
              userId: this.userId,
            };

            listOfGeneralCompResLabel.push(customObj);
            reqBody.listOfGeneralCompResLabel = listOfGeneralCompResLabel;
          }
        });
        if (deletedLength) {
          this.services
            .post(`admin/deleteGeneralCompRespLabel`, reqBody)
            .then((res) => { })
            .catch((err) => {
              if (err.response.status == 401) {
                this.sessionTimeOut();
              }
            });
        }
      }
    },
    cancelCompetencies() {
      this.formData.competencyName = "";
      this.formData.description = "";
      this.getGeneralCompetancyLabels();
      this.isEditCompetencies = false;
    },
    editCompetenciesResponse() {
      this.showError = false;
      setTimeout(() => {
        this.getGeneralCompetancyResponseLabels();
      }, 500);
      this.isEditCompetenciesResponse = true;
    },
    updateCompetenciesResponse() {
      this.createResponseCompentencies();
      this.removeResponseCompentencies();

      this.isEditCompetenciesResponse = false;
    },
    cancelCompetenciesResponse() {
      this.showError = false;
      this.formData.hash_index = "";
      this.formData.criteriaLabel = "";
      this.getGeneralCompetancyResponseLabels();
      this.isEditCompetenciesResponse = false;
    },
    onChangeCompetency(val, index, key, record) {
      if (val) {
        if (key == "competancy") {
          record.previousData = record[key];
        }

        record.selectedFlag = true;
        record[key] = val;
        this.competenciesList.splice(index, 1, record);
      } else {
        this.isDataMissing = true;
      }
    },

    onChangeCompetencyResponse(val, index, key, record) {
      record.previousData = record[key];
      record[key] = val;
      this.showError = false;
      record.selectedFlag = true;
      if (key == "index_id") {
        this.competenciesReponseList[index].index_id = val;
      } else if (key == "criteriaLabel") {
        this.competenciesReponseList[index].description = val;
        this.competenciesReponseList[index].selectedFlag = true;
        this.orignalCompetenciesReponseList[index].description = val;
      }
    },
    checkExistCompentency(val, key, record, i) {
      if (val) {
        record.previousData = record[key];
        record[key] = val;
        record.selectedFlag = true;

        let isdataDuplicateFlag = false,
          apiCall = true;
        this.competenciesList.forEach((item, index) => {
          if (
            item.competancy.toLowerCase().trim() == val.toLowerCase().trim() &&
            i != index
          ) {
            isdataDuplicateFlag = true;
          } else if (
            item.competancy.toLowerCase().trim() == val.toLowerCase().trim() &&
            i == index
          ) {
            apiCall = false;
          }
        });
        if (!isdataDuplicateFlag) {
          if (apiCall) {
            this.isGeneralLabelExist(val);
          }
        } else {
          this.isDataDuplicate = true;
          record[key] = record.previousData;
        }
      } else {
        this.isDataMissing = true;
      }
    },
    checkExistCompentencyResponse(val, key, record, i) {
      if (val) {
        record.previousData = record[key];
        this.competenciesReponseList.forEach((item, index) => {
          if (item.description == record.description) {
            item.selectedFlag = true;
            item.description = val;
            // this.competenciesReponseList.splice(index, 1, item);
          }
        });

        record[key] = val;
        record.selectedFlag = true;
        let isdataDuplicateFlag = false,
          apiCall = true;
        this.orignalCompetenciesReponseList.forEach((item, index) => {
          if (
            item.description.toLowerCase().trim() == val.toLowerCase().trim() &&
            i != index
          ) {
            isdataDuplicateFlag = true;
          } else if (
            item.description.toLowerCase().trim() == val.toLowerCase().trim() &&
            i == index
          ) {
            apiCall = false;
          }
        });
        if (!isdataDuplicateFlag) {
          if (apiCall) {
            this.isGeneralResponseExist(val);
          }
        } else {
          this.isDataDuplicate = true;
          record[key] = record.previousData;
        }
      } else {
        this.isDataMissing = true;
      }
    },
    isGeneralLabelExist(val) {
      this.services
        .post("admin/isGeneralCompLabelExist", { competancy: val })
        .then((res) => { })
        .catch((err) => {
          if (err.response.status == 302) {
            this.isDataDuplicate = true;
          }
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
        });
    },
    isGeneralResponseExist(val) {
      this.services
        .post("admin/isGeneralCompRespLabelExist", { competancy: val })
        .then((res) => { })
        .catch((err) => {
          if (err.response.status == 302) {
            this.isDataDuplicate = true;
          }
          if (err.response.status == 401) {
            this.sessionTimeOut();
          }
        });
    },
    onChangeIndustry(val, key) {
      this.industryValidate = false;
      this.form.setFieldsValue({
        [key]: val,
      });
      this.formData[key] = val;
    },
    addIndustry() {
      let industry = this.formData.industry;
      let postData = {
        industryName: industry,
      };
      if (industry) {
        this.services
          .post("admin/isIndustryExist", postData)
          .then((res) => {
            if (res.status == 200) {
              this.industryList.forEach((element) => {
                if (element.industryName == industry) {
                  this.industryValidate = true;
                }
              });

              if (!this.industryValidate) {
                this.industryList.push({
                  industryName: industry,
                  selectedIndustryFlag: true,
                  addedIndustryFlag: true,
                });
                this.form.setFieldsValue(
                  (this.formData = {
                    industry: "",
                  })
                );
                this.industryList.forEach((item, index) => {
                  if (item.industryName == "Other") {
                    let fromIndex = index;
                    Array.prototype.move = function (from, to) {
                      this.splice(to, 0, this.splice(from, 1)[0]);
                      return this;
                    };
                    this.industryList.move(
                      fromIndex,
                      this.industryList.length - 1
                    );
                  }
                });
              }
            }
            this.loadingFlag = false;
          })
          .catch((err) => {
            if (err.response.status == 302) {
              this.isDataDuplicate = true;
            }
            if (err.response.status == 401) {
              this.sessionTimeOut();
            }
            this.loadingFlag = false;
          });
      } else {
        this.isDataMissing = true;
      }
    },
    addIndustryUpdate() {
      this.addedList = [];

      for (let i = 0; i < this.industryList.length; i++) {
        if (
          this.industryList[i].selectedIndustryFlag &&
          this.industryList[i].addedIndustryFlag
        ) {
          // let addedList = [];
          this.addedList.push({
            industryName: this.industryList[i].industryName,
            userId: this.userId,
          });
        }
      }

      if (this.addedList.length > 0) {
        let postData = {
          listOfIndustries: this.addedList,
        };
        this.services
          .post("admin/createIndustry", postData)
          .then((res) => {
            if (res.status == 200) {
            }
            this.loadingFlag = false;
          })
          .catch((err) => {
            if (err.response.status == 400) {
              // this.traitValidate = true;
            }
            if (err.response.status == 401) {
              this.sessionTimeOut();
            }
            this.loadingFlag = false;
          });
      }
    },
    removeIndustryUpdate() {
      this.removeList = [];

      for (let i = 0; i < this.industryList.length; i++) {
        if (
          !this.industryList[i].selectedIndustryFlag ||
          (!this.industryList[i].selectedIndustryFlag && this.addedIndustryFlag)
        ) {
          this.removeList.push({
            industryId: this.industryList[i].id,
            userId: this.userId,
          });
        }
      }

      if (this.removeList.length > 0) {
        let postData = {
          listOfIndustries: this.removeList,
        };
        this.services
          .post(`admin/deleteIndustry`, postData)
          .then((res) => {
            if (res.status == 200) {
            }
            this.loadingFlag = false;
          })
          .catch((err) => {
            if (err.response.status == 400) {
              // this.traitValidate = true;
            }
            if (err.response.status == 401) {
              this.sessionTimeOut();
            }
            this.loadingFlag = false;
          });
      }
    },
    cancelIndustry() {
      this.getAllIndustries();
      this.isEditIndustry = false;
    },
    editIndustry() {
      this.form.setFieldsValue(
        (this.formData = {
          industry: "",
        })
      );
      this.isEditIndustry = true;
    },
    updateIndustry() {
      setTimeout(() => {
        this.addIndustryUpdate();
        setTimeout(() => {
          this.removeIndustryUpdate();
          setTimeout(() => {
            this.getAllIndustries();
          }, 1000);
        }, 100);
      }, 100);
      this.isEditIndustry = false;
    },
    deleteCriteriaRecord(i, record) {
      record.deletedFlag = true;
      this.competenciesList.splice(i, 1, record);
    },
    deleteCriteriaRecordResponse(i, record) {
      this.competenciesReponseList.forEach((item, index) => {
        if (
          record.description.toLowerCase() == item.description.toLowerCase()
        ) {
          item.deletedFlag = true;
          this.competenciesReponseList.splice(index, 1, item);
        }
      });

      this.showError = false;

      this.orignalCompetenciesReponseList.splice(i, 1);
    },
    addGeneralCriteriaReponse() {
      this.showError = false;
      let isDataDuplicateflag = false;
      let hash_index = this.formData.hash_index;
      let criteriaLabel = this.formData.criteriaLabel;
      if (criteriaLabel && this.orignalCompetenciesReponseList.length <= 5) {
        this.orignalCompetenciesReponseList.forEach((item, index) => {
          if (
            criteriaLabel.toLowerCase().trim() ==
            item.description.toLowerCase().trim()
          ) {
            isDataDuplicateflag = true;
          }
        });
        if (!isDataDuplicateflag) {
          this.orignalCompetenciesReponseList.push({
            index_id: parseInt(hash_index),
            description: criteriaLabel,
            addedFlag: true,
          });
          this.competenciesReponseList.push({
            index_id: parseInt(hash_index),
            description: criteriaLabel,
            addedFlag: true,
          });
          this.form.setFieldsValue(
            (this.formData = {
              hash_index: "",
              criteriaLabel: "",
            })
          );
        } else {
          this.isDataDuplicate = true;
        }
      } else if (this.orignalCompetenciesReponseList.length > 5) {
        this.showError = true;
      } else {
        this.isDataMissing = true;
      }
    },
    addGeneralCriteria() {
      let isDataDuplicateFlag = false;
      let competencyName = this.formData.competencyName;
      let description = this.formData.description;
      if (competencyName && description) {
        this.competenciesList.forEach((item, index) => {
          if (
            item.competancy.toLowerCase().trim() ==
            competencyName.toLowerCase().trim()
          ) {
            isDataDuplicateFlag = true;
          }
        });
        if (!isDataDuplicateFlag) {
          this.competenciesList.push({
            competancy: competencyName,
            description: description,
            addedFlag: true,
          });
          this.form.setFieldsValue(
            (this.formData = {
              competencyName: "",
              description: "",
            })
          );
        } else {
          this.isDataDuplicate = true;
        }
      } else {
        this.isDataMissing = true;
      }
    },
    disabledDate(current) {
      let currentDate = new Date().toLocaleDateString();

      let selectedDate = this.moment(this.formatedStartDate.trim()).format(
        "MM/DD/YYYY"
      );

      const dateOne = new Date(currentDate);
      const dateTwo = new Date(selectedDate);
      let diff = dateOne.getTime() - dateTwo.getTime();
      diff = diff / (1000 * 60 * 60 * 24);
      return current && current.isBefore(moment().subtract(diff + 1, "days"));
    },
    onChangeCriteria(val, key) {
      this.form.setFieldsValue({
        [key]: val,
      });
      this.formData[key] = val;
    },
    onChangeStartDate(date, dateString) {
      this.startDateError = false;
      this.isExportBtn = false;
      this.isExportClick = false;
      if (dateString) {
        this.formatedStartDate = this.moment(dateString.trim()).format(
          "YYYY-MM-DD"
        );
        if (this.formatedStartDate && this.formatedEndDate) {
          this.exportdata();
        }
      } else {
        this.formatedStartDate = "";
      }
    },
    onChangeEndDate(date, dateString) {
      this.endDateError = false;
      this.isExportBtn = false;
      this.isExportClick = false;
      if (dateString) {
        this.formatedEndDate = this.moment(dateString.trim()).format(
          "YYYY-MM-DD"
        );
        if (this.formatedStartDate && this.formatedEndDate) {
          this.exportdata();
        }
      } else {
        this.formatedEndDate = "";
      }
    },
    mouseoverExportClick() {
      this.isExportClick = true;
    },
    async exportdata() {
      if (this.formatedStartDate && this.formatedEndDate) {
        let postDate = {
          startDate: this.formatedStartDate,
          endDate: this.formatedEndDate,
        };
        this.isExportDis = true;
        this.services
          .post(`admin/exportData`, postDate)
          .then((res) => {
            if (res.status == 200) {
              let rersData = res.data.body;
              this.exportJson = [];
              var finalExportData = [];
              //  this.exportJson = [];
              if (rersData.length) {
                this.isExportBtn = false;

                for (let index = 0; index < rersData.length; index++) {
                  var teamMember = rersData[index].teamMember;
                  var teamMemberFirstName = teamMember.firstName;
                  var teamMemberLastName = teamMember.lastName;
                  var teamMemberEmail = teamMember.email;
                  var teamMemberName =
                    teamMemberFirstName + " " + teamMemberLastName;
                  var teamMemberJobTitle = teamMember.job.jobTitle;
                  var surveyId = rersData[index].id;
                  var initiatedOn = moment(rersData[index].createdOn).format(
                    "yyyy-MM-DD"
                  );
                  var dueDate = moment(rersData[index].dueDate).format(
                    "yyyy-MM-DD"
                  );

                  var userInfo = rersData[index].user;
                  var userDetailsInfo = userInfo.userDetails[0];
                  var userName =
                    userDetailsInfo.firstName + " " + userDetailsInfo.lastName;
                  var UserIndustry = userDetailsInfo.industry.industryName;
                  var userOrg = userDetailsInfo.organisation;
                  var userEmail = userInfo.email;
                  var reviewerInfo = rersData[index].surveyReviewers;

                  var noOfReviwers = reviewerInfo.length;

                  for (
                    let reviewerIndex = 0;
                    reviewerIndex < reviewerInfo.length;
                    reviewerIndex++
                  ) {
                    var surveyResults =
                      reviewerInfo[reviewerIndex].surveyResults;
                    var ReviewerName =
                      reviewerInfo[reviewerIndex].reviewer.firstName +
                      " " +
                      reviewerInfo[reviewerIndex].reviewer.lastName;
                    var ReviewerEmail =
                      reviewerInfo[reviewerIndex].reviewer.email;
                    var ReviewerRelationship =
                      reviewerInfo[reviewerIndex].reviewer.reviewerRelationship
                        .Relationship;
                    for (
                      let surveyResultsIndex = 0;
                      surveyResultsIndex < surveyResults.length;
                      surveyResultsIndex++
                    ) {
                      var questionType =
                        surveyResults[surveyResultsIndex].questionsType;

                      switch (questionType.question) {
                        case "general":
                          var generalData = surveyResults[surveyResultsIndex]
                            .generalCompetancyLabel
                            ? surveyResults[surveyResultsIndex]
                              .generalCompetancyLabel
                            : "";
                          var competancy = generalData.competancy
                            ? generalData.competancy
                            : "";
                          var generalDataRes = surveyResults[surveyResultsIndex]
                            .generalCompetancyResponseLabel
                            ? surveyResults[surveyResultsIndex]
                              .generalCompetancyResponseLabel
                            : "";
                          var score = generalDataRes.ra
                            ? generalDataRes.ra
                            : "";
                          finalExportData.push({
                            SurveyID: surveyId,
                            ResponseID: 0,
                            InitiatedOn: initiatedOn,
                            DueDate: dueDate,
                            ResponseStart: "00:00:00",
                            ResponseComplete: "00:00:00",
                            SubjectName: teamMemberName,
                            SubjectEmail: teamMemberEmail,
                            SubjectOrganisation: "-",
                            SubjectIndustry: "-",
                            SubjectJobtitle: teamMemberJobTitle,
                            SubjectManager: userName,
                            InitiatorName: userName,
                            InitiatiorEmail: userEmail,
                            InitiatorOrganisation: userOrg,
                            InitiatorIndustry: UserIndustry,
                            ReviewerName: ReviewerName,
                            ReviewerEmail: ReviewerEmail,
                            ReviewerOrganisation: "-",
                            ReviewerIndustry: "-",
                            ReviewerRelationship: ReviewerRelationship,
                            NumberReviewersNominated: noOfReviwers,
                            DataPoint: questionType.question,
                            Data: competancy,
                            Score: score,
                          });
                          break;
                        case "needsWork":
                          var generalData = surveyResults[surveyResultsIndex]
                            .trait
                            ? surveyResults[surveyResultsIndex].trait
                            : "";
                          var TraitName = generalData.TraitName
                            ? generalData.TraitName
                            : "";
                          var generalDataRes = surveyResults[surveyResultsIndex]
                            .generalCompetancyResponseLabel
                            ? surveyResults[surveyResultsIndex]
                              .generalCompetancyResponseLabel
                            : "";
                          var score = generalDataRes.ra
                            ? generalDataRes.ra
                            : "";
                          finalExportData.push({
                            SurveyID: surveyId,
                            ResponseID: 0,
                            InitiatedOn: initiatedOn,
                            DueDate: dueDate,
                            ResponseStart: "00:00:00",
                            ResponseComplete: "00:00:00",
                            SubjectName: teamMemberName,
                            SubjectEmail: teamMemberEmail,
                            SubjectOrganisation: "-",
                            SubjectIndustry: "-",
                            SubjectJobtitle: teamMemberJobTitle,
                            SubjectManager: userName,
                            InitiatorName: userName,
                            InitiatiorEmail: userEmail,
                            InitiatorOrganisation: userOrg,
                            InitiatorIndustry: UserIndustry,
                            ReviewerName: ReviewerName,
                            ReviewerEmail: ReviewerEmail,
                            ReviewerOrganisation: "-",
                            ReviewerIndustry: "-",
                            ReviewerRelationship: ReviewerRelationship,
                            NumberReviewersNominated: noOfReviwers,
                            DataPoint: questionType.question,
                            Data: TraitName,
                            Score: score,
                          });
                          break;
                        case "doesWell":
                          var generalData = surveyResults[surveyResultsIndex]
                            .trait
                            ? surveyResults[surveyResultsIndex].trait
                            : "";
                          var TraitName = generalData.TraitName
                            ? generalData.TraitName
                            : "";
                          var generalDataRes = surveyResults[surveyResultsIndex]
                            .generalCompetancyResponseLabel
                            ? surveyResults[surveyResultsIndex]
                              .generalCompetancyResponseLabel
                            : "";
                          var score = generalDataRes.ra
                            ? generalDataRes.ra
                            : "";
                          finalExportData.push({
                            SurveyID: surveyId,
                            ResponseID: 0,
                            InitiatedOn: initiatedOn,
                            DueDate: dueDate,
                            ResponseStart: "00:00:00",
                            ResponseComplete: "00:00:00",
                            SubjectName: teamMemberName,
                            SubjectEmail: teamMemberEmail,
                            SubjectOrganisation: "-",
                            SubjectIndustry: "-",
                            SubjectJobtitle: teamMemberJobTitle,
                            SubjectManager: userName,
                            InitiatorName: userName,
                            InitiatiorEmail: userEmail,
                            InitiatorOrganisation: userOrg,
                            InitiatorIndustry: UserIndustry,
                            ReviewerName: ReviewerName,
                            ReviewerEmail: ReviewerEmail,
                            ReviewerOrganisation: "-",
                            ReviewerIndustry: "-",
                            ReviewerRelationship: ReviewerRelationship,
                            NumberReviewersNominated: noOfReviwers,
                            DataPoint: questionType.question,
                            Data: TraitName,
                            Score: score,
                          });
                          break;
                        case "likehood":
                          var generalData = surveyResults[surveyResultsIndex]
                            .likehoodCategory
                            ? surveyResults[surveyResultsIndex].likehoodCategory
                            : "";
                          var description = generalData.description
                            ? generalData.description
                            : "";
                          var generalDataRes = surveyResults[surveyResultsIndex]
                            .generalCompetancyResponseLabel
                            ? surveyResults[surveyResultsIndex]
                              .generalCompetancyResponseLabel
                            : "";
                          var score = generalDataRes.ra
                            ? generalDataRes.ra
                            : "";
                          finalExportData.push({
                            SurveyID: surveyId,
                            ResponseID: 0,
                            InitiatedOn: initiatedOn,
                            DueDate: dueDate,
                            ResponseStart: "00:00:00",
                            ResponseComplete: "00:00:00",
                            SubjectName: teamMemberName,
                            SubjectEmail: teamMemberEmail,
                            SubjectOrganisation: "-",
                            SubjectIndustry: "-",
                            SubjectJobtitle: teamMemberJobTitle,
                            SubjectManager: userName,
                            InitiatorName: userName,
                            InitiatiorEmail: userEmail,
                            InitiatorOrganisation: userOrg,
                            InitiatorIndustry: UserIndustry,
                            ReviewerName: ReviewerName,
                            ReviewerEmail: ReviewerEmail,
                            ReviewerOrganisation: "-",
                            ReviewerIndustry: "-",
                            ReviewerRelationship: ReviewerRelationship,
                            NumberReviewersNominated: noOfReviwers,
                            DataPoint: questionType.question,
                            Data: description,
                            Score: score,
                          });
                          break;
                        default:
                          break;
                      }
                    }

                    this.exportJson = finalExportData;
                    this.isExportDis = false;
                    this.loadingFlag = false;
                    // return this.exportJson;
                  }
                }
              } else {
                this.isExportDis = false;
                if (this.isExportClick) {
                  this.isExportBtn = true;
                }

                // this.isExportClick = false;
              }
            }
          })
          .catch((err) => {
            if (err.response.status == 400) {
              // this.traitValidate = true;
            }
            if (err.response.status == 401) {
              this.sessionTimeOut();
            }
            this.loadingFlag = false;
          });
      } else {
        this.isExportDis = false;
        if (!this.formatedStartDate && !this.formatedEndDate) {
          this.startDateError = true;
          this.endDateError = true;
          this.isExportDis = false;
        } else if (!this.formatedStartDate) {
          this.startDateError = true;
          this.isExportDis = false;
        } else if (!this.formatedEndDate) {
          this.endDateError = true;
          this.isExportDis = false;
        }
      }
    },
    sessionTimeOut() {
      localStorage.removeItem("admin-token");
      localStorage.removeItem("userAdminDetails");
      localStorage.removeItem("userDeviceName");
      if (!this.isTimeOut) {
        this.isTimeOut = true;
      }
    },
    goToProfile() {
      this.$router.push("/admin-profile");
    },
    // goToLandingPage() {

    //   this.$router.push("/admin-login");
    // },
    goToLandingPage() {
      this.isShowLogOut = true;
    },
    colseLoginPopup(val) {
      this.isShowLogOut = val;
    },
    colseAllLogOutPopup(val) {
      this.isShowAllLogOut = val;
      this.$router.push("/admin-login");
    },
    logOutDevice(val) {
      this.isShowLogOut = false;
      this.loadingFlag = true;
      let postData = {
        userID: this.userId,
        device: localStorage.getItem("userDeviceName"),
        logOutFromAllDevice: val,
      };
      this.services
        .post(`user/logOut`, postData)
        .then((res) => {
          if (res.status == 200) {
            localStorage.removeItem("token");
            localStorage.removeItem("admin-token");
            localStorage.removeItem("userAdminDetails");

            this.loadingFlag = false;
            this.isAllDevice = val;
            this.isShowAllLogOut = true;
            // this.$router.push("/");
          }
          // this.isStartNewSurveyPop = true;
        })
        .catch((err) => {
          this.loadingFlag = false;
          if (err.response.status == 303) {
            this.errorPopupFlag = true;
          }
        });
    },
    changedUpdatedFlag(val) {
      this.editTraitsAndRolesFlag = val;
    },
   
    async getSuperUser() {
      try {
        const res = await services.get("user/getAllUsers");
        if (res.status === 200) {
          const resData= res.data.body
          this.superUsers = resData.filter(user=>user.role === UserRole.HR)
          if(this.isEditSuperUser) this.isEditSuperUser = false
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.industUpdateBtn2 {
  display: none;
}

// .custom-scroll-traits-only {
//   overflow-x: auto;
// }
::v-deep {
  .float-label {
    margin-bottom: 0px !important;
  }

  .industries-scroll {
    .ant-col {
      display: block;
      box-sizing: border-box;
      width: auto !important;
    }
  }
}

.ant-list-sm .ant-list-item.active,
.pointer .value-txt.active {
  background-color: #6454f0 !important;
  color: #fff !important;

  .txt-css {
    font-family: "Inter", fantasy !important;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 200%;
    align-items: center;
    text-align: center;
    color: #fff !important;
  }
}

.round-list-grey-faint:hover {
  background: rgba(100, 84, 240, 0.55);
}

.round-list-grey-faint {
  justify-content: flex-end;
  align-items: center;
  padding: 8px 18px;
  white-space: nowrap !important;
  width: fit-content;
  height: 32px;
  background-color: #bfc3d6 !important;
  border-radius: 20px;
}

.grey-div-css {
  margin-top: 15px !important;
  background: #edeef5;
  border-radius: 5px;
}

.bg-css {
  background: #ffffff;
  filter: drop-shadow(10px 10px 30px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  padding: 43px 36px 43px 36px;

  @media screen and (max-width: 658px) {
    padding: 43px 6px 43px 6px;
  }
}

::v-deep {
  .ant-select-selection {
    padding-top: 7px !important;
  }

  .float-label .ant-input {
    padding-top: 14px !important;
  }

  .label-float,
  .ant-form-item .label-float {
    top: -6px !important;
    font-size: 10px !important;
    color: #ffff !important;
  }

  .label,
  .ant-form-item label {
    color: #ffff !important;
    left: 25px !important;
    top: 0px !important;
  }
}

button.confirm-grey-btn.ant-btn.ant-btn-primary {
  width: 113px !important;
  height: 36px !important;
  font-size: 1rem !important;
  color: #ffff;
  background-color: #797994 !important;
}

button.confirm-grey-btn.ant-btn.ant-btn-primary:hover {
  background-color: #4b4b5a !important;
}

::v-deep {
  .ant-btn.ant-btn-primary {
    font-size: 1rem !important;
    width: 113px !important;
    height: 36px !important;
  }
}

.plus-img-css {
  font-size: 23px;
  color: #6454f0;
  border-radius: 100px;
  cursor: pointer;
  margin-top: 10px;
}

.plus-img-css:hover {
  color: #4a3cc0;
}

.bold-label-css {
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 200%;
  color: #262626;
}

.com-div-scoll {
  height: 270px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.industries-scroll {
  height: 350px;
  overflow-y: auto !important;
  padding: 5px;
  overflow-x: hidden !important;
}

.comR-div-scoll {
  height: 315px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.minus-img-css {
  position: absolute !important;
  left: -86% !important;
  top: 8px !important;
  font-size: 22px !important;
  color: #6454f0 !important;
  background: linear-gradient(to left, white 50%, white 50%) !important;
  border-radius: 50% !important;
}

.minus-img-css:hover,
.minus-img-css:focus {
  color: #493bc8 !important;
  border-color: #493bc8 !important;
  cursor: pointer !important;
}

.minusCR-img-css {
  position: absolute !important;
  right: 1% !important;
  top: 20% !important;
  font-size: 22px !important;
  color: #6454f0 !important;
  background: linear-gradient(to left, white 50%, white 50%) !important;
  border-radius: 50% !important;
}

.minusCR-img-css:hover,
.minusCR-img-css:focus {
  color: #493bc8 !important;
  border-color: #493bc8 !important;
  cursor: pointer !important;
}

.grey-input-field-css {
  border: 1px solid #bfc3d6;
  background: #bfc3d6;
  box-sizing: border-box;
  border-radius: 5px;
  height: 41px !important;
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 17px;
  color: #ffffff !important;
}

.input-field-css {
  // background: #ffffff;
  border: 1px solid #bfc3d6;
  box-sizing: border-box;
  border-radius: 5px;
  height: 41px !important;
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 17px;
  color: #363644;
}

.label-css {
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #363644;
}

.edit-icon-css {
  padding-top: 10px;
  font-size: 17px;
}

.edit-icon-css:hover {
  color: #6454f0;
  cursor: pointer;
  font-size: 18px;
}

.edit-css {
  padding-right: 0px;
  text-align: end;
}

.header-title-css {
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #363644;
}

.left-div-css {
  //   width: 100%;
  // height: 550px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 26px 32px 26px 32px;
}

.right-div-css {
  //   width: 100%;
  // height: 550px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 26px 32px 26px 32px;
}

.grey-bg-color {
  overflow-x: hidden;
  position: relative;
  background: #ffff !important;
  widows: 100%;
}

.header-title-txt {
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #262626;
}

.date-div {
  background: #ffffff;
  border: 1px solid #bfc3d6;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 6.0625rem;
}

.date-txt {
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.ant-calendar-picker-input.ant-input {
  line-height: 1.5 !important;
  background-color: #edeef5 !important;
}

.ant-calendar-picker-icon {
  /* display: flex; */
  color: #6454f0 !important;
  font-size: 14px;
  line-height: 1;
}

::v-deep {
  .ant-calendar-selected-day .ant-calendar-date {
    background: #6454f0 !important;
  }

  .ant-calendar-date {
    display: block !important;
    width: 24px !important;
    height: 24px !important;
    margin: 0 auto !important;
    padding: 0 !important;
    color: rgba(0, 0, 0, 0.65) !important;
    line-height: 22px !important;
    text-align: center !important;
    background: transparent !important;
    border: 1px solid transparent !important;
    border-radius: 2px !important;
  }

  .date-div {

    .ant-calendar-picker-input,
    .ant-input {
      box-sizing: border-box !important;
      margin: 0 !important;
      font-variant: tabular-nums !important;
      list-style: none !important;
      font-feature-settings: "tnum" !important;
      position: relative !important;
      display: inline-block !important;
      width: 11.3125rem !important;
      height: 4rem !important;
      padding: 4px 11px 4px 45px !important;
      color: rgba(0, 0, 0, 0.65) !important;
      font-size: 16px !important;
      line-height: 1.5 !important;
      background-color: #fff !important;
      background-image: none !important;
      border: 1px solid #edeef5 !important;
      border-radius: 4px !important;
      transition: all 0.3s !important;
    }
  }

  .has-error {

    .ant-calendar-picker-input,
    .ant-input {
      border-color: #f5222d !important;
    }
  }

  .ant-calendar-picker-input.ant-input {
    line-height: 1.5 !important;
    background-color: #edeef5 !important;
  }

  .ant-calendar-picker-icon {
    /* display: flex; */
    color: #6454f0 !important;
    font-size: 14px;
    line-height: 1;
    padding-left: 10px !important;
  }

  .ant-calendar-picker-clear {
    display: none !important;
    position: absolute !important;
    top: 50% !important;
    left: 12px !important;
    z-index: 1 !important;
    width: 14px !important;
    height: 14px !important;
    margin-top: -7px !important;
    font-size: 16px !important;
    line-height: 14px !important;
    transition: all 0.3s !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }

  .ant-calendar-picker-clear,
  .ant-calendar-picker-icon {
    position: absolute !important;
    top: 50% !important;
    left: 12px !important;
    z-index: 1 !important;
    width: 14px !important;
    height: 14px !important;
    margin-top: -7px !important;
    font-size: 16px !important;
    line-height: 14px !important;
    transition: all 0.3s !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }

  @media screen and (max-width: 590px) {
    .mr-top-res {
      margin-top: 30px !important;
    }

    .user-name-sec {
      margin-left: -8.4% !important;
      margin-right: 2.6% !important;
    }
  }

  .edit-icon {
    transition: 0.3s ease all;

    &:hover {
      img {
        transform: scale(1.3);
        transition: 0.3s ease all;
      }
    }
  }

  .ant-calendar-prev-year-btn,
  .ant-calendar-prev-month-btn {
    pointer-events: none !important;
  }

  .create-survey-button {
    width: 70%;
    font-size: 14px !important;
    position: absolute;
    top: 5%;
    left: 5%;
  }

  .error-txt {
    font-size: 14px !important;
  }

  .anticon-check {
    padding-left: 5px;

    svg {
      height: 15px !important;
      stroke: 10px #cccc;
    }
  }
}

.listUser {
  min-height: 100px;
  max-height: 210px;
  margin-top: 1rem;
  overflow-y: auto;
}

// @media screen and (max-width: 658px) {
//   .grey-bg-color {
//     overflow-x: auto;
//   }
// }
</style>