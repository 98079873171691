<template>
  <div class="bg-color">
    <div class="center-signIn-div white-bg-css">
      <a-row :gutter="24" class="center">
        <a-col :span="24" class="wlcm">
          <div class="wlcm">
            Welcome to the<br />
            Gestaltra Admin Console
          </div>
        </a-col>
      </a-row>
      <a-form
        :form="form"
        @submit="handleSubmit"
        class="mt-25"
        style="padding: 0% 8% !important"
      >
        <a-form-item
          class="float-label"
          :class="dataValidate ? 'has-error' : null"
        >
          <label
            :class="
              focusData.focusDataEmail || formData.email
                ? 'label label-float'
                : 'label'
            "
            >Email</label
          >
          <a-input
            @blur="setFocus(false, 'focusDataEmail')"
            @focus="setFocus(true, 'focusDataEmail')"
            v-decorator="[
              'email',
              {
                rules: [
                  {
                    type: 'email',
                    message: 'Please input valid email!',
                  },
                  {
                    required: true,
                    message:
                      'Email can’t be blank - please enter a valid email address',
                  },
                ],
                validateTrigger: 'blur',
              },
            ]"
            :value="formData.email"
            @change="onChange($event.target.value, 'email')"
          />
        </a-form-item>
        <a-form-item
          class="float-label"
          :class="dataValidate ? 'has-error' : null"
        >
          <label
            :class="
              focusData.focusDataPassword || formData.password
                ? 'label label-float'
                : 'label'
            "
            >Password</label
          >
          <a-input-password
            @blur="setFocus(false, 'focusDataPassword')"
            @focus="setFocus(true, 'focusDataPassword')"
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message:
                      'Password can’t be blank - please enter a  password',
                  },
                ],
              },
            ]"
            :value="formData.password"
            @change="onChange($event.target.value, 'password')"
          />
          <div class="ant-form-explain" v-if="dataValidate">
            The email or password is incorrect
          </div>
        </a-form-item>
        <a-form-item class="pb-2 mt-25">
          <a-button type="primary" html-type="submit" class="arrow-cls">
            Log in<a-icon
              style="
                font-size: 25px !important;
                font-weight: bold !important;
                margin-top: -4px !important;
                border-bottom-style: none;
              "
              type="arrow-right"
            />
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <error-pop-up :is-show="errorPopupFlag" @closePopup="closedErrorPopup" />
  </div>
</template>
<script>
import CommonPopUp from "@/components/CommonPopUp.vue";
import services from "../../services/services.js";
import ErrorPopUp from "@/components/ErrorPopUp.vue";
export default {
  name: "AdminLogin",
  components: {
    CommonPopUp,
    ErrorPopUp,
  },
  data() {
    return {
      errorPopupFlag: false,
      services,
      secret: "123#$%",
      dataValidate: false,
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      formData: {
        email: "",
        password: "",
        rememberMe: false,
      },
      focusData: {
        focusDataEmail: false,
        focusDataPassword: false,
      },
    };
  },
  created() {
    if (localStorage.getItem("admin-token")) {
      this.$router.push("/admin-main");
    }
  },
  methods: {
    closedErrorPopup(val) {
      this.errorPopupFlag = val;
    },
    setFocus(val, key) {
      this.focusData[key] = val;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loginClick();
          // console.log("Received values of form: ", values);
        }
      });
    },
    loginClick() {
      this.$store.state.signUpDataObject.email = this.formData.email;
      let data = {
        email: this.formData.email,
        password: this.formData.password,
      };
      this.dataValidate = false;
      if (data.email && data.password) {
        this.services
          .post("user/login", data)
          .then((res) => {
            if (res) {
              if (res.data.propUserDetails.isAdmin) {
                let userDetails = res.data.propUserDetails;
                this.$store.state.adminUserId = userDetails.userId;
                this.$store.state.userDetails = res.data.propUserDetails;

                localStorage.setItem(
                  "userAdminDetails",
                  JSON.stringify(res.data.propUserDetails)
                );
                let token = res.data.token;
                token = this.$CryptoJS.AES.encrypt(
                  token,
                  this.secret
                ).toString();

                localStorage.setItem("admin-token", token);
                const decryptedText = this.$CryptoJS.AES.decrypt(
                  token,
                  this.secret
                ).toString(this.CryptoJS.enc.Utf8);
                this.$store.state.adminToken = decryptedText;
                this.$router.push("/admin-main");
              } else {
                this.errorPopupFlag = true;
              }
            }
          })
          .catch((err) => {
            if (err.response.status == 400) {
              this.dataValidate = true;
            } else if (err.response.status == 301) {
              this.$store.state.adminUserId = err.response.data.userId;
              this.$store.state.signUpFlag = true;
              this.verificationeEmail();
            } else {
              //this.errorPopupFlag = true//;
            }
          });
      }
    },
    verificationeEmail() {
      let sendTo = this.$store.state.signUpDataObject.email;
      let data = {
        toEmail: sendTo,
      };
      if (data) {
        this.services
          .post("user/sendVerificationEmail", data)
          .then((res) => {
            if (res) {
              this.$store.state.signUpFlag = true;
              this.$router.push("/verify");
            }
          })
          .catch((err) => {
            //this.errorPopupFlag = true//;
          });
      }
    },
    onChange(val, key) {
      this.dataValidate = false;
      this.form.setFieldsValue({
        [key]: val,
      });
      this.formData[key] = val;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .anticon.ant-input-password-icon,
  .ant-select-arrow-icon {
    color: #363644 !important;
  }
  .ant-input,
  .ant-select-selection {
    font-size: 14px !important;
    height: 44px !important;
    background-color: #edeef5 !important;
    border: 1px solid #edeef5;
    padding: 0px 9px !important;
    padding-top: 10px !important;
  }
  .grey-txt-sm.ant-checkbox-wrapper {
    font-size: 14px !important;
  }
  .grey-txt-sm,
  .pointer {
    font-size: 18px !important;
  }
  .sm-txt {
    font-size: 18px;
  }

  .ant-btn-primary {
    height: 43px !important;
    font-size: 18px !important;
  }
  .arrow-cls span {
    padding-left: 9%;
  }
  .pointer {
    border-bottom: 1px solid #6454f0;
  }
  .bg-color {
    display: flex !important;
    justify-content: center !important;
  }
}
</style>