<template>
  <a-modal
    :visible="isShowData"
    :maskClosable="false"
    @cancel="handleOk"
    :centered="true"
    width="56%"
    class="carousel-res"
  >
    <div class="pl-pr">
      <a-row class="mt-2">
        <a-col
          style="padding-top: 5px; text-align: left; padding-left: 14px"
          :span="24"
        >
          <span class="bold-right-txt">The survey</span>
        </a-col>
      </a-row>
      <a-row class="mt-2">
        <a-col
          style="padding-top: 5px; text-align: left; padding-left: 14px"
          :span="24"
        >
          <span class="confirm-contain-txt" style="font-size: 16px !important "
            >The reviewer will be asked a series of questions, split up into
            four parts:</span
          >
        </a-col>
      </a-row>
      <div>
        <div class="carousel-wrapper">
          <a-carousel arrows v-bind:loop="true" v-bind:autoplaySpeed="4000">
            <div
              slot="prevArrow"
              slot-scope="props"
              class="custom-slick-arrow"
              style="left: 10px; z-index: 9"
            >
              <a-icon type="left-circle" />
            </div>
            <div
              slot="nextArrow"
              slot-scope="props"
              class="custom-slick-arrow"
              style="right: 10px"
            >
              <a-icon type="right-circle" />
            </div>

            <div>
              <div class="image-wrapper">
                <span><img alt="img1" src="../assets/1.png" /></span>
              </div>
              <div class="content-wrapper"></div>
            </div>
            <div>
              <div class="image-wrapper">
                <span><img alt="img2" src="../assets/2.png" /></span>
              </div>
            </div>
            <div>
              <div class="image-wrapper">
                <span><img alt="img3" src="../assets/3.png" /></span>
              </div>
            </div>
            <div>
              <div class="image-wrapper">
                <span><img alt="img4" src="../assets/4.png" /></span>
              </div>
            </div>
            <div>
              <div class="image-wrapper">
                <span><img alt="img5" src="../assets/5.png" /></span>
              </div>
            </div>
          </a-carousel>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
export default {
  name: "CarouselPopUp",
  data() {
    const isShowData = this.isShow;
    return {
      isShowData,
    };
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isShow(newVal) {
      this.isShowData = newVal;
    },
  },
  methods: {
    showModal() {
      this.visible = this.isShowData;
    },
    handleOk() {
      this.isShowData = false;
      this.$emit("closePopup", false);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .carousel-wrapper {
    position: relative;
    // width: 1000px;
    margin: 25px auto;
    overflow: hidden;
    text-align: center;
    .ant-carousel {
      width: 100%;
      overflow: hidden;
      padding: 0px 0px 5px 0px;
    }
    .image-wrapper {
      margin: 0px 13px !important;
      //  width: 95%;
      // margin: 0px 10px !important;
      background: #edeef5;
      border-radius: 10px;
      // padding-top: 55px;
    }
    img {
      margin: auto;
      width: 97%;
    }

    .ant-carousel .slick-dots li {
      margin-right: 20px;
      margin-bottom: 10px !important;
      margin-top: 10px !important;
      button {
        background: black !important;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
    }
    .ant-carousel .slick-dots li:last-child {
      margin-right: 0px !important;
    }
    .ant-carousel .slick-dots li.slick-active button {
      background: #666666 !important;
    }

    .ant-carousel .slick-slide h3 {
      color: #6454f0;
    }
    .ant-carousel .custom-slick-arrow {
      width: 30px;
      height: 30px;
      font-size: 30px;
      border-radius: 50%;
      color: #fff;
      background-color: #6454f0;
      opacity: 1;
    }
    .ant-carousel .custom-slick-arrow:before {
      display: none;
    }
    .ant-carousel .custom-slick-arrow:hover {
      background-color: #4a3cc0;
    }

    .ant-carousel >>> .slick-slide h3 {
      color: #6454f0;
    }
    .anticon-right-circle svg,
    .anticon-left-circle svg {
      width: 30px !important;
      height: 30px !important;
      path:last-child {
        display: none;
      }
    }
    .content-wrapper {
      text-align: center;
      margin-bottom: 15px;
      padding: 0px 45px;
      color: #363644;
      .heading-text {
        font-size: 1.625rem;
        font-weight: 700;
        margin-top: 20px;
      }
      .content-text {
        font-size: 1.25rem;
      }
    }
    .ant-carousel .slick-prev {
      left: 0px !important;
    }
    .ant-carousel .slick-next {
      right: 0px !important;
    }
    .ant-carousel .slick-prev,
    .ant-carousel .slick-next {
      top: 45% !important;
    }
  }
}
</style>