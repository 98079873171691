<template>
  <a-row v-if="loading" class="pb-2 center">
    <a-col :span="24">
      <img alt="loading" class="center" src="../assets/loading_2.gif" />
    </a-col>
  </a-row>
</template>
<script>
export default {
  name: "CommonLoader",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.center {
  width: 180px;
  height: 180px;
  margin: auto;
  text-align: center;
}
</style>
