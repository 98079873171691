<template>
  
  <div>
    <div class="landing">
      <TopBar />
      <Header />
      <Carousel />
      <GetStarted />
      <Purpose />
      <WholePicture />
      <FAQ />
      <Newsletter />
      <Footer />
    </div>

  </div>
</template>

<script>
import TopBar from './TopBar.vue'
import Header from './Header.vue'
import Carousel from './Carousel.vue'
import GetStarted from './GetStarted.vue'
import Purpose from './Purpose.vue'
import WholePicture from './WholePicture.vue'
import FAQ from './FAQ.vue'
import Newsletter from './Newsletter.vue'
import Footer from './Footer.vue'
import {WOW} from 'wowjs'

import { pageview } from "vue-gtag";

export default {
  name: 'LandingPage',
  data() {
    return {
      pageview
    }
  },
  created() {
    this.track();
  },
  mounted() { 
    console.log("Node Environment: ", process.env.NODE_ENV);
    new WOW().init();
  },
  components: {
    TopBar,
    Header,
    Carousel,
    GetStarted,
    Purpose,
    WholePicture,
    FAQ,
    Newsletter,
    Footer
  },

  methods: {
    track() {
      this.pageview("landingpage_main");
    },
  }
}
</script>

<style lang="scss">
  @import "../../styles/landing-page/main.scss";
</style>