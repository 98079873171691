<template>
  <div class="purpose wrapper">
    <div class="w-50 left wow fadeIn">
      <p class="subtitle">Our purpose</p>
      <h1>Making workplaces fair</h1>
      <img src="../../assets/holding.png" alt=""/>
    </div>
    <div class="w-50 right wow fadeInUp">
      <p>We think feedback is underrated and overcomplicated.</p>
      <p>In an age where collaboration is the norm, employees often spend more time working with cross-functional colleagues than their direct managers. But somehow, teammates and other stakeholders are rarely consulted when it comes time for reviewing performance. Why?</p>
      <p>360 surveys solved this problem some time ago. But they're often cumbersome to initiate, awkward to do, and expensive.</p>

      <p>Gestaltra removes many of the barriers preventing employees from providing their peers and managers alike with quality, actionable feedback.</p>

      <p>Nip problems in the bud. Allow quiet brilliance to shine through. Get the right insights, from the right people, at the right time.</p>
    </div>
  </div>
</template>


<script>
export default {
  name: 'Purpose'
}
</script>