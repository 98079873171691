<template>
  <div>
    <common-pop-up
      v-if="mainPopUp"
      :pop-email-box="true"
      pop-email-placeholder="Email"
      pop-email="You will receive an email with instructions to reset your password if an account exists for this email address."
      pop-heading="Forgot your password?"
      pop-button-text="Reset password"
      :custom-error-msg="inputBoxMsg"
      @click="onClick"
    />
    <common-pop-up
      class="send-mail-popup"
      v-if="confirmPassPopUp"
      pop-email="We’ve sent password recovery instructions to your email."
      pop-heading="Help is on the way!"
      pop-button-text="Back home"
      @click="backToHome"
    />
    <create-new-password v-if="updatePassScreen" />
    <error-pop-up :is-show="errorPopupFlag" @closePopup="closedErrorPopup" />
  </div>
</template>
<script>
import CommonPopUp from "@/components/CommonPopUp.vue";
import ErrorPopUp from "@/components/ErrorPopUp.vue";
import services from "../../../services/services.js";

import { pageview, event } from "vue-gtag";
export default {
  name: "ForgotPassword",
  components: {
    CommonPopUp,
    ErrorPopUp,
  },
  data() {
    return {
      pageview,
      event,
      errorPopupFlag: false,
      services,
      mainPopUp: true,
      otherPopUp: false,
      confirmPassPopUp: false,
      updatePassScreen: false,
      validationErrorMsg: false,
      inputBoxMsg: "",
    };
  },
  created() {
    this.url_id = this.$route.query.ID;
    this.url_iv = this.$route.query.iv;
    if (this.url_id) {
      this.$store.state.forgotFlag = true;
    }
  },

  methods: {
    closedErrorPopup(val) {
      this.errorPopupFlag = val;
    },
    backToHome() {
      this.$router.push("/");
    },
    onClick(val) {
      this.forgotPasswordSendEmail(val.email);
    },
    forgotPasswordSendEmail(emailId) {
      let sendTo = emailId;
      let data = {
        email: sendTo,
      };
      if (data) {
        this.validationErrorMsg = false;
        this.inputBoxMsg = "";
        this.services
          .post("user/forgotPasswordSendEmail", data)
          .then((res) => {
            if (res) {
              this.mainPopUp = false;
              this.otherPopUp = false;
              this.confirmPassPopUp = true;
              this.gaEventTrack(
                data.email,
                "createaccount_resetpassword_success",
                "Forgot password - screen 2"
              );
            }
          })
          .catch((err) => {
            if (err.response.status == 304) {
              this.validationErrorMsg = true;
              this.inputBoxMsg =
                "This email address is not registered – please check and try again";
            }
            //this.errorPopupFlag = true//;
          });
      }
    },

    gaEventTrack(data, action, category) {
      this.event(action, {
        event_category: category,
        event_label: data,
        value: 1,
      });
    },
    createNewPassword() {
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .anticon.ant-input-password-icon,
  .ant-select-arrow-icon {
    color: #363644 !important;
  }
  .send-mail-popup .center-div {
    padding: 3% 6% !important;
    padding-top: 7% !important;
    .wlcm {
      margin-top: 50px !important;
    }
  }
}
.has-error .ant-form-explain,
.has-error .ant-form-split {
  text-align: left !important;
}
</style>