<template>
  <div class="newsletter">
    <h2 class="wow fadeInUp">Sign up and get started with using Gestaltra</h2>

    <a-form :form="form" @submit="handleSubmit">
      <a-form-item :class="emailValidate ? 'has-error' : null">
        <a-input
          class="signup-class"
          style="width: 100%; height: 45px !important"
          placeholder="name@company.com"
          v-decorator="[
            'email',
            {
              rules: [
                {
                  type: 'email',
                  message: 'Please enter a valid email address',
                },
                {
                  required: true,
                  message: 'Please enter a valid email address',
                },

                {
                  validator: checkExistEmail,
                },
              ],
              validateTrigger: 'blur',
            },
          ]"
          @change="onChange($event.target.value, 'email')"
        />
        <div class="ant-form-explain" v-if="emailValidate">
          Email already exists
        </div>
      </a-form-item>
      <button html-type="submit" class="button--reversed">
        Sign up
      </button>
    </a-form>
  </div>
</template>

<script>
import services from "../../services/services.js";
import { pageview, event } from "vue-gtag";
export default {
  name: "LandingPage",

  data() {
    return {
      pageview,
      event,
      services,
      emailValidate: false,
      form: this.$form.createForm(this, { name: "coordinated" }),
      formData: {
        industry: "",
      },
    };
  },
  methods: {
    gaEventTrack(data, action, category) {
      this.event(action, {
        event_category: category,
        event_label: data,
        value: 1,
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.checkExistingEmail(values.email);
          this.$store.state.reviewersDetails.email = values.email;
        }
      });
    },
    checkExistEmail(rule, value, callback) {
      const form = this.form;
      let emailValidate = false;
      if (value && emailValidate) {
        callback("");
      } else {
        callback();
      }
      this.emailErrormsg = form.getFieldError("email");
    },
    onChange(val, key) {
      if (key == "email") {
        this.form.setFieldsValue({
          [key]: val,
        });
        this.emailValidate = false;
      }
      this.formData[key] = val;
    },
    checkExistingEmail(val) {
      this.callApiCheckExistEmail(this.emailErrormsg, val);
    },
    callApiCheckExistEmail(emailErrormsg, val) {
      this.emailValidate = false;
      if (val && (!emailErrormsg || this.emailErrormsg[0] == "")) {
        this.isSpinning = true;
        this.services
          .post("user/isUserExist", { email: val })
          .then((res) => {
            if (res.status == 200) {
              this.isSpinning = false;
              this.emailValidate = false;
              this.goToSignUp("sign_up_email");
            }
          })
          .catch((err) => {
            this.isSpinning = false;
            if (err.response.status == 302) {
              this.emailValidate = true;
            } else {
              this.emailValidate = false;
              //this.errorPopupFlag = true//;
            }
          });
      }
    },
    goToSignUp(data) {
      this.gaEventTrack(
        "Landing page signUp",
        data,
        "Landing page signUp link"
      );
      this.$router.push("/signup");
    },
  },
};
</script>
