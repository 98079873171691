<template>
  <div>
    <a-modal
      v-model="isShowData"
      @ok="handleOk"
      :maskClosable="false"
      :class="className"
      :centered="true"
    >
      <a-row class="mt-10 center">
        <a-col :span="24">
          <span class="header-txt-pop">Oops, something’s missing</span>
        </a-col>
      </a-row>
      <a-row class="mt-20 center">
        <a-col :span="24" class="confirm-contain-txt">
          <p>
            Looks like there’s some missing information. Fix it and try again!
          </p>
        </a-col>
      </a-row>
      <a-row class="pb-2 center mt-20">
        <a-col :span="24">
          <a-button @click="handleOk" class="confirm-grey-btn" type="primary"
            >Close</a-button
          >
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "ErrorPopUpForMissing",
  data() {
    const isShowData = this.isShow;
    return {
      isShowData,
      visible: false,
    };
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    logo: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default: "",
    },
    containText: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    className: {
      type: String,
      default: null,
    },
  },
  watch: {
    isShow(newVal) {
      this.isShowData = newVal;
    },
  },
  methods: {
    showModal() {
      this.visible = this.isShowData;
    },
    handleOk() {
      this.isShowData = false;
      this.$emit("closePopup", false);
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .ant-modal-footer {
    display: none !important;
  }
  .ant-modal-close-x {
    display: none !important;
  }
  .ant-modal {
    width: 39% !important;
  }
  .ant-modal-content {
    padding: 4%;
    border-radius: 10px !important;
  }
  button.confirm-grey-btn.ant-btn.ant-btn-primary {
    width: 150px !important;
    min-height: 45px !important;
    margin-top: 10px;
    font-size: 18px !important;
    background-color: #edeef5 !important;
  }
  button.confirm-grey-btn.ant-btn.ant-btn-primary:hover {
    background-color: #bfc3d6 !important;
  }
  .confirm-contain-txt {
    padding: 2px 19%;
    p {
      margin-bottom: 0px !important;
    }
  }
  @media screen and (max-width: 590px) {
    .ant-modal {
      width: 70.5% !important;
    }
  }
}
</style>
