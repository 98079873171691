<template>
  <div>
    <div>
      <a-modal
        :visible="isShowAddMemberPopUpLocal"
        :maskClosable="false"
        @cancel="cancelBtn"
        :centered="true"
        width="58%"
      >
        <div class="pl-pr">
          <a-row class="mt-2">
            <a-col style="padding-top: 5px; text-align: left" :span="24">
              <span class="bold-right-txt">{{ headerText }}</span>
            </a-col>
          </a-row>
          <a-row class="pb-2 mt-2">
            <a-col style="padding-top: 5px; text-align: left" :span="24">
              <span class="confirm-contain-txt">{{ containText }}</span>
            </a-col>
          </a-row>
          <a-form :form="form" @submit="handleSubmit">
            <a-row class="pb-2 round-grey-box" style="margin-top: 18px">
              <a-icon
                v-if="plusIcon"
                width="30px"
                height="30px"
                class="plus-img-css"
                type="plus-circle"
                theme="filled"
                html-type="submit"
                @click="addNewMember($event)"
              />
              <a-col :span="24">
                <a-row class="mt-2" :gutter="24">
                  <a-col :span="12" :lg="12" :xs="24">
                    <a-form-item class="float-label">
                      <label
                        :class="
                          focusData.focusDataFn || formData.firstName
                            ? 'label label-float'
                            : 'label'
                        "
                        >First name</label
                      >
                      <a-input
                        @blur="setFocus(false, 'focusDataFn')"
                        @focus="setFocus(true, 'focusDataFn')"
                        :value="formData.firstName"
                        v-decorator="[
                          'firstName',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Please enter first name',
                              },
                            ],
                          },
                        ]"
                        @change="onChange($event.target.value, 'firstName')"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" :lg="12" :xs="24">
                    <a-form-item class="float-label">
                      <label
                        :class="
                          focusData.focusDataLn || formData.lastName
                            ? 'label label-float'
                            : 'label'
                        "
                        >Last name</label
                      >
                      <a-input
                        @blur="setFocus(false, 'focusDataLn')"
                        @focus="setFocus(true, 'focusDataLn')"
                        :value="formData.lastName"
                        v-decorator="[
                          'lastName',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Please enter last name',
                              },
                            ],
                          },
                        ]"
                        @change="onChange($event.target.value, 'lastName')"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row class="mt-2" :gutter="24">
                  <a-col :span="24">
                    <a-form-item
                      :class="emailValidate ? 'has-error' : null"
                      class="float-label"
                    >
                      <label
                        :class="
                          focusData.email || formData.email
                            ? 'label label-float'
                            : 'label'
                        "
                        >Email</label
                      >
                      <a-input
                        @focus="setFocus(true, 'email')"
                        :value="formData.email"
                        :disabled="updatedOption && statusNumber != 2"
                        v-decorator="[
                          'email',
                          {
                            rules: [
                              {
                                type: 'email',
                                message: 'Please enter a valid email address',
                              },
                              {
                                required: true,
                                message: 'Please enter a valid email address',
                              },
                              {
                                validator: checkExistEmail,
                              },
                            ],
                            validateTrigger: 'blur',
                          },
                        ]"
                        @change="onChange($event.target.value, 'email')"
                        @blur="checkExistingEmail($event.target.value)"
                      />
                      <div class="ant-form-explain" v-if="emailValidate">
                        There’s already someone in your team with this email
                        address
                      </div>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row class="mt-2" :gutter="24">
                  <a-col :span="dynamicSpan" :lg="dynamicSpan" :xs="24">
                    <a-form-item class="float-label custom-top">
                      <label
                        :class="
                          focusData.jobTitle || formData.jobTitle
                            ? 'label label-float'
                            : 'label'
                        "
                        >Select a job title</label
                      >
                      <a-select
                        :disabled="updatedOption && statusNumber == 0"
                        :show-search="true"
                        not-found-content="No matches found"
                        @blur="setFocus(false, 'jobTitle')"
                        @focus="setFocus(true, 'jobTitle')"
                        optionFilterProp="children"
                        v-decorator="[
                          'jobTitle',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Please select a job title',
                              },
                            ],
                          },
                        ]"
                        :value="dropDataShow(jobTitleList, 'jobTitle')"
                        @change="onChangeOthers(jobTitleList[$event], 'id')"
                      >
                        <a-select-option
                          v-for="(item, index) in jobTitleList"
                          :key="index"
                          :filter-option="
                            (input, item) => {
                              option.jobTitle
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0;
                            }
                          "
                        >
                          {{ item.jobTitle }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col
                    :span="dynamicSpan2"
                    v-if="jobTitleTextBox"
                    :lg="dynamicSpan2"
                    :xs="24"
                  >
                    <a-form-item class="float-label">
                      <label
                        :class="
                          focusData.other || formData.other
                            ? 'label label-float'
                            : 'label'
                        "
                        >Job title if ‘other’ is selected</label
                      >
                      <a-input
                        :disabled="updatedOption && statusNumber == 0"
                        @blur="setFocus(false, 'other')"
                        @focus="setFocus(true, 'other')"
                        :value="formData.other"
                        v-decorator="[
                          'other',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Please enter a job title',
                              },
                            ],
                          },
                        ]"
                        @change="onChange($event.target.value, 'other')"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
            <a-row :gutter="24" style="margin-top: 20px">
              <a-col :span="24">
                <a-list
                  size="small"
                  :data-source="teamMemberList"
                  v-if="teamMemberList.length > 0 && !updatedOption"
                >
                  <a-list-item
                    class="list-item-css add-mem-res"
                    slot="renderItem"
                    slot-scope="record, index"
                  >
                    <a-row :gutter="24" class="row-css">
                      <a-icon
                        class="minus-img-css"
                        type="minus-circle"
                        theme="filled"
                        @click="deleteTeamMember(index)"
                      />
                      <a-col class="name-css" :span="6">
                        <a-popover placement="right" :mouse-enter-delay="0.5">
                          <template slot="content">
                            {{ record.firstName }} &nbsp;
                            {{ record.lastName }}</template
                          >
                          <span class="role-item-text">
                            {{ record.firstName }}&nbsp;{{ record.lastName }}
                          </span></a-popover
                        >
                      </a-col>
                      <a-col class="email-css" :span="10">
                        <a-popover placement="right" :mouse-enter-delay="0.5">
                          <template slot="content">
                            {{ record.email }}</template
                          >
                          <span class="role-item-text">
                            {{ record.email }}
                          </span></a-popover
                        >
                      </a-col>
                      <a-col
                        class="role-css role-item"
                        :span="8"
                        v-if="record.other"
                        ><a-popover placement="right" :mouse-enter-delay="0.5">
                          <template slot="content"
                            >{{ record.other }}
                          </template>
                          <span class="role-item-text"
                            >{{ record.other }}
                          </span></a-popover
                        ></a-col
                      >
                      <a-col class="role-css role-item" :span="8" v-else
                        ><a-popover placement="right" :mouse-enter-delay="0.5">
                          <template slot="content"
                            >{{ record.jobTitle }}
                          </template>
                          <span class="role-item-text"
                            >{{ record.jobTitle }}
                          </span></a-popover
                        ></a-col
                      >
                    </a-row>
                  </a-list-item>
                </a-list>
              </a-col>
            </a-row>
            <a-row v-if="teamMemberList.length == 0 && !updatedOption">
              <a-col class="font-16 clr">
                <p>Your team members will display here for you to preview.</p>
              </a-col>
            </a-row>
            <a-row :gutter="24" class="pb-2">
              <a-col style="padding-top: 13px" :span="18" :lg="18" :xs="24">
                <div
                  style="padding-top: 15px"
                  class="red"
                  v-if="
                    dataValidate && teamMemberList.length == 0 && !updatedOption
                  "
                >
                  Please add at least one team member by hitting the '+' button
                </div>
              </a-col>
              <a-col
                style="padding-top: 13px; text-align: end"
                :span="18"
                :lg="18"
                :xs="24"
                v-if="updatedOption && statusNumber != 0"
              >
                <a class="pointer" @click="removeTeamMember">
                  Remove team member
                </a>
              </a-col>
              <a-col
                style="padding-top: 5px; text-align: end"
                :span="6"
                :lg="6"
                :xs="24"
              >
                <a-button
                  style="width: fit-content !important"
                  type="primary"
                  :html-type="teamMemberList.length == 0 ? 'submit' : null"
                  @click="saveTeamMember"
                  >{{ buttonText }}</a-button
                >
              </a-col>
            </a-row>
          </a-form>
        </div>
      </a-modal>
    </div>
    <confirm-pop-up
      :is-show="loadingFlag"
      :loading="true"
      header-text="Loading"
      contain-text="Hang tight..."
    />
    <confirm-pop-up
      :is-show="successFlag"
      class="success-popup"
      :logo="true"
      header-text="Great"
      contain-text="Team members have been successfully added."
      button-text="OK"
      @closePopup="closedPopup"
    />

    <time-out-pop-up :is-show="isTimeOut" @closePopup="logInTimeOutPopup" />
  </div>
</template>
<script>
import ConfirmPopUp from "./ConfirmPopUp.vue";
import services from "../services/services";
import { event } from "vue-gtag";

import TimeOutPopUp from "@/components/TimeOutPopUp.vue";
export default {
  name: "AddMembersPopUp",
  components: { ConfirmPopUp, TimeOutPopUp },
  props: {
    statusNumber: {
      type: Number,
      required: true,
    },
    popUpName: {
      type: String,
      default: "",
    },
    isShowAddMemberPopUpAll: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default: "",
    },
    containText: {
      type: String,
      default: "",
    },
    firstName: {
      type: String,
      default: "",
    },
    lastName: {
      type: String,
      default: "",
    },
    jobTitleList: {
      type: Array,
      default: null,
    },

    relationList: {
      type: Array,
      default: null,
    },
    email: {
      type: String,
      default: "",
    },
    plusIcon: {
      type: Boolean,
      default: false,
    },
    minusIcon: {
      type: Boolean,
      default: false,
    },
    addedList: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: "",
    },
    removeLink: {
      type: Boolean,
      default: false,
    },
    listErrorMsg: {
      type: Boolean,
      default: false,
    },
    updatedOption: {
      type: Boolean,
      default: false,
    },
    updatedMemberData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isTimeOut: false,
      event,
      isShowAddMemberPopUpLocal: false,
      dataValidate: false,
      preValue: false,
      loadingFlag: false,
      successFlag: false,
      addMemberFlag: true,
      emailValidate: false,
      teamMemberList: [],
      isSpinning: false,
      services,
      emailErrormsg: "",
      jobTitleTextBox: false,
      dynamicSpan: 24,
      form: this.$form.createForm(this, { name: "coordinated" }),
      userId: 0,
      formData: {
        firstName: null,
        lastName: null,
        email: null,
        jobId: null,
        userId: null,
        jobTitle: null,
        other: null,
        createdBy: 0,
        updatedBy: 0,
      },
      focusData: {
        focusDataFn: false,
        focusDataLn: false,
        jobTitle: false,
        other: false,
        email: false,
      },
    };
  },
  watch: {
    isShowAddMemberPopUpAll(newVal) {
      this.isShowAddMemberPopUpLocal = newVal;
      if (newVal) {
        this.emailValidate = false;
        this.teamMemberList = [];
        this.dataValidate = false;

        this.setDataOnUpdate();
      }
    },
  },

  created() {
    let userDetailsData = localStorage.getItem("userDetails");
    this.userDetails = JSON.parse(userDetailsData);
    this.userId = this.userDetails.userId
      ? this.userDetails.userId
      : this.userId;

    this.formData.createdBy = this.userId;
    this.formData.updatedBy = this.userId;
    this.formData.userId = this.userId;
  },

  methods: {
    logInTimeOutPopup(val) {
      this.isTimeOut = val;
      this.$router.push("/login");
    },
    setDataOnUpdate() {
      let getformData = JSON.parse(JSON.stringify(this.updatedMemberData));

      if (this.updatedOption && getformData.firstName) {
        for (let key in getformData) {
          this.formData[key] = getformData[key];
          if (key == "job") {
            this.formData[key] = getformData.job.jobTitle;
            key = "jobTitle";

            this.onChangeOthers(
              {
                id: getformData.jobId,
                jobTitle: getformData.job.jobTitle,
              },
              "id"
            );
          }
          if (!this.preValue) {
            this.form.getFieldDecorator(key, {
              initialValue: this.formData[key],
            });
          } else {
            this.form.getFieldDecorator("other", {
              initialValue: this.formData["other"],
            });
            this.form.setFieldsValue(this.formData);
          }
        }
        this.preValue = this.updatedOption;
      } else {
      }
    },
    removeTeamMember() {
      this.$emit("removedTeamMember", this.formData);
    },
    setFocus(val, key) {
      this.focusData[key] = val;
    },
    deleteTeamMember(index) {
      this.emailValidate = false;
      this.teamMemberList.splice(index, 1);
    },
    addNewTeamMember() {
      this.teamMemberList.unshift(this.formData);
      this.formData = {
        firstName: null,
        lastName: null,
        email: null,
        jobId: null,
        userId: this.userId,
        jobTitle: null,
        other: null,
        createdBy: this.userId,
        updatedBy: this.userId,
      };
      this.jobTitleTextBox = false;
      this.dynamicSpan = 24;
      this.form.setFieldsValue(this.formData);
      this.form.setFieldsValue({ jobTitle: null });
    },
    addNewMember(e) {
      this.handleSubmit(e, true);
    },
    dropDataShow(val, key) {
      return val[key];
    },
    saveTeamMember() {
      if (this.teamMemberList.length > 0 && !this.updatedOption) {
        this.addMemberFlag = false;
        this.isShowAddMemberPopUpLocal = false;
        this.loadingFlag = true;
        let data,
          reqArr = [];
        this.teamMemberList.forEach((element) => {
          data = {
            firstName: element.firstName,
            lastName: element.lastName,
            email: element.email,
            jobId: element.jobId,
            userId: element.userId,
            other: element.other,
            createdBy: element.createdBy,
            updatedBy: element.updatedBy,
          };
          reqArr.push(data);
        });

        this.services
          .post(`team/createMyTeam`, reqArr)
          .then((res) => {
            this.loadingFlag = false;
            this.successFlag = true;
            this.isShowAddMemberPopUpLocal = false;
            this.gaEventTrack(
              reqArr.email,
              "portal_addnewmember",
              "add new member - pop-up confirmation success"
            );

            this.$emit("savedDataEvent", this.teamMemberList);
          })
          .catch((err) => {
            this.loadingFlag = false;
            this.isShowAddMemberPopUpLocal = false;
            if (err.response.status == 401) {
              this.sessionTimeOut();
            }
          });
      } else if (this.updatedOption) {
        this.$emit("updatedTeamMember", this.formData);
      } else {
        this.dataValidate = true;
      }
    },
    gaEventTrack(data, action, category) {
      this.event(action, {
        event_category: category,
        event_label: data,
        value: 1,
      });
    },
    checkExistEmail(rule, value, callback) {
      const form = this.form;
      let emailValidate = false;
      if (value && emailValidate) {
        callback("");
      } else {
        callback();
      }
      this.emailErrormsg = form.getFieldError("email");
    },
    callApiCheckExistEmail(emailErrormsg, val) {
      this.emailValidate = false;
      if (val && (!emailErrormsg || this.emailErrormsg[0] == "")) {
        if (this.teamMemberList.length > 0) {
          this.teamMemberList.forEach((item, index) => {
            if (item.email.toLowerCase() == val.toLowerCase()) {
              this.emailValidate = true;
            }
          });
        }
        if (!this.emailValidate) {
          this.loadingFlag = true;
          this.services
            .post(`team/isTeamMemberExist`, { email: val, userId: this.userId })
            .then((res) => {
              this.addNewTeamMember();
              this.setFocus(false, "jobTitle");

              this.loadingFlag = false;
            })
            .catch((err) => {
              if (err.response.status == 302) {
                this.emailValidate = true;
                this.loadingFlag = false;
                this.setFocus(false, "jobTitle");
              }
              if (err.response.status == 401) {
                this.sessionTimeOut();
              }
            });
        }
      }
    },
    checkExistingEmail(val) {
      this.setFocus(false, "email");
    },
    onChange(val, key) {
      if (key == "email") {
        this.emailValidate = false;
        this.form.setFieldsValue({
          [key]: val.trim(),
        });
      }
      this.formData[key] = val;
      this.$store.state.addMembersDataObject = this.formData;
    },
    onChangeOthers(val, key) {
      this.formData.jobId = val[key];
      this.formData.jobTitle = val.jobTitle;
      this.$store.state.addMembersDataObject = this.formData;
      if (val.jobTitle.toLowerCase() == "other") {
        this.jobTitleTextBox = true;
        this.dynamicSpan = 12;
        this.dynamicSpan2 = 12;
      } else {
        this.jobTitleTextBox = false;
        this.formData.other = "";
        this.dynamicSpan = 24;
      }
    },

    handleClose() {
      this.successFlag = !this.successFlag;
    },
    closedPopup(val) {
      this.addMemberFlag = !val;
      this.isShowAddMemberPopUpLocal = val;
      this.$emit("sendCode", this.isShowAddMemberPopUpLocal);
    },
    cancelBtn() {
      this.jobTitleTextBox = false;
      this.dynamicSpan = 24;
      this.formData = {
        firstName: null,
        lastName: null,
        email: null,
        jobId: null,
        userId: this.userId,
        jobTitle: null,
        other: null,
        createdBy: this.userId,
        updatedBy: this.userId,
      };
      this.form.setFieldsValue(this.formData);

      this.isShowAddMemberPopUpLocal = false;

      this.$emit("sendCode", false);
    },
    handleSubmit(e, flag) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err && !this.emailValidate) {
          if (flag) {
            this.callApiCheckExistEmail(
              this.emailErrormsg,
              this.formData.email
            );
          } else {
            this.saveTeamMember();
          }
        }
      });
    },
    sessionTimeOut() {
      localStorage.removeItem("token");
      localStorage.removeItem("userDetails");
      localStorage.removeItem("userDeviceName");
      if (!this.isTimeOut) {
        this.isTimeOut = true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .ant-form-explain {
    text-align: left !important;
    color: #f13c47;
    font-size: 12px !important;
    line-height: 19px;
    margin-bottom: -20px !important;
  }
  .ant-modal-footer {
    display: none !important;
  }

  .round-grey-box {
    background: #bfc3d6;
    border-radius: 10px;
    padding: 30px;
  }

  .list-item-css {
    background: #edeef5;
    border-radius: 5px;
    margin-bottom: 10px !important;
  }
  .name-css {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #363644;
  }
  .email-css {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #363644;
  }
  .role-css {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #363644;
  }
  .row-css {
    width: 100%;
    margin-left: 0px !important;
    margin-right: 12px !important;
  }
  .plus-img-css {
    position: absolute !important;
    right: -2.8% !important;
    top: 40% !important;
    font-size: 40px !important;
    color: #6454f0 !important;
    background: linear-gradient(to left, white 50%, white 50%) !important;
    border-radius: 50% !important;
  }
  .plus-img-css:hover,
  .plus-img-css:focus {
    color: #493bc8 !important;
    border-color: #493bc8 !important;
    cursor: pointer !important;
  }

  .minus-img-css {
    position: absolute !important;
    right: -34px;
    top: 10%;
    font-size: 18px !important;
    color: #6454f0 !important;
  }

  @media screen and (min-width: 1370px) {
    .minus-img-css {
      right: -39px !important;
    }
    .plus-img-css {
      right: -2% !important;
    }
  }
  @media screen and (min-width: 1511px) {
    .minus-img-css {
      right: -35px !important;
    }
    .plus-img-css {
      right: -2.7% !important;
    }
  }
  @media screen and (min-width: 1700px) {
    .minus-img-css {
      right: -36px !important;
    }
    .plus-img-css {
      right: -2.5% !important;
    }
  }
  @media screen and (min-width: 1813.33px) {
    .minus-img-css {
      right: -38px !important;
    }
    .plus-img-css {
      right: -2.3% !important;
    }
  }
  @media screen and (min-width: 2720px) {
    .minus-img-css {
      right: -46px !important;
    }
    .plus-img-css {
      right: -1.5% !important;
    }
  }
  @media screen and (min-width: 4080px) {
    .minus-img-css {
      right: -62px !important;
    }
    .plus-img-css {
      right: -0.9% !important;
    }
  }
  @media screen and (min-width: 5440px) {
    .minus-img-css {
      right: -72px !important;
    }
    .plus-img-css {
      right: -0.85% !important;
    }
  }

  .minus-img-css:hover,
  .minus-img-css:focus {
    color: #493bc8 !important;
    border-color: #493bc8 !important;
    cursor: pointer !important;
  }
  .ant-popover-inner-content {
    padding: 8px 8px !important;
    color: rgba(0, 0, 0, 0.65) !important;
  }
  .role-item {
    width: 33.33%;
  }
  .role-item-text {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;

    @media screen and (max-width: 600px) {
      overflow: normal;
      text-overflow: initial;
    }
  }

  .ant-btn-primary {
    font-size: 18px !important;
    height: 45px !important;
    span {
      margin: auto 35px !important;
    }
  }
  .confirm-contain-txt {
    font-size: 16px !important;
  }
  .pl-pr {
    padding-left: 4%;
    padding-right: 7%;
  }
  .ant-input {
    padding-top: 13px !important;
  }
  .ant-select-selection {
    padding-top: 9px !important;
  }
  .pointer {
    font-size: 16px;
    border-bottom: 1px solid #6454f0;
  }
  .red {
    color: #f13c47 !important;
    text-align: end !important;
    font-size: 14px !important;
    margin: 0px !important;
  }
  .ant-input,
  .ant-select-selection {
    font-size: 14px !important;
  }
  .clr {
    color: #bfc3d6 !important;
  }
  .label,
  .ant-form-item label {
    color: #bfc3d6 !important;
  }
  .custom-top .label,
  .custom-top .ant-form-item label {
    color: #363644 !important;
    top: 5px !important;
  }
  .custom-top .label-float,
  .custom-top .ant-form-item .label-float {
    color: #363644 !important;
    top: -5px !important;
  }

  @media screen and (max-width: 590px) {
    .ant-modal {
      width: 90% !important;
    }
    .plus-img-css {
      right: -6.5% !important;
    }
  }
}
</style>
