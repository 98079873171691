<template>
  <div>
    <a-modal
      :class="className"
      v-model="isShow"
      @cancel="handleClose"
      :maskClosable="false"
      :centered="true"
      width="40%"
      class="remove-pop-res"
    >
      <div>
        <a-row class="pb-2 center">
          <a-col :span="24">
            <img
              alt="blue_check"
              width="50px"
              height="50px"
              src="../assets/blue_check.png"
            />
          </a-col>
        </a-row>

        <a-row v-if="isAllDevice" class="mt-10 center">
          <a-col :span="24">
            <span class="header-txt-pop">All logged out</span>
          </a-col>
        </a-row>
        <a-row v-if="!isAllDevice" class="mt-10 center">
          <a-col :span="24">
            <span class="header-txt-pop">Logged out</span>
          </a-col>
        </a-row>
        <a-row class="mt-20 center">
          <a-col :span="24">
            <p class="confirm-contain-txt">
              A bit more to do? <a @click="goTOLogin">Log in</a>
            </p>
          </a-col>
        </a-row>
        <a-row
          class="pb-2 center"
          :class="buttonText && buttonText != 'OK' ? 'mt-20' : null"
          v-if="buttonText"
        >
          <a-col :span="24">
            <a-button
              @click="handleClose"
              class="confirm-grey-btn"
              type="primary"
              >Close</a-button
            >
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "AllLogOutPopUp",
  props: {
    isAllDevice: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    logo: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default: "",
    },
    fullName: {
      type: String,
      default: "",
    },
    containText: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    className: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    showModal() {
      this.visible = isShow;
    },
    goTOLogin() {
      this.visible = false;
      if (this.isAdmin) {
        this.$router.push("/admin-login");
      } else {
        this.$router.push("/login");
      }
    },
    handleClose() {
      this.visible = false;
      this.$emit("closeRemove", false);
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .ant-modal-footer {
    display: none !important;
  }
  //   .ant-modal-close-x {
  //     display: none !important;
  //   }
  .ant-modal {
    width: 41%;
    .ant-modal-content {
      border-radius: 10px !important;
      padding: 30px;
    }
  }
  @media screen and (max-width: 590px) {
    .ant-modal {
      width: 80%;
    }
  }
  .confirm-msg {
    font-size: 18px;
    color: #666666 !important;
    padding-right: 25% !important;
  }
  .profile-confirm-msg {
    font-size: 18px;
    color: #666666 !important;
    padding-right: 5% !important;
  }
  .remove-css {
    .confirm-msg {
      padding-right: 0% !important;
    }
  }
  .ant-btn.ant-btn-primary {
    min-width: 150px !important;
  }
}
</style>
