var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main-wrapper"},[_c('div',{staticClass:"grey-div-css desktop-traits-wrapper"},[_c('div',{staticClass:"admin-traits-role-wrapper-header"},[_c('div',{staticClass:"left-wrapper"},[_c('a-row',{staticClass:"heading-wrapper",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":8,"lg":8,"xs":24}},[_vm._v("Traits & behaviours")]),_c('a-col',{attrs:{"span":14,"lg":14,"xs":24}},[_vm._v("Description")])],1)],1),_c('div',{staticClass:"right-wrapper",style:(_vm.dynamicRightWidth),attrs:{"id":"text-box-custom-padding-scroll"}},[_c('div',{staticClass:"coulmn-wrapper",style:(_vm.dynamicWidth)},_vm._l((_vm.traitsAndRolsListOrg),function(item,index){return _c('div',{key:index},[(index == 0)?_c('a-row',{staticClass:"text-box-custom-padding",attrs:{"gutter":24}},_vm._l((_vm.columnData),function(record,colIndex){return _c('div',{key:colIndex,staticClass:"custom-css"},[(!record.deletedFlag)?_c('a-col',[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',[_c('span',[_c('a-input',{attrs:{"disabled":record.jobTitle.toLowerCase().trim() == 'other'
                                ? true
                                : _vm.editFlag,"value":record.jobTitle},on:{"blur":function($event){return _vm.checkExist(
                                $event.target.value,
                                'jobTitle',
                                record,
                                colIndex
                              )}}}),(
                              record.jobTitle.toLowerCase().trim() == 'other'
                                ? false
                                : !_vm.editFlag
                            )?_c('a-icon',{staticClass:"minus-img-css",attrs:{"type":"minus-circle","theme":"filled"},on:{"click":function($event){return _vm.deleteColumn(colIndex, record)}}}):_vm._e()],1)])],1)],1):_vm._e()],1)}),0):_vm._e()],1)}),0)])]),_c('div',{staticClass:"custom-scroll-wrapper"},[_c('div',{staticClass:"admin-traits-role-wrapper"},[_c('div',{staticClass:"left-wrapper"},[_c('a-form',[_c('a-form-item',_vm._l((_vm.traitsAndRolsListOrg),function(record,index){return _c('span',{key:index},[(!record.deletedFlag)?_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":8,"lg":8}},[_c('a-input',{attrs:{"disabled":_vm.editFlag,"value":record.traitsAndBehaviours},on:{"blur":function($event){return _vm.checkExist(
                            $event.target.value,
                            'traitsAndBehaviours',
                            record,
                            index
                          )}}})],1),_c('a-col',{attrs:{"span":14,"lg":14}},[_c('span',{staticClass:"edit-css minus-btn-res2",attrs:{"span":1,"lg":1}},[(!_vm.editFlag)?_c('a-icon',{staticClass:"minus-img-css",attrs:{"type":"minus-circle","theme":"filled"},on:{"click":function($event){return _vm.deleteRow(index, record)}}}):_vm._e()],1),_c('a-input',{attrs:{"disabled":_vm.editFlag,"value":record.description},on:{"blur":function($event){return _vm.onChangeText(
                            $event.target.value,
                            'description',
                            record
                          )}}})],1),_c('a-col',{staticClass:"edit-css minus-btn-res1",attrs:{"span":1,"lg":1}},[(!_vm.editFlag)?_c('a-icon',{staticClass:"minus-img-css",attrs:{"type":"minus-circle","theme":"filled"},on:{"click":function($event){return _vm.deleteRow(index, record)}}}):_vm._e()],1)],1):_vm._e()],1)}),0)],1)],1),_c('div',{staticClass:"right-wrapper right-wrapper-scroll",style:(_vm.dynamicRightWidth),on:{"scroll":_vm.dynamicScroll}},[_c('div',{staticClass:"coulmn-wrapper",style:(_vm.dynamicWidth)},_vm._l((_vm.traitsAndRolsListOrg),function(item,index){return _c('div',{key:index},[(!item.deletedFlag)?_c('a-row',{attrs:{"gutter":24}},_vm._l((_vm.columnData),function(record,index1){return _c('span',{key:index1},[(!record.deletedFlag)?_c('a-col',{staticClass:"custom-css"},[_c('span',[_c('a-row',{staticClass:"custom-padding",attrs:{"gutter":24}},[_c('a-col',[_c('span',[_c('a-checkbox',{attrs:{"disabled":_vm.editFlag,"checked":item['rel' + index1] == record.jobTitle},on:{"change":function($event){return _vm.checkedJobCheckBox(
                                    $event.target.checked,
                                    item,
                                    index,
                                    record,
                                    index1
                                  )}}})],1)])],1)],1)]):_vm._e()],1)}),0):_vm._e()],1)}),0)])])])]),_c('div',{staticClass:"grey-div-css trait-res"},[(!_vm.editFlag)?_c('div',{staticClass:"update-wrapper"},[_c('div',{staticClass:"trait1"},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{staticClass:"content-heading",attrs:{"span":14,"lg":14}},[_vm._v(" Add trait or behaviour: ")]),_c('a-col',{staticClass:"content-heading",attrs:{"span":7,"lg":7}},[_vm._v(" Add new job title: ")]),_c('a-col',{attrs:{"span":3,"lg":3}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.updateSection}},[_vm._v("Update")])],1)],1),_c('a-form',[_c('a-form-item',{staticClass:"float-label"},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":4,"lg":4}},[_c('label',{class:_vm.focusData.traitsAndBehaviours ||
                      _vm.formData.traitsAndBehaviours
                        ? 'label label-float'
                        : 'label'},[_vm._v("Add char or trait")]),_c('a-input',{staticClass:"grey-input-field-css",attrs:{"value":_vm.formData.traitsAndBehaviours},on:{"blur":function($event){return _vm.setFocus(false, 'traitsAndBehaviours')},"focus":function($event){return _vm.setFocus(true, 'traitsAndBehaviours')},"change":function($event){return _vm.onChange($event.target.value, 'traitsAndBehaviours')}}})],1),_c('a-col',{attrs:{"span":9,"lg":9}},[_c('label',{class:_vm.focusData.description || _vm.formData.description
                        ? 'label label-float'
                        : 'label'},[_vm._v("Add description")]),_c('a-input',{staticClass:"grey-input-field-css",attrs:{"value":_vm.formData.description},on:{"blur":function($event){return _vm.setFocus(false, 'description')},"focus":function($event){return _vm.setFocus(true, 'description')},"change":function($event){return _vm.onChange($event.target.value, 'description')}}})],1),_c('a-col',{staticClass:"plus-img-css-wrapper",attrs:{"span":2,"lg":1}},[_c('a-icon',{staticClass:"plus-img-css",attrs:{"type":"plus-circle","theme":"filled"},on:{"click":function($event){return _vm.addTraits($event)}}})],1),_c('a-col',{attrs:{"span":6,"lg":6}},[_c('label',{class:_vm.focusData.jobTitle || _vm.formData.jobTitle
                        ? 'label label-float'
                        : 'label'},[_vm._v("Add job title")]),_c('a-input',{staticClass:"grey-input-field-css",attrs:{"value":_vm.formData.jobTitle},on:{"blur":function($event){return _vm.setFocus(false, 'jobTitle')},"focus":function($event){return _vm.setFocus(true, 'jobTitle')},"change":function($event){return _vm.onChange($event.target.value, 'jobTitle')}}})],1),_c('a-col',{staticClass:"plus-img-css-wrapper",attrs:{"span":2,"lg":1}},[_c('a-icon',{staticClass:"plus-img-css",attrs:{"type":"plus-circle","theme":"filled"},on:{"click":function($event){return _vm.addJobTitle($event)}}})],1),_c('a-col',{attrs:{"span":3,"lg":3}},[_c('a-button',{staticClass:"confirm-grey-btn",attrs:{"type":"primary"},on:{"click":_vm.cancelTraits}},[_vm._v("Cancel")])],1)],1)],1)],1)],1),_c('div',{staticClass:"trait2"},[_c('a-form',[_c('a-form-item',{staticClass:"float-label"},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{staticClass:"content-heading",attrs:{"span":14,"lg":14,"xs":24}},[_vm._v(" Add trait or behaviour: ")]),_c('a-col',{attrs:{"span":4,"lg":4,"xs":24}},[_c('label',{class:_vm.focusData.traitsAndBehaviours ||
                      _vm.formData.traitsAndBehaviours
                        ? 'label label-float'
                        : 'label'},[_vm._v("Add char or trait")]),_c('a-input',{staticClass:"grey-input-field-css",attrs:{"value":_vm.formData.traitsAndBehaviours},on:{"blur":function($event){return _vm.setFocus(false, 'traitsAndBehaviours')},"focus":function($event){return _vm.setFocus(true, 'traitsAndBehaviours')},"change":function($event){return _vm.onChange($event.target.value, 'traitsAndBehaviours')}}})],1),_c('a-col',{attrs:{"span":9,"lg":9,"xs":24}},[_c('label',{class:_vm.focusData.description || _vm.formData.description
                        ? 'label label-float'
                        : 'label'},[_vm._v("Add description")]),_c('a-input',{staticClass:"grey-input-field-css",attrs:{"value":_vm.formData.description},on:{"blur":function($event){return _vm.setFocus(false, 'description')},"focus":function($event){return _vm.setFocus(true, 'description')},"change":function($event){return _vm.onChange($event.target.value, 'description')}}})],1),_c('a-col',{staticClass:"plus-img-css-wrapper",attrs:{"span":2,"lg":1,"xs":24}},[_c('a-icon',{staticClass:"plus-img-css",attrs:{"type":"plus-circle","theme":"filled"},on:{"click":function($event){return _vm.addTraits($event)}}})],1),_c('a-col',{staticClass:"content-heading",attrs:{"span":7,"lg":7,"xs":24}},[_vm._v(" Add new job title: ")]),_c('a-col',{attrs:{"span":6,"lg":6,"xs":24}},[_c('label',{class:_vm.focusData.jobTitle || _vm.formData.jobTitle
                        ? 'label label-float'
                        : 'label'},[_vm._v("Add job title")]),_c('a-input',{staticClass:"grey-input-field-css",attrs:{"value":_vm.formData.jobTitle},on:{"blur":function($event){return _vm.setFocus(false, 'jobTitle')},"focus":function($event){return _vm.setFocus(true, 'jobTitle')},"change":function($event){return _vm.onChange($event.target.value, 'jobTitle')}}})],1),_c('a-col',{staticClass:"plus-img-css-wrapper",attrs:{"span":2,"lg":1,"xs":24}},[_c('a-icon',{staticClass:"plus-img-css",attrs:{"type":"plus-circle","theme":"filled"},on:{"click":function($event){return _vm.addJobTitle($event)}}})],1),_c('a-col',{staticStyle:{"text-align":"end !important"},attrs:{"span":3,"lg":3,"xs":24}},[_c('a-button',{staticClass:"confirm-grey-btn",attrs:{"type":"primary"},on:{"click":_vm.cancelTraits}},[_vm._v("Cancel")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.updateSection}},[_vm._v("Update")])],1)],1)],1)],1)],1)]):_vm._e()])]),_c('error-pop-up-for-duplicate',{attrs:{"is-show":_vm.isDataDuplicate},on:{"closePopup":_vm.closePopup}}),_c('error-pop-up-for-missing',{attrs:{"is-show":_vm.isDataMissing},on:{"closePopup":_vm.closePopupMissing}}),_c('time-out-pop-up',{attrs:{"is-show":_vm.isTimeOut},on:{"closePopup":_vm.logInTimeOutPopup}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }