<template>
  <div class="carousel">
    <h1 class="wow fadeInUp">360s are nothing new, but we've made them easier to do</h1>

    <div class="carousel-wrapper">
      <a-carousel
        arrows
        v-bind:autoplay="true"
        v-bind:loop="true"
        v-bind:autoplaySpeed="10000"
      >
        <div>
          <div class="image-wrapper">
            <img alt="step1-1" src="../../assets/step1-1.png" />
          </div>
          <div class="content-wrapper">
            <h2>Add your team and go</h2>
            <p>Quick and intuitive setup with no clunky onboarding.</p>

            <p>
              Initiate 360 surveys for your whole team within minutes. No
              need for a customer success manager or IT department.
            </p>
          </div>
        </div>

        <div>
          <div class="image-wrapper">
            <img alt="step1-2" src="../../assets/step1-2.png" />
          </div>
          <div class="content-wrapper">
            <h2>Take your pick</h2>
            <p> Easy selection of reviewers, nominated by team members. </p>

            <p>
              Reviewers receive a unique link and ongoing reminders to
              complete survey. Manager can add additional reviewers if
              they wish.
            </p>
          </div>
        </div>

        <div>
          <div class="image-wrapper">
            <img alt="step1-3" src="../../assets/step1-3.png" />
          </div>
          <div class="content-wrapper">
           <h2>Not your regular survey</h2>
           <p>Thoughtfully designed to maximise response rate.</p>

            <p>
              Intelligently prompted responses reduce effort and protect
              anonymity. Supplemented by optional free text capture for
              more contextual feedback.
            </p>
          </div>
        </div>

        <div>
          <div class="image-wrapper">
            <img alt="step1-4" src="../../assets/step1-4.png" />
          </div>
          <div class="content-wrapper">
            <h2>Data for action</h2>
            <p> All the information you need, nothing you don't.</p>

            <p>
              Insights are presented clearly, with intuitive charts and
              categorised verbatims. Focus in on specific reviewer
              feedback using convenient filters.
            </p>
          </div>
        </div>
      </a-carousel>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Carousel',
  }
</script>