<template>
  <div class="grey-bg-color">
    <div class="logo-img-wrapper">
      <img alt="gestaltra_logo_grey" width="180px" src="../../assets/gestaltra_logo_grey.png" />
    </div>
    <div class="step-wrapper">
      <div class="capsule-wrapper">
        <span>Reviewing:
          <span class="bold">{{ reviewersDetails.firstName }}&nbsp;{{
            reviewersDetails.lastName
          }}, </span><br class="mobile-show" />
          <span v-if="reviewersDetails.other">{{
            reviewersDetails.other
          }}</span>
          <span v-else>{{ reviewersDetails.job.jobTitle }}</span>
        </span>
      </div>
      <a-steps :current="current" progress-dot @change="onChange" :direction="'horizontal'">
        <a-step v-for="item in steps" :key="item.title" :title="item.title" :class="item._class" />
      </a-steps>

      <div class="steps-content">
        <keep-alive>
          <component :is="steps[current].content" :setup1-props="setup1Flag" :setup4-props="likehoodFlag"
            :submit-clicked="submitClicked" @changedStatus="changedStatus" />
        </keep-alive>
      </div>

      <div class="steps-action">
        <a-button v-if="current > 0" @click="prev" class="grey-btn mb-10">
          Back
        </a-button>
        <div class="submit-btn-wrapper">
          <span class="error-txt" v-if="submitClicked && !likehoodFlag">Please select a response</span>
          <a-button v-if="current == steps.length - 1" type="primary" :loading="isDisabled" @click="submitAllData">
            Submit
          </a-button>
        </div>
        <a-button v-if="current < steps.length - 1" type="primary" @click="next">
          Next
        </a-button>
      </div>
    </div>
    <confirm-pop-up-for-error class-name="max-error-popup" :is-show="maxDataError" header-text="That’s too many!"
      contain-text="Take it easy, keen bean.<br> You only need to select a maximum of five traits or behaviours."
      button-text="Close" @closePopup="closedMaxPop" />
    <confirm-pop-up-for-error class-name="min-error-popup" :is-show="minDataError" header-text="Think a little harder!"
      :contain-text="containText" button-text="Close" @closePopup="closedMinPop" />
  </div>
</template>
<script>
import SurveyStep1 from "./SurveyStep1.vue";
import SurveyStep2 from "./SurveyStep2.vue";
import SurveyStep3 from "./SurveyStep3.vue";
import SurveyStep4 from "./SurveyStep4.vue";
import services from "../../services/services";

import { pageview } from "vue-gtag";
import { event } from "vue-gtag";
import ConfirmPopUpForError from "@/components/ConfirmPopUpForError.vue";
export default {
  name: "SurveySetup",
  components: {
    SurveyStep1,
    SurveyStep2,
    SurveyStep3,
    SurveyStep4,
    ConfirmPopUpForError,
  },
  data() {
    return {
      isDisabled: false,
      services,
      pageview,
      userId: 1,
      event,
      jobId: 0,
      current: 0,
      surveyId: 0,
      teamMemberId: 0,
      customTraits: [],
      customTraits2: [],
      reviewersDetails: {},
      submitClicked: false,
      likehoodFlag: false,
      maxDataError: false,
      minDataError: false,
      setup1Flag: false,
      containText: "Please select or provide at least one trait or behaviour",
      surveyReviewersId: 12,
      steps: [
        {
          title: "General",
          content: SurveyStep1,
        },
        {
          title: "Does well",
          content: SurveyStep2,
        },
        {
          title: "Needs work",
          content: SurveyStep3,
        },
        {
          title: "Values and contribution",
          content: SurveyStep4,
          _class: "last-step",
        },
      ],
    };
  },

  computed: {
    windowHeight() {
      return window.innerWidth;
    },
  },

  created() {
    this.track();
    this.jobId = this.$store.state.jobId;
    if (this.$store.state.surveyReviewersId) {
      this.surveyReviewersId = this.$store.state.surveyReviewersId;
      this.surveyId = this.$store.state.surveyId;
      this.teamMemberId = this.$store.state.teamMemberId;
    }
    this.reviewersDetails = this.$store.state.reviewersDetails;
  },

  methods: {
    track() {
      this.pageview("respondsurvey_general");
    },

    async submitAllData() {
      this.submitClicked = true;
      this.isDisabled = true;

      let generalData = this.setup1Datastructure(),
        likehoodData = this.setup4Datastructure(),
        doesWellData,
        needWorkData;
      try {
        doesWellData = await this.setup2Datastructure();
        needWorkData = await this.setup3Datastructure();
      } catch (err) {
        console.log(err);
      }
      const reqBody = {
        surveyReviewersId: this.surveyReviewersId,
        surveyId: this.surveyId,
        teamMemberId: this.teamMemberId,
        general: generalData,
        doesWell: doesWellData,
        needsWork: needWorkData,
        likehood: likehoodData,
      };

      if (this.likehoodFlag) {
        try {
          const res = await this.services.get(
            `survey/isSurveySubmited?surveyReviewersId=${this.surveyReviewersId}`
          );

          let resData = res.data.message;

          if (!resData) {
            await this.services.post(`survey/submitSurvey`, reqBody);
            this.$router.push("/survey-complete");
          } else {
            this.$router.push("/survey-already-completed");
          }
        } catch (err) {
          console.error(err);
          this.isDisabled = false;
          this.submitClicked = false;
        }
      }
    },

    setup1Datastructure() {
      let setup1Data = this.$store.state.setup1Data;
      let generalData = [];
      setup1Data.competancyLabels.forEach((record, index) => {
        let customObj = {
          generalCompetancyLabelsId: record.id,
          generalCompetancyResponseLabelsId: null,
        };
        setup1Data.competancyResponseLabels.forEach((item, i) => {
          let rangeValue = record.selected_0
            ? "0"
            : record.selected_1
              ? "1"
              : record.selected_2
                ? "2"
                : record.selected_3
                  ? "3"
                  : record.selected_4
                    ? "4"
                    : record.selected_5
                      ? "5"
                      : null;
          if (item.ratingNo && item.ratingNo == rangeValue) {
            customObj.generalCompetancyResponseLabelsId = item.id;
          }
        });
        generalData.push(customObj);
      });
      return generalData;
    },

    async setup2Datastructure() {
      let setup2Data = this.$store.state.setup2Data,
        doesWell = [],
        doesWellDetails = [],
        commentObj = {
          comment: setup2Data.textAreaComment ? setup2Data.textAreaComment : "",
        };

      setup2Data.traitsList.forEach((record) => {
        if (record.selectedTraitsFlag) {
          doesWellDetails.push({
            traitId: record.id,
          });
        }
      });

      if (
        setup2Data.customTraitsList &&
        setup2Data.customTraitsList.length > 0
      ) {
        setup2Data.customTraitsList.forEach((record, index) => {
          if (
            record.selectedTraitsFlag &&
            this.customTraits.indexOf(record.traitsAndBehaviours) == -1
          ) {
            this.customTraits.push({
              traitsAndBehaviours: record.traitsAndBehaviours,
            });
          }
        });
      }

      if (this.customTraits.length > 0) {
        const setup2CustomTraits = await this.saveTraits(this.customTraits);
        if (setup2CustomTraits.length > 0) {
          setup2CustomTraits.forEach(element => {
            doesWellDetails.push({
              traitId: element.id,
            });
          });
        }
      }
      doesWell.push(
        { comments: commentObj },
        { doesWellDetails: doesWellDetails }
      );
      return doesWell;
    },

    async setup3Datastructure() {
      let setup3Data = this.$store.state.setup3Data,
        doesWell = [],
        needsWorkDetails = [],
        commentObj = {
          comment: setup3Data.textAreaComment ? setup3Data.textAreaComment : "",
        };

      setup3Data.traitsList.forEach((record) => {
        if (record.selectedTraitsFlag) {
          needsWorkDetails.push({
            traitId: record.id,
          });
        }
      });

      if (
        setup3Data.customTraitsList &&
        setup3Data.customTraitsList.length > 0
      ) {
        setup3Data.customTraitsList.forEach((record, index) => {
          if (
            record.selectedTraitsFlag &&
            this.customTraits.indexOf(record.traitsAndBehaviours) == -1
          ) {
            this.customTraits2.push({
              traitsAndBehaviours: record.traitsAndBehaviours,
            });
          }
        });
      }
      if (this.customTraits2.length > 0) {
        const setup3CustomTraits = await this.saveTraits(this.customTraits2);
        if (setup3CustomTraits.length > 0) {
          setup3CustomTraits.forEach(element => {
            needsWorkDetails.push({
              traitId: element.id,
            });
          });
        }
      }
      doesWell.push(
        { comments: commentObj },
        { needsWorkDetails: needsWorkDetails }
      );
      return doesWell;
    },

    async deactivateTraits(rec) {
      let listOfTraits = [{ traitId: rec.id, userId: this.userId }],
        reqBody = {
          listOfTraits,
        };

      if (listOfTraits.length > 0) {
        await this.services.post("admin/deleteTraits", reqBody);
      }
    },

    async saveTraits(data) {
      let traitDetail = [];
      for (const rec of data) {
        let res = await this.addCustomTrait(rec);
        traitDetail.push(res.data.body);
      }
      return traitDetail;
    },

    async addCustomTrait(rec) {
      if (rec.traitsAndBehaviours) {
        let postData = {
          jobId: 0,
          traitName: rec.traitsAndBehaviours,
        };
        let trRes = [];
        try {
          const res = await this.services.post("survey/isTraitExist", postData);
          if (res.status == 200) {
            trRes = await this.addTraits(rec);
          }
        } catch (err) {
          if (err.response.status == 302) {
            let resData = err.response.data.body[0];
            await this.createTraitsAssigment(resData);
          } else {
            console.error(err);
          }
        } finally {
          this.loadingFlag = false;
        }
        return trRes;
      }
    },

    async addTraits(rec) {
      if (rec.traitsAndBehaviours) {
        let res = [];
        let postData = {
          jobId: 0,
          traitName: rec.traitsAndBehaviours,
          description: "",
          userId: this.userId,
        };
        try {
          res = await this.services.post("survey/createTrait", postData);
          if (res.status == 200) {
            let custList = res.data.body;

            await this.deactivateTraits(custList);
            await this.createTraitsAssigment(custList);
          }
        } catch (err) {
          console.error(err);
        }
        return res;
      }
    },

    async createTraitsAssigment(record) {
      let listOfTraitsJobs = [
        {
          traitId: record.id ? record.id : 0,
          isDeleted: true,
          jobId: this.jobId,
        },
      ],
        reqBody = {
          listOfTraitsJobs,
        };

      this.loadingFlag = true;

      if (listOfTraitsJobs.length > 0) {
        try {
          await this.services.post("admin/traitsJobsAssigment", reqBody);
        } catch (err) {
          console.error(err);
        } finally {
          this.loadingFlag = false;
        }
      }
    },

    setup4Datastructure() {
      this.likehoodFlag = false;
      let setup4Data = this.$store.state.setup4Data;
      let likehood = [];

      setup4Data.forEach((record, index) => {
        if (record.isClicked) {
          this.likehoodFlag = true;
          likehood.push({
            likehoodId: record.id,
          });
        }
      });

      return likehood;
    },
    closedMinPop(val) {
      this.minDataError = val;
    },
    closedMaxPop(val) {
      this.maxDataError = val;
    },
    getCountForSetup2(storeData) {
      let count = 0;
      let setupData = storeData;

      setupData.traitsList.forEach((record, index) => {
        if (record.selectedTraitsFlag) {
          count = count + 1;
        }
      });
      if (setupData.customTraitsList && setupData.customTraitsList.length > 0) {
        setupData.customTraitsList.forEach((record, index) => {
          if (record.selectedTraitsFlag) {
            count = count + 1;
          }
        });
      }
      return count;
    },
    changedStatus() {
      this.submitClicked = false;
    },
    next() {
      if (this.current == 0) {
        this.setup1Flag = false;
        let setup1result = this.$store.state.setup1Data.competancyLabels;
        for (let i = 0; i < setup1result.length; i++) {
          if (
            !(
              setup1result[i].selected_0 ||
              setup1result[i].selected_1 ||
              setup1result[i].selected_2 ||
              setup1result[i].selected_3 ||
              setup1result[i].selected_4 ||
              setup1result[i].selected_5
            )
          ) {
            setup1result[i].showError = true;
            this.setup1Flag = true;
          }
        }
        if (!this.setup1Flag) {
          this.current++;
        }
      } else if (this.current == 1) {
        let count2Setup = this.getCountForSetup2(this.$store.state.setup2Data);
        if (count2Setup == 0) {
          this.minDataError = true;
          this.gaEventTrack(
            "does well",
            "respondsurvey_selecttoofew",
            "ERROR MESSAGE: Responding to survey pop up (not enough traits)"
          );
        } else if (count2Setup > 5) {
          this.maxDataError = true;
          this.gaEventTrack(
            "does well",
            "respondsurvey_selecttoomany",
            "ERROR MESSAGE: Responding to survey pop up (too many traits selected)"
          );
        } else {
          this.current++;
        }
      } else if (this.current == 2) {
        let count2Setup = this.getCountForSetup2(this.$store.state.setup3Data);
        if (count2Setup == 0) {
          this.minDataError = true;
          this.gaEventTrack(
            "need work",
            "respondsurvey_selecttoofew",
            "ERROR MESSAGE: Responding to survey pop up (not enough traits)"
          );
        } else if (count2Setup > 5) {
          this.maxDataError = true;
          this.gaEventTrack(
            "need work",
            "respondsurvey_selecttoomany",
            "ERROR MESSAGE: Responding to survey pop up (too many traits selected)"
          );
        } else {
          this.current++;
        }
      }
    },
    prev() {
      if (this.current == 3) {
        this.submitClicked = false;
      }
      this.current--;
    },

    gaEventTrack(data, action, category) {
      this.event(action, {
        event_category: category,
        event_label: data,
        value: 1,
      });
    },
    onChange(current) {
      if (this.current == 3 && (current == 1 || current == 2 || current == 0)) {
        this.submitClicked = false;
      }

      if (this.current == 0 && (current == 1 || current == 2 || current == 3)) {
        this.setup1Flag = false;
        let setup1result = this.$store.state.setup1Data.competancyLabels;
        for (let i = 0; i < setup1result.length; i++) {
          if (
            !(
              setup1result[i].selected_0 ||
              setup1result[i].selected_1 ||
              setup1result[i].selected_2 ||
              setup1result[i].selected_3 ||
              setup1result[i].selected_4 ||
              setup1result[i].selected_5
            )
          ) {
            setup1result[i].showError = true;
            this.setup1Flag = true;
          }
        }
        if (!this.setup1Flag) {
          this.current = current;
        }
      } else if (this.current == 1 && (current == 2 || current == 3)) {
        let count2Setup = this.getCountForSetup2(this.$store.state.setup2Data);
        if (count2Setup == 0) {
          this.minDataError = true;
        } else if (count2Setup > 5) {
          this.maxDataError = true;
        } else {
          this.current = current;
        }
      } else if (this.current == 2 && current == 3) {
        let count2Setup = this.getCountForSetup2(this.$store.state.setup3Data);
        if (count2Setup == 0) {
          this.minDataError = true;
        } else if (count2Setup > 5) {
          this.maxDataError = true;
        } else {
          this.current = current;
        }
      } else {
        this.current = current;
      }
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .ant-steps-item-title {
    font-size: 14px !important;

    @media screen and (max-width: 900px) {
      font-size: 13px !important;
    }
  }

  .last-step {
    .ant-steps-item-content {
      width: 170px !important;
      margin-right: 5px;
    }
  }
}

.ant-steps {
  @media screen and (max-width: 900px) {
    margin-top: 50px;
  }
}

.step-wrapper {
  position: relative;
  width: 85%;
  margin: auto;
  background-color: #fff;
  padding: 7% 5% 4% 5%;
  margin-top: 3%;
  box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.2);
  border-radius: 10px;

  @media screen and (max-width: 1024px) {
    width: 98%;
  }
}

.steps-content {
  margin-top: 16px;
  text-align: center;
  padding-top: 40px;
}

.steps-action {
  display: inline-block;
  width: 100%;

  button {
    width: auto !important;
    float: right;
    margin-left: 10px;
    margin-top: 0px !important;
  }
}

.capsule-wrapper {
  position: absolute;
  width: 50%;
  top: -25px;
  left: 25%;
  z-index: 99;
  margin: 0 auto 10px;
  padding: 0 5px;
  background: linear-gradient(89.62deg,
      #6454f0 14.09%,
      rgba(100, 84, 240, 0.87) 38.41%,
      rgba(113, 84, 240, 0.53) 60.76%,
      rgba(58, 130, 241, 0.6) 72.11%,
      rgba(40, 149, 242, 0.495932) 80.38%,
      rgba(0, 194, 243, 0.35) 92.24%),
    #00c2f3;
  border-radius: 28px;

  @media screen and (max-width: 600px) {
    width: 70%;
    left: 15%;
    top: -10px;
  }

  &>* {
    display: block;
    padding: 2.5% 0px;
    text-align: center;
    color: #fff;
  }
}

::v-deep {
  .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot::after {
    display: block;
    background: #fff !important;
    width: 6px;
    height: 6px;
    top: 37%;
    left: 36%;
    border-radius: 50%;
  }

  .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon-dot::after {
    left: 10px;
    top: 7px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .ant-steps-item-icon,
  .ant-steps-item-tail {
    @media screen and (max-width: 800px) {
      margin-left: 30px;
    }
  }

  .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background: #6454f0;
  }

  .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot,
  .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    position: relative;
    background: #6454f0 !important;
    width: 25px;
    height: 25px;
    top: -8px;
    left: -8px;
    border-radius: 50%;
    z-index: 1;
  }
}

.float-r button {
  display: block;
  width: 150px !important;
}

.grey-btn {
  position: relative;
  background-color: #e5e5e5;
  border: none;
  height: 45px;
  margin-top: 10px !important;
  color: #363644;
  font-size: 1.125rem;
  font-weight: 700;

  &:hover {
    background-color: #bfc3d6 !important;
  }
}

::v-deep {
  @media screen and (max-width: 920px) {
    .ant-steps-item-content {
      width: 130px !important;
    }

    .last-step {
      .ant-steps-item-content {
        width: 130px !important;
        margin-right: 0px;
      }
    }
  }

  @media screen and (max-width: 720px) {
    .ant-steps-item-content {
      width: 70px !important;
    }

    .last-step {
      .ant-steps-item-content {
        width: 90px !important;
      }
    }
  }
}

.submit-btn-wrapper {
  .error-txt {
    position: absolute;
    font-size: 14px;
    bottom: 65px;
    right: 21%;
  }
}

.bold {
  font-weight: 700;
}
</style>
