<template>
  <div class="bg-color-grey">
    <div class="center-div center-div-popup center white-bg-css">
      <a-row :gutter="24" v-if="popHeading" class="pdb-20">
        <a-col :span="24" class="wlcm popup-heading">
          <div v-html="popHeading" />
        </a-col>
      </a-row>
      <a-row :gutter="24" v-if="popEmail" class="pdb-20 mt-25 sm-txt popup-txt">
        <a-col :span="24">
          <div class="pdh-7" v-html="popEmail" />
        </a-col>
      </a-row>
      <a-form :form="form" @submit="handleSubmit" class="mt-25 padh-15">
        <a-row :gutter="24" v-if="popEmailBox || popEmailPlaceholder">
          <a-col :span="24" v-if="!textMsg">
            <a-form-item class="float-label" :class="customErrorMsg && dataFill ? 'has-error' : null">
              <label :class="focusData.focusDataEmail || formData.email
                  ? 'label label-float'
                  : 'label'
                ">{{ popEmailPlaceholder }}</label>
              <a-input @blur="setFocus(false, 'focusDataEmail')" @focus="setFocus(true, 'focusDataEmail')" v-decorator="[
                'email',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter a valid email address',
                    },
                    {
                      type: 'email',
                      message: 'Please enter a valid email address',
                    },
                    {
                      validator: checkExistEmail,
                    },
                  ],
                  validateTrigger: 'blur',
                },
              ]" @change="onChange($event.target.value, 'email')" />
            </a-form-item>
          </a-col>
          <a-col :span="24" v-if="textMsg">
            <a-form-item class="float-label" :class="optionalErrorType && dataFill ? 'has-error' : null">
              <label :class="focusData.focusDataEmail || formData.email
                  ? 'label label-float'
                  : 'label'
                ">{{ popEmailPlaceholder }}</label>
              <a-input @blur="setFocus(false, 'focusDataEmail')" @focus="setFocus(true, 'focusDataEmail')" v-decorator="[
                'code',
                {
                  rules: [{ required: true, message: textMsg }],
                },
                {
                  type: optionalErrorType,
                  message: textMsg,
                },
              ]" @change="onChange($event.target.value, 'email')" />
            </a-form-item>
          </a-col>
          <a-col :span="24" v-if="optionalErrorType && dataFill" class="red ant-form-explain">
            <span>{{ textMsg }}</span>
          </a-col>
          <a-col :span="24" v-if="customErrorMsg && dataFill" class="red ant-form-explain" style="line-height: 5px">
            <span>{{ customErrorMsg }}</span>
          </a-col>
        </a-row>
        <a-row :gutter="24" v-if="popPasswordBox">
          <a-col :span="24">
            <a-form-item class="float-label">
              <a-popover placement="right" trigger="click" :overlay-style="{
                'font-size': '12px !important',
                'border-radius': '10px !important',
              }">
                <template slot="content" :overlay-style="{
                  'border-radius': '10px !important',
                }">
                  <p><strong>Password must:</strong></p>
                  <p>
                    • Be a minimum of 8 characters
                    <span v-if="passlength">
                      <img src="../assets/tick.png" style="width: 18px" alt="" class="success-unsuccess-icons" />
                    </span>
                    <span v-else>
                      <img src="../assets/cross.png" style="width: 18px" alt="" />
                    </span>
                  </p>
                  <p>
                    • Have a mixture of letters and numbers
                    <span v-if="letterNumber">
                      <img src="../assets/tick.png" alt="" style="width: 18px" />
                    </span>
                    <span v-else>
                      <img src="../assets/cross.png" alt="" style="width: 18px" /></span>
                  </p>
                  <p>
                    • Include at least one special character<br />&nbsp;&nbsp;(e.g.
                    ! @ # ?)
                    <span v-if="specialCharacter">
                      <img src="../assets/tick.png" style="width: 18px" alt="" />
                    </span>
                    <span v-else>
                      <img src="../assets/cross.png" style="width: 18px" alt="" /></span>
                  </p>
                </template>

                <label :class="focusData.focusDataPassword || formData.password
                    ? 'label label-float'
                    : 'label'
                  ">{{ passwordPlaceholder }}</label>
                <a-input-password @blur="setFocus(false, 'focusDataPassword')"
                  @focus="setFocus(true, 'focusDataPassword')" v-decorator="[
                    'password',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter a valid password',
                        },
                        {
                          validator: validateToNextPassword,
                        },
                        {
                          pattern: passwordRegex,
                          message: 'Please enter a valid password',
                        },
                      ],
                    },
                  ]" @change="onChange($event.target.value, 'password')" />
              </a-popover>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24" v-if="popPasswordBox">
          <a-col :span="24">
            <a-form-item class="float-label">
              <label :class="focusData.confirmPassword || formData.confirmPassword
                  ? 'label label-float'
                  : 'label'
                ">{{ confirmPasswordPlaceholder }}</label>
              <a-input-password @blur="setFocus(false, 'confirmPassword')" @focus="setFocus(true, 'confirmPassword')"
                v-decorator="[
                  'confirmPassword',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Passwords don’t match – please try again',
                      },
                      {
                        validator: compareToFirstPassword,
                      },
                    ],
                  },
                ]" @change="onChange($event.target.value, 'confirmPassword')" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24" class="pdb-10 mt-2" v-if="codeLinkText">
          <a-col :span="6"> </a-col>
          <a-col :span="18">
            <div class="blue-txt-sm underline" @click="sendCodeClick">
              {{ codeLinkText }}
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="24" class="pdb-20 mt-2" v-if="codeSuccessText">
          <a-col :span="6"> </a-col>
          <a-col :span="18">
            <div class="blue-txt-sm" style="font-size: 18px">
              <a-icon type="check-circle" theme="filled" class="font-18" /><span class="marl-5">{{ codeSuccessText
              }}</span>
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="24" class="mar-auto" v-if="popButtonText">
          <a-col :span="12" :lg="12" :xs="24" :sm="12" class="mt-25">
            <a-form-item>
              <a-button type="primary" html-type="submit">
                {{ popButtonText }}
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </div>
</template>
<script>
export default {
  name: "CommonPopUp",
  props: {
    popHeading: {
      type: String,
      default: "",
    },
    popEmail: {
      type: String,
      default: "",
    },
    popEmailBox: {
      type: Boolean,
      default: false,
    },
    popEmailPlaceholder: {
      type: String,
      default: "",
    },
    textMsg: {
      type: String,
      default: "",
    },
    codeLink: {
      type: String,
      default: "",
    },
    codeLinkText: {
      type: String,
      default: "",
    },
    popButtonText: {
      type: String,
      default: "",
    },
    codeSuccessText: {
      type: String,
      default: "",
    },
    popPasswordBox: {
      type: Boolean,
      default: false,
    },
    passwordPlaceholder: {
      type: String,
      default: "",
    },
    confirmPasswordPlaceholder: {
      type: String,
      default: "",
    },
    optionalErrorType: {
      type: Boolean,
      default: false,
    },
    customErrorMsg: {
      type: String,
      default: "",
    },
  },
  data() {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\\\])(?=.{8,})/;
    const lengthRegex = /^[a-zA-Z0-9]{8,}$/;
    const letternumRegex = /^(?=.*[a-z])(?=.*[0-9])/;
    const specialCharRegex = /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\\\]).*$/;
    return {
      passlength: false,
      letterNumber: false,
      specialCharacter: false,
      lengthRegex,
      passwordRegex,
      specialCharRegex,
      letternumRegex,
      formLayout: "horizontal",
      emailErrormsg: false,
      dataFill: true,
      formData: {},
      focusData: {
        focusDataEmail: false,
        focusDataPassword: false,
        confirmPassword: false,
      },
      form: this.$form.createForm(this, { name: "coordinated" }),
    };
  },
  watch: {
    customErrorMsg(newVal) {
      if (newVal) {
        this.dataFill = true;
      }
    },
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$emit("click", this.formData);
        }
      });
    },
    checkExistEmail(rule, value, callback) {
      const form = this.form;
      let emailValidate = false;
      if (value && emailValidate) {
        callback("");
      } else {
        callback();
      }
      this.emailErrormsg = form.getFieldError("email");
    },
    onChange(val, key) {
      this.formData[key] = val;
      this.dataFill = false;
      this.form.setFieldsValue({
        [key]: val,
      });
      if (this.optionalErrorType && key == "email") {
        this.dataFill = val ? true : false;
      } else if (this.customErrorMsg && key == "email") {
        this.dataFill = false;
      }
    },
    sendCodeClick() {
      this.$emit("sendCode", "val");
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("password")) {
        callback("Passwords don’t match – please try again");
      } else {
        callback();
      }
    },
    setFocus(val, key) {
      this.focusData[key] = val;
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      if (value && this.confirmDirty) {
        form.validateFields(["confirm"], { force: true });
      }

      if (value) {
        this.passlength =
          this.lengthRegex.test(value) || this.passwordRegex.test(value);
        this.letterNumber = this.letternumRegex.test(value);
        this.specialCharacter = this.specialCharRegex.test(value);
      } else {
        this.passlength = false;
        this.letterNumber = false;
        this.specialCharacter = false;
      }

      callback();
    },
  },
};
</script>
<style scoped lang="scss">
.bg-color-grey {
  padding: 20px 0% !important;
}

::v-deep {
  .ant-form-item {
    margin-bottom: 0px !important;
  }

  .white-bg-css .has-error .ant-form-explain,
  .white-bg-css .has-error .ant-form-split {
    text-align: left !important;
  }

  .center-div {
    width: 650px;
    padding: 6% 8%;
  }

  .blue-txt-sm.underline {
    font-size: 18px !important;
  }

  .white-bg-css .ant-input,
  .white-bg-css .ant-select-selection {
    padding-top: 14px !important;
  }

  .ant-form-explain {
    text-align: left !important;
  }

  @media screen and (max-width: 760px) {
    .center-div {
      min-width: 520px;
    }
  }

  @media screen and (max-width: 590px) {
    .popup-txt {
      word-break: break-word !important;
    }

    .center-div {
      min-width: auto;
    }
  }
}
</style>