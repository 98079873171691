var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"newsletter"},[_c('h2',{staticClass:"wow fadeInUp"},[_vm._v("Sign up and get started with using Gestaltra")]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{class:_vm.emailValidate ? 'has-error' : null},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          {
            rules: [
              {
                type: 'email',
                message: 'Please enter a valid email address',
              },
              {
                required: true,
                message: 'Please enter a valid email address',
              },

              {
                validator: _vm.checkExistEmail,
              } ],
            validateTrigger: 'blur',
          } ]),expression:"[\n          'email',\n          {\n            rules: [\n              {\n                type: 'email',\n                message: 'Please enter a valid email address',\n              },\n              {\n                required: true,\n                message: 'Please enter a valid email address',\n              },\n\n              {\n                validator: checkExistEmail,\n              },\n            ],\n            validateTrigger: 'blur',\n          },\n        ]"}],staticClass:"signup-class",staticStyle:{"width":"100%","height":"45px !important"},attrs:{"placeholder":"name@company.com"},on:{"change":function($event){return _vm.onChange($event.target.value, 'email')}}}),(_vm.emailValidate)?_c('div',{staticClass:"ant-form-explain"},[_vm._v(" Email already exists ")]):_vm._e()],1),_c('button',{staticClass:"button--reversed",attrs:{"html-type":"submit"}},[_vm._v(" Sign up ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }