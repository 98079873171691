<template>
  <div>
    <div>
      <a-row class="mt-2">
        <a-col style="text-align: center" :span="24">
          <span class="title-txt-css"
            >Comparing
            {{ reviewersDetails.firstName }}&nbsp;{{
              reviewersDetails.lastName
            }} to others across the business, how strongly do they embody the business values and positively contribute to the organisation's culture?</span
          >
        </a-col>
      </a-row>
      <a-row
        :gutter="24"
        class="smile-css"
        :style="
          !showError && submitClicked
            ? 'border: red 1px solid; border-radius: 5px'
            : null
        "
      >
        <a-col
          span="4"
          v-for="(record, index) in dataList"
          :key="index"
          class="col-section-wrapper"
          :class="record.isClicked ? 'selected-col' : 'not-selected-col'"
          @click="selectedImage(record, index)"
        >
          <div class="icon-wrapper">
            <div>
              <em :class="record.img" />
            </div>
          </div>
          <div class="description-wrapper">
            <span>{{ record.description }}</span>
          </div>
        </a-col>
      </a-row>
    </div>
    <confirm-pop-up
      :is-show="loadingFlag"
      :loading="true"
      header-text="Loading"
      contain-text="Hang tight..."
    />
  </div>
</template>
<script>
import services from "../../services/services";
import ConfirmPopUp from "@/components/ConfirmPopUp.vue";

export default {
  name: "SurveyStep4",
  components: { ConfirmPopUp },
  props: {
    setup4Props: {
      type: Boolean,
      default: false,
    },
    submitClicked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingFlag: false,
      reviewersDetails: {},
      iconList: [
        "icon-icon-sad",
        "icon-icon-sad-more",
        "icon-icon-blank",
        "icon-icon-smiley",
        "icon-icon-smiley-more",
      ],
      services,
      dataList: [],
    };
  },
  computed: {
    showError() {
      return this.setup4Props;
    },
  },
  created() {
    this.reviewersDetails = this.$store.state.reviewersDetails;
    //this.loadingFlag = true;
    this.services
      .get("survey/listAllLikeHoods")
      .then((res) => {
        this.loadingFlag = false;
        this.dataList = res.data.body;
        this.$store.state.setup4Data = this.dataList;
      })
      .catch((err) => {
        //this.errorPopupFlag = true//;
        this.loadingFlag = false;
      });
  },
  methods: {
    selectedImage(record, index) {
      this.$emit("changedStatus", false);

      this.dataList.forEach((item, i) => {
        item.isClicked = false;
      });
      record.isClicked = !record.isClicked;
      this.dataList.splice(index, 1, record);
      this.$store.state.setup4Data = this.dataList;
    },
  },
};
</script>
<style lang="scss"scoped>
.title-txt-css {
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 200%;
  text-align: center;
  color: #262626;
}
.grey-sm-css {
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #bfc3d6;
}
.smile-css {
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 20px !important;
  margin-bottom: 50px !important;
  padding: 20px 5px;

  .col-section-wrapper.ant-col.ant-col-4 {
    cursor: pointer;
    transition: 0.3s all;
    width: 20% !important;
    padding: 0px 15px !important;

    .icon-wrapper {
      background: #797994;
      width: 70px;
      height: 70px;
      z-index: 1;
      border-radius: 50%;
      margin: auto;
      div {
        margin: auto;
        em {
          position: relative;
          font-size: 80px;
          color: #bfc3d6;
          left: -4px;
          top: -4px;
        }
      }
    }
    .description-wrapper {
      margin: auto;
      margin-top: 20px;
      color: #bfc3d6;
      padding: 0px 6%;
      font-size: 14px;
    }
  }
  .col-section-wrapper.ant-col.ant-col-4.not-selected-col:hover {
    .icon-wrapper {
      background: #363644;
      div {
        em {
          color: #7fd9f0;
        }
      }
    }
    .description-wrapper {
      color: #262626;
    }
  }
}
.col-section-wrapper.ant-col.ant-col-4.selected-col:hover {
  .icon-wrapper {
    background: #363644;
    div {
      em {
        color: #00c2f3;
      }
    }
  }
  .description-wrapper {
    color: #262626;
  }
}
.col-section-wrapper.ant-col.ant-col-4.selected-col {
  .icon-wrapper {
    background: #363644;
    div {
      em {
        color: #00c2f3;
      }
    }
  }
  .description-wrapper {
    color: #262626;
    font-weight: 700;
  }
}
@media screen and (max-width: 720px) {
  .smile-css .col-section-wrapper.ant-col.ant-col-4 {
    width: 100% !important;
    margin-bottom: 40px;
  }
}
</style>