<template>
  <div class="step1-wrapper">
    <div class="content-wrapper">
      <p class="heading-wrapper">
        In your experience, rate how well you feel
        {{ reviewersDetails.firstName }}&nbsp;{{ reviewersDetails.lastName }}
        demonstrates the following general competencies.
      </p>
      <p class="text-wrapper font-clr-black">
        Please select a rating for each competency. Hover over the ‘i’ next to
        each item for more detail.
      </p>
    </div>
    <div>
      <a-table
        class="general-table-wrapper"
        :pagination="false"
        :columns="columnData"
        :data-source="teamMemberList"
        :rowClassName="
          (record, index) =>
            setup1Flag && record.showError ? 'border-red' : null
        "
        width="100%"
      >
        <div slot="competancy" slot-scope="text, record">
          <span>{{ record.competancy }}</span>
        </div>
        <span slot="info" slot-scope="text, record">
          <a-popover
            placement="right"
            :overlay-style="{
              width: '300px',
              'word-break': 'normal',
            }"
          >
            <template slot="content">
              <span class="bold">{{ record.competancy }}:</span>
              <span>{{ record.description }}</span>
            </template>
            <a-icon type="info-circle" /></a-popover
        ></span>
        <span
          slot="index_2"
          slot-scope="text, record, index"
          class="circle-after"
          :class="record['selected_0'] ? 'active' : null"
          data-attr="0"
          @click="selectedOption(record, 0, index)"
        />
        <span
          slot="index_3"
          slot-scope="text, record, index"
          class="circle-after"
          :class="record['selected_1'] ? 'active' : null"
          data-attr="1"
          @click="selectedOption(record, 1, index)"
        />
        <span
          slot="index_4"
          slot-scope="text, record, index"
          class="circle-after"
          :class="record['selected_2'] ? 'active' : null"
          data-attr="2"
          @click="selectedOption(record, 2, index)"
        />
        <span
          slot="index_5"
          slot-scope="text, record, index"
          class="circle-after"
          :class="record['selected_3'] ? 'active' : null"
          data-attr="3"
          @click="selectedOption(record, 3, index)"
        />
        <span
          slot="index_6"
          slot-scope="text, record, index"
          class="circle-after"
          :class="record['selected_4'] ? 'active' : null"
          data-attr="4"
          @click="selectedOption(record, 4, index)"
        />
        <span
          slot="index_7"
          slot-scope="text, record, index"
          class="circle-after"
          :class="record['selected_5'] ? 'active' : null"
          data-attr="5"
          @click="selectedOption(record, 5, index)"
        />
      </a-table>
      <div v-if="setup1Flag">
        <p class="red error-text">
          Please provide a response for each competency
        </p>
      </div>
    </div>
    <confirm-pop-up
      :is-show="loadingFlag"
      :loading="true"
      header-text="Loading"
      contain-text="Hang tight..."
    />
  </div>
</template>
<script>
import services from "../../services/services";
import ConfirmPopUp from "@/components/ConfirmPopUp.vue";
export default {
  name: "SurveyStep1",
  components: { ConfirmPopUp },
  props: {
    setup1Props: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reviewersDetails: {},
      services,
      columnData: [],
      errorMsgShow: false,
      lastIndex: false,
      teamMemberList: [],
      loadingFlag: false,
      errMsgHide: false,
      seletcedFlag: false,
      surveyId: 0,
    };
  },
  created() {
    this.surveyId = this.$store.state.surveyId;
    this.reviewersDetails = this.$store.state.reviewersDetails;
    this.getGeneralCompetancyLabels();
  },
  mounted() {
    let dynamicWidth = document.querySelector(".steps-content").clientWidth;
    dynamicWidth = dynamicWidth + 15;
  },
  computed: {
    setup1Flag() {
      if (this.setup1Props) {
        for (let i = 0; i < this.teamMemberList.length; i++) {
          if (
            !(
              this.teamMemberList[i].selected_0 ||
              this.teamMemberList[i].selected_1 ||
              this.teamMemberList[i].selected_2 ||
              this.teamMemberList[i].selected_3 ||
              this.teamMemberList[i].selected_4 ||
              this.teamMemberList[i].selected_5
            )
          ) {
            this.teamMemberList[i].showError = true;
            this.$store.state.setup1Data.competancyLabels = this.teamMemberList;
            return true;
          }
        }
        this.$store.state.setup1Data.competancyLabels = this.teamMemberList;
        return false;
      } else {
        this.$store.state.setup1Data.competancyLabels = this.teamMemberList;
        return this.setup1Props;
      }
    },
  },
  methods: {
    getGeneralCompetancyLabels() {
      this.services
        .get(`survey/listGeneralCompetancyLabels?surveyId=${this.surveyId}`)
        .then((res) => {
          this.teamMemberList = res.data.body;
          this.$store.state.setup1Data = {
            competancyLabels: this.teamMemberList,
          };
          this.getGeneralCompetancyResponseLabels();
        })
        .catch((err) => {
          //this.errorPopupFlag = true//;
        });
    },
    getGeneralCompetancyResponseLabels() {
      this.services
        .get(
          `survey/listGeneralCompetancyResponseLabels?surveyId=${this.surveyId}`
        )
        .then((res) => {
          if (res) {
            let columnData = res.data.body;
            columnData.splice(0, 0, {
              title: "",
              dataIndex: "competancy",
              scopedSlots: { customRender: "competancy" },
            });
            columnData.splice(1, 0, {
              title: "",
              dataIndex: "info",
              scopedSlots: { customRender: "info" },
            });

            this.columnData = columnData;
            this.columnData.forEach((element, index) => {
              let width = 100 / this.columnData.length;
              if (index == 0) {
                width = width + 5;
                element.width = width + "%";
                this.columnData.splice(index, 1, element);
              } else if (index == 1) {
                width = width - 10;
                element.width = width + "%";
                this.columnData.splice(index, 1, element);
              } else {
                // if (index == 2) {
                //   width = width + 5;
                // } else if (index == 4) {
                //   width = width - 5;
                // }
                element.width = width + "%";
                element.title = element.description;
                element.dataIndex = "index_" + index;
                element.scopedSlots = { customRender: element.dataIndex };
                this.columnData.splice(index, 1, element);
              }
            });
            this.$store.state.setup1Data.competancyResponseLabels =
              this.columnData;
          }
          this.loadingFlag = false;
        })
        .catch((err) => {
          //this.errorPopupFlag = true//;
          this.loadingFlag = false;
        });
    },
    selectedOption(record, val, index) {
      this.seletcedFlag = false;

      let selected = "selected_" + val;
      record.showError = false;
      record["selected_0"] = false;
      record["selected_1"] = false;
      record["selected_2"] = false;
      record["selected_3"] = false;
      record["selected_4"] = false;
      record["selected_5"] = false;
      record[selected] = !record[selected];

      this.teamMemberList.splice(index, 1, record);
      this.$store.state.setup1Data.competancyLabels = this.teamMemberList;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .ant-table-thead > tr > th {
    padding: 16px 8px !important;
  }
  .ant-popover-inner {
    width: 300px !important;
    word-break: normal !important;
  }
  .ant-popover-inner-content {
    padding: 5px 16px !important;
    p {
      margin-bottom: 0px !important;
    }
  }
  .ant-table-thead
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
  .ant-table-tbody
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
  .ant-table-thead
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: #edeef5 !important;
  }
  .general-table-wrapper {
    width: 100%;

    tbody:last-child {
      height: 300px !important;
      display: block !important;
      width: 100%;

      overflow: hidden;
      overflow-y: auto;
      padding-right: 15px;
    }
    margin: 35px 0px;
    // table tbody {

    // }
    .ant-table-tbody > tr > td {
      border-bottom: none !important;
    }
    table thead,
    table tbody tr {
      display: table !important;
      width: 100%;
      table-layout: fixed;
    }
  }
  @media screen and (max-width: 600px) {
    .general-table-wrapper {
      .ant-table-thead > tr > th .ant-table-header-column {
        width: 50px;
      }
      overflow: hidden;
      overflow-x: scroll;
    }
  }
  .border-red,
  .ant-table-tbody > tr.border-red {
    border: 1px solid red !important;
  }
  .ant-table-tbody > tr,
  .ant-table-tbody > tr:hover {
    border: 1px solid transparent !important;
    background: #edeef5 !important;
    margin-bottom: 14px !important;
    border-radius: 7px !important;
  }

  .ant-table-thead > tr {
    > th {
      border-spacing: 0px !important;
      background: #fff !important;
    }
  }
  .ant-table-tbody > tr > td:first-child {
    border-radius: 5px 0px 0px 5px !important;
  }

  .ant-table-tbody > tr > td:last-child {
    border-radius: 0px 5px 5px 0px !important;
  }
  // .ant-table-tbody > tr.border-red > td {
  //   border-top: 1px solid red !important;
  //   border-bottom: 1px solid red !important;
  // }
  // .ant-table-tbody > tr.border-red > td:first-child {
  //   border-left: 1px solid red;
  // }

  // .ant-table-tbody > tr.border-red > td:last-child {
  //   border-right: 1px solid red;
  // }

  .ant-table-thead > tr > th {
    border-bottom: none;
    text-align: center !important;
  }
  .ant-table {
    color: #262626;

    table {
      text-align: center !important;
      border-spacing: 0px 10px !important;
      .ant-table-tbody > tr > td:first-child {
        text-align: left !important;
        font-weight: 700;
      }
    }
  }
}
.step1-wrapper {
  text-align: left !important;
  .heading-wrapper {
    color: #262626;
    font-weight: 700;
    font-size: 16px;
  }
}
.ant-list-sm .ant-list-item {
  padding-top: 12px;
  padding-bottom: 12px;
}
.list-item-css {
  background: #edeef5;
  border-radius: 5px;
  margin-bottom: 14px !important;
}

.ant-spin-container {
  height: 300px !important;
  overflow: hidden !important;
  overflow-y: scroll !important;
}
.name-css {
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #363644;
}
.email-css {
  font-family: "Inter", fantasy !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #363644;
}
.list-wrapper {
  text-align: center;
  padding: 5% 1.5% 3% 1.5%;
}

.circle-after::after {
  content: attr(data-attr);
  width: 20px;
  height: 20px;
  padding: 2px 6px;
  border: 1px solid #363644;
  border-radius: 50%;
  box-sizing: border-box;
  transform: matrix(1, 0, 0, -1, 0, 0);
  cursor: pointer;
}
.circle-after:hover::after {
  background: rgba(100, 84, 240, 0.55);
  border: 1px solid #6454f0;
  color: #fff;
}
.circle-after.active::after {
  background-color: #6454f0 !important;
  color: #fff !important;
  border: 1px solid #6454f0;
}

.error-text {
  position: absolute;
  bottom: 5%;
  right: 22%;
}
.list-error {
  border: 1px solid red;
}
.bold {
  font-weight: 700;
  display: block !important;
}
</style>