import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
  state() {
    return {
      forgotFlag: false,
      signUpDataObject: {},
      signUpFlag: false,
      apiData: [],
      userId: '',
      setup1Data: [],
      setup2Data: {},
      setup3Data: {},
      setup4Data: [],
      reviewersDetails:{},
      adminConsole: [],
    }
  },
  mutations: {
    updateProperty: (state, apiData) => {
      state.apiData = apiData;
    },
  },
  actions: {
    usersCreate: ({ commit }, url) => {
      axios.get(url)
        .then(response => commit('updateProperty', response.data.body))
        // eslint-disable-next-line
        .catch(error => console.error(error));
    },
  },
  modules: {
  }
})
